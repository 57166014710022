/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useList } from 'react-use';
import { useCallback, useEffect, useMemo } from 'react';
import { ErrorResolver, ErrorResolverResult } from './resolvers/types';

export interface UseErrorsStorageProps<S, E extends Error = Error> {
  // Internal state that can be accessed by resolvers to extract certain errors
  state: S;
  // Resolvers access state to determine if there are any errors or not
  resolvers: ErrorResolver<S, E>[];
}

const ResolvedBySymbol = Symbol('ResolvedBy');

type ErrorFromResolver<E extends Error = Error> = ErrorResolverResult<E> & {
  [ResolvedBySymbol]: string;
};

export function useErrorsStorage<E extends Error = Error, S = unknown>({
  state,
  resolvers,
}: UseErrorsStorageProps<S, E>) {
  const [errors, methods] = useList<ErrorResolverResult<E>>();
  const { filter, push, set } = methods;

  const removeErrorByName = useCallback(
    (name: string) => {
      filter((result) => !result.errors.some((e) => e.name === name));
    },
    [filter]
  );

  useEffect(() => {
    let newErrors = [...errors];

    resolvers.forEach((resolver) => {
      // First, remove all errors that were resolved by this resolver
      newErrors = newErrors.filter(
        (error) =>
          (error as ErrorFromResolver<E>)[ResolvedBySymbol] !== resolver.name
      );

      // Get new errors
      const resolverErrors = resolver.getErrors(state as S);

      if (resolverErrors?.errors?.length) {
        (resolverErrors as ErrorFromResolver<E>)[ResolvedBySymbol] =
          resolver.name;

        newErrors.push(resolverErrors);

        //push(resolverErrors);
      }
    });

    set(newErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, state, push, resolvers]);

  return useMemo(
    () => [
      { errors, hasError: Boolean(errors.length) },
      {
        ...methods,
        removeErrorByName,
      },
    ],
    [errors, methods, removeErrorByName]
  );
}
