/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { MutableRefObject, ReactNode, useRef, useState } from 'react';
import { ChakraProps } from '@chakra-ui/system';
import { Box, BoxProps, Center, HStack, VStack } from '@chakra-ui/react';
import { useResizeScrollReset } from '@time-neko/frontend/resize-scroll-reset';
import { SwitchTransition } from 'react-transition-group';
import { Button, ButtonProps, Frame } from '../../atoms';
import { WebTransition } from '@time-neko/frontend/web-transition';
import { Transition } from '@time-neko/frontend/providers/theme';

export interface TabParams {
  title: ReactNode;
  key: string;
  content: ReactNode;
  buttonProps?: Omit<ButtonProps, 'children'>;
  contentProps?: BoxProps;
}

export interface TabsProps extends ChakraProps {
  tabs: TabParams[];
  onChange?: (index: number) => void;
  afterTabButtonsAddon?: ReactNode;
  footer?: ReactNode;
}

export function Tabs({
  tabs,
  onChange,
  afterTabButtonsAddon,
  footer,
  ...props
}: TabsProps) {
  const tabsRef = useRef<HTMLDivElement>();

  const [activeIndex, setActiveIndex] = useState(0);

  const { content, contentProps } = tabs[activeIndex];

  useResizeScrollReset(tabsRef);

  return (
    <VStack
      width="100%"
      flex={1}
      position="relative"
      overflow="hidden"
      {...props}
      className="tabs"
      ref={tabsRef as MutableRefObject<HTMLDivElement>}
    >
      <Center mb={3}>
        <HStack width="100%">
          {tabs.map(({ title, key, buttonProps }, index) => (
            <Button
              variant="nes-ghost"
              tabIndex={0}
              key={key}
              display="flex"
              alignItems="center"
              position="relative"
              onClick={() => {
                setActiveIndex(index);

                onChange?.(index);
              }}
              {...buttonProps}
            >
              <Frame
                zIndex={2}
                bottom={-1.5}
                left={0}
                width="100%"
                height="7px"
                sx={{
                  position: 'absolute',
                  backgroundColor:
                    index === activeIndex ? 'brand.primary' : undefined,
                }}
              />
              {title}
            </Button>
          ))}
        </HStack>
        {afterTabButtonsAddon}
      </Center>
      <Box p={1} flex={1} width="100%" overflow="hidden">
        <SwitchTransition>
          <WebTransition<HTMLDivElement>
            transition={Transition.FadeIn}
            key={activeIndex}
            timeout={{
              appear: 500,
              exit: 0,
              enter: 0,
            }}
          >
            {(ref) => (
              <Box
                ref={ref}
                height="100%"
                display="flex"
                flexDirection="column"
                className="tab-panel"
                {...contentProps}
              >
                {content}
              </Box>
            )}
          </WebTransition>
        </SwitchTransition>
      </Box>
      {footer}
    </VStack>
  );
}
