/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { useCallback, useEffect, useRef, useState } from 'react';
import { Order } from '@time-neko/shared/common-types';
import { Task, tasksSchema, TaskState } from '@time-neko/shared/domain/tasks';
import create from 'zustand';
import { OperationSchemaOperations } from '@musubi/core';
import { frontendTasksSchema } from '@time-neko/frontend/domain/tasks/schema';
import { useOperationErrorToast } from '@time-neko/frontend/toast';

export interface TasksHookProps {
  defaultOrder?: Order<Task>;
}

export const useTasksListState = create<{
  state: TaskState;
  setState: (state: TaskState) => void;
}>((set) => ({
  state: TaskState.Todo,
  setState: (state) => {
    set({ state });
  },
}));

export function useTasksList({ defaultOrder }: TasksHookProps = {}) {
  const updateTasksMutation = frontendTasksSchema.updateTasks.useCommand({
    invalidateQueries: [
      ['getTasks' as OperationSchemaOperations<typeof tasksSchema>],
      ['countTasksByState' as OperationSchemaOperations<typeof tasksSchema>],
    ],
  });

  const isDragRef = useRef(false);

  const [state, setTaskState] = useTasksListState((store) => [
    store.state,
    store.setState,
  ]);
  const [order, setOrder] = useState<
    Order<Omit<Task, 'providerMeta'>> | undefined
  >(defaultOrder);

  const getTasksQuery = frontendTasksSchema.getTasks.useQuery({
    variables: {
      state,
      order,
    },
    refetchOnWindowFocus: true,
    placeholderData: [],
    onSuccess: (data) => {
      setStoredTasks(data ?? []);
    },
  });
  useOperationErrorToast(getTasksQuery, 'Failed to fetch tasks');

  useEffect(() => {
    setStoredTasks(getTasksQuery.data ?? []);
  }, [getTasksQuery.data]);

  const [storedTasks, setStoredTasks] = useState<Task[]>(
    getTasksQuery.data ?? []
  );

  const updateTask = useCallback(
    async (task: Task) => {
      await updateTasksMutation.mutateAsync([task]);
    },
    [updateTasksMutation]
  );

  frontendTasksSchema.tasksUpdated.useEvent(async () => {
    void getTasksQuery.refetch();
  }, [getTasksQuery.refetch]);

  return {
    order,
    setOrder,
    tasks: storedTasks,
    setTasks: setStoredTasks,
    error: getTasksQuery.error,
    getTasks: getTasksQuery.refetch,
    isLoading:
      getTasksQuery.isLoading ||
      (getTasksQuery.isFetching && !getTasksQuery.isRefetching),
    state,
    setTaskState,
    didFetch: getTasksQuery.isFetchedAfterMount,
    isDragRef,
    updateTask,
  };
}
