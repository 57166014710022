/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef, useCallback } from 'react';
import { Button, ButtonProps, Text } from '../../atoms';

export interface CloseButtonProps extends ButtonProps {
  isDirty?: boolean;
  onClose: () => unknown;
  onDirty?: (handleClose: CloseButtonProps['onClose']) => unknown;
}

export const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
  ({ isDirty, onClose, onDirty, children, ...props }, ref) => {
    const handleClose = useCallback(() => {
      if (isDirty) {
        onDirty?.(onClose);

        return;
      }

      onClose();
    }, [isDirty, onClose, onDirty]);

    return (
      <Button onClick={handleClose} {...props} ref={ref}>
        <Text>{children ?? 'Close'}</Text>
      </Button>
    );
  }
);
