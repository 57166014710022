/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { documentHasFocus } from '@time-neko/frontend/common';
import { playAudio } from '../playAudio';
import { useSharedAudioElement } from './useSharedAudioElement';
import { logger } from '@time-neko/shared/logger';
import { audioFrontendSchema } from '@time-neko/frontend/domain/audio/schema';
import { AudioName, resolveAudioPath } from '../assets/audio';

interface UseAudioPlayerParams {
  requireFocus?: boolean;
  parseAudioPath?: (path: string) => string;
}

export function useAudioPlayer({
  requireFocus = false,
  parseAudioPath,
}: UseAudioPlayerParams = {}) {
  const element = useSharedAudioElement((store) => store.element);

  audioFrontendSchema.playAudioRequested.useEvent(
    async ({ payload }) => {
      if (requireFocus && !documentHasFocus(true)) {
        return;
      }

      const audioPath = await resolveAudioPath(payload.name as AudioName);

      await playAudio({
        path: parseAudioPath ? parseAudioPath(audioPath) : audioPath,
        audio: element,
      }).catch((error) => {
        logger.error(error);
      });
    },
    [requireFocus, element]
  );
}
