/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useCallback, useState } from 'react';
import { useUnmount } from 'react-use';
import { Nullable } from '@time-neko/shared/common-types';

export function useScheduler(callback: () => unknown) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timeoutId, setTimeoutId] = useState<Nullable<any>>();

  const schedule = useCallback(
    (ms: number) => {
      setTimeoutId(setTimeout(callback, ms));
    },
    [callback]
  );

  const cancel = useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }, [timeoutId]);

  useUnmount(() => {
    cancel();
  });

  return {
    schedule,
    cancel,
  };
}
