/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useColorModePreference } from '@chakra-ui/media-query';
import { ColorMode } from '@time-neko/shared/common-types';

export const usePrefersColorMode = () => {
  const preference = useColorModePreference({
    fallback: [true, false],
  });

  return preference as ColorMode;
};
