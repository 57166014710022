/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { DetectNewDayResult } from '@time-neko/shared/domain/new-day-detector';
import { useCallback, useEffect, useState } from 'react';
import { logger } from '@time-neko/shared/logger';
import { frontendNewDaySchema } from '@time-neko/frontend/domain/new-day-detector/schema';

export interface UseNewDayDetectorParams {
  autoAcknowledgeNewDay?: boolean;
}

const log = logger.getChild('useNewDayDetector');

export function useNewDayDetector({
  autoAcknowledgeNewDay = true,
}: UseNewDayDetectorParams = {}) {
  const [isNewDay, setIsNewDay] = useState(false);
  const [didAcknowledgedNewDay, setDidAcknowledgedNewDay] = useState(false);

  const acknowledgeMutation =
    frontendNewDaySchema.acknowledgeNewDay.useCommand();

  const handleDayChange = useCallback((value?: DetectNewDayResult) => {
    if (!value) {
      return;
    }

    log.debug(`Detect new day result -> ${value.isNewDay}`);

    setIsNewDay(Boolean(value.isNewDay));
  }, []);

  // TODO Check if we need to refetch this (it was a query before refactor :0)
  const detectNewDay = frontendNewDaySchema.detectNewDay.useCommand({
    onSuccess: (data) => handleDayChange(data.result),
  });

  frontendNewDaySchema.newDayDetected.useEvent(() => {
    log.debug('New day detected!');

    setIsNewDay(true);
  }, []);

  useEffect(() => {
    if (isNewDay && autoAcknowledgeNewDay && !didAcknowledgedNewDay) {
      setDidAcknowledgedNewDay(true);
      acknowledgeMutation.mutate(undefined);
    }
  }, [
    acknowledgeMutation,
    autoAcknowledgeNewDay,
    didAcknowledgedNewDay,
    isNewDay,
  ]);

  return {
    isNewDay,
    isNewDayDetectionLoading: detectNewDay.isLoading,
    acknowledgeMutation,
  };
}
