/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ReleaseInfoRouteParams } from '@time-neko/shared/routes';
import {
  Alert,
  AppView,
  AppViewProps,
  Box,
  Button,
  Center,
  CenterContainer,
  Heading,
  Information,
  List,
  ListItem,
  Loading,
} from '@time-neko/frontend/ui';
import ReactMarkdown from 'react-markdown';
import { trimRight } from '@time-neko/shared/utils';
import { Children } from 'react';
import { useParams } from '@time-neko/frontend/router';
import { frontendUpdatesSchema } from '@time-neko/frontend/domain/updates/schema';
import { getUserFriendlyError } from '@time-neko/shared/errors';

export function ReleaseInfo(props: AppViewProps) {
  const { version } = useParams<ReleaseInfoRouteParams>();

  if (!version) {
    throw new Error('No version provided');
  }

  const updateInfoQuery = frontendUpdatesSchema.getVersionInfo.useQuery({
    variables: {
      version,
    },
    retry: false,
  });

  const showLoading = updateInfoQuery.isLoading && !updateInfoQuery.isFetched;
  return (
    <AppView
      {...props}
      titleBarProps={{
        canGoBack: true,
        pageTitle: 'Release info',
        hideWindowControls: true,
        ...props.titleBarProps,
      }}
    >
      {showLoading && (
        <Center width="100%" height="100%">
          <Loading boxSize="lg" />
        </Center>
      )}
      {!showLoading && (
        <CenterContainer width="100%" height="100%" flexDirection="column">
          {updateInfoQuery.error && (
            <Center>
              <Alert
                width="100%"
                status="error"
                hideIcon
                actions={() => (
                  <Button
                    isLoading={updateInfoQuery.isLoading}
                    onClick={() => updateInfoQuery.refetch()}
                  >
                    Retry
                  </Button>
                )}
              >
                Failed to fetch release:{' '}
                {getUserFriendlyError(updateInfoQuery.error)}
              </Alert>
            </Center>
          )}

          {updateInfoQuery.isFetched && !updateInfoQuery.data && (
            <Information
              title="Not found"
              subTitle="Release with given version was not found."
            />
          )}

          {updateInfoQuery.data?.releaseNotes && (
            <Box color="brand.textPrimary">
              <ReactMarkdown
                children={updateInfoQuery.data.releaseNotes}
                components={{
                  h1: ({ node, ...props }) => (
                    <Heading {...props}>{props.children}</Heading>
                  ),
                  h3: ({ node, ...props }) => (
                    <Heading {...props} as="h3" fontSize="2xl">
                      {props.children}{' '}
                    </Heading>
                  ),
                  ul: ({ node, ...props }) => (
                    <List {...props}>{props.children}</List>
                  ),
                  li: ({ node, ...props }) => {
                    const childrenArray = Children.toArray(props.children).map(
                      (child) => {
                        if (typeof child === 'string') {
                          return trimRight(child, ['(', ')']);
                        }

                        return child;
                      }
                    );

                    return <ListItem {...props}>- {childrenArray}</ListItem>;
                  },
                  a: (props) => {
                    if (props.href?.includes('commit')) {
                      return null;
                    }

                    return <span>{props.children}</span>;
                  },
                }}
              />
            </Box>
          )}
        </CenterContainer>
      )}
    </AppView>
  );
}
