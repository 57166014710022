/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { TaskState } from '../../../domain/tasks/src/types/tasks';

export const taskStateDictionary: Record<TaskState, string> = {
  [TaskState.Todo]: 'Todo',
  [TaskState.Completed]: 'Done',
};
