/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Badge } from '../Badge';
import { ChakraProps } from '@chakra-ui/system';

export const ComingSoonBadge = (props: ChakraProps) => {
  return (
    <Badge
      variant="nes-badge"
      w="60px"
      h="20px"
      backgroundColor="brand.secondary"
      color="black"
      {...props}
    >
      Soon
    </Badge>
  );
};
