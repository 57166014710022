/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { BoxProps } from '@chakra-ui/react';
import { composeMedia } from '../composeMedia';
import CatPointerLeft from '../../assets/CatPointerLeft.png';
import CatPointerRight from '../../assets/CatPointerRight.png';

export interface CatPointerProps extends BoxProps {
  variant: 'left' | 'right';
}

const LeftVariant = composeMedia({
  assetMap: {
    dark: CatPointerLeft,
    light: CatPointerLeft,
  },
  type: 'img',
  alt: 'Cat pointing left',
});

const RightVariant = composeMedia({
  assetMap: {
    dark: CatPointerRight,
    light: CatPointerRight,
  },
  alt: 'Cat pointing right',
  type: 'img',
});

export function CatPointer({ variant, ...rest }: CatPointerProps) {
  return variant === 'right' ? (
    <RightVariant {...rest} />
  ) : (
    <LeftVariant {...rest} />
  );
}
