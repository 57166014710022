/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

const timingFn = 'cubic-bezier(0.4, 0, 1, 1)';

export function cssTransition(duration?: string, properties = ['all']) {
  if (!duration) {
    return timingFn;
  }

  return properties.map((p) => `${p} ${duration} ${timingFn}`).join(',');
}
