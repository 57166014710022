/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { OnboardingStepProps } from './types';
import { Balloon, Button, CenterContainer, Heading, HStack, Text, VStack } from '@time-neko/frontend/ui';
import { useDomain } from '@time-neko/frontend/providers/domain';
import { Asset, CatIdleIcon } from '@time-neko/frontend/assets';

export function Welcome({ onSkip, onNext }: OnboardingStepProps) {
  const appName = useDomain('appName');

  return (
    <VStack height="100%" width="100%" spacing={6}>
      <CenterContainer bg="transparent" height="100%">
        <Balloon p={2} balloonOffset="40%">
          Welcome to {appName}!
        </Balloon>
        <CatIdleIcon />
        <Heading mt={12} mb={3} fontSize="2xl">
          <Text color="brand.primary">{appName}</Text>
        </Heading>
        <Text>A minimalistic pomodoro app.</Text>
      </CenterContainer>
      <HStack spacing={4}>
        <Button
          onClick={onNext}
          leftIcon={<Asset boxSize="sm" name="CatPointing" />}
          width="200px"
          colorScheme="primary"
        >
          <Text>Start</Text>
        </Button>
      </HStack>
      <Button onClick={onSkip} variant="nes-ghost">
        <Text>Skip</Text>
      </Button>
    </VStack>
  );
}
