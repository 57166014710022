/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useForm } from 'react-hook-form';
import {
  UserFeedbackAsset,
  UserFeedbackKind,
  UserFeedbackSchema,
  userFeedbackValidationConfig,
} from '@time-neko/shared/domain/feedback';
import { useDomain } from '@time-neko/frontend/providers/domain';
import { useCallback } from 'react';
import { makeFileSizeValidator } from '@time-neko/shared/validation';
import { fileToBase64 } from '@time-neko/frontend/files';
import { getRecaptchaToken } from '@time-neko/frontend/domain/captcha';
import { useConst } from '@chakra-ui/hooks';
import { RecaptchaError } from '@time-neko/shared/errors';
import { RecaptchaErrorCodes } from '@time-neko/shared/domain/captcha';
import { logger } from '@time-neko/shared/logger';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { frontendApiSchema } from '@time-neko/frontend/domain/api/schema';

const fileValidator = makeFileSizeValidator<File>({
  extractFileSize: (value) => value.size,
  maxSizeInBytes: userFeedbackValidationConfig.maxAttachmentSize,
});

const UserFeedbackFormStateSchema = UserFeedbackSchema.omit({
  attachments: true,
}).extend({
  attachments: z.array(
    z.instanceof(File).refine(fileValidator.validator, fileValidator.getMessage)
  ),
});

export type FeedbackFormState = z.infer<typeof UserFeedbackFormStateSchema>;

const resolver = zodResolver(UserFeedbackFormStateSchema);

const recaptchaKey = process.env.NX_RECAPTCHA_KEY;

export function useFeedbackForm() {
  const isRecaptchaEnabled = useConst(() => Boolean(recaptchaKey));

  const platform = useDomain('platform');
  const version = useDomain('version');

  const submitMutation = frontendApiSchema.sendFeedback.useCommand({
    retry: false,
    onError: (error) => {
      if (
        error.name === RecaptchaError.name &&
        error.message.includes(RecaptchaErrorCodes.LowScore)
      ) {
        // TODO Add recaptcha test here
        error.message = 'Unknown error, please try to submit the form later.';
      }
    },
  });

  const form = useForm<FeedbackFormState>({
    mode: 'all',
    resolver,
    shouldUnregister: false,
    defaultValues: {
      platform,
      appVersion: version,
      kind: UserFeedbackKind.Feedback,
      attachments: [],
    },
  });

  const handleSubmit = useCallback(
    async (values: FeedbackFormState) => {
      const recaptchaToken = await getRecaptchaToken(
        recaptchaKey as string,
        'feedback_submit'
      );

      const assets: UserFeedbackAsset[] = await Promise.all(
        values.attachments?.map(async (file) => ({
          fileName: file.name,
          base64: await fileToBase64(file),
        })) ?? []
      );

      try {
        await submitMutation.mutateAsync({
          ...values,
          platform,
          appVersion: version ?? 'dev',
          attachments: assets,
          recaptchaToken,
        });
      } catch (error) {
        logger.error(error);
      }
    },
    [platform, submitMutation, version]
  );

  return {
    form,
    handleSubmit,
    feedbackSent: submitMutation.isSuccess,
    error: submitMutation.error,
    isRecaptchaEnabled,
  };
}
