/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

export enum RouteReturnType {
  FullPath = 'fullPath',
  Path = 'path',
}

interface NestedRoute {
  [RouteReturnType.FullPath]: string;
  [RouteReturnType.Path]: string;
}

export const nestedRoute =
  (route: NestedRoute) =>
  (routeType: RouteReturnType = RouteReturnType.FullPath) =>
    route[routeType];
