/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */
import { frontendUpdatesSchema } from '@time-neko/frontend/domain/updates/schema';
import { useEffect } from 'react';
import { logger } from '@time-neko/shared/logger';
import { useUpdateStateQuery } from './useUpdateStateQuery';

export function useUpdate() {
  const checkForUpdateQuery = frontendUpdatesSchema.checkForUpdates.useQuery({
    variables: {
      force: true,
    },
    enabled: false,
    refetchInterval: 60_000,
    onError: (error) => {
      logger.error(error);
    },
  });
  const release = checkForUpdateQuery.data;

  const updateMutation = frontendUpdatesSchema.performUpdate.useCommand();
  const postponeMutation = frontendUpdatesSchema.postponeUpdate.useCommand();
  const updateStrategyQuery =
    frontendUpdatesSchema.getUpdateStrategy.useQuery();

  const { updateStateQuery } = useUpdateStateQuery();

  const releaseMetaQuery = frontendUpdatesSchema.getUpdateMeta.useQuery({
    variables: release ?? undefined,
    enabled: Boolean(release),
  });

  useEffect(() => {
    if (updateMutation.error) {
      logger.error('Update failed:', updateMutation.error);
    }
  }, [updateMutation.error]);

  frontendUpdatesSchema.updateAvailable.useEvent(({ payload }) => {
    checkForUpdateQuery.setQueryData(payload);
  });

  return {
    updateMutation,
    postponeMutation,
    updateStrategyQuery,
    updateStateQuery,
    releaseMetaQuery,
    checkForUpdateQuery,
  };
}
