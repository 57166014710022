/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { HTMLProps, PropsWithChildren } from 'react';
import { Box, useColorMode, useToken } from '@chakra-ui/react';
import { chakra, ChakraProps } from '@chakra-ui/system';
import classNames from 'classnames';
import { ReactComponent as BalloonPartComponent } from './Balloon.svg';
import { parseIconComponent } from '@time-neko/frontend/assets';

const BalloonPart = chakra(parseIconComponent(BalloonPartComponent));

export interface BalloonProps
  extends Omit<HTMLProps<HTMLDivElement>, 'width' | 'height' | 'color' | 'as'>,
    ChakraProps {
  className?: string;
  balloonOffset?: string | number;
  hidePart?: boolean;
  balloonPartPlacement?: 'bottom' | 'left';
}

export const Balloon = ({
  children,
  className,
  balloonOffset,
  hidePart = false,
  background,
  balloonPartPlacement = 'bottom',
  ...props
}: PropsWithChildren<BalloonProps>) => {
  const bg = useToken('colors', background?.toString() ?? 'brand.bgSecondary');

  const { colorMode } = useColorMode();

  return (
    <Box
      bg={`${bg} !important`}
      className={classNames('nes-balloon', 'balloon', className, {
        'is-dark': colorMode === 'dark',
      })}
      {...props}
      sx={{
        ...props.sx,

        'div&.nes-balloon.balloon': {
          '&, &::before': {
            boxShadow: 'none',
          },

          '&::before, &::after': {
            display: 'none',
          },
        },
      }}
    >
      {children}
      {!hidePart && (
        <BalloonPart
          transform={
            balloonPartPlacement === 'bottom'
              ? undefined
              : 'rotate(90deg) scaleX(-1)'
          }
          width="24px"
          height="16px"
          position="absolute"
          left={balloonPartPlacement === 'left' ? '-26px' : balloonOffset}
          bottom={
            balloonPartPlacement === 'bottom' ? '-22px' : balloonOffset ?? '50%'
          }
          sx={{
            '& .outer': {
              fill: 'brand.bgReverse',
            },

            '& .inner': {
              fill: bg,
            },
          }}
        />
      )}
    </Box>
  );
};
