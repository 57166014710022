/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  InvertObjectResult,
  PrefixProperties,
} from '@time-neko/shared/common-types';
import { z } from 'zod';

export const prefixProperties = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  Prefix extends string
>(
  prefix: Prefix,
  obj: T
): PrefixProperties<T, Prefix> => {
  const entries = Object.entries(obj);

  const mappedEntries = entries.map(([key, val]) => [`${prefix}${key}`, val]);

  return Object.fromEntries(mappedEntries);
};

export const objectToMap = <T extends object>(obj: T) =>
  new Map(Object.entries(obj));

export function invertObject<T extends Record<PropertyKey, PropertyKey>>(
  obj: T
) {
  const entries = Object.entries(obj).map(([key, val]) => [val, key]);

  return Object.fromEntries(entries) as InvertObjectResult<T>;
}

export function tryJsonParse<T>(
  str: string,
  schema: z.Schema<T>,
  defaultValue: T | null = null
) {
  try {
    return schema.parse(JSON.parse(str)) as T;
  } catch {
    return defaultValue;
  }
}

export function assign<T extends object, S extends object = T>(
  target: T,
  source: S
): T & S {
  return Object.assign(target, source);
}
