/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppPlatform } from '@time-neko/shared/domain/types';
import { PomodoroSettingsSchema } from '@time-neko/shared/domain/pomodoro';
import { TaskSettingsSchema } from '@time-neko/shared/domain/tasks';
import { TrelloSettingsSchema } from '@time-neko/shared/domain/integrations/trello';
import { BlockedSiteRuleSchema } from '@time-neko/shared/domain/sites-blocking';
import { UpdatesConfigSchema } from '@time-neko/shared/domain/updates';
import { PrivacySettingsSchema } from '@time-neko/shared/domain/tracking';
import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';
import { NotionSettingsSchema } from '@time-neko/shared/domain/integrations/notion';

export type PlatformSpecificSettings = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Key in AppPlatform]?: any;
};

export type AppSettings = z.infer<typeof AppSettingsSchema>;

export type GetSettingPayload = z.infer<typeof GetSettingPayloadSchema>;

// Settings with omitted discreet data
export type PublicAppSettings = z.infer<typeof PublicAppSettingsSchema>;

export const DesktopSitesBlockingSettingsSchema = z.object({
  useProxy: z.boolean().optional(),
});

export const DesktopSpecificSettingsSchema = z.object({
  autoStart: z.boolean().optional(),
  showOpacitySlider: z.boolean().optional(),
  siteBlocking: DesktopSitesBlockingSettingsSchema.optional(),
});

export type DesktopSpecificSettings = z.infer<
  typeof DesktopSpecificSettingsSchema
>;

export const AppSettingsIntegrationsSchema = z.object({
  trello: TrelloSettingsSchema.optional(),
  notion: NotionSettingsSchema.optional(),
});

export type AppSettingsIntegrations = z.infer<
  typeof AppSettingsIntegrationsSchema
>;

export const AppSettingsSchema = z.object({
  pomodoroSettings: PomodoroSettingsSchema.optional(),
  integrations: AppSettingsIntegrationsSchema.optional(),
  taskSettings: TaskSettingsSchema.optional(),
  blockedSites: z.array(BlockedSiteRuleSchema).optional(),
  updatesConfig: UpdatesConfigSchema.optional(),
  privacySettings: PrivacySettingsSchema.optional(),
  desktopSettings: DesktopSpecificSettingsSchema.optional(),
});

export const GetSettingPayloadSchema = z.object({
  key: AppSettingsSchema.keyof(),
});

export const GetIntegrationSettingPayloadSchema = z.object({
  key: AppSettingsIntegrationsSchema.keyof(),
});

export const PublicAppSettingsSchema = AppSettingsSchema.omit({
  integrations: true,
});

export const SettingsChangedSchema = z.object({
  newSettings: AppSettingsSchema,
  oldSettings: AppSettingsSchema,
});

export const PublicSettingsChangedSchema = z.object({
  newSettings: PublicAppSettingsSchema,
  oldSettings: PublicAppSettingsSchema,
});

export const SetSettingSchema = z.custom<{
  key: keyof AppSettings;
  value: AppSettings[keyof AppSettings];
}>((value) => {
  const key = GetSettingPayloadSchema.parse(value).key;

  const ValueSchema = AppSettingsSchema.pick({
    [key]: true,
  });

  return ValueSchema.parse(value);
});

export const SetIntegrationSettingSchema = z.custom<{
  key: keyof AppSettingsIntegrations;
  value: AppSettingsIntegrations[keyof AppSettingsIntegrations];
}>((value) => {
  const key = GetIntegrationSettingPayloadSchema.parse(value).key;

  const ValueSchema = AppSettingsIntegrationsSchema.pick({
    [key]: true,
  });

  return ValueSchema.parse(value);
});

export const AsyncSettingActionSchema = z.union([
  z.object({
    dismissOnSuccess: z.boolean().optional(),
    name: z.string(),
    isUrl: z.literal(false),
    label: z.string(),
  }),
  z.object({
    url: z.string().url(),
    isUrl: z.literal(true),
    label: z.string(),
  }),
]);

export type AsyncSettingAction = z.infer<typeof AsyncSettingActionSchema>;

export const AsyncSettingErrorSchema = z.object({
  message: z.string(),
  isClosable: z.boolean(),
  name: z.string(),
  title: z.string().optional(),
  metadata: z.record(z.any()).optional(),
  actions: z.array(AsyncSettingActionSchema),
});

export type AsyncSettingError = z.infer<typeof AsyncSettingErrorSchema>;

export const AsyncSettingErrorActionTriggeredPayloadSchema = z.object({
  error: AsyncSettingErrorSchema,
  actionName: z.string(),
});

export type AsyncSettingErrorActionTriggeredPayload = z.infer<
  typeof AsyncSettingErrorActionTriggeredPayloadSchema
>;

export const AsyncSettingErrorRemovedPayloadSchema =
  AsyncSettingErrorSchema.pick({
    name: true,
  });

export type AsyncSettingErrorRemovedPayload = z.infer<
  typeof AsyncSettingErrorRemovedPayloadSchema
>;

export const settingsSchema = defineSchema({
  queries: {
    getSettings: operation.query.withResult(AppSettingsSchema),
    getPublicSettings: operation.query.withResult(PublicAppSettingsSchema),
    getSetting: operation.query
      .withPayload(GetSettingPayloadSchema)
      // TODO Add meta that will dynamically attach schema basing on settings key
      .withResult(z.any()),
    getIntegrationSetting: operation.query
      .withPayload(GetIntegrationSettingPayloadSchema)
      .withResult(z.any()),
    getAsyncSettingErrors: operation.query.withResult(
      z.array(AsyncSettingErrorSchema)
    ),
  },
  commands: {
    setSettings: operation.command
      .withPayload(AppSettingsSchema)
      .withResult(z.boolean()),
    setIntegrationSetting: operation.command.withPayload(
      SetIntegrationSettingSchema
    ),
    setSetting: operation.command.withPayload(SetSettingSchema),
    pushAsyncSettingError: operation.command.withPayload(
      AsyncSettingErrorSchema
    ),
    removeAsyncSettingError: operation.command.withPayload(
      AsyncSettingErrorSchema.pick({ name: true })
    ),
    clearAsyncSettingErrors: operation.command,
    triggerAsyncSettingAction: operation.command.withPayload(
      AsyncSettingErrorActionTriggeredPayloadSchema
    ),
  },
  events: {
    asyncSettingErrorActionTrigger: operation.event.withPayload(
      AsyncSettingErrorActionTriggeredPayloadSchema
    ),
    settingsUpdated: operation.event.withPayload(SettingsChangedSchema),
    publicSettingsUpdated: operation.event.withPayload(
      PublicSettingsChangedSchema
    ),
    asyncSettingErrorRemoved: operation.event.withPayload(
      AsyncSettingErrorRemovedPayloadSchema
    ),
    asyncSettingErrorCreated: operation.event.withPayload(
      AsyncSettingErrorSchema
    ),
  },
});

export type SettingsChangedPayload = z.infer<typeof SettingsChangedSchema>;

export type PublicSettingsChangedPayload = z.infer<
  typeof PublicSettingsChangedSchema
>;

export interface SetSettingPayload<
  Key extends keyof AppSettings = keyof AppSettings
> {
  key: Key;
  value: AppSettings[Key];
}

export function isAsyncSettingError(err: any): err is AsyncSettingError {
  return AsyncSettingErrorSchema.safeParse(err).success;
}

export enum SettingsNotices {
  SettingsAutoSave = 'SettingsAutoSave',
}
