/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Textarea, TextareaProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { FormControl, FormControlProps } from '../formControl/FormControl';

export interface CommonTextareaProps extends FormControlProps {
  textAreaProps?: TextareaProps;
}

export const CommonTextarea = forwardRef<
  HTMLTextAreaElement,
  CommonTextareaProps
>(({ name, textAreaProps = {}, ...rest }, ref) => {
  return (
    <FormControl name={name} {...rest}>
      <Textarea name={name} ref={ref} {...textAreaProps} />
    </FormControl>
  );
});
