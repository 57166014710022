/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import {
  AppView,
  AppViewProps,
  Box,
  Button,
  Center,
  Frame,
  HStack,
  Loading,
  SubmitButton,
  Text,
  TitleBarProps,
  VStack,
} from '@time-neko/frontend/ui';
import { Outlet } from 'react-router-dom';
import { MutableRefObject, useMemo } from 'react';
import { ChakraProps } from '@chakra-ui/system';
import { useIntegrationDefinitions } from '@time-neko/frontend/domain/integrations/providers';
import { useLocation } from '@time-neko/frontend/router';
import { RouteReturnType } from '@time-neko/shared/routes';
import { WebTransition } from '@time-neko/frontend/web-transition';
import { Transition } from '@time-neko/frontend/providers/theme';

export interface SettingsFormRootProps extends Pick<AppViewProps, 'TitleBar'> {
  onClose: () => void;
  onBack?: () => void;
  containerStyles?: ChakraProps;
  titleBarProps?: TitleBarProps;
  isQueryLoading: boolean;
  isSubmitting: boolean;
  scrollContainerRef: MutableRefObject<HTMLDivElement>;
  onSubmit: () => void;
  isDirty: boolean;
  onReset: () => void;
  didSubmit?: boolean;
  hideActionFooterText?: boolean;
}

export function SettingsFormRoot({
  containerStyles,
  titleBarProps,
  onClose,
  TitleBar,
  isQueryLoading,
  isSubmitting,
  scrollContainerRef,
  onSubmit,
  isDirty,
  onReset,
  didSubmit,
  onBack,
  hideActionFooterText = false,
}: SettingsFormRootProps) {
  const integrations = useIntegrationDefinitions();

  const location = useLocation();

  const integrationMatch = useMemo(
    () =>
      integrations.find((integration) =>
        location.pathname.includes(
          integration.managePath?.(RouteReturnType.FullPath) ?? ''
        )
      ),
    [integrations, location.pathname]
  );

  const title = useMemo(() => {
    if (integrationMatch) {
      return `Manage ${integrationMatch.label.toLowerCase()}`;
    }

    return 'Settings';
  }, [integrationMatch]);

  const showActionFooter = isDirty || isSubmitting;

  return (
    <AppView
      width="100%"
      height="100%"
      titleBarProps={{
        pageTitle: title,
        onClose,
        onGoBack: onBack,
        canGoBack: Boolean(integrationMatch),
        ...titleBarProps,
      }}
      TitleBar={TitleBar}
      {...containerStyles}
    >
      {isQueryLoading && (
        <Center height="100%" width="100%">
          <Loading boxSize="md" />
        </Center>
      )}
      <VStack width="100%" height="100%" overflow="hidden">
        <Box
          py={4}
          px={{
            base: 3,
            sm: 6,
          }}
          pb={0}
          flex={1}
          width="100%"
          overflow="auto"
          ref={scrollContainerRef}
        >
          <Outlet />
        </Box>
        <WebTransition<HTMLDivElement>
          in={showActionFooter}
          timeout={300}
          transition={Transition.FadeInGrow}
          mountOnEnter
        >
          {(ref) => (
            <Center
              className="center"
              ref={ref}
              width="100%"
              pointerEvents={showActionFooter ? undefined : 'none'}
              style={
                {
                  '--height': '90px',
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } as any
              }
            >
              <Frame p={4}>
                <HStack spacing={4}>
                  {!hideActionFooterText && <Text>Don't forget to save!</Text>}
                  <SubmitButton
                    didSubmit={didSubmit}
                    id="save_settings"
                    onClick={onSubmit}
                    isLoading={isSubmitting}
                  >
                    Save
                  </SubmitButton>
                  <Button colorScheme="danger" onClick={onReset}>
                    <Text>Reset</Text>
                  </Button>
                </HStack>
              </Frame>
            </Center>
          )}
        </WebTransition>
      </VStack>
    </AppView>
  );
}
