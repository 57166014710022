/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { hexToRgb } from '@time-neko/frontend/common';

export const getBorderImageSource = (hexColor: string) => {
  let color: string;

  try {
    const rgb = hexToRgb(hexColor);

    color = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
  } catch {
    color = 'transparent';
  }

  return `url(
    'data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8" ?><svg version="1.1" width="5" height="5" xmlns="http://www.w3.org/2000/svg"><path d="M2 1 h1 v1 h-1 z M1 2 h1 v1 h-1 z M3 2 h1 v1 h-1 z M2 3 h1 v1 h-1 z" fill="${color}" /></svg>'
  )`;
};
