/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { frontendTasksSchema } from '@time-neko/frontend/domain/tasks/schema';
import { OperationSchemaOperations } from '@musubi/core';
import { tasksSchema } from '@time-neko/shared/domain/tasks';
import { useAutoSwitch } from '@time-neko/frontend/common';
import { useCallback } from 'react';
import { Box, IconButton, Tooltip } from '@time-neko/frontend/ui';
import classNames from 'classnames';
import { Asset } from '@time-neko/frontend/assets';
import { useTasksSync } from '@time-neko/frontend/domain/tasks/hooks';

export interface TasksListSideActionProps {
  activeIndex: number;
  tasksLength: number;
  hideSync?: boolean;
}

export function TasksListSideAction({
  activeIndex,
  tasksLength,
  hideSync,
}: TasksListSideActionProps) {
  const { sync: syncTasks, isSyncing } = useTasksSync();

  const removeDeletedCompletedTasksMutation =
    frontendTasksSchema.deleteCompletedTasks.useCommand({
      invalidateQueries: [
        ['countTasksByState' as OperationSchemaOperations<typeof tasksSchema>],
        ['getTasks' as OperationSchemaOperations<typeof tasksSchema>],
      ],
    });

  const [didDeleteCompletedTasks, setDidDeleteCompletedTasks] = useAutoSwitch(
    false,
    1500
  );

  const handleDeleteCompletedTasks = useCallback(() => {
    setDidDeleteCompletedTasks(true);

    removeDeletedCompletedTasksMutation.mutate(undefined);
  }, [removeDeletedCompletedTasksMutation, setDidDeleteCompletedTasks]);

  return (
    <Box position="absolute" right={2} top={3.5}>
      {activeIndex === 0 && !hideSync && (
        <Tooltip label={isSyncing ? 'Syncing tasks...' : 'Sync tasks'}>
          <IconButton
            id="sync_tasks"
            className={classNames({
              'animation-rotate': isSyncing,
            })}
            onClick={syncTasks}
            isDisabled={isSyncing}
            aria-label="Sync tasks"
            variant="nes-ghost"
            boxSize="md"
          >
            <Asset name="Sync" boxSize="sm" />
          </IconButton>
        </Tooltip>
      )}
      {activeIndex === 1 && (
        <Tooltip label="Delete completed tasks">
          <IconButton
            id="delete_completed_tasks"
            isDisabled={!tasksLength}
            className={classNames({
              'animation-shake': didDeleteCompletedTasks,
            })}
            onClick={handleDeleteCompletedTasks}
            aria-label="Delete completed tasks"
            variant="nes-ghost"
            boxSize="md"
          >
            <Asset name="TrashCan" boxSize="sm" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
