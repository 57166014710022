/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  Box,
  Center,
  Heading,
  List,
  ListProps,
  Loading,
} from '@time-neko/frontend/ui';
import { ReactNode, useCallback } from 'react';
import { TaskListItem } from './taskListItem/TaskListItem';
import { Task } from '@time-neko/shared/domain/tasks';
import { TaskListItemProps } from './taskListItem/TaskListItem.types';
import { TransitionGroup } from 'react-transition-group';
import { css } from '@emotion/react';
import { Theme, useThemedCss } from '@time-neko/frontend/providers/theme';
import { useFirstMountState } from 'react-use';

export interface TasksListProps extends Omit<ListProps, 'arrIndex'> {
  tasks: Task[];
  itemProps?: Omit<TaskListItemProps, 'task' | 'arrIndex'>;
  emptyContent?: ReactNode;
  isLoading?: boolean;
  onListDragEnd?: (tasks: Task[]) => unknown;
  isDragDisabled?: boolean;
  isDirty?: boolean;
  showActiveTaskHighlight?: boolean;
}

const styles = (theme: Theme) => css`
  .task-list-item {
    &.exit-done {
      opacity: 1;
    }

    &.exit {
      height: var(--height);
    }

    &.exit-active {
      transition: ${theme.cssTransition('.5s')} !important;
      opacity: 0 !important;
      padding-bottom: 0;
      padding-top: 0;
      height: 0;
    }

    &.enter {
      opacity: 0;
    }

    &.enter-active {
      opacity: 1;
      transition: ${theme.cssTransition('.5s')} !important;
    }
  }
`;

export function TasksList({
  tasks,
  itemProps,
  emptyContent,
  isLoading,
  isDirty,
  onListDragEnd,
  isDragDisabled,
  showActiveTaskHighlight,
  ...props
}: TasksListProps) {
  const isFirstMount = useFirstMountState();
  const css = useThemedCss(styles);

  const handleTaskDrag = useCallback(
    (oldIndex: number, newIndex: number) => {
      if (!onListDragEnd) {
        return;
      }

      const newTasks = [...tasks];

      const [removedTask] = newTasks.splice(oldIndex, 1);
      newTasks.splice(newIndex, 0, removedTask);

      const mappedTasks = newTasks.map((task, index) => ({
        ...task,
        index,
      }));

      onListDragEnd(mappedTasks);
    },
    [onListDragEnd, tasks]
  );

  return (
    <Box
      css={css}
      width="100%"
      height="100%"
      position="relative"
      overflowX="visible"
      overflowY="auto"
      py={1}
      px={1}
    >
      {isLoading && !tasks.length && (
        <Center h="100%">
          <Loading boxSize="lg" />
        </Center>
      )}
      {!isLoading && (
        <>
          {!tasks.length && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {emptyContent ?? (
                <Center h="100%">
                  <Heading size="sm">No tasks found.</Heading>
                </Center>
              )}
            </>
          )}
          {Boolean(tasks.length) && (
            <List
              className="tasks-list"
              overflowX="hidden"
              overflowY="auto"
              position="relative"
              {...props}
            >
              <TransitionGroup
                enter={!isFirstMount}
                exit={tasks.length > 1}
                component={null}
                className="tasks-list"
              >
                {tasks.map((task, index) => {
                  return (
                    <TaskListItem
                      highlighIfActive={showActiveTaskHighlight}
                      key={task.id}
                      isDisabled={isDirty}
                      isDragDisabled={isDragDisabled}
                      arrIndex={index}
                      mb={3}
                      task={task}
                      onTaskDrag={handleTaskDrag}
                      {...itemProps}
                    />
                  );
                })}
              </TransitionGroup>
            </List>
          )}
        </>
      )}
    </Box>
  );
}
