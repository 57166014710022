/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';

export type NoticePayload = z.infer<typeof NoticePayloadSchema>;

export const NoticePayloadSchema = z.object({
  noticeId: z.string(),
});

export const noticesSchema = defineSchema({
  commands: {
    dismissNotice: operation.command.withPayload(NoticePayloadSchema),
  },
  events: {},
  queries: {
    isNoticeDismissed: operation.query
      .withPayload(NoticePayloadSchema)
      .withResult(z.boolean()),
  },
});
