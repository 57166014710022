/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { getBackendPlatform } from './getBackendPlatform';

export interface KeyboardShortcut {
  // Key that can be referenced in frontend process
  frontendKey: string;
  // Key that can be used in backend process
  backendKey: string;
  // Key that can be used for display (both in electron and renderer process)
  display: string;
}

type ShortcutMap = {
  [Key in NodeJS.Platform]?: KeyboardShortcut;
};

const registerShortcut =
  (platformMap: ShortcutMap) =>
  (providedPlatform?: NodeJS.Platform): KeyboardShortcut | null => {
    const platform = providedPlatform ?? getBackendPlatform();

    return platformMap[platform] ?? platformMap.win32 ?? null;
  };

export const keyboardShortcuts = {
  addTaskAsActive: registerShortcut({
    win32: {
      display: 'shift enter',
      frontendKey: 'shift+enter',
      backendKey: 'Shift+Enter',
    },
    darwin: {
      display: 'shift enter',
      frontendKey: 'shift+enter',
      backendKey: 'Shift+Enter',
    },
  }),
  deleteTask: registerShortcut({
    win32: {
      display: 'ctrl D',
      frontendKey: 'ctrl+D',
      backendKey: 'Control+D',
    },
    darwin: {
      display: '⌘ D',
      frontendKey: '⌘+D',
      backendKey: 'CommandOrControl+D',
    },
  }),
  openSettings: registerShortcut({
    darwin: {
      display: '⌘ ,',
      frontendKey: 'cmd+,',
      backendKey: 'CommandOrControl+Comma',
    },
    win32: {
      display: 'ctrl ,',
      frontendKey: 'ctrl+,',
      backendKey: 'Control+Comma',
    },
  }),
  togglePomodoro: registerShortcut({
    win32: {
      display: 'ctrl P',
      frontendKey: 'Control+P',
      backendKey: 'Control+P',
    },
    darwin: {
      display: '⌘ P',
      frontendKey: 'cmd+P',
      backendKey: 'CommandOrControl+P',
    },
  }),
  moveToNextPomodoroState: registerShortcut({
    win32: {
      display: 'ctrl N',
      frontendKey: 'ctrl+N',
      backendKey: 'Control+N',
    },
    darwin: {
      display: '⌘ ]',
      frontendKey: 'cmd+]',
      backendKey: 'CommandOrControl+]',
    },
  }),
  restartPomodoro: registerShortcut({
    win32: {
      display: 'ctrl alt R',
      frontendKey: 'Ctrl+Alt+R',
      backendKey: 'Control+Alt+R',
    },
    darwin: {
      display: '⌘ alt R',
      frontendKey: 'Cmd+Alt+R',
      backendKey: 'CommandOrControl+Alt+R',
    },
  }),
  stopTimer: registerShortcut({
    win32: {
      display: 'ctrl S',
      frontendKey: 'ctrl+S',
      backendKey: 'Control+S',
    },
    darwin: {
      display: '⌘ S',
      frontendKey: 'cmd+S',
      backendKey: 'CommandOrControl+S',
    },
  }),
};

export type KeyboardShortcuts = typeof keyboardShortcuts;
