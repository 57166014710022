/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { css } from '@emotion/react';
import { Theme } from '../types';

export const scrollbar = (theme: Theme) => css`
  * {
    scrollbar-width: auto;
    scrollbar-color: ${theme.colors.brand.primary}
      ${theme.colors.brand.bgReverse};
  }

  *::-webkit-scrollbar {
    width: ${theme.sizes.xs};
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.brand.primary};
    border-radius: 0;
    border: 3px outset ${theme.colors.brand.bgReverse};
  }
`;
