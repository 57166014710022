/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { useEffect } from 'react';
import { UseCommandReturn, UseQueryReturn } from '@musubi/react';
import { useToast } from './useToast';
import { getUserFriendlyError } from '@time-neko/shared/errors';

export function useOperationErrorToast(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryOrMutation: UseQueryReturn<any> | UseCommandReturn<any, any>,
  errorPrefix = 'Something went wrong'
) {
  const toast = useToast();

  useEffect(() => {
    if (queryOrMutation.error) {
      toast.show({
        id: queryOrMutation.key.toString(),
        status: 'error',
        description: `${errorPrefix}: ${getUserFriendlyError(
          queryOrMutation.error
        )}`,
        isClosable: true,
      });
    }
  }, [errorPrefix, queryOrMutation.error, queryOrMutation.key, toast]);
}
