/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ReactNode } from 'react';
import { Asset, AssetProps } from '@time-neko/frontend/assets';
import { Center, Flex, FlexProps, VStack } from '@chakra-ui/react';
import { Heading } from '../../atoms/heading/Heading';
import { Text } from '../../atoms/text/Text';
import { ChakraProps } from '@chakra-ui/system';

export interface InformationProps extends ChakraProps {
  title: ReactNode;
  subTitle: ReactNode;
  assetProps?: Omit<AssetProps, 'css'>;
  asset?: ReactNode;
  direction?: FlexProps['direction'];
  action?: ReactNode;
  spacing?: number;
  className?: string;
}

export const Information = ({
  title,
  subTitle,
  assetProps,
  asset,
  direction = 'column',
  action,
  spacing,
  ...props
}: InformationProps) => {
  return (
    <Center h="80%" {...props}>
      <Flex direction={direction} height="auto" alignItems="center">
        {asset ?? (
          <Asset
            width="80px"
            height="83px"
            name="SmartCat"
            mb={2}
            {...assetProps}
          />
        )}
        <VStack
          mt={2}
          spacing={spacing}
          ml={direction === 'row' ? 6 : 0}
          justifyContent="center"
          alignItems="center"
        >
          <Heading size="sm">{title}</Heading>
          <Text>{subTitle}</Text>
          {action}
        </VStack>
      </Flex>
    </Center>
  );
};
