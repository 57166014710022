/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { paramRoute } from './paramRoute';
import { DownloadRouteParams } from './websiteRoutes.types';

export const websiteRoutes = {
  download: paramRoute<DownloadRouteParams>('/download/:platform'),
  downloads: () => '/downloads',
  trello: {
    authResult: () => '/trello/auth/result',
  },
  notion: {
    authResult: () => '/notion/auth/result',
  },
  roadmap: () => '/about/roadmap',
  privacyPolicy: () => '/about/privacy-policy',
  termsOfService: () => '/about/terms-of-service',
  siteBlocked: () => '/site-blocked',
};
