/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { PlainSettingsComponentProps } from '@time-neko/frontend/domain/settings/field-definitions';
import {
  Button,
  Information,
  ListItem,
  ManagableList,
  Text,
} from '@time-neko/frontend/ui';
import { useFieldArray } from 'react-hook-form';
import { useCallback } from 'react';
import { v4 } from 'uuid';
import { PomodoroReminderSetting } from './PomodoroReminderSetting';
import { useNotificationPermissionRequest } from '@time-neko/frontend/notifications';
import { logger } from '@time-neko/shared/logger';

export function PomodoroReminderSettings({
  form,
}: PlainSettingsComponentProps) {
  const { requestPermissionIfNotGranted } = useNotificationPermissionRequest();
  const value = form.watch('pomodoroSettings.reminders');

  const fieldArray = useFieldArray({
    control: form.control,
    name: 'pomodoroSettings.reminders',
    keyName: 'id',
  });

  const append = useCallback(async () => {
    const result = await requestPermissionIfNotGranted();

    if (!result) {
      logger.warn(
        'Permission not granted, no notifications for reminders will be shown'
      );
    }

    fieldArray.append(
      {
        id: v4(),
        message: '',
        showOnState: [],
        isTimeSensitive: false,
      },
      {
        shouldFocus: false,
      }
    );
  }, [fieldArray, requestPermissionIfNotGranted]);

  if (!value?.length) {
    return (
      <Information
        className="pomodoro-reminder-settings"
        title="No reminders configured"
        subTitle="Reminders are custom notifications that can be shown whenever pomodoro state changes."
        action={
          <Button onClick={append}>
            <Text>Add reminder</Text>
          </Button>
        }
      />
    );
  }

  return (
    <ManagableList
      className="pomodoro-reminder-settings"
      spacing={6}
      width="100%"
      items={fieldArray.fields}
      onAdd={append}
      onRemove={(index) => {
        fieldArray.remove(index);
      }}
      addItemText="Add reminder"
      render={({ index, item, removeBtn }) => (
        <ListItem key={item.id}>
          <PomodoroReminderSetting
            removeBtn={removeBtn}
            value={item}
            index={index}
            form={form}
          />
        </ListItem>
      )}
    />
  );
}
