/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */
import { useMemo } from 'react';
import { SettingsFields } from '@time-neko/frontend/domain/settings/field-definitions';
import {
  UpdateCheck,
  UpdateCheckProps,
} from './components/updateCheck/UpdateCheck';

export type UseVersionItemProps = UpdateCheckProps;

export function useVersionSettingsItem(props: UseVersionItemProps = {}) {
  return useMemo<SettingsFields>(() => {
    const component = () => <UpdateCheck {...props} key="version" />;

    return {
      renderAs: 'plainComponent',
      Component: component,
    };
  }, [props]);
}
