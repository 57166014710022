/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { memo, useMemo } from 'react';
import { useDomain } from '@time-neko/frontend/providers/domain';
import {
  Alert,
  Button,
  HStack,
  IconButton,
  Text,
  Tooltip,
} from '@time-neko/frontend/ui';
import { AppRelease, UpdateState } from '@time-neko/shared/domain/updates';
import { Asset } from '@time-neko/frontend/assets';
import classNames from 'classnames';
import { appRoutes } from '@time-neko/shared/routes';
import { useNavigate } from '@time-neko/frontend/router';
import { ChakraProps } from '@chakra-ui/system';
import { useUpdate } from '@time-neko/frontend/domain/updates/hooks';
import { getUserFriendlyError } from '@time-neko/shared/errors';
import { getDownloadUrl } from '@time-neko/shared/website';

export interface UpdateCheckProps extends ChakraProps {
  disableUpdates?: boolean;
  disableUpdateCheck?: boolean;
}

function BaseUpdateCheck({
  disableUpdates,
  disableUpdateCheck = false,
  ...props
}: UpdateCheckProps) {
  const platform = useDomain('platform');

  const navigate = useNavigate();

  const { updateStateQuery, updateMutation, checkForUpdateQuery } = useUpdate();

  const version = useDomain('version');

  const isLoading =
    checkForUpdateQuery.isFetching ||
    updateStateQuery.isLoading ||
    updateStateQuery.data === UpdateState.Downloading;

  const updateLabel = useMemo(() => {
    switch (updateStateQuery.data) {
      case UpdateState.Downloaded:
        return 'Update downloaded!';

      case UpdateState.Downloading:
        return 'Downloading...';

      default:
        return 'Update';
    }
  }, [updateStateQuery.data]);

  return (
    <HStack py={2} px={3} key="version" spacing={1} {...props}>
      {updateMutation.error && (
        <Alert
          isCentered
          hideIcon
          isClosable={false}
          status="error"
          title="Update failed"
          actions={() => (
            <>
              <Button
                onClick={async () => {
                  updateMutation.mutate(checkForUpdateQuery.data as AppRelease);
                }}
              >
                Retry
              </Button>
              <Button
                target="_blank"
                as="a"
                href={getDownloadUrl({
                  platform,
                  version: checkForUpdateQuery.data?.version,
                  isUpdateError: true,
                })}
              >
                Download manually
              </Button>
            </>
          )}
        >
          <Text>
            Whoops, looks like update failed:{' '}
            {getUserFriendlyError(updateMutation.error)}.
            <br />
            <br />
            If the error persists, please download the update manually.
          </Text>
        </Alert>
      )}
      {(!updateMutation.error || !checkForUpdateQuery.data) && (
        <>
          {version && !checkForUpdateQuery.data?.version && (
            <Tooltip label="Click to view release notes">
              <Button
                onClick={() =>
                  navigate(
                    appRoutes.releaseInfo({
                      version,
                    })
                  )
                }
                variant="nes-ghost"
                p={0}
              >
                <Text textDecoration="underline">v{version}</Text>
              </Button>
            </Tooltip>
          )}
          {checkForUpdateQuery.data?.version && !disableUpdateCheck && (
            <Alert
              status="info"
              hideIcon
              isClosable={false}
              actions={() => (
                <Button
                  colorScheme="secondary"
                  isDisabled={isLoading}
                  onClick={() => {
                    if (checkForUpdateQuery.data) {
                      updateMutation.mutate(checkForUpdateQuery.data);
                    }
                  }}
                >
                  {updateLabel}
                </Button>
              )}
            >
              <Text display="inline-block">
                A new version of the app is available: <br />
                <strong>{checkForUpdateQuery.data.version}</strong>
              </Text>
            </Alert>
          )}
          {!checkForUpdateQuery.data?.version && !disableUpdateCheck && (
            <Tooltip placement="start-start" label="Check for updates">
              <IconButton
                isDisabled={isLoading}
                onClick={async () => {
                  await checkForUpdateQuery.refetch();
                }}
                className={classNames({ 'animation-rotate': isLoading })}
                variant="nes-ghost"
                aria-label="Check for updates"
                p={0}
              >
                <Asset
                  boxSize="sm"
                  name="Sync"
                  sx={{
                    '&, & rect': {
                      fill: 'brand.iconPrimary',
                    },
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </HStack>
  );
}

export const UpdateCheck = memo(BaseUpdateCheck);
