/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren, useMemo } from 'react';
import { getTimerBoxIcon } from '../getTimerBoxIcon';
import { VStack } from '@time-neko/frontend/ui';
import {
  PomodoroState,
  PomodoroStates,
} from '@time-neko/shared/domain/pomodoro';
import { TimerBalloon } from './TimerBalloon';
import classNames from 'classnames';
import { Task } from '@time-neko/shared/domain/tasks';
import { BreakSuggestion } from '@time-neko/shared/domain/break';
import { ChakraProps } from '@chakra-ui/system';

export interface PomodoroImageProps {
  pomodoro?: PomodoroState;
  activeTask?: Pick<Task, 'title' | 'id' | 'estimatedPomodoroDuration'> & {
    durationText?: string;
  };
  breakSuggestion?: BreakSuggestion;
  containerProps?: ChakraProps;
}

export function PomodoroImage({
  children,
  pomodoro,
  activeTask,
  breakSuggestion,
  containerProps,
}: PropsWithChildren<PomodoroImageProps>) {
  const IconComponent = useMemo(
    () =>
      getTimerBoxIcon({
        pomodoroState: pomodoro?.state,
        breakSuggestion: breakSuggestion,
      }),
    [breakSuggestion, pomodoro?.state]
  );

  const hasBalloon =
    pomodoro?.state === PomodoroStates.Work
      ? Boolean(activeTask)
      : Boolean(breakSuggestion);

  return (
    <VStack
      spacing={1}
      className={classNames('image-stack', { 'has-balloon': hasBalloon })}
      height={{
        base: '170px',
        sm: '100%',
      }}
      alignItems="center"
      width={{
        base: '100%',
        sm: '50%',
      }}
      position="relative"
      overflow="visible"
      {...containerProps}
      sx={{
        '>span': {
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'visible',
          position: 'relative',
          transition: 'all .3s',
        },
        img: {
          maxWidth: 'initial',
          position: 'absolute',
          pointerEvents: 'none',
          transition: 'all .3s',
          left: {
            base: 0,
            sm: undefined,
          },
          right: {
            base: 0,
            sm: undefined,
          },
          margin: {
            base: '0 auto',
            sm: undefined,
          },
        },

        '.cat-smelling-flowers': {
          height: '200px',
          right: '5px',
        },

        '.cat-reads-book': {
          height: '140px',
        },

        '.cat-idle': {
          left: {
            base: '25px',
            sm: undefined,
          },
        },

        '&.has-balloon .cat-reads-book': {
          top: '10px',
        },
        ...containerProps?.sx,
      }}
    >
      {children}
      {pomodoro?.state === PomodoroStates.Work
        ? activeTask && (
            <TimerBalloon
              mb={{
                base: 2,
                sm: undefined,
              }}
              addon={
                activeTask.durationText
                  ? `(${activeTask.durationText})`
                  : undefined
              }
            >
              {activeTask.title}
            </TimerBalloon>
          )
        : breakSuggestion && (
            <TimerBalloon
              className="break-suggestion"
              marginBottom={2}
              tooltipDisabled={!breakSuggestion}
            >
              {breakSuggestion?.text}
            </TimerBalloon>
          )}
      {IconComponent && (
        <span>
          <IconComponent className="animation-fade-in" />
        </span>
      )}
    </VStack>
  );
}
