/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { z } from 'zod';
import { NotionDatabasePropertyType } from './types';

export const NotionPropertyValueSchema = z.union([
  z.string(),
  z.number(),
  z.array(z.string()),
]);

export type NotionPropertyValue = z.infer<typeof NotionPropertyValueSchema>;

export const NotionPropertySchema = z.object({
  propertyId: z.string(),
  value: NotionPropertyValueSchema,
  type: z.custom<NotionDatabasePropertyType>((value) =>
    z.string().parse(value)
  ),
});
