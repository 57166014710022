/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { ChakraProps } from '@chakra-ui/system';
import {
  FormControl,
  Frame,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  VStack,
} from '@time-neko/frontend/ui';
import { Asset } from '@time-neko/frontend/assets';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import {
  BlockedSiteRule,
  maybeAddProtocol,
  normalizeUrl,
  removeProtocol,
} from '@time-neko/shared/domain/sites-blocking';
import { PomodoroStates } from '@time-neko/shared/domain/pomodoro';
import { BlockedSiteEntry } from './BlockedSiteEntry';
import { Key } from 'ts-key-enum';
import { v4 } from 'uuid';
import { tryFn } from '@time-neko/shared/utils/try';

export interface BlockedSitesFieldProps extends ChakraProps {
  form: UseFormReturn;
  name: string;
  allowPathname?: boolean;
}

export const BlockedSitesField = forwardRef<
  HTMLDivElement,
  BlockedSitesFieldProps
>(({ form, name, allowPathname = true, ...props }, ref) => {
  const [textError, setTextError] = useState<Error | null>(null);
  const [textValue, setTextValue] = useState('');

  const { fields, append, update, remove } = useFieldArray<
    Record<string, BlockedSiteRule[]>
  >({
    control: form.control,
    name,
  });

  const handleAdd = useCallback(() => {
    if (!textValue) {
      return;
    }

    let url = normalizeUrl(removeProtocol(textValue));

    const [err, urlObj] = tryFn(() => new URL(maybeAddProtocol(url)));

    if (err) {
      setTextError(new Error('Invalid URL'));

      return;
    }

    if (!allowPathname) {
      urlObj.pathname = '/';
    }

    url = urlObj.hostname + (urlObj.pathname === '/' ? '' : urlObj.pathname);

    const exists = fields.find((field) => field.url === url);

    if (!exists) {
      append({
        id: v4(),
        url,
        blockOnPomodoroStates: [PomodoroStates.Work],
      });
    }

    setTextValue('');
  }, [allowPathname, append, fields, textValue]);

  useEffect(() => {
    setTextError(null);
  }, [textValue]);

  return (
    <VStack spacing={4} {...props} ref={ref}>
      <FormControl error={textError?.message}>
        <InputGroup display="flex">
          <Input
            className="url-input"
            onKeyDown={(event) => {
              if (event.key === Key.Enter) {
                event.preventDefault();
                event.stopPropagation();

                handleAdd();
              }
            }}
            value={textValue}
            onChange={(event) => setTextValue(event.target.value)}
            placeholder="facebook.com"
            pr={12}
          />
          <InputRightElement
            display="flex"
            h="full"
            alignItems="center"
            right={1}
          >
            <IconButton
              isDisabled={!textValue}
              onClick={handleAdd}
              variant="nes-ghost"
              className="add-url"
              size="sm"
              aria-label="Add URL to block"
            >
              <Asset boxSize="sm" name="Plus" />
            </IconButton>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <List width="100%" spacing={4}>
        {fields.map((field, index) => (
          <ListItem key={field.id}>
            <Frame py={2} px={4} borderColor="brand.borderColor">
              <BlockedSiteEntry
                onChange={(value) => update(index, value)}
                onRemove={() => remove(index)}
                name={`${name}.[${index}]`}
                value={field}
              />
            </Frame>
          </ListItem>
        ))}
      </List>
    </VStack>
  );
});
