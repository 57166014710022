/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { isGenericError } from '@time-neko/shared/type-guards';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function castAsError(value: any) {
  if (isGenericError(value)) {
    return value;
  }

  if (typeof value === 'object' && 'message' in value && 'name' in value) {
    const err = new Error(value.message);

    Object.assign(err, value);

    return err;
  }

  return new Error(String(value));
}
