/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { composeMedia } from '../composeMedia';
import img from '../../assets/CatReadsBook.gif';

export const CatReadsBook = composeMedia({
  alt: 'Cat reads book',
  className: 'cat-reads-book',
  assetMap: {
    dark: img,
    light: img,
  },
  type: 'img',
});
