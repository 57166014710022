/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { useCallback, useMemo } from 'react';
import { useInlineConfirm } from '@time-neko/frontend/common';
import { isTaskSnoozed, Task, TaskState } from '@time-neko/shared/domain/tasks';
import { Box, MenuItemProperties } from '@time-neko/frontend/ui';
import { useTasksRemoval } from './useTasksRemoval';
import { frontendTasksSchema } from '@time-neko/frontend/domain/tasks/schema';
import { FrontendIntegrationSectionDefinition } from '@time-neko/frontend/domain/integrations/web';
import { addDays } from 'date-fns';

export function useTaskMenu(
  task: Task,
  integrationDefinition?: FrontendIntegrationSectionDefinition
) {
  const { removeTasks, loading } = useTasksRemoval();

  const updateTaskMutation = frontendTasksSchema.updateTask.useCommand();

  const handleTaskRemoval = useCallback(
    () => removeTasks([task]),
    [removeTasks, task]
  );
  const { text: deleteText, handleClick: handleDeleteClick } = useInlineConfirm(
    {
      onClick: handleTaskRemoval,
      confirmText: 'Delete?',
      actionText: 'Delete task',
    }
  );

  const handleSnooze = useCallback(() => {
    const date = addDays(new Date(), 1);
    date.setHours(0, 1, 0, 0);

    updateTaskMutation.mutate({
      ...task,
      snoozedUntil: date,
    });
  }, [task, updateTaskMutation]);

  const changePomodoro = useCallback(
    (action: 'increment' | 'decrement') => async () => {
      const currentDuration = task.estimatedPomodoroDuration ?? 0;

      let newDuration =
        action === 'increment' ? currentDuration + 1 : currentDuration - 1;

      if (newDuration < 0) {
        newDuration = 0;
      }

      updateTaskMutation.mutate({
        ...task,
        estimatedPomodoroDuration: newDuration,
      });
    },
    [task, updateTaskMutation]
  );

  return useMemo<MenuItemProperties[]>(() => {
    const base: MenuItemProperties[] = [
      {
        renderAs: 'item',
        color: 'brand.danger',
        className: 'delete-task',
        id: 'delete_task',
        label: loading ? 'Deleting...' : deleteText,
        onClick: handleDeleteClick,
      },
    ];

    if (task.state === TaskState.Completed) {
      return base;
    }

    return [
      {
        renderAs: 'item',
        label: 'Add 1 pomodoro',
        onClick: changePomodoro('increment'),
        id: 'increment_pomodoro',
      },
      {
        renderAs: 'item',
        label: 'Remove 1 pomodoro',
        onClick: changePomodoro('decrement'),
        id: 'decrement_pomodoro',
        disabled: !task.estimatedPomodoroDuration,
      },
      ...(isTaskSnoozed(task)
        ? []
        : ([
            {
              renderAs: 'item',
              className: 'snooze-task',
              id: 'snooze_task',
              isDisabled: updateTaskMutation.isLoading,
              label: 'Snooze until tomorrow',
              onClick: handleSnooze,
              closeOnSelect: true,
            },
          ] as MenuItemProperties[])),
      ...(integrationDefinition && task.sourceUrl
        ? ([
            {
              icon: (
                <Box
                  sx={{
                    '& svg': {
                      boxSize: '2sm',
                    },
                  }}
                >
                  {integrationDefinition.icon}
                </Box>
              ),
              renderAs: 'item',
              label: `View in ${integrationDefinition.label}`,
              id: 'view_in_integration',
              onClick: () => {
                if (task.sourceUrl) {
                  window.open(task.sourceUrl, '_blank');
                }
              },
            },
          ] as MenuItemProperties[])
        : []),
      {
        renderAs: 'separator',
        id: 'separator',
      },
      ...base,
    ];
  }, [
    loading,
    deleteText,
    handleDeleteClick,
    task,
    changePomodoro,
    updateTaskMutation.isLoading,
    handleSnooze,
    integrationDefinition,
  ]);
}
