/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { PlacementWithLogical } from '@time-neko/frontend/ui';

export const placement: PlacementWithLogical = 'bottom-end';
export const menuSelector = `& [data-popper-placement="${placement}"]`;
