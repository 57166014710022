/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useMemo } from 'react';
import type { InputProps } from '../input/Input';
import { Theme, useTheme } from '@time-neko/frontend/providers/theme';
import { css } from '@emotion/react';
import { Balloon } from '../balloon/Balloon';
import { chakra, ChakraProps } from '@chakra-ui/system';
import Paw from '@time-neko/frontend/assets/file/Paw.png';

const Input = chakra.input;

export type SliderProps = Omit<InputProps, 'type'>;

const makeStyles = (theme: Theme) => {
  const thumbBg = 'transparent';

  const commonThumbStyles = css`
    height: 20px;
    width: 40px;
    margin-left: 0;
    background: ${thumbBg} url(${Paw as unknown as string}) no-repeat center;
    background-size: contain;
    cursor: ${theme.cursors.pointer};
    box-shadow: -400px 0 0 400px ${thumbBg};
  `;

  const commonTrackStyles = css`
    width: 100%;
    height: 100%;
    border-radius: 0;
    background: transparent;
    cursor: inherit;
  `;

  return css`
    & {
      -webkit-appearance: none;
      width: 100%;
      background-color: transparent;
      cursor: inherit;
      overflow: hidden;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      ${commonThumbStyles}
    }

    &::-moz-range-thumb {
      ${commonThumbStyles}
    }

    &::-ms-thumb {
      ${commonThumbStyles}
    }

    &:focus {
      outline: none;
    }

    &::-ms-track {
      width: 100%;
      cursor: inherit;

      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-webkit-slider-runnable-track {
      ${commonTrackStyles}
    }
  `;
};

export function Slider(props: SliderProps) {
  const theme = useTheme();

  const cssStyles = useMemo(() => makeStyles(theme), [theme]);

  return (
    <Balloon
      display="inline-flex"
      alignItems="center"
      background="brand.surface"
      hidePart
      width="100%"
      height="auto"
      sx={{
        padding: 0,
        margin: 0,
        boxSizing: 'content-box',
      }}
    >
      <Input
        w="100%"
        h="100%"
        borderRadius={0}
        {...(props as ChakraProps)}
        padding={0}
        margin={0}
        css={cssStyles}
        type="range"
      />
    </Balloon>
  );
}
