import { PartialDeep } from 'type-fest';
import {
  PlainComponentSettingsFormField,
  SeparatorSettingsField,
  SettingsFields,
} from '@time-neko/frontend/domain/settings/field-definitions';
import { useDnd } from '@time-neko/frontend/domain/dnd';
import { useCallback, useMemo, useState } from 'react';
import { useNotificationPermissionRequest } from '@time-neko/frontend/notifications';
import { ExcludeFalsy } from '@time-neko/shared/utils';
import merge from 'lodash.merge';
import {
  isLongBreakDisabled,
  PomodoroSettings,
} from '@time-neko/shared/domain/pomodoro';
import { logger } from '@time-neko/shared/logger';
import { FormController } from '@time-neko/frontend/ui';

export type PomodoroSettingsFieldsDefinition = {
  [Key in keyof PomodoroSettings]: SettingsFields & {
    name: `pomodoroSettings.${Key}`;
  };
} & {
  separator: SeparatorSettingsField;
};

export interface UsePomodoroSettingsFieldsParams {
  overrides?: PartialDeep<SettingsFields>[];
  PomodoroReminderSettingsComponent?: PlainComponentSettingsFormField['Component'];
}

/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */
export function usePomodoroSettingsFields({
  overrides = [],
  PomodoroReminderSettingsComponent,
}: UsePomodoroSettingsFieldsParams = {}) {
  const { isSupportedQuery } = useDnd();

  const [longBreakDisabled, setLongBreakDisabled] = useState(false);
  const [breakWindowAsOverlayDisabled, setBreakWindowAsOverlayDisabled] =
    useState(false);

  const { requestPermissionIfNotGranted } = useNotificationPermissionRequest();

  const filterFields = useCallback(
    (fields: Array<SettingsFields | undefined>): SettingsFields[] => {
      return fields.filter(ExcludeFalsy).map((field) => {
        if (field.renderAs === 'section') {
          return {
            ...field,
            fields: filterFields(field.fields),
          };
        }

        const override = overrides?.find(
          (override) =>
            'name' in override &&
            'name' in field &&
            override.name === field.name
        );

        return override ? merge(field, override as SettingsFields[]) : field;
      }) as SettingsFields[];
    },
    [overrides]
  );

  return useMemo<SettingsFields[]>(() => {
    const fields: Array<SettingsFields | undefined> = [
      {
        renderAs: 'section',
        title: 'Session durations',
        fields: [
          {
            label: 'Work duration',
            name: 'pomodoroSettings.workDurationSeconds',
            rules: {
              max: 9999,
            },
            renderAs: 'duration',
          },
          {
            label: 'Break duration',
            name: 'pomodoroSettings.shortBreakDurationSeconds',
            rules: {
              max: 9999,
            },
            renderAs: 'duration',
          },
          {
            label: 'Long break duration',
            name: 'pomodoroSettings.longBreakDurationSeconds',
            rules: {
              max: 9999,
            },
            renderAs: 'duration',
            isDisabled: longBreakDisabled,
          },
          {
            label: 'Long break interval',
            name: 'pomodoroSettings.longBreakInterval',
            helperText: `Amount of short breaks before long break happens. Set to 0 to disable long breaks.`,
            renderAs: 'number',
            onChange: (value: number) => {
              if (!value) {
                return;
              }

              setLongBreakDisabled(
                isLongBreakDisabled(parseInt(value.toString()))
              );
            },
          },
        ],
      },
      {
        renderAs: 'section',
        title: 'Break preferences',
        fields: [
          {
            label: 'Open separate window on break',
            name: 'pomodoroSettings.openFullWindowOnBreak',
            renderAs: 'yesNo',
            onChange: (value?: boolean) => {
              setBreakWindowAsOverlayDisabled(!value);
            },
          },
          {
            label: 'Show break window as overlay instead',
            name: 'pomodoroSettings.breakWindowAsOverlay',
            helperText:
              'When enabled, break window will appear as an overlay, hiding content behind it. If you are using multiple monitors, the overlay will be shown on all of them.',
            renderAs: 'yesNo',
            isDisabled: breakWindowAsOverlayDisabled,
          },
        ],
      },
      {
        renderAs: 'section',
        title: 'Auto session start',
        fields: [
          {
            label: 'Start break automatically',
            name: 'pomodoroSettings.autoRunBreak',
            renderAs: 'yesNo',
          },
          {
            label: 'Start work automatically',
            name: 'pomodoroSettings.autoRunWork',
            renderAs: 'yesNo',
          },
        ],
      },

      {
        renderAs: 'section',
        title: 'Session state change preferences',
        fields: [
          {
            label: 'Show overlay one minute before break',
            name: 'pomodoroSettings.showOverlayBeforeBreak',
            renderAs: 'yesNo',
          },
          {
            label: 'Show notification whenever state changes',
            name: 'pomodoroSettings.showNotificationOnStateChange',
            renderAs: 'yesNo',
            onChange: (
              value: boolean,
              setValue: (value: boolean) => unknown
            ) => {
              if (value) {
                const handleDenial = () => setValue(false);

                requestPermissionIfNotGranted({
                  onDenied: handleDenial,
                }).catch(logger.error);
              }
            },
          },
          ...(isSupportedQuery.data
            ? [
                {
                  label: 'Enable "Do Not Disturb" mode on break',
                  name: 'pomodoroSettings.dndOnBreak',
                  renderAs: 'yesNo',
                } as SettingsFields,
              ]
            : []),
          {
            label: 'Sound to play when work starts',
            name: 'pomodoroSettings.workSound',
            renderAs: 'audio',
            FormController,
          },
          {
            label: 'Sound to play when break starts',
            name: 'pomodoroSettings.breakSound',
            renderAs: 'audio',
            FormController,
          },
          {
            label: 'Sound to play when long break starts',
            name: 'pomodoroSettings.longBreakSound',
            renderAs: 'audio',
            FormController,
          },
          ...(PomodoroReminderSettingsComponent
            ? ([
                {
                  renderAs: 'accordion',
                  accordionProps: {
                    mt: 3,
                  },
                  fields: {
                    reminders: [
                      {
                        renderAs: 'plainComponent',
                        Component: PomodoroReminderSettingsComponent,
                      },
                    ],
                  },
                },
              ] as SettingsFields[])
            : []),
        ],
      },
    ];

    return filterFields(fields);
  }, [
    PomodoroReminderSettingsComponent,
    breakWindowAsOverlayDisabled,
    filterFields,
    isSupportedQuery.data,
    longBreakDisabled,
    requestPermissionIfNotGranted,
  ]);
}
