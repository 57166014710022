/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Box } from '@time-neko/frontend/ui';

import { ChakraProps, keyframes } from '@chakra-ui/system';
import { usePomodoro } from '@time-neko/frontend/domain/pomodoro/hooks';

export function NextStateNotificationOverlay(props: ChakraProps) {
  const { pomodoro } = usePomodoro();

  return (
    <Box
      top={0}
      left={0}
      zIndex={2500}
      className="next-state-overlay"
      pointerEvents="none"
      userSelect="none"
      position="absolute"
      width="100%"
      height="100%"
      opacity={NextStateNotificationOverlay.opacity}
      cursor="initial"
      backgroundColor={
        pomodoro ? `brand.${pomodoro.nextState}` : 'brand.primaryScheme.400'
      }
      {...props}
    />
  );
}

NextStateNotificationOverlay.opacity = 0.2;
NextStateNotificationOverlay.fadeInOverlay = keyframes`
 from {
    opacity: 0;
  }

  to {
    opacity: ${NextStateNotificationOverlay.opacity};
  }
`;
