/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useQueryClient } from '@tanstack/react-query';
import { settingsSchema } from '@time-neko/shared/domain/settings';
import { OperationSchemaOperations } from '@musubi/core';
import { frontendSettingsSchema } from '@time-neko/frontend/domain/settings/schema';

export function useSettingsListener() {
  const queryClient = useQueryClient();

  frontendSettingsSchema.settingsUpdated.useEvent(async () => {
    await Promise.all([
      queryClient.invalidateQueries([
        'getSettings' as OperationSchemaOperations<typeof settingsSchema>,
      ]),
      queryClient.invalidateQueries([
        'getSetting' as OperationSchemaOperations<typeof settingsSchema>,
      ]),
    ]);
  }, [queryClient]);
}
