/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { AudioName, resolveAudioPath } from '@time-neko/shared/domain/audio';
import { useCallback, useState } from 'react';
import { useEvent } from 'react-use';
import { useSharedAudioElement } from './useSharedAudioElement';

/**
 * Fixes autoplay issue on iOS by creating muted audio element on first user interaction, and then re-using it.
 * */
export function useFixIosAutoplay() {
  const [didFix, setDidFix] = useState(false);
  const setElement = useSharedAudioElement((store) => store.setElement);

  const applyFix = useCallback(async () => {
    if (!didFix) {
      const audio = new Audio();

      audio.addEventListener('ended', function onEnded() {
        audio.muted = false;

        audio.removeEventListener('ended', onEnded);
      });

      audio.muted = true;
      audio.src = resolveAudioPath(AudioName.Achievement1);

      await audio.play();

      setElement(audio);

      setDidFix(true);
    }
  }, [didFix, setElement]);

  useEvent('click', applyFix, window as Window, { once: true });
  useEvent('touchstart', applyFix, window as Window, { once: true });
}
