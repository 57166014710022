/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef, ReactNode } from 'react';
import {
  Alert as BaseAlert,
  AlertProps as BaseAlertProps,
  AlertStatus,
  ButtonGroup,
  HStack,
  IconButton,
  useColorMode,
  useToken,
} from '@chakra-ui/react';
import classNames from 'classnames';
import { Text } from '../../atoms';
import { Asset, AssetName } from '@time-neko/frontend/assets';
import { OmitUnderscored } from '@time-neko/shared/common-types';

export interface AlertActionsBag {
  handleClose?: () => unknown;
}

export interface AlertProps
  extends Omit<OmitUnderscored<BaseAlertProps>, 'backgroundColor' | 'title'> {
  title?: ReactNode;
  isClosable?: boolean;
  onClose?: () => unknown;
  isCentered?: boolean;
  hideIcon?: boolean;
  borderless?: boolean;
  actions?: (bag: AlertActionsBag) => ReactNode;
}

const defaultTitleMap: Record<AlertStatus, string> = {
  info: 'Info',
  success: 'Success',
  warning: 'Warning',
  error: 'Error',
  loading: 'Loading',
};

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      status,
      title = status ? defaultTitleMap[status] : undefined,
      isClosable,
      onClose,
      children,
      isCentered,
      className,
      hideIcon,
      sx,
      borderless,
      actions,
      ...props
    },
    ref
  ) => {
    const { colorMode } = useColorMode();

    const [successColor, dangerColor, warningColor, infoColor] = useToken(
      'colors',
      ['brand.success', 'brand.danger', 'brand.warning', 'brand.info']
    );

    const colors: Record<AlertStatus, string> = {
      success: successColor,
      error: dangerColor,
      warning: warningColor,
      info: infoColor,
      loading: infoColor,
    };

    const icons: Record<AlertStatus, AssetName> = {
      info: 'Info',
      success: 'PixelCheck',
      error: 'X',
      warning: 'X',
      loading: 'Hourglass',
    };

    const color = status ? colors[status] : colors.info;
    const icon = status && !hideIcon ? icons[status] : undefined;

    return (
      <BaseAlert
        ref={ref}
        display="flex"
        flexDirection="column"
        sx={{
          '&.alert.nes-container': {
            backgroundColor: color,

            '&.with-title .title': {
              display: 'inline-flex',
            },

            '&.borderless': {
              borderImage: 'none',
              border: 'none',
            },
          },
          ...sx,
        }}
        className={classNames(
          'nes-container',
          'alert',
          'is-rounded',
          className,
          {
            'with-title': Boolean(title),
            'is-dark': colorMode === 'dark',
            'is-centered': isCentered,
            borderless,
          }
        )}
        {...props}
      >
        {(title || icon) && (
          <HStack
            mt="0 !important"
            maxW="75%"
            className="title title-stack"
            justifyContent={isCentered ? 'center' : undefined}
            as="span"
          >
            {icon && <Asset name={icon} boxSize="20" />}
            {title && (
              <Text as="div" fontSize="lg">
                {title}
              </Text>
            )}
          </HStack>
        )}
        {children}
        {actions && (
          <ButtonGroup mt={4}>{actions({ handleClose: onClose })}</ButtonGroup>
        )}
        {isClosable && (
          <IconButton
            className="close-dialog"
            position="absolute"
            right="10px"
            top={borderless ? '5px' : '10px'}
            onClick={onClose}
            variant="nes-ghost"
            aria-label="Close alert"
          >
            <Text color="white">X</Text>
          </IconButton>
        )}
      </BaseAlert>
    );
  }
);
