/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { frontendTasksSchema } from '@time-neko/frontend/domain/tasks/schema';

export function useTasksListeners() {
  const activeTaskQuery = frontendTasksSchema.getActiveTask.useQuery({
    enabled: false,
  });

  frontendTasksSchema.activeTaskUpdated.useEvent(
    ({ payload }) => {
      activeTaskQuery.setQueryData(payload ?? null);
    },
    [activeTaskQuery.setQueryData]
  );
}
