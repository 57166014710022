/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { RadioProps } from '../../atoms/radio/Radio';
import { chakra, ChakraProps } from '@chakra-ui/system';
import { forwardRef, KeyboardEventHandler, useCallback } from 'react';
import { Balloon } from '../../atoms/balloon/Balloon';
import { Paw } from '@time-neko/frontend/assets';
import { useTheme } from '@time-neko/frontend/providers/theme';

export interface SwitchFieldProps
  extends Pick<RadioProps, 'name' | 'id'>,
    Pick<Required<RadioProps>, 'isChecked'>,
    ChakraProps {
  onChange: (checked: boolean) => unknown;
  size?: string;
  checkedBg?: string;
}

const ChakraInput = chakra.input;

export const SwitchField = forwardRef<HTMLInputElement, SwitchFieldProps>(
  (
    {
      name,
      isChecked,
      onChange,
      id,
      size = 'md',
      checkedBg = 'brand.primary',
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const themeSize = theme.sizes[size];

    const handleChange = useCallback(() => {
      onChange(!isChecked);
    }, [isChecked, onChange]);

    const handleKeyPress = useCallback<KeyboardEventHandler>(
      (event) => {
        if (event.key === ' ') {
          event.preventDefault();
          event.stopPropagation();

          handleChange();
        }
      },
      [handleChange]
    );

    return (
      <Balloon
        className="switch-field"
        tabIndex={0}
        onClick={handleChange}
        role="checkbox"
        aria-checked={isChecked}
        aria-labelledby={id}
        hidePart
        onKeyDown={handleKeyPress}
        height={themeSize.toString()}
        width={`calc(${themeSize.toString()} * 2)`}
        display="inline-flex"
        {...props}
        sx={{
          padding: 0,
          margin: 0,
          outline: 'none',
          transition: 'background .5s',

          '&.switch-field.nes-balloon': {
            background: isChecked ? `${checkedBg} !important` : undefined,

            '&:focus': {
              boxShadow: theme.shadows.focusVisible,
            },
          },
          ...props.sx,
        }}
      >
        <ChakraInput
          ref={ref}
          type="checkbox"
          display="none"
          checked={isChecked}
          readOnly
        />
        <Paw
          pointerEvents="none"
          maxWidth="50%"
          width="auto"
          height="auto"
          transition="all .3s"
          marginLeft={isChecked ? `50%` : undefined}
        />
      </Balloon>
    );
  }
);
