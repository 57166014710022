/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useScheduler } from './useScheduler';

interface InlineConfirmHookProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (event: MouseEvent, ...args: any[]) => unknown;
  actionText: string;
  confirmText: string;
  confirmedText?: string;
}

export function useInlineConfirm({
  actionText,
  confirmText,
  onClick,
  confirmedText,
}: InlineConfirmHookProps) {
  const [didConfirm, setDidConfirm] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const text = useMemo(() => {
    if (didConfirm && confirmedText) {
      return confirmedText;
    }

    return isConfirm ? confirmText : actionText;
  }, [actionText, confirmText, confirmedText, didConfirm, isConfirm]);

  const cancelConfirmation = useCallback(() => {
    setIsConfirm(false);
  }, []);

  const revertConfirmation = useCallback(() => {
    setDidConfirm(false);
  }, []);

  const { schedule: scheduleCancel } = useScheduler(cancelConfirmation);
  const { schedule: scheduleRevertConfirmation } =
    useScheduler(revertConfirmation);

  const handleClick = useCallback(
    async (event: MouseEvent, ...args: unknown[]) => {
      if (!isConfirm) {
        setIsConfirm(true);

        scheduleCancel(4000);

        return;
      }

      await onClick(event, ...args);
      setIsConfirm(false);
      setDidConfirm(true);

      scheduleRevertConfirmation(4000);
    },
    [isConfirm, onClick, scheduleCancel, scheduleRevertConfirmation]
  );

  return {
    isConfirm,
    text,
    handleClick,
    cancelConfirmation,
    didConfirm,
  };
}
