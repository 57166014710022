/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  applyVariablesToText,
  TemplateType,
} from '@time-neko/shared/utils/template';
import { RouteCreator, RouteParams } from './types';

export const paramRoute =
  <Params extends RouteParams>(
    route: string,
    defaults?: Partial<Params>
  ): RouteCreator<Params> =>
  (params?: Params) => {
    const allParams = {
      ...defaults,
      ...params,
    };

    if (!params) {
      return route.split('?')[0];
    }

    return applyVariablesToText(route, allParams, TemplateType.Colon).replace(
      /:\w+/g,
      ''
    );
  };

export type ParamRouteResult = ReturnType<typeof paramRoute>;
