/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { AppPlatform, ErrorSchema } from '@time-neko/shared/domain/types';
import { MaybePromise } from '@time-neko/shared/common-types';
import type { Endpoints } from '@octokit/types';
import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';

export const UpdatesConfigSchema = z.object({
  includePrereleases: z.boolean().optional(),
  autoUpdateEnabled: z.boolean().optional(),
});

export type UpdatesConfig = z.infer<typeof UpdatesConfigSchema>;

export const AppUpdateAssetSchema = z.object({
  fileName: z.string(),
  url: z.string().url(),
  downloadUrl: z.string().url(),
});

export type AppUpdateAsset = z.infer<typeof AppUpdateAssetSchema>;

export const AppReleaseSchema = z.object({
  version: z.string(),
  prerelease: z.boolean(),
  releaseDate: z.coerce.date().optional(),
  releaseNotes: z.string().optional(),
  tag: z.string(),
  url: z.string().url(),
  assets: z.array(AppUpdateAssetSchema).optional(),
  isDraft: z.boolean().optional(),
});

export type AppRelease = z.infer<typeof AppReleaseSchema>;

export const AppReleasesSchema = z.record(
  z.nativeEnum(AppPlatform),
  AppReleaseSchema.optional().nullable()
);

export type AppReleases = z.infer<typeof AppReleasesSchema>;

export const ReleaseRepositorySchema = z.object({
  owner: z.string(),
  repo: z.string(),
});

export type ReleaseRepository = z.infer<typeof ReleaseRepositorySchema>;

export const CheckForUpdatesPayloadSchema = z.object({
  force: z.boolean().optional(),
});

export type CheckForUpdatesPayload = z.infer<
  typeof CheckForUpdatesPayloadSchema
>;

export const AppUpdateDownloadProgressSchema = z.object({
  percentage: z.number(),
});

export type AppUpdateDownloadProgress = z.infer<
  typeof AppUpdateDownloadProgressSchema
>;

export const GetVersionInfoPayloadSchema = z.object({
  version: z.string(),
});

export type GetVersionInfoPayload = z.infer<typeof GetVersionInfoPayloadSchema>;

export interface PerformUpdateContext {
  appVersion: string;
}

export interface UpdateStrategy<Service = unknown> {
  // Indicates whenever strategy downloads update file
  readonly downloadsFile: boolean;
  // Determines whether update should be performed
  isUpdateValid?: (update: AppRelease) => MaybePromise<boolean>;
  // Custom logic for performing update
  performUpdate: (
    update: AppRelease,
    ctx: PerformUpdateContext
  ) => MaybePromise<boolean>;
  // Custom logic that checks for new updates
  checkForUpdate?: (service: Service) => MaybePromise<AppRelease | undefined>;
  // Provides additional metadata for update
  getMetaForRelease?: (update: AppRelease) => MaybePromise<AppReleaseMeta>;
}

export const AppReleaseMetaSchema = z.object({
  manualUpdate: z.boolean(),
});

export type AppReleaseMeta = z.infer<typeof AppReleaseMetaSchema>;

export const UpdateStrategyInfoSchema = z.object({
  downloadsFile: z.boolean(),
});

export type UpdateStrategyInfo = z.infer<typeof UpdateStrategyInfoSchema>;

export enum UpdateState {
  Idle = 'Idle',
  Downloading = 'Downloading',
  Downloaded = 'Downloaded',
  Failed = 'Failed',
}

export type GithubRelease =
  Endpoints['GET /repos/{owner}/{repo}/releases']['response']['data'][number];

export const GetAppReleasesQuerySchema = z.object({
  includePrereleases: z.boolean().optional(),
});

export type GetAppReleasesQuery = z.infer<typeof GetAppReleasesQuerySchema>;

export const UpdateErrorSchema = ErrorSchema.pick({
  message: true,
  name: true,
}).extend({
  update: AppReleaseSchema,
});

export type UpdateError = z.infer<typeof UpdateErrorSchema>;

export const updatesSchema = defineSchema({
  events: {
    updateDownloadProgress: operation.event.withPayload(
      AppUpdateDownloadProgressSchema
    ),
    updateDownloadCompleted: operation.event,
    updateStateChanged: operation.event.withPayload(z.nativeEnum(UpdateState)),
    updateAvailable: operation.event.withPayload(AppReleaseSchema),
  },
  queries: {
    areUpdatesEnabled: operation.query.withResult(z.boolean()),
    checkForUpdates: operation.query
      .withPayload(CheckForUpdatesPayloadSchema)
      .withResult(AppReleaseSchema.optional().nullable()),
    getUpdateStrategy: operation.query.withResult(UpdateStrategyInfoSchema),
    getUpdateState: operation.query.withResult(z.nativeEnum(UpdateState)),
    getUpdateMeta: operation.query
      .withPayload(AppReleaseSchema)
      .withResult(AppReleaseMetaSchema.optional()),
    getVersionInfo: operation.query
      .withPayload(GetVersionInfoPayloadSchema)
      .withResult(AppReleaseSchema.nullable().optional()),
  },
  commands: {
    performUpdate: operation.command.withPayload(AppReleaseSchema),
    postponeUpdate: operation.command,
  },
});
