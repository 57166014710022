/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ReactNode } from 'react';

export const isReactNode = (item: unknown): item is ReactNode =>
  typeof item === 'string' ||
  typeof item === 'number' ||
  (typeof item === 'object' && item !== null && '$$typeof' in item);
