/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { maybePlural } from '@time-neko/shared/utils';
import { TimeUnits } from '@time-neko/shared/common-types';

export class Time {
  private static shortUnitsMap = {
    [TimeUnits.Seconds]: 's',
    [TimeUnits.Minutes]: 'm',
    [TimeUnits.Hours]: 'h',
  };

  constructor(
    public hours = 0,

    public minutes = 0,

    public seconds = 0
  ) {}

  get hasHours() {
    return this.hours > 0;
  }

  get hasMinutes() {
    return this.minutes > 0;
  }

  get hasSeconds() {
    return this.seconds > 0;
  }

  get asSeconds() {
    return this.hours * 3600 + this.minutes * 60 + this.seconds;
  }

  get asMilliseconds() {
    return this.asSeconds * 1000;
  }

  get asMinutes() {
    return this.asSeconds / 60;
  }

  addSeconds(seconds: number) {
    this.seconds += seconds;

    return this;
  }

  static fromSeconds(seconds: number) {
    return new Time(
      Math.floor(seconds / 60 / 60),
      Math.floor((seconds / 60) % 60),
      Math.floor(seconds % 60)
    );
  }

  static fromMinutes(minutes: number) {
    return new Time(Math.floor(minutes / 60), Math.floor(minutes % 60), 0);
  }

  static fromHours(hours: number) {
    return this.fromMinutes(hours * 60);
  }

  getByUnit(timeUnit: TimeUnits) {
    switch (timeUnit) {
      case TimeUnits.Hours:
        return this.hours;

      case TimeUnits.Minutes:
        return this.minutes;

      case TimeUnits.Seconds:
        return this.seconds;
    }
  }

  toWrappedTimeString(timeUnits: TimeUnits[]) {
    return timeUnits
      .reduce<string[]>(
        (acc, unit) => [
          ...acc,
          this.getByUnit(unit).toString().concat(Time.shortUnitsMap[unit]),
        ],
        []
      )
      .join('');
  }

  toClockString() {
    const timeArr = [];

    if (this.hours) {
      timeArr.push(this.hours);
    }

    timeArr.push(this.minutes);
    timeArr.push(this.seconds);

    return timeArr.map((val) => (val < 10 ? `0${val}` : val)).join(':');
  }

  toDetailedString() {
    const result = [];

    if (this.hours) {
      result.push(`${this.hours} ${maybePlural(this.hours, 'hour', 'hours')}`);
    }

    if (this.minutes) {
      result.push(
        `${this.minutes} ${maybePlural(this.minutes, 'minute', 'minutes')}`
      );
    }

    if (this.seconds) {
      result.push(
        `${this.seconds} ${maybePlural(this.seconds, 'second', 'seconds')}`
      );
    }

    return result.join(' ');
  }
}
