/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import PawImage from '../../assets/Paw.png';
import { composeMedia } from '../composeMedia';

export const Paw = composeMedia({
  type: 'img',
  alt: 'Paw',
  assetMap: {
    light: PawImage,
    dark: PawImage,
  },
});
