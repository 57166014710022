/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ChakraProps } from '@chakra-ui/system';
import { ComponentType, PropsWithChildren } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { TitleBar, TitleBarProps } from '../../molecules/titleBar/TitleBar';

export interface AppViewProps extends ChakraProps {
  titleBarProps?: TitleBarProps;
  TitleBar?: ComponentType<TitleBarProps>;
  contentsContainerProps?: ChakraProps;
}

export function AppView({
  children,
  titleBarProps,
  TitleBar: TitleBarComponent = TitleBar,
  contentsContainerProps,
  ...props
}: PropsWithChildren<AppViewProps>) {
  return (
    <VStack background="brand.bg" height="var(--chakra-vh)" {...props}>
      {<TitleBarComponent {...titleBarProps} />}
      <Box
        overflow="auto"
        width="100%"
        flex={1}
        as="section"
        {...contentsContainerProps}
      >
        {children}
      </Box>
    </VStack>
  );
}
