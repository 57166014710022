/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactComponent as DotEmpty } from '../assets/Dot_Empty.svg';
import { ReactComponent as DotFilled } from '../assets/Dot_Filled.svg';
import { ReactComponent as X } from '../assets/X.svg';
import { ReactComponent as Clock } from '../assets/Clock.svg';
import { ReactComponent as PixelCheck } from '../assets/PixelCheck.svg';
import { ReactComponent as Arrow } from '../assets/Arrow.svg';
import { ReactComponent as Coin } from '../assets/Coin.svg';
import { ReactComponent as Info } from '../assets/Info.svg';
import { ReactComponent as Squares } from '../assets/Squares.svg';
import { ReactComponent as Star } from '../assets/Star.svg';
import { ReactComponent as Hourglass } from '../assets/Hourglass.svg';
import { ReactComponent as Cup } from '../assets/Cup.svg';
import { ReactComponent as Workout } from '../assets/Workout.svg';
import { ReactComponent as Leaf } from '../assets/Leaf.svg';
import { ReactComponent as Tree } from '../assets/Tree.svg';
import { ReactComponent as Stretch } from '../assets/Stretch.svg';
import { ReactComponent as Settings } from '../assets/Settings.svg';
import { ReactComponent as TrashCan } from '../assets/TrashCan.svg';
import { ReactComponent as ArrowRepeat } from '../assets/ArrowRepeat.svg';
import { ReactComponent as DoubleArrow } from '../assets/DoubleArrow.svg';
import { ReactComponent as Plus } from '../assets/Plus.svg';
import { ReactComponent as Check } from '../assets/Check.svg';
import { ReactComponent as Statistics } from '../assets/Statistics.svg';
import { ReactComponent as Sync } from '../assets/Sync.svg';
import { ReactComponent as Ellipsis } from '../assets/Ellipsis.svg';
import { ReactComponent as ChevronBold } from '../assets/ChevronBold.svg';
import { ReactComponent as CatPointing } from '../assets/CatPointing.svg';
import { ReactComponent as Notion } from '../assets/Notion.svg';
import { ReactComponent as SmartCat } from '../assets/SmartCat.svg';
import { ReactComponent as Trello } from '../assets/Trello.svg';
import { ReactComponent as AngryCat } from '../assets/AngryCat.svg';
import { ReactComponent as External } from '../assets/External.svg';
import { ReactComponent as StopRounded } from '../assets/StopRounded.svg';
import { ReactComponent as Play } from '../assets/Play.svg';
import { ReactComponent as PauseRounded } from '../assets/PauseRounded.svg';
import { ReactComponent as StopYen } from '../assets/StopYen.svg';
import { ReactComponent as Square } from '../assets/Square.svg';
import { chakra } from '@chakra-ui/system';
import { FunctionComponent } from 'react';

export const parseIconComponent = (
  component: FunctionComponent
): FunctionComponent<any> => {
  return component ?? (() => <div>Icon</div>);
};

export const assetsMap = {
  DotEmpty: chakra(parseIconComponent(DotEmpty)),
  DotFilled: chakra(parseIconComponent(DotFilled)),
  X: chakra(parseIconComponent(X)),
  Clock: chakra(parseIconComponent(Clock)),
  PixelCheck: chakra(parseIconComponent(PixelCheck)),
  Check: chakra(parseIconComponent(Check)),
  // TODO Add <div>Icon made from <a href="http://www.onlinewebfonts.com/icon">Icon Fonts</a> is licensed by CC BY 3.0</div>
  Arrow: chakra(parseIconComponent(Arrow)),
  Info: chakra(parseIconComponent(Info)),
  Coin: chakra(parseIconComponent(Coin)),
  Star: chakra(parseIconComponent(Star)),
  Squares: chakra(parseIconComponent(Squares)),
  Hourglass: chakra(parseIconComponent(Hourglass)),
  Ellipsis: chakra(parseIconComponent(Ellipsis)),
  Cup: chakra(parseIconComponent(Cup)),
  Workout: chakra(parseIconComponent(Workout)),
  Leaf: chakra(parseIconComponent(Leaf)),
  Tree: chakra(parseIconComponent(Tree)),
  Stretch: chakra(parseIconComponent(Stretch)),
  Settings: chakra(parseIconComponent(Settings)),
  TrashCan: chakra(parseIconComponent(TrashCan)),
  ArrowRepeat: chakra(parseIconComponent(ArrowRepeat)),
  DoubleArrow: chakra(parseIconComponent(DoubleArrow)),
  Plus: chakra(parseIconComponent(Plus)),
  Statistics: chakra(parseIconComponent(Statistics)),
  Sync: chakra(parseIconComponent(Sync)),
  ChevronBold: chakra(parseIconComponent(ChevronBold)),
  CatPointing: chakra(parseIconComponent(CatPointing)),
  Notion: chakra(parseIconComponent(Notion)),
  SmartCat: chakra(parseIconComponent(SmartCat)),
  Trello: chakra(parseIconComponent(Trello)),
  AngryCat: chakra(parseIconComponent(AngryCat)),
  External: chakra(parseIconComponent(External)),
  StopRounded: chakra(parseIconComponent(StopRounded)),
  PauseRounded: chakra(parseIconComponent(PauseRounded)),
  Play: chakra(parseIconComponent(Play)),
  StopYen: chakra(parseIconComponent(StopYen)),
  Square: chakra(parseIconComponent(Square)),
};

assetsMap.SmartCat.defaultProps = {
  sx: {
    '*': {
      shapeRendering: 'crispedges',
    },
  },
};

const sx = {
  '& path, & rect': {
    fill: 'brand.iconPrimary',
  },
};

assetsMap.DotFilled.defaultProps = {
  sx,
};

assetsMap.DotFilled.defaultProps = {
  sx,
};

assetsMap.X.defaultProps = {
  sx,
};

assetsMap.TrashCan.defaultProps = {
  sx,
};

assetsMap.Sync.defaultProps = {
  sx,
};

assetsMap.ArrowRepeat.defaultProps = {
  sx,
};

assetsMap.Check.defaultProps = {
  sx,
};

assetsMap.Plus.defaultProps = {
  sx,
};

assetsMap.Ellipsis.defaultProps = {
  sx,
};

assetsMap.Notion.defaultProps = {
  sx,
};

assetsMap.ChevronBold.defaultProps = {
  sx,
};

assetsMap.PauseRounded.defaultProps = {
  sx,
};

assetsMap.StopRounded.defaultProps = {
  sx,
};

assetsMap.StopYen.defaultProps = {
  sx,
};

assetsMap.Square.defaultProps = {
  sx,
};

assetsMap.Statistics.defaultProps = {
  sx,
};

export type AssetName = keyof typeof assetsMap;
