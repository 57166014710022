/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { cloneElement, ReactElement, useState } from 'react';
import { ChakraProps } from '@chakra-ui/system';
import { useVideoControls } from '@time-neko/frontend/common';
import { Maybe } from '@time-neko/shared/common-types';
import { Box, Center, VStack } from '@chakra-ui/react';
import { Button, Text } from '../../atoms';

export interface VideoControlsProps extends ChakraProps {
  video: ReactElement;
}

export function VideoControls({ video, ...props }: VideoControlsProps) {
  const [videoElement, setVideoElement] =
    useState<Maybe<HTMLVideoElement>>(null);

  const { togglePlay, isPlaying, stop } = useVideoControls(videoElement);

  return (
    <VStack height="100%" width="100%" spacing={0} {...props}>
      <Box overflow="hidden" height="100%">
        {cloneElement(video, {
          ref: setVideoElement,
        })}
      </Box>
      <Center>
        <Button
          mt={2}
          className="video-controls-btn"
          onClick={() => {
            isPlaying ? stop() : togglePlay();
          }}
        >
          <Text className="default-color">
            {isPlaying ? 'Stop' : 'Start'} video
          </Text>
        </Button>
      </Center>
    </VStack>
  );
}
