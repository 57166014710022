/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { z } from 'zod';

export enum AppPlatform {
  Desktop = 'Desktop',
  Website = 'Website',
  Webapp = 'Webapp',
}

export const AppPlatformSchema = z.nativeEnum(AppPlatform);

export const appPlatformLabels = {
  [AppPlatform.Desktop]: 'Desktop',
  [AppPlatform.Website]: 'Website',
  [AppPlatform.Webapp]: 'Web app',
};
