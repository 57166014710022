/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PomodoroStates } from '@time-neko/shared/domain/pomodoro';
import { AppPlatform } from '@time-neko/shared/domain/types';
import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';

export const BlockedSiteRuleSchema = z.object({
  id: z.string(),
  // Not .url(), because sometimes it might be without Protocol
  url: z.custom<string>((value) => {
    if (typeof value !== 'string') {
      return false;
    }

    try {
      if (value.startsWith('http')) {
        new URL(value);
      } else {
        new URL(`http://${value}`);
      }

      return true;
    } catch {
      return false;
    }
  }),
  blockOnPomodoroStates: z.array(z.nativeEnum(PomodoroStates)).optional(),
});

export type BlockedSiteRule = z.infer<typeof BlockedSiteRuleSchema>;

export const BlockedSiteQueryParamsSchema = z.object({
  blockedUrl: z.string().url().optional(),
  ruleId: z.string().optional(),
  goBackUrl: z.string().url().optional(),
});

export type BlockedSiteQueryParams = z.infer<
  typeof BlockedSiteQueryParamsSchema
>;
export const AnonymousBlockedSiteQueryParamsSchema = z.object({
  pomodoroState: z.nativeEnum(PomodoroStates).optional(),
  platform: z.nativeEnum(AppPlatform).optional(),
});

export type AnonymousBlockedSiteQueryParams = z.infer<
  typeof AnonymousBlockedSiteQueryParamsSchema
>;
export enum SiteBlockedType {
  OnPomodoroStateChange = 'OnPomodoroStateChange',
  UserAttemptedVisit = 'UserAttemptedVisit',
}

export const SiteBlockedSchema = z.object({
  url: z.instanceof(URL),
  type: z.nativeEnum(SiteBlockedType),
  date: z.date(),
});

export type SiteBlockedPayload = z.infer<typeof SiteBlockedSchema>;

export const sitesBlockingSchema = defineSchema({
  queries: {},
  commands: {},
  events: {
    siteBlocked: operation.event.withPayload(SiteBlockedSchema),
  },
});
