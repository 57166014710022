/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

export enum NotionDatabaseFilterOperator {
  Equals = 'equals',
  DoesNotEqual = 'DoesNotEqual',
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
}
