/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ForwardedRef, forwardRef, useMemo } from 'react';
import { Select } from './Select';
import { SelectProps } from '@chakra-ui/react';

type Enum = Record<string, string | number>;

export interface EnumSelectProps<T extends Enum> extends SelectProps {
  enumObj: T;
  dictionary?: Record<keyof T, string>;
}

export const EnumSelect = forwardRef<HTMLSelectElement, EnumSelectProps<Enum>>(
  <T extends Enum>(
    { enumObj, dictionary, ...props }: EnumSelectProps<T>,
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    const enumArray = useMemo(() => Object.entries(enumObj), [enumObj]);

    return (
      <Select ref={ref} {...props}>
        {enumArray.map(([key, value]) => (
          <option key={key} value={key}>
            {dictionary?.[key] ?? value}
          </option>
        ))}
      </Select>
    );
  }
);
