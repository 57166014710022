/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { useCallback, useState } from 'react';
import { frontendOnboardingSchema } from '@time-neko/frontend/domain/onboarding/schema';
import { logger } from '@time-neko/shared/logger';

export enum OnboardingStep {
  Welcome,
  Features,
}

const stepOrder = [OnboardingStep.Welcome, OnboardingStep.Features];

function advanceStep(step: OnboardingStep) {
  const stepIndex = stepOrder.indexOf(step);

  return stepOrder[stepIndex + 1];
}

export interface UseOnboardingParams {
  onCompleted?: (wasSkipped: boolean) => unknown;
}

const log = logger.getChild('useOnboarding');

export function useOnboarding({ onCompleted }: UseOnboardingParams = {}) {
  const completeOnboarding =
    frontendOnboardingSchema.completeOnboarding.useCommand();

  const [step, setStep] = useState<OnboardingStep>(OnboardingStep.Welcome);

  const advance = useCallback(async () => {
    const nextStep = advanceStep(step);

    if (nextStep) {
      log.debug('Moving to next step', nextStep);

      setStep(nextStep);

      return;
    }

    log.debug('Onboard complete');

    await completeOnboarding.mutateAsync({
      wasSkipped: false,
    });

    onCompleted?.(false);
  }, [onCompleted, completeOnboarding, step]);

  const skip = useCallback(async () => {
    await completeOnboarding.mutateAsync({
      wasSkipped: true,
    });

    onCompleted?.(true);

    log.debug('Onboard skipped');
  }, [onCompleted, completeOnboarding]);

  return {
    step,
    advance,
    skip,
  };
}
