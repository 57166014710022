/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useNewDayDetector } from '@time-neko/frontend/domain/new-day-detector';
import { useOpenSettingShortcut } from '@time-neko/frontend/domain/settings/hooks';
import { Route } from 'react-router-dom';
import { PomodoroView } from './app/pomodoro/views/PomodoroView';
import { appRoutes } from '@time-neko/shared/routes';
import { WebAppContainer } from './components/WebAppContainer';
import {
  useAudioPlayer,
  useFixIosAutoplay,
} from '@time-neko/frontend/domain/audio';
import { useTasksListeners } from '@time-neko/frontend/domain/tasks/hooks';
import { useIosSafariInputPushFix } from '@time-neko/frontend/common';
import { lazy, Suspense, useEffect } from 'react';
import {
  Box,
  Loading,
  MobileBottomNavigation,
  VStack,
} from '@time-neko/frontend/ui';
import {
  getRecaptchaScript,
  RecaptchaInjectStrategy,
} from '@time-neko/frontend/domain/captcha';
import { Helmet } from 'react-helmet';
import { SettingsFormView } from './app/settings/views/SettingsFormView';
import { ReleaseInfo } from '@time-neko/frontend/domain/updates/web';
import { useIsSmallMobile } from '@time-neko/frontend/responsive';
import {
  usePomodoro,
  usePomodoroListeners,
} from '@time-neko/frontend/domain/pomodoro/hooks';
import { useMetaThemeColor } from './hooks/useMetaThemeColor';
import { useTheme } from '@time-neko/frontend/providers/theme';
import { PomodoroTasksList } from '@time-neko/frontend/domain/core/web';
import { useLocation, useNavigate } from '@time-neko/frontend/router';
import { AnimatedRoutes } from '@time-neko/frontend/router/react-router-dom';
import { frontendOnboardingSchema } from '@time-neko/frontend/domain/onboarding/schema';
import { useSplashStore } from './components/Splash';
import { OnboardingView } from './app/onboarding/OnboardingView';

const recaptchaScript = getRecaptchaScript(
  RecaptchaInjectStrategy.Remote,
  process.env.NX_RECAPTCHA_KEY as string
);

const FeedbackFormView = lazy(() =>
  import('./app/feedback/views/FeedbackFormView').then((mod) => ({
    default: mod.FeedbackFormView,
  }))
);

export function App() {
  useNewDayDetector();
  useOpenSettingShortcut();
  useAudioPlayer();
  useTasksListeners();
  useIosSafariInputPushFix();
  useFixIosAutoplay();
  usePomodoroListeners();

  const onbordingState = frontendOnboardingSchema.getOnboardingState.useQuery();
  const isOnboardingCompleted = Boolean(onbordingState.data?.completedAt);

  const setIsLoaded = useSplashStore((state) => state.setIsLoaded);

  const location = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();

  const isMobile = useIsSmallMobile();

  const { pomodoro } = usePomodoro();

  const [, setColor] = useMetaThemeColor();

  useEffect(() => {
    if (pomodoro) {
      setColor(theme.colors.brand[pomodoro.state]);
    }
  }, [pomodoro, setColor, theme.colors.brand]);

  useEffect(() => {
    if (!isMobile && location.pathname === appRoutes.tasks()) {
      navigate('/');
    }
  }, [isMobile, location.pathname, navigate]);

  useEffect(() => {
    if (!onbordingState.isLoading) {
      setIsLoaded(true);
    }
  }, [onbordingState.isLoading, setIsLoaded]);

  return (
    <VStack
      maxHeight="var(--chakra-vh)"
      height="100%"
      width="100%"
      backgroundColor={pomodoro ? `brand.${pomodoro.state}` : undefined}
      spacing={0}
    >
      <Helmet>{recaptchaScript}</Helmet>
      <Box flex={1} height="100%" overflow="hidden" width="100%">
        {!isOnboardingCompleted && <OnboardingView />}
        {isOnboardingCompleted && (
          <AnimatedRoutes>
            <Route path="/" element={<PomodoroView />} />
            <Route path={appRoutes.timer()} element={<PomodoroView />} />
            <Route
              path={`${appRoutes.settings()}/*`}
              element={<SettingsFormView />}
            />
            <Route
              path={appRoutes.feedback()}
              element={
                <Suspense fallback={<Loading />}>
                  <FeedbackFormView />
                </Suspense>
              }
            />
            {isMobile && (
              <Route
                path={appRoutes.tasks()}
                element={
                  <WebAppContainer>
                    <PomodoroTasksList
                      taskContainerProps={{ height: '100%' }}
                    />
                  </WebAppContainer>
                }
              />
            )}
            <Route
              path={appRoutes.releaseInfo()}
              element={
                <WebAppContainer overflow="hidden">
                  <Suspense fallback={<Loading />}>
                    <ReleaseInfo height="100%" />
                  </Suspense>
                </WebAppContainer>
              }
            />
          </AnimatedRoutes>
        )}
      </Box>
      {isMobile && pomodoro && isOnboardingCompleted && (
        <Box width="100%">
          <MobileBottomNavigation />
        </Box>
      )}
    </VStack>
  );
}
