/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { KeyboardShortcuts } from '@time-neko/shared/utils';
import { useHotkeys } from 'react-hotkeys-hook';
import type { KeyHandler } from 'hotkeys-js';
import { useKeyboardShortcut } from './useKeyboardShortcut';

export function useKeyboardShortcutHandler(
  key: keyof KeyboardShortcuts,
  handler: KeyHandler,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps?: any[]
) {
  const shortcut = useKeyboardShortcut(key);

  const hotkeyRef = useHotkeys(shortcut.frontendKey, handler, deps);

  return {
    shortcut,
    hotkeyRef,
  };
}
