/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren } from 'react';
import { KeyboardShortcuts } from '@time-neko/shared/utils';
import { Box, Tooltip } from '@time-neko/frontend/ui';
import {
  useKeyboardShortcut,
  useShouldShowKeyboardShortcut,
} from '@time-neko/frontend/keyboard-shortcuts';

export interface ShortcutTooltipProps {
  keyboardKey: keyof KeyboardShortcuts;
  label?: string;
}

export function ShortcutTooltip({
  keyboardKey,
  children,
  label = '',
}: PropsWithChildren<ShortcutTooltipProps>) {
  const shortcut = useKeyboardShortcut(keyboardKey);

  const tooltipDisabled = !useShouldShowKeyboardShortcut();

  return (
    <Tooltip
      isDisabled={tooltipDisabled}
      label={
        <span>
          {label}

          <Box as="span" ml={1}>
            {label && '('}
            {shortcut.display}
            {label && ')'}
          </Box>
        </span>
      }
    >
      {children}
    </Tooltip>
  );
}
