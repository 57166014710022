/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef } from 'react';
import {
  Input as BaseInput,
  InputProps as BaseInputProps,
  useStyleConfig,
} from '@chakra-ui/react';

export type InputProps = BaseInputProps;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ variant = 'nes', ...props }, ref) => {
    const styles = useStyleConfig('Input', props);

    return (
      <BaseInput
        w="100%"
        borderRadius={0}
        {...props}
        ref={ref}
        sx={{
          ...styles,
          ...props.sx,
        }}
      />
    );
  }
);
