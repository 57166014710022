/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef } from 'react';
import { Select as BaseSelect, SelectProps } from '@chakra-ui/react';
import { Asset, assetDefinitions } from '@time-neko/frontend/assets';

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (props: SelectProps, ref) => {
    const height = assetDefinitions.Arrow?.sizes?.sm?.height;
    const width = assetDefinitions.Arrow?.sizes?.sm?.width;

    return (
      <BaseSelect
        ref={ref}
        color="text.primary"
        {...props}
        icon={
          props.icon ?? (
            <Asset
              height={`${height} !important`}
              width={`${width} !important`}
              transform="rotate(90deg)"
              name="Arrow"
            />
          )
        }
      />
    );
  }
);
