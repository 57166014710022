/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BaseEntitySchema,
  Maybe,
  Order,
  OrderSchema,
  PaginationSchema,
} from '@time-neko/shared/common-types';
import { ErrorPayloadSchema } from '@time-neko/shared/domain/types';
import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';
import { IntegrationType } from '@time-neko/shared/domain/integrations';
import { DateSchema } from '@time-neko/shared/date';

export enum TaskSource {
  Trello = 'Trello',
  Local = 'Local',
  Notion = 'Notion',
}

export const taskSourceIntegrationMap = {
  [TaskSource.Trello]: IntegrationType.Trello,
  [TaskSource.Notion]: IntegrationType.Notion,
} as Record<TaskSource, IntegrationType | undefined>;

export enum TaskState {
  Todo = 'Todo',
  Completed = 'Completed',
}

export const TaskPomodoroSpentSchema = z.object({
  durationInSeconds: z.number(),
  finishedAt: z.string(),
});

export const TaskSchema = BaseEntitySchema.extend({
  title: z.string(),
  description: z.string().optional().nullable(),
  source: z.nativeEnum(TaskSource),
  sourceId: z.string().optional().nullable(),
  sourceUrl: z.string().url().optional().nullable(),
  state: z.nativeEnum(TaskState),
  estimatedPomodoroDuration: z.number().optional().nullable(),
  pomodoroSpent: z.array(TaskPomodoroSpentSchema).optional().nullable(),
  index: z.number().optional().nullable(),
  providerMeta: z.any().optional().nullable(),
  snoozedUntil: DateSchema.optional().nullable(),
});

export type Task<ProviderMeta = any> = Omit<
  z.infer<typeof TaskSchema>,
  'providerMeta'
> & {
  providerMeta?: Maybe<ProviderMeta>;
};

export type TaskPomodoroSpent = z.infer<typeof TaskPomodoroSpentSchema>;

export const CreateTaskInputSchema = TaskSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  state: true,
  pomodoroSpent: true,
  source: true,
}).extend({
  source: z.nativeEnum(TaskSource).optional(),
  isActive: z.boolean().default(false).optional(),
});

export type CreateTaskInput<ProviderMeta = any> = Omit<
  z.infer<typeof CreateTaskInputSchema>,
  'providerMeta'
> &
  Pick<Task<ProviderMeta>, 'providerMeta'>;

export const TasksStorageDataSchema = z.object({
  lastTasksSyncDate: z.string().optional(),
  isTaskListHidden: z.boolean().optional(),
});

export type TasksStorageData = z.infer<typeof TasksStorageDataSchema>;

export const ToggleTasksListPayloadSchema = TasksStorageDataSchema.pick({
  isTaskListHidden: true,
});

export type ToggleTasksListPayload = z.infer<
  typeof ToggleTasksListPayloadSchema
>;

export const DeleteTasksPayloadSchema = z.object({
  ids: z.array(z.string()),
});

export type DeleteTasksPayload = z.infer<typeof DeleteTasksPayloadSchema>;

export const TasksUpdatedPayloadSchema = z.object({
  updatedTasks: z.array(TaskSchema),
  previousTasks: z.array(TaskSchema),
});

export type TasksUpdatedPayload = z.infer<typeof TasksUpdatedPayloadSchema>;

const OnlySnoozedSchema = z.union([
  z.boolean().default(false).optional(),
  z.literal('ALL'),
]);

export type OnlySnoozedOption = z.infer<typeof OnlySnoozedSchema>;

export const GetTasksPayloadSchema = z.object({
  source: z.nativeEnum(TaskSource).optional(),
  order: OrderSchema.optional(),
  pagination: PaginationSchema.optional(),
  state: z.nativeEnum(TaskState).optional(),
  onlySnoozed: OnlySnoozedSchema,
});

export type GetTasksPayload = Omit<
  z.infer<typeof GetTasksPayloadSchema>,
  'order'
> & {
  order?: Order<Task>;
};

export const TasksByStateSchema = z.record(
  z.nativeEnum(TaskState),
  z.array(TaskSchema)
);

export type TasksByState = z.infer<typeof TasksByStateSchema>;

export const CountTasksByStateSchema = z.record(
  z.nativeEnum(TaskState),
  z.number()
);

export type CountTasksByState = z.infer<typeof CountTasksByStateSchema>;

export const TaskSynchronizerStateSchema = z.object({
  isSyncing: z.boolean(),
  lastSyncDate: z.string().datetime().optional(),
  lastError: z
    .object({
      message: z.string(),
      name: z.string(),
    })
    .optional(),
});

export type TaskSynchronizerState = z.infer<typeof TaskSynchronizerStateSchema>;

export const IsSyncingWithApisResultSchema = TaskSynchronizerStateSchema.pick({
  isSyncing: true,
});

export type IsSyncingWithApisResult = z.infer<
  typeof IsSyncingWithApisResultSchema
>;

const GetTasksBySourcePayloadSchema = z.object({
  source: z.nativeEnum(TaskSource),
});

export type GetTasksBySourcePayload = z.infer<
  typeof GetTasksBySourcePayloadSchema
>;

export const GetTasksNotInSourceIdsByStateSchema = z.object({
  sourceIds: z.array(z.string()),
  state: z.nativeEnum(TaskState),
});

export const tasksSchema = defineSchema({
  commands: {
    createTask: operation.command
      .withPayload(CreateTaskInputSchema)
      .withResult(TaskSchema),
    updateTask: operation.command
      .withPayload(TaskSchema)
      .withResult(TaskSchema),
    updateTasks: operation.command
      .withPayload(z.array(TaskSchema))
      .withResult(z.array(TaskSchema)),
    deleteTasks: operation.command.withPayload(DeleteTasksPayloadSchema),
    deleteCompletedTasks: operation.command,
    syncTasksWithApis: operation.command.withPayload(
      z
        .object({
          cancelOngoingSync: z.boolean(),
        })
        .optional()
    ),
    lockTaskApiSync: operation.command.withResult(z.string()),
    releaseTaskApiSyncLock: operation.command.withPayload(
      z.object({
        lockId: z.string(),
      })
    ),
    cancelTasksSync: operation.command.withResult(z.boolean()),
    toggleTasksList: operation.command.withPayload(
      ToggleTasksListPayloadSchema
    ),
  },
  queries: {
    isAtLeastOneTaskProviderConfigured: operation.query.withResult(z.boolean()),
    getTasks: operation.query
      .withPayload(GetTasksPayloadSchema)
      .withResult(z.array(TaskSchema)),
    getTasksBySource: operation.query
      .withPayload(GetTasksBySourcePayloadSchema)
      .withResult(z.array(TaskSchema)),
    getTasksNotInSourceIdsByState: operation.query
      .withPayload(GetTasksNotInSourceIdsByStateSchema)
      .withResult(z.array(TaskSchema)),
    getActiveTask: operation.query.withResult(TaskSchema.optional().nullable()),
    getTasksByState: operation.query.withResult(TasksByStateSchema),
    countTasksByState: operation.query.withResult(CountTasksByStateSchema),
    areTasksSyncingWithApis: operation.query.withResult(
      IsSyncingWithApisResultSchema
    ),
    isTasksListHidden: operation.query.withResult(z.boolean()),
  },
  events: {
    tasksDeleted: operation.event.withPayload(
      z.array(
        TaskSchema.omit({ id: true }).extend({
          id: z.string().optional().nullable(),
        })
      )
    ),
    taskCreated: operation.event.withPayload(TaskSchema),
    activeTaskUpdated: operation.event.withPayload(TaskSchema.optional()),
    tasksListToggled: operation.event.withPayload(z.boolean()),
    tasksCompleted: operation.event.withPayload(z.array(TaskSchema)),
    tasksUncompleted: operation.event.withPayload(z.array(TaskSchema)),
    tasksUpdated: operation.event.withPayload(TasksUpdatedPayloadSchema),
    tasksSyncStarted: operation.event.withPayload(TaskSynchronizerStateSchema),
    tasksSyncFinished: operation.event.withPayload(TaskSynchronizerStateSchema),
    tasksSyncFailed: operation.event.withPayload(ErrorPayloadSchema),
  },
});

export const TaskSettingsSchema = z.object({
  showToggleTaskListBtn: z.boolean().optional(),
});

export type TaskSettings = z.infer<typeof TaskSettingsSchema>;
