/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Container, ContainerProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

export const AppWrapper = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, ...props }, ref) => {
    return (
      <Container
        w="100%"
        maxWidth="100%"
        pl={0}
        pr={0}
        {...props}
        ref={ref}
        data-test-id="app-wrapper"
      >
        {children}
      </Container>
    );
  }
);
