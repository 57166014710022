/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Accordion, Divider } from '@time-neko/frontend/ui';
import { SettingsFields } from '@time-neko/frontend/domain/settings/field-definitions';
import { SettingsFormFieldRenderer } from './SettingsFormFieldRenderer';
import { SettingsFieldRendererProps } from '../SettingsForm.types';
import { SettingsFormSection } from './SettingsFormSection';
import { IntegrationsForm } from '@time-neko/frontend/domain/integrations/web';
import { useMemo } from 'react';

export const SettingsFieldRenderer = <
  Field extends SettingsFields = SettingsFields
>(
  props: SettingsFieldRendererProps<Field>
) => {
  const { form, field } = props;

  const allFieldsSkipped = useMemo(() => {
    if (field.renderAs !== 'section') {
      return;
    }

    return field.fields.every((field) => {
      return 'name' in field && props.skippedFieldNames?.includes(field.name);
    });
  }, [field, props.skippedFieldNames]);

  switch (field.renderAs) {
    case 'separator':
      return <Divider />;

    case 'plainComponent': {
      const { Component } = field;

      return <Component form={props.form} />;
    }

    case 'integrations': {
      return (
        <SettingsFormSection
          title={field.title}
          showDivider={field.showDivider}
        >
          <IntegrationsForm
            definitions={field.definitions}
            onOpen={field.onOpen}
          />
        </SettingsFormSection>
      );
    }

    case 'accordion': {
      if (allFieldsSkipped) {
        return null;
      }

      return (
        <Accordion
          width="100%"
          {...field.accordionProps}
          items={Object.entries(field.fields).flatMap(
            ([title, fields], index) => {
              return fields.map((field) => {
                const key = 'name' in field ? field.name : index;

                return {
                  title: title,
                  id: key.toString(),
                  content: (
                    <SettingsFieldRenderer
                      skippedFieldNames={props.skippedFieldNames}
                      field={field}
                      form={form}
                    />
                  ),
                };
              });
            }
          )}
        />
      );
    }

    case 'section': {
      if (allFieldsSkipped) {
        return null;
      }

      return (
        <SettingsFormSection
          title={field.title}
          showDivider={field.showDivider}
          badge={field.badge}
        >
          {field.fields.map(
            (field, index) =>
              field && (
                <SettingsFieldRenderer
                  skippedFieldNames={props.skippedFieldNames}
                  key={'name' in field ? field.name : index}
                  field={field}
                  form={form}
                />
              )
          )}
        </SettingsFormSection>
      );
    }

    default:
      return <SettingsFormFieldRenderer {...props} />;
  }
};
