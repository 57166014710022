/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ErrorResolver } from '@time-neko/frontend/errors/hooks';
import { AsyncSettingError } from '@time-neko/shared/domain/settings';

export interface AsyncErrorsState {
  asyncErrors: AsyncSettingError[];
}

export const asyncErrorsResolver: ErrorResolver<AsyncErrorsState> = {
  name: 'Async error resolver',
  getErrors: (state) => ({
    errors: state.asyncErrors,
    priority: 0,
  }),
};
