/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef, HTMLProps } from 'react';
import { Text as BaseText, TextProps as BaseTextProps } from '@chakra-ui/react';
import classNames from 'classnames';

export type TextProps = BaseTextProps &
  Pick<Partial<HTMLProps<HTMLAnchorElement>>, 'href' | 'target' | 'tabIndex'>;

export const Text = forwardRef<HTMLParagraphElement, TextProps>(
  (props, ref) => {
    return (
      <BaseText
        ref={ref}
        display={props.display ?? 'inline-flex'}
        alignItems="center"
        {...props}
        className={classNames(props.className, 'text')}
        color={props.color ?? 'brand.textPrimary'}
      />
    );
  }
);
