/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ButtonGroup } from '@chakra-ui/react';
import { Asset } from '@time-neko/frontend/assets';
import { Tooltip } from '../tooltip/Tooltip';
import { forwardRef } from 'react';
import { IconButton } from '../iconButton/IconButton';
import { Text } from '../../atoms';

export interface EditableControlsProps {
  hideCancel?: boolean;
  hideConfirm?: boolean;
  onConfirm?: () => unknown;
  onCancel?: () => unknown;
}

export const EditableControls = forwardRef<
  HTMLDivElement,
  EditableControlsProps
>(({ hideConfirm, hideCancel, onConfirm, onCancel }, ref) => {
  return (
    <ButtonGroup
      alignItems="center"
      className="editable-controls"
      justifyContent="center"
      size="md"
      spacing={1}
      ref={ref}
    >
      {!hideCancel && (
        <Tooltip label="Confirm changes">
          <IconButton
            colorScheme="success"
            variant="nes"
            aria-label="Confirm changes"
            onClick={onConfirm}
            boxSize="lg"
          >
            <Asset
              sx={{
                '*': {
                  fill: 'white',
                },
              }}
              position="relative"
              right="2px"
              boxSize="md"
              name="Check"
            />
          </IconButton>
        </Tooltip>
      )}
      {!hideConfirm && (
        <Tooltip label="Cancel changes">
          <IconButton
            boxSize="lg"
            colorScheme="danger"
            variant="nes"
            aria-label="Cancel changes"
            onClick={onCancel}
          >
            <Text position="relative" top="1px" fontSize="xl">
              x
            </Text>
          </IconButton>
        </Tooltip>
      )}
    </ButtonGroup>
  );
});
