/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export function maybePlural(value: number, singular: string, plural: string) {
  if (value === 0) {
    return plural;
  }

  return value === 1 ? singular : plural;
}
