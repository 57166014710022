/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Button, Text } from '@time-neko/frontend/ui';

export interface TimerEditProps {
  remainingSeconds: number;
  onChange: (seconds: number) => unknown;
  allowAdd?: boolean;
  allowSubtract?: boolean;
}

export function TimerEditButtons({
  remainingSeconds,
  onChange,
  allowAdd = true,
  allowSubtract = true,
}: TimerEditProps) {
  return (
    <>
      {allowSubtract && (
        <>
          <Button
            className="sub-60 sub timer-edit-btn"
            isDisabled={remainingSeconds < 60}
            onClick={() => onChange(remainingSeconds - 60)}
          >
            <Text>-1m</Text>
          </Button>
          <Button
            className="sub-300 sub timer-edit-btn"
            isDisabled={remainingSeconds < 300}
            onClick={() => onChange(remainingSeconds - 300)}
          >
            <Text>-5m</Text>
          </Button>
        </>
      )}
      {allowAdd && (
        <>
          <Button
            className="add-60 add timer-edit-btn"
            onClick={() => onChange(remainingSeconds + 60)}
          >
            <Text>+1m</Text>
          </Button>
          <Button
            className="add-300 add timer-edit-btn"
            onClick={() => onChange(remainingSeconds + 300)}
          >
            <Text>+5m</Text>
          </Button>
        </>
      )}
    </>
  );
}
