/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PomodoroSettings, PomodoroState, PomodoroStates } from './types';
import { getBoolEnv, getIntEnv } from '@time-neko/shared/utils';
import { durations } from './const/duration';
import { Time } from '@time-neko/shared/time';

export const getInitialPomodoroState = (): PomodoroState => {
  const remainingSeconds = getIntEnv(
    'NX_WORK_DURATION_SECONDS',
    durations[6].seconds
  );
  return {
    remainingSeconds,
    start: new Date(),
    state: PomodoroStates.Work,
    isRunning: getBoolEnv('IS_POMODORO_RUNNING', false),
    shortBreakCount: 0,
    clockString: Time.fromSeconds(remainingSeconds).toClockString(),
    remainingPercentage: 0,
    stateDurationSeconds: 0,
    nextState: PomodoroStates.Break,
  };
};

export const getInitialPomodoroSettings = (): PomodoroSettings => ({
  shortBreakDurationSeconds: getIntEnv(
    'NX_SHORT_BREAK_DURATION_SECONDS',
    durations[1].seconds
  ),
  longBreakDurationSeconds: getIntEnv(
    'NX_LONG_BREAK_DURATION_SECONDS',
    durations[4].seconds
  ),
  workDurationSeconds: getIntEnv(
    'NX_WORK_DURATION_SECONDS',
    durations[6].seconds
  ),
  longBreakInterval: getIntEnv('NX_LONG_BREAK_INTERVAL', 4),
  autoRunWork: getBoolEnv('NX_AUTO_RUN_WORK', false),
  autoRunBreak: getBoolEnv('NX_AUTO_RUN_BREAK', false),
  openFullWindowOnBreak: getBoolEnv('OPEN_FULL_WINDOW_ON_BREAK', false),
  breakWindowAsOverlay: getBoolEnv('BREAK_WINDOW_AS_OVERLAY', false),
  showOverlayBeforeBreak: getBoolEnv('SHOW_OVERLAY_BEFORE_BREAK', false),
});
