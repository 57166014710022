/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren, ReactNode } from 'react';
import { Divider, Heading, HStack, VStack } from '@time-neko/frontend/ui';

export interface SettingsFormSectionProps {
  showDivider?: boolean;
  title: ReactNode;
  badge?: ReactNode;
}

export function SettingsFormSection({
  showDivider,
  title,
  children,
  badge,
}: PropsWithChildren<SettingsFormSectionProps>) {
  return (
    <VStack as="section" spacing={4} width="100%" py={showDivider ? 0 : 4}>
      {showDivider && <Divider mt={1} mb={4} />}
      <HStack width="100%" spacing={4} alignItems="center">
        <Heading as="h5" fontSize="xs">
          {title}
        </Heading>
        {badge}
      </HStack>
      {children}
    </VStack>
  );
}
