/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  BaseMediaProps,
  CatJugglingIcon,
  CatSleepIcon,
  CatSmellingFlowers,
} from '@time-neko/frontend/assets';
import { BreakKey } from '@time-neko/shared/domain/break';
import { ComponentType } from 'react';
import { ChakraProps } from '@chakra-ui/system';

export const breakSuggestionMediaMap: Record<
  BreakKey,
  ComponentType<ChakraProps & BaseMediaProps & { className?: string }>
> = {
  [BreakKey.Workout]: CatJugglingIcon,
  [BreakKey.Stretch]: CatJugglingIcon,
  [BreakKey.TakeANap]: CatSleepIcon,
  [BreakKey.SmellFlowers]: CatSmellingFlowers,
  [BreakKey.LookAtTrees]: CatSmellingFlowers,
};
