/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { Onboarding } from '@time-neko/frontend/domain/onboarding/web';
import { WebAppContainer } from '../../components/WebAppContainer';
import { frontendOnboardingSchema } from '@time-neko/frontend/domain/onboarding/schema';

export function OnboardingView() {
  const onboardingState = frontendOnboardingSchema.getOnboardingState.useQuery({
    enabled: false,
  });

  return (
    <WebAppContainer>
      <Onboarding
        py={6}
        onCompleted={async (wasSkipped) => {
          onboardingState.setQueryData({
            completedAt: new Date(),
            wasSkipped,
          });
        }}
      />
    </WebAppContainer>
  );
}
