/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { keyboardShortcuts, KeyboardShortcuts } from '@time-neko/shared/utils';
import { useConst } from '@chakra-ui/hooks';
import { getPlatformFromNavigator } from '@time-neko/frontend/common';

export function useKeyboardShortcut(key: keyof KeyboardShortcuts) {
  const platform = useConst(getPlatformFromNavigator());
  const shortcut = useConst(keyboardShortcuts[key]?.(platform));

  if (!shortcut) {
    throw new Error(`Keyboard shortcut ${key} is not supported on ${platform}`);
  }

  return shortcut;
}
