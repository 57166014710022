/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useState } from 'react';
import { useDebounce } from 'react-use';

export const useAutoSwitch = <T>(initialValue: T, ms = 4000) => {
  const hook = useState(initialValue);
  const [value, setValue] = hook;

  useDebounce(
    () => {
      if (value !== initialValue) {
        setValue(initialValue);
      }
    },
    ms,
    [value]
  );

  return hook;
};
