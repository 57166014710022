/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { composeMedia } from '../composeMedia';
import PawsImg from '../../assets/Paws.png';

export const Paws = composeMedia({
  assetMap: {
    light: PawsImg,
    dark: PawsImg,
  },
  type: 'img',
  alt: 'Paws',
});
