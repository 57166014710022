/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  AsyncSettingAction,
  AsyncSettingError,
} from '@time-neko/shared/domain/settings';
import { useAsyncSettingErrors } from '@time-neko/frontend/domain/settings/hooks';
import { Button } from '@time-neko/frontend/ui';

export interface SettingsFormAsyncErrorActionProps {
  error: AsyncSettingError;
  action: AsyncSettingAction;
}

export function SettingsFormAsyncErrorAction({
  action,
  error,
}: SettingsFormAsyncErrorActionProps) {
  const { triggerAsyncErrorActionMutation } = useAsyncSettingErrors();

  return (
    <Button
      as={action.isUrl ? 'a' : 'button'}
      href={action.isUrl ? action.url : undefined}
      target={action.isUrl ? '_blank' : undefined}
      isLoading={triggerAsyncErrorActionMutation.isLoading}
      onClick={() => {
        if (!action.isUrl) {
          triggerAsyncErrorActionMutation.mutate({
            error,
            actionName: action.name,
          });
        }
      }}
    >
      {action.label}
    </Button>
  );
}
