/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ReactNode } from 'react';
import {
  Accordion as BaseAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionProps as BaseProps,
  BoxProps,
} from '@chakra-ui/react';
import { Heading } from '../../atoms/heading/Heading';
import { Asset } from '@time-neko/frontend/assets';
import { Frame } from '../../atoms';

export interface AccordionItem {
  title: ReactNode;
  content: ReactNode;
  id: string;
  buttonAddonRight?: ReactNode;
}

export interface AccordionProps extends BaseProps {
  items: AccordionItem[];
  frameProps?: BoxProps;
  spacing?: number;
}

export function Accordion({
  items,
  frameProps,
  spacing = 6,
  ...props
}: AccordionProps) {
  return (
    <BaseAccordion
      {...props}
      data-test-id="accordion"
      allowToggle
      display="grid"
      gridRowGap={spacing}
    >
      {items.map((item) => (
        <AccordionItem border="none" key={item.id}>
          {({ isExpanded }) => (
            <Frame {...frameProps}>
              <AccordionButton
                data-test-id={`accordion-${item.id}`}
                data-is-expanded={isExpanded.toString()}
              >
                <Heading textAlign="left" flex={1} variant="h5" fontSize="xs">
                  {item.title}
                </Heading>
                {item.buttonAddonRight}
                <Asset
                  name="Arrow"
                  boxSize="md"
                  transform={isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'}
                />
              </AccordionButton>
              <AccordionPanel>{item.content}</AccordionPanel>
            </Frame>
          )}
        </AccordionItem>
      ))}
    </BaseAccordion>
  );
}
