/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Tracker, TrackerData } from '@time-neko/shared/domain/tracking';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { Observable, Subject } from 'rxjs';

export interface TrackingProviderProps {
  tracker: Tracker;
  superProperties?: TrackerData;
}

export interface TrackingProviderContext extends TrackingProviderProps {
  isUserLoggedIn: boolean;
  userLogged$: Observable<void>;
  loginUser: (userId: string) => void;
  maybeLoginUser: (userId: string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Context = createContext<TrackingProviderContext>({} as any);

export const useTracker = () => useContext(Context);

export function TrackingProvider({
  tracker,
  children,
  superProperties,
}: PropsWithChildren<TrackingProviderProps>) {
  const userLoggedRef$ = useRef(new Subject<void>());

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const loginUser = useCallback(
    (userId: string) => {
      tracker.loginUser?.(userId);

      setIsUserLoggedIn(true);

      userLoggedRef$.current.next();
      userLoggedRef$.current.complete();
    },
    [tracker]
  );

  const maybeLoginUser = useCallback(
    (userId: string) => {
      if (!isUserLoggedIn) {
        loginUser(userId);
      }
    },
    [isUserLoggedIn, loginUser]
  );

  return (
    <Context.Provider
      value={{
        tracker,
        superProperties,
        isUserLoggedIn,
        loginUser,
        userLogged$: userLoggedRef$.current.asObservable(),
        maybeLoginUser,
      }}
    >
      {children}
    </Context.Provider>
  );
}
