/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ChakraProps } from '@chakra-ui/system';
import { Asset } from '@time-neko/frontend/assets';

export type LoadingProps = ChakraProps;

export const Loading = ({ css, ...props }: LoadingProps) => {
  return <Asset className="animation-rotate" name="Hourglass" {...props} />;
};
