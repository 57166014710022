/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  AbsoluteBackground,
  AbsoluteBackgroundProps,
  Box,
  ContainerProps,
} from '@time-neko/frontend/ui';

import { CompletedTasksTodayCount } from '@time-neko/frontend/domain/statistics';
import { MutableRefObject, useMemo, useRef } from 'react';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { useResizeScrollReset } from '@time-neko/frontend/resize-scroll-reset';
import {
  TabbedTasksList,
  TaskItemDragStrategy,
} from '@time-neko/frontend/domain/tasks/web';

export interface PomodoroTasksListProps {
  taskContainerProps?: ContainerProps;
  taskBackgroundProps?: AbsoluteBackgroundProps;
}

export function PomodoroTasksList(props: PomodoroTasksListProps) {
  const desktopContainerRef = useRef<HTMLElement>();

  const taskDragStrategy = useBreakpointValue<TaskItemDragStrategy>({
    base: TaskItemDragStrategy.Handle,
    sm: TaskItemDragStrategy.ListItem,
  });

  const listProps = useMemo(
    () => ({
      itemProps: {
        dragStrategy: taskDragStrategy,
      },
    }),
    [taskDragStrategy]
  );

  useResizeScrollReset(desktopContainerRef);

  return (
    <Box
      ref={desktopContainerRef as MutableRefObject<HTMLDivElement>}
      flex={1}
      display="flex"
      flexDirection="column"
      w="100%"
      overflow="hidden"
      position="relative"
      {...props.taskContainerProps}
    >
      <AbsoluteBackground bg="brand.bg" {...props.taskBackgroundProps} />
      <TabbedTasksList
        listProps={listProps}
        footer={<CompletedTasksTodayCount />}
      />
    </Box>
  );
}
