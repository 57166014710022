/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef } from 'react';
import {
  IconButtonProps as BaseProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { Button, ButtonProps } from '../../atoms/button/Button';
import { CenterContainer } from '../../templates';
import { chakra, ChakraProps } from '@chakra-ui/system';
import { ReactComponent as IconButtonBase } from './base.svg';

export interface IconButtonProps
  extends ChakraProps,
    Pick<BaseProps, 'aria-label'>,
    Omit<ButtonProps, 'aria-label' | 'leftIcon' | 'rightIcon'> {}

const ChakraIconButtonBase = chakra(IconButtonBase);

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, ...props }, ref) => {
    const styles = useMultiStyleConfig('CustomIconButton', props);

    return (
      <Button
        {...props}
        sx={{
          ...styles.container,
          ...props.sx,
        }}
        containerProps={{
          position: 'relative',
          ...props.containerProps,
        }}
        ref={ref}
      >
        <CenterContainer
          as="span"
          w="100%"
          h="100%"
          bg="transparent"
          centerContent
          zIndex={4}
          sx={styles.textContainer}
        >
          {children}
        </CenterContainer>
        <ChakraIconButtonBase
          left={0}
          top={0}
          zIndex={2}
          position="absolute"
          width="100%"
          height="100%"
          sx={styles.base}
        />
      </Button>
    );
  }
);
