/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { defineSchema, operation } from '@musubi/core';
import { z } from 'zod';

export const dndSchema = defineSchema({
  events: {},
  queries: {
    isDndSupported: operation.query.withResult(z.boolean()),
    isDndEnabled: operation.query.withResult(z.boolean()),
    isDndInstallRequired: operation.query.withResult(z.boolean()),
  },
  commands: {
    installDnd: operation.command,
    toggleDnd: operation.command,
    enableDnd: operation.command,
    disableDnd: operation.command,
  },
});
