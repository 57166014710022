/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';

import { Asset } from '@time-neko/frontend/assets';

export interface CheckboxProps extends BaseProps {
  variant?: string;
}

const IconComponent = (props: Pick<CheckboxProps, 'isChecked' | '__css'>) => {
  if (!props.isChecked) {
    return null;
  }

  return (
    <Asset
      boxSize="100%"
      margin="0 auto"
      fill="inherit"
      position="relative"
      right="1px"
      sx={{
        '& path, & rect': {
          fill: 'inherit',
        },
      }}
      name="Check"
      __css={props.__css}
    />
  );
};

export const Checkbox = (props: CheckboxProps) => {
  const styles = useStyleConfig('Checkbox', props as ThemingProps);

  return (
    <BaseCheckbox
      icon={<IconComponent isChecked={props.isChecked} />}
      sx={{
        ...styles,
        '& .chakra-checkbox__control': {
          boxSize: props.size ?? props.boxSize ?? 'md',
          maxW: '100%',
          maxH: '100%',
        },
      }}
      {...props}
      boxSize={props.boxSize ?? 'md'}
    >
      {props.children}
    </BaseCheckbox>
  );
};
