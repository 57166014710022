/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

// eslint-disable-next-line @typescript-eslint/triple-slash-reference
///<reference path="../font.d.ts"/>

import { css } from '@emotion/react';
import normalFont from '../fonts/slkscr.ttf';
import boldFont from '../fonts/slkscrb.ttf';

export { normalFont, boldFont };

export const styles = (bodyBg: string) => css`
  .grecaptcha-badge {
    visibility: hidden;
  }

  :root {
    --app-height: 100%;
  }

  @font-face {
    font-family: 'PixelFont';
    src: url(${normalFont}) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'PixelFont';
    src: url(${boldFont}) format('truetype');
    font-weight: bold;
  }

  body {
    margin: 0;
  }

  body:not(.is-transparent) {
    background: ${bodyBg};
  }

  body,
  #shadow_root {
    font-family: 'PixelFont', cursive;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
  }

  html,
  body {
    overflow: hidden;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  html {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABFklEQVRYR9WXURLDIAhE6/0PbSdOtUpcd1Gnpv1KGpTHBpCE1/cXq+vrMph7dGvXZTtpfW10DCA5jrH1H0Jhs5E0hnZdCR+vb5S8Nn8mQCeS9BdSalYJqMBjAGzq59xAESN7VFVUgV8AZB/dZBR7QTFDCqGquvUBVVoEtgIwpQRzmANSFHgWQKExHdIrPeuMvQNDarXe6nC/AutgV3JW+6bgqQLeV8FekRtgV+ToDKEKnACYKsfZjjkam7a0ZpYTytwmgainpC3HvwBocgKOxqRjehoR9DFKNFYtOwCGYCszobeCbl26N6yyQ6g8X/Wex/rBPsNEV6qAMaJPMynIHQCoSqS9JSMmwef51LflTgCRszU7DvAGiV6mHWfsaVUAAAAASUVORK5CYII=),
      auto;
  }

  #root {
    overflow-y: hidden;
  }

  a,
  button,
  [role='button'],
  .chakra-button {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC)
        14 0,
      pointer;
  }

  * {
    /* Fixes "funky" border on some devices */
    border-image-repeat: initial !important;
  }

  * {
    user-select: none;
    shape-rendering: geometricPrecision;
  }
`;
