/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  getWebAppBroadcastChannel,
  WebAppWorkerType,
  webSchema,
  WorkerRegistration,
} from '@time-neko/shared/web-app';
import { logger, LogPayload } from '@time-neko/shared/logger';
import { MusubiClient } from '@musubi/core';
import { castAsError } from '@time-neko/shared/utils';
import { Subject } from 'rxjs';
import { isSafari } from '@time-neko/frontend/platform';

export const workerError$ = new Subject<Error>();

let currentRegistration: WorkerRegistration;

Object.assign(window, {
  getWorkerRegistration: () => currentRegistration,
});

function sharedWorker(
  client: MusubiClient<typeof webSchema>
): WorkerRegistration | null {
  if (typeof globalThis.SharedWorker === 'undefined') {
    return null;
  }

  logger.info('Creating shared worker');

  const worker = new SharedWorker(
    new URL('./worker/sharedWorker.ts', import.meta.url),
    {
      type: 'module',
      name: 'time-neko-shared-worker',
    }
  );

  const channel = getWebAppBroadcastChannel();

  const handleError = (error: Error) => {
    logger.error('Shared Worker error', error);

    workerError$.next(error);
  };

  worker.addEventListener('error', (event) => {
    handleError(castAsError(event.error));
  });

  channel.addEventListener('message', (event) => {
    if (event.data?.type === 'error') {
      handleError(castAsError(event.data.error));
    }

    if (event.data?.type === 'log' && isSafari()) {
      const data = event.data as LogPayload;

      logger[data.level](...data.args);
    }
  });

  worker.port.start();

  return {
    worker,
    type: WebAppWorkerType.SharedWorker,
    channel,
    dispose: async () => {
      logger.info('Closing shared worker');

      worker.port.close();

      await client.command('closeWorker');
    },
  };
}

function normalWorker(
  client: MusubiClient<typeof webSchema>
): WorkerRegistration | null {
  if (typeof globalThis.Worker === 'undefined') {
    return null;
  }

  logger.info('Creating worker');

  const worker = new Worker(new URL('./worker/worker.ts', import.meta.url), {
    type: 'module',
    name: 'time-neko-worker',
  });

  const handleError = (error: Error) => {
    logger.error('Worker error', { error });

    workerError$.next(error);

    worker.terminate();
  };

  worker.addEventListener('error', (event) => {
    handleError(castAsError(event.error));
  });

  worker.addEventListener('message', (event) => {
    if (event.data?.type === 'error') {
      handleError(castAsError(event.data.error));
    }
  });

  return {
    worker,
    channel: worker,
    type: WebAppWorkerType.Worker,
    dispose: async () => {
      logger.info('Closing worker');

      await client.command('closeWorker');

      worker.terminate();
    },
  };
}

function getWorkers() {
  if (process.env.NX_DISABLE_SHARED_WORKER === 'true') {
    logger.info('Shared worker disabled');
    return [normalWorker];
  }

  return [sharedWorker, normalWorker];
}

export async function registerWorker(client: MusubiClient<typeof webSchema>) {
  await currentRegistration?.dispose();

  const workers = getWorkers();

  for (const createWorker of workers) {
    const worker = createWorker(client);

    if (worker) {
      currentRegistration = worker;

      return worker;
    }
  }

  return false;
}
