/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { pomodoroStateDictionary } from '@time-neko/shared/domain/pomodoro';
import { usePomodoro } from './usePomodoro';
import { frontendPomodoroSchema } from '@time-neko/frontend/domain/pomodoro/schema';

export function usePomodoroNextState() {
  const { pomodoro } = usePomodoro();

  const nextState = pomodoro?.nextState;

  const moveToNextStateMutation =
    frontendPomodoroSchema.moveToNextPomodoroState.useCommand();

  return {
    nextState,
    nextStateText: nextState ? pomodoroStateDictionary[nextState] : undefined,
    moveToNextStateMutation,
  };
}
