/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { z } from 'zod';
import { DateSchema } from '@time-neko/shared/date';

export const BaseEntitySchema = z.object({
  id: z.string(),
  createdAt: DateSchema,
  updatedAt: DateSchema,
});

export type BaseEntity = z.infer<typeof BaseEntitySchema>;

export const PaginationSchema = z.object({
  take: z.number(),
  skip: z.number(),
});

export type Pagination = z.infer<typeof PaginationSchema>;

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export const OrderSchema = z.record(z.string(), z.nativeEnum(OrderDirection));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Order<T> = {
  [Key in keyof T]?: T[Key] extends Record<string, unknown>
    ? Order<T[Key]>
    : T[Key] extends Array<infer P>
    ? Order<P>
    : OrderDirection;
};
