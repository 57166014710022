/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef } from 'react';
import { EditableContentComponentProps, Heading } from '@time-neko/frontend/ui';
import { Time } from '@time-neko/shared/time';
import type { PomodoroState } from '@time-neko/shared/domain/pomodoro';
import classNames from 'classnames';
import { omit } from '@fxts/core';

const smallerFontSeconds = Time.fromMinutes(59).addSeconds(59).asSeconds;

export type EditablePreviewHeadingProps = Pick<
  PomodoroState,
  'remainingSeconds' | 'clockString'
> &
  EditableContentComponentProps;

export const EditablePreviewHeading = forwardRef<
  HTMLHeadingElement,
  EditablePreviewHeadingProps
>(({ clockString, remainingSeconds, ...props }, ref) => {
  return (
    <Heading
      transition="all .5s"
      ref={ref}
      {...omit(['className'], props)}
      as="h1"
      whiteSpace="nowrap"
      fontSize={remainingSeconds > smallerFontSeconds ? '4xl' : '5xl'}
      color="white"
      position="relative"
      letterSpacing={4}
      className={classNames('timer-header', props.className)}
      mt={1}
      mb={1}
    >
      {clockString}
    </Heading>
  );
});
