/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren, ReactNode } from 'react';
import { Balloon, Loading, Text, Tooltip } from '@time-neko/frontend/ui';
import { ChakraProps } from '@chakra-ui/system';
import classNames from 'classnames';

export interface TimerBalloonProps extends ChakraProps {
  addon?: ReactNode;
  isLoading?: boolean;
  tooltipDisabled?: boolean;
  className?: string;
}

export function TimerBalloon({
  addon,
  isLoading,
  children,
  tooltipDisabled,
  className,
  ...props
}: PropsWithChildren<TimerBalloonProps>) {
  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <Balloon
          maxWidth={{
            base: '50%',
            sm: '90%',
          }}
          margin={0}
          py={0}
          px={{
            base: 1,
            sm: 2,
          }}
          display="inline-flex"
          alignItems={{
            base: 'baseline',
            sm: undefined,
          }}
          className={classNames(
            'timer-box-main-text',
            'animation-fade-in',
            className
          )}
          balloonOffset="50%"
          width="auto"
          {...props}
          sx={{
            '& .main-text': {
              display: 'inline-block',
            },
          }}
        >
          <Tooltip
            label={children}
            isDisabled={tooltipDisabled}
            shouldWrapChildren={false}
          >
            <Text
              className="main-text"
              fontSize="xs"
              noOfLines={1}
              whiteSpace={{
                base: undefined,
                sm: 'nowrap',
              }}
            >
              {children}
            </Text>
          </Tooltip>

          {addon && (
            <Text fontSize="xs" ml="1">
              {addon}
            </Text>
          )}
        </Balloon>
      )}
    </>
  );
}
