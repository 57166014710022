/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Disposable } from '@time-neko/shared/domain/types';
import { PomodoroStateSchema } from '@time-neko/shared/domain/pomodoro';
import { AppSettingsSchema } from '@time-neko/shared/domain/settings';
import { defineSchema, mergeSchemas, operation } from '@musubi/core';
import { z } from 'zod';
import { mergedCoreSchema } from '@time-neko/shared/domain/core';
import { CommunicationChannel } from '@musubi/broadcast-channel-link';

export const WorkerReadyPayloadSchema = z.object({
  pomodoroState: PomodoroStateSchema,
  appSettings: AppSettingsSchema,
});

export type WorkerReadyPayload = z.infer<typeof WorkerReadyPayloadSchema>;

export const WorkerStateSchema = z.object({
  isReady: z.boolean(),
});

export type WorkerState = z.infer<typeof WorkerStateSchema>;

export const webSchema = defineSchema({
  queries: {
    getWorkerState: operation.query.withResult(WorkerStateSchema),
  },
  events: {
    workerReady: operation.event.withPayload(WorkerReadyPayloadSchema),
  },
  commands: {
    initServiceWorkerPort: operation.command,
    closeWorker: operation.command,
  },
});

export const mergedWebSchema = mergeSchemas(mergedCoreSchema, webSchema);

export type WorkerRegistration = Disposable & {
  channel: WebAppCommunicationChannel;
} & (
    | {
        worker: Worker;
        type: WebAppWorkerType.Worker;
      }
    | {
        worker: SharedWorker;
        type: WebAppWorkerType.SharedWorker;
      }
  );

export enum WebAppWorkerType {
  Worker = 'Worker',
  SharedWorker = 'SharedWorker',
}

export type WebAppCommunicationChannel = CommunicationChannel;
