/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Fragment } from 'react';
import { Alert, AlertProps } from '@time-neko/frontend/ui';
import { ErrorResolverResult } from '@time-neko/frontend/errors/hooks';

export interface ErrorsAlertsListProps<E extends Error = Error> {
  errors: ErrorResolverResult<E>[];
  getAlertPropsForError?: (
    result: ErrorResolverResult<E>,
    error: E,
    defaultProps: AlertProps
  ) => AlertProps | undefined;
}

export function ErrorsAlertsList<E extends Error = Error>({
  errors,
  getAlertPropsForError,
}: ErrorsAlertsListProps<E>) {
  if (!errors.length) {
    return null;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {errors?.map((result, index) => {
        return result.errors.map((error) => {
          const defaultAlertProps: AlertProps = {
            isClosable: false,
            hideIcon: true,
            status: 'error',
            children: error.message,
          };
          const receivedProps = getAlertPropsForError?.(
            result,
            error,
            defaultAlertProps
          );
          const alertProps = receivedProps ?? defaultAlertProps;

          return (
            <Fragment key={index}>
              <Alert {...alertProps}>{alertProps.children}</Alert>
            </Fragment>
          );
        });
      })}
    </>
  );
}
