/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { settingsSchema } from '@time-neko/shared/domain/settings';
import { OperationSchemaOperations } from '@musubi/core';
import { frontendSettingsSchema } from '@time-neko/frontend/domain/settings/schema';

export function useAsyncSettingErrors() {
  const errorsQuery = frontendSettingsSchema.getAsyncSettingErrors.useQuery();

  const triggerAsyncErrorActionMutation =
    frontendSettingsSchema.triggerAsyncSettingAction.useCommand({
      invalidateQueries: [
        [
          'getAsyncSettingErrors' as OperationSchemaOperations<
            typeof settingsSchema
          >,
        ],
      ],
    });

  const removeErrorMutation =
    frontendSettingsSchema.removeAsyncSettingError.useCommand({
      invalidateQueries: [
        [
          'getAsyncSettingErrors' as OperationSchemaOperations<
            typeof settingsSchema
          >,
        ],
      ],
    });

  const clearErrorsMutation =
    frontendSettingsSchema.clearAsyncSettingErrors.useCommand({
      invalidateQueries: [
        [
          'getAsyncSettingErrors' as OperationSchemaOperations<
            typeof settingsSchema
          >,
        ],
      ],
    });

  frontendSettingsSchema.asyncSettingErrorCreated.useEvent(() => {
    void errorsQuery.refetch();
  }, [errorsQuery.refetch]);

  frontendSettingsSchema.asyncSettingErrorRemoved.useEvent(() => {
    void errorsQuery.refetch();
  }, [errorsQuery.refetch]);

  return {
    errorsQuery,
    removeErrorMutation,
    clearErrorsMutation,
    triggerAsyncErrorActionMutation,
  };
}
