/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { z } from 'zod';
import { IntegrationType } from './integrations';
import { validate } from '@time-neko/shared/validation';

const AUTH_HOST = 'auth';

const IntegrationTypeSchema = z.nativeEnum(IntegrationType);

export function getAuthRedirectPath(result: string, type: IntegrationType) {
  return `${AUTH_HOST}/?result=${result}&type=${type}`;
}

export function parseProtocol(uri: string) {
  const url = new URL(uri);

  if (url.hostname !== AUTH_HOST && url.pathname === AUTH_HOST) {
    throw new Error(`Unexpected url: ${url.toString()}`);
  }

  const type = validate(url.searchParams.get('type'), IntegrationTypeSchema);
  const result = validate(url.searchParams.get('result'), z.string());

  return {
    type,
    result,
  };
}
