/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { Task } from './types/tasks';

export function isTaskSnoozed(task: Pick<Task, 'snoozedUntil'>) {
  return task.snoozedUntil && task.snoozedUntil.valueOf() > Date.now();
}
