/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren, ReactNode } from 'react';
import { PomodoroTimerProps } from '../pomodoroTimer/PomodoroTimer';
import {
  AbsoluteBackground,
  AbsoluteBackgroundProps,
  AbsoluteCenter,
  Box,
  BoxProps,
  Flex,
  Loading,
} from '@time-neko/frontend/ui';
import {
  PomodoroSettings,
  PomodoroState,
  PomodoroStates,
} from '@time-neko/shared/domain/pomodoro';
import { PomodoroImage, PomodoroImageProps } from './parts/PomodoroImage';
import { Time } from '@time-neko/shared/time';
import { ChakraProps } from '@chakra-ui/system';
import {
  PomodoroTimerSection,
  PomodoroTimerSectionProps,
} from './parts/PomodoroTimerSection';

export interface TimerBoxProps
  extends Pick<
      PomodoroTimerProps,
      | 'hidePomodoroStateOnEdit'
      | 'hideStateText'
      | 'editableProps'
      | 'stateTextProps'
    >,
    Pick<PomodoroTimerSectionProps, 'controlProps' | 'additionalControls'> {
  containerProps?: BoxProps;
  centerContent?: ReactNode;
  footer?: ReactNode;
  height?: ChakraProps['height'];
  pomodoro?: PomodoroState;
  valueAsSeconds: number;
  imageProps?: Omit<PomodoroImageProps, 'pomodoro'>;
  pomodoroSettings?: PomodoroSettings;
  backgroundProps?: AbsoluteBackgroundProps;
  isLoading?: boolean;
}

export function TimerBox(props: PropsWithChildren<TimerBoxProps>) {
  const {
    containerProps,
    footer,
    height,
    pomodoro,
    centerContent,
    editableProps,
    imageProps,
    backgroundProps,
    valueAsSeconds,
    isLoading,
    children,
  } = props;

  const isEdit = Boolean(editableProps.isEdit);

  return (
    <Box
      className="timer-box"
      height={{
        base: '100%',
        sm: 'auto',
      }}
      {...containerProps}
    >
      {children}
      <AbsoluteBackground
        transition="background .3s"
        background={`brand.${pomodoro?.state ?? PomodoroStates.Work}`}
        {...backgroundProps}
      />

      <Flex
        width="100%"
        direction={{
          base: 'column',
          sm: 'row',
        }}
        alignItems={{
          base: 'center',
          sm: undefined,
        }}
        height={height}
        className="stack-content"
        position="relative"
        pr={{
          base: 0,
          sm: 6,
        }}
        justifyContent={{
          base: 'center',
          sm: undefined,
        }}
      >
        {isLoading ? (
          <AbsoluteCenter>
            <Loading boxSize="md" />
          </AbsoluteCenter>
        ) : (
          <>
            {!isEdit && (
              <PomodoroImage
                pomodoro={pomodoro}
                {...imageProps}
                containerProps={{
                  ...imageProps?.containerProps,
                  mb: 0,
                  sx: {
                    '&.has-balloon': {
                      mb: {
                        base: 6,
                        sm: 0,
                      },
                    },
                  },
                }}
              >
                {centerContent}
              </PomodoroImage>
            )}
            <PomodoroTimerSection
              {...props}
              isEdit={isEdit}
              remainingSeconds={valueAsSeconds}
              onChange={(seconds) => {
                editableProps?.onChange?.(
                  Time.fromSeconds(seconds).toClockString()
                );
              }}
            />
          </>
        )}
      </Flex>
      {footer}
    </Box>
  );
}
