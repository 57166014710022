/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Fragment } from 'react';
import { MenuItemProperties } from '../../shared/menu.types';
import { MenuDivider, MenuGroup, MenuGroupProps } from '@chakra-ui/react';
import { SubmenuItemRenderer } from './SubmenuItemRenderer';
import { MenuItemWithSubContents } from './menuTypes/MenuItemWithSubContents';
import { PlainMenuItem } from './menuTypes/PlainMenuItem';
import { useMenuItemsState } from './MenuItemsState.provider';
import { ChakraProps } from '@chakra-ui/system';
import { omit } from '@fxts/core';

export interface MenuItemRendererProps extends ChakraProps {
  item: MenuItemProperties;
  highlightBackground?: string;
}

export function MenuItemRenderer({
  item: menuItem,
  highlightBackground,
  ...props
}: MenuItemRendererProps) {
  const state = useMenuItemsState();

  const { id } = menuItem;

  const shouldDim = Boolean(
    state.highlightedItemId && state.highlightedItemId !== id
  );
  const isHighlighted = state.highlightedItemId === id;

  const commonStyle = {
    ...props.sx,
    opacity: shouldDim ? '0 !important' : 1,
  };

  switch (menuItem.renderAs) {
    case 'separator': {
      return (
        <MenuDivider
          key={id}
          sx={commonStyle}
          {...omit(['renderAs'], menuItem)}
        />
      );
    }

    case 'group': {
      const { items, ...rest } = menuItem;

      return (
        <MenuGroup
          key={id}
          color="brand.textPrimary"
          sx={commonStyle}
          {...(omit(['renderAs'], rest) as MenuGroupProps)}
        >
          {items.map((item) => (
            <MenuItemRenderer
              key={item.id}
              item={item}
              highlightBackground={highlightBackground}
            />
          ))}
        </MenuGroup>
      );
    }

    case 'nested': {
      return <SubmenuItemRenderer sx={commonStyle} item={menuItem} key={id} />;
    }

    case 'plainComponent': {
      const { Component } = menuItem;

      return (
        <Fragment key={id}>
          <Component sx={commonStyle} isHighlighted={isHighlighted} />
        </Fragment>
      );
    }

    case 'itemWithSubContents':
      return (
        <MenuItemWithSubContents
          key={id}
          highlightBackground={highlightBackground}
          item={{
            ...menuItem,
            id,
          }}
          sx={commonStyle}
        />
      );

    case 'item': {
      return (
        <PlainMenuItem
          item={omit(['renderAs'], menuItem)}
          key={id}
          sx={commonStyle}
        />
      );
    }

    default:
      return null;
  }
}
