/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { isSafariMobile } from '@time-neko/frontend/platform';
import { useMount } from 'react-use';

export function useIosSafariInputPushFix() {
  useMount(() => {
    if (isSafariMobile()) {
      const style = document.createElement('style');

      style.innerText = `
            html,
            body {
              overflow-y: auto !important;
            }
      `;

      document.head.appendChild(style);
    }
  });
}
