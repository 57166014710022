/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef } from 'react';
import {
  Heading as BaseHeading,
  HeadingProps as BaseHeadingProps,
} from '@chakra-ui/react';
import classNames from 'classnames';

export type HeadingProps = BaseHeadingProps;

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ ...props }, ref) => {
    return (
      <BaseHeading
        {...props}
        className={classNames('heading', props.className)}
        color={props.color ?? 'brand.textPrimary'}
        ref={ref}
      />
    );
  }
);
