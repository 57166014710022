/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { DialogHookProps } from './types';
import { ReactNode } from 'react';
import { create } from 'zustand';

export interface DialogState {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentProps?: DialogHookProps | ReactNode;
  setCurrentProps: (props: DialogHookProps | ReactNode) => void;
}

export const useDialogState = create<DialogState>((set) => ({
  open: false,
  currentProps: undefined,
  setCurrentProps: (props) => set({ currentProps: props }),
  setOpen: (open) => set({ open }),
}));
