/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import {
  Button,
  DropdownMenu,
  DropdownMenuProps,
  PlacementWithLogical,
} from '@time-neko/frontend/ui';
import { Asset } from '@time-neko/frontend/assets';

export interface PomodoroDropdownMenuProps {
  dropdownMenuProps: Pick<
    DropdownMenuProps,
    'menuProps' | 'items' | 'menuListProps' | 'menuButtonProps'
  >;
  placement?: PlacementWithLogical;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

export function PomodoroDropdownMenu({
  dropdownMenuProps,
  placement,
  onOpen,
  isOpen,
  onClose,
}: PomodoroDropdownMenuProps) {
  return (
    <DropdownMenu
      background="brand.bg"
      scrollContainerProps={{
        px: 2,
        pt: 2,
        width: 300,
      }}
      usePortal
      {...dropdownMenuProps}
      menuProps={{
        strategy: 'absolute',
        placement,
        autoSelect: false,
        isLazy: true,
        ...dropdownMenuProps.menuProps,
        isOpen,
        onClose,
        onOpen,
      }}
      menuListProps={{
        ...dropdownMenuProps.menuListProps,
        display: 'flex',
        flexDirection: 'column',
      }}
      menuButtonProps={{
        size: '2sm',
        padding: 0,
        as: Button,
        variant: 'nes-ghost',
        className: 'app-menu-btn no-drag',
        ...dropdownMenuProps.menuButtonProps,
      }}
    >
      <Asset name="Ellipsis" boxSize="20px" position="relative" top={0.5} />
    </DropdownMenu>
  );
}
