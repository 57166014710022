/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { frontendPomodoroSchema } from '@time-neko/frontend/domain/pomodoro/schema';

export function useNextStateNotificationTimerFlags() {
  const flagQuery =
    frontendPomodoroSchema.wasBreakNotificationFlagTimeAdded.useQuery();

  const flagMutation =
    frontendPomodoroSchema.flagBreakNotificationTimeAdd.useCommand();

  return {
    flagQuery,
    flagMutation,
  };
}
