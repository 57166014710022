/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef, useCallback } from 'react';
import {
  Box,
  BoxProps,
  Button as BaseButton,
  ButtonProps as BaseProps,
  Center,
} from '@chakra-ui/react';
import { Loading } from '../loading/Loading';
import {
  ColorSchemeType,
  useColorSchemeVariant,
} from '@time-neko/frontend/providers/theme';
import { LongPressCallback, useLongPress } from 'use-long-press';
import { Frame } from '../Frame/Frame';
import { motion } from 'framer-motion';
import {
  useLongPressIndicator,
  UseLongPressIndicatorProps,
} from './useLongPressIndicator';
import {
  InteractionSound,
  InteractionSoundType,
} from '@time-neko/frontend/interaction-sound';

export interface ButtonProps
  extends Omit<BaseProps, 'colorScheme'>,
    UseLongPressIndicatorProps {
  colorScheme?: ColorSchemeType;
  href?: string;
  target?: string;
  containerProps?: BoxProps;
  contentProps?: BoxProps;
  clickSound?: InteractionSoundType;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      containerProps,
      onLongPressStart,
      onLongPress,
      onLongPressReverted,
      isLoading,
      children,
      loadingText,
      colorScheme,
      contentProps,
      clickSound,
      ...props
    },
    ref
  ) => {
    const colorSchemeVariant = useColorSchemeVariant(colorScheme);

    const {
      leftSpring,
      rightSpring,
      bottomSpring,
      topSpring,
      longPressHandler,
      isLongPress,
      startRevert,
    } = useLongPressIndicator({
      onLongPress,
      onLongPressReverted,
      onLongPressStart,
    });

    const handleLongPress = useCallback<LongPressCallback>(() => {
      if (!onLongPress) {
        return;
      }

      longPressHandler();
    }, [longPressHandler, onLongPress]);

    const longPress = useLongPress(handleLongPress, {
      threshold: 1000,
      onFinish: startRevert,
      cancelOnMovement: true,
      cancelOutsideElement: true,
    });
    const longPressEvents = longPress();

    return (
      <InteractionSound sound={clickSound}>
        {({ play }) => (
          <Box
            position="relative"
            display="inline-block"
            width={props?.width}
            w={props?.w}
            height={props?.height}
            h={props?.h}
            {...containerProps}
          >
            {isLongPress && (
              <Box
                userSelect="none"
                pointerEvents="none"
                overflow="hidden"
                position="absolute"
                left={0}
                top={0}
                width="100%"
                height="100%"
                zIndex={2}
              >
                <motion.div
                  style={{
                    width: topSpring,
                    position: 'absolute',
                    left: 0,
                    zIndex: 2,
                  }}
                >
                  <Frame
                    borderWidth="1px !important"
                    height="1px"
                    width="100%"
                  />
                </motion.div>
                <motion.div
                  style={{
                    height: rightSpring,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    zIndex: 2,
                  }}
                >
                  <Frame
                    borderWidth="1px !important"
                    height="100%"
                    width="100%"
                  />
                </motion.div>
                <motion.div
                  style={{
                    bottom: 0,
                    right: bottomSpring,
                    width: '100%',
                    position: 'absolute',
                  }}
                >
                  <Frame
                    borderWidth="1px !important"
                    height="1px"
                    width="100%"
                  />
                </motion.div>
                <motion.div
                  style={{
                    height: '100%',
                    position: 'absolute',
                    left: 0,
                    top: leftSpring,
                    zIndex: 2,
                  }}
                >
                  <Frame
                    borderWidth="1px !important"
                    height="100%"
                    width="100%"
                  />
                </motion.div>
              </Box>
            )}
            <BaseButton
              {...props}
              {...longPressEvents}
              onClick={(event) => {
                if (isLongPress) {
                  return;
                }

                play();

                props.onClick?.(event);
              }}
              sx={{
                ...props.sx,
                ...colorSchemeVariant,
              }}
              position="relative"
              ref={ref}
              isDisabled={props.isDisabled || isLoading}
            >
              <Box
                display="flex"
                alignItems="center"
                className="button-content"
                visibility={isLoading ? 'hidden' : 'visible'}
                {...contentProps}
              >
                {children}
              </Box>
              {isLoading && (
                <Center position="absolute">
                  <Loading boxSize="sm" />
                </Center>
              )}
            </BaseButton>
          </Box>
        )}
      </InteractionSound>
    );
  }
);
