/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { BottomNavigation } from '../bottomNavigation/BottomNavigation';
import { Asset } from '@time-neko/frontend/assets';
import { appRoutes } from '@time-neko/shared/routes';

export function MobileBottomNavigation() {
  return (
    <BottomNavigation
      className="mobile-navigation"
      items={[
        {
          'aria-label': 'Tasks',
          id: 'tasks',
          icon: <Asset name="Check" boxSize="2sm" />,
          href: appRoutes.tasks(),
          isLink: true,
        },
        {
          'aria-label': 'Timer',
          id: 'timer',
          isLink: true,
          icon: <Asset name="Clock" boxSize="2sm" />,
          href: '/',
          notActiveSx: {
            '& .clock-top-ear, & .clock-top': {
              fill: 'brand.iconPrimary',
            },

            '& .clock-body': {
              fill: 'brand.iconPrimary',
            },
          },
          activeSx: {
            fill: undefined,

            '& .clock-top-ear, & .clock-top': {
              fill: 'brand.secondary',
            },

            '& .clock-body': {
              fill: 'white',
            },
          },
        },
        {
          'aria-label': 'Settings',
          id: 'settings',
          icon: (
            <Asset
              name="Settings"
              boxSize="2sm"
              sx={{
                '*': {
                  fill: 'brand.iconPrimary',
                },
              }}
            />
          ),
          isLink: true,
          href: appRoutes.settings(),
        },
      ]}
    />
  );
}
