/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ReactNode } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Center,
  ComponentWithAs,
  HStack,
  ModalContentProps,
  ModalHeaderProps,
  PropsOf,
} from '@chakra-ui/react';
import { Asset, AssetName, AssetProps } from '@time-neko/frontend/assets';
import { Frame } from '../../atoms';

export interface DialogProps extends AlertDialogProps {
  title?: ReactNode;
  footer?: ReactNode;
  headerProps?: ModalHeaderProps;
  icon?: AssetName;
  iconProps?: Omit<AssetProps, 'name'>;
  contentProps?: PropsOf<ComponentWithAs<'section', ModalContentProps>>;
}

export function Dialog({
  title,
  footer,
  headerProps,
  icon,
  iconProps,
  children,
  contentProps,
  ...rest
}: DialogProps) {
  return (
    <AlertDialog {...rest}>
      <AlertDialogOverlay>
        <AlertDialogContent
          bg="transparent"
          border="none"
          maxWidth="80vw"
          minWidth="min(400px, 80vw)"
          {...contentProps}
        >
          <Frame width="100%" p={6}>
            {icon && (
              <Center pt={2} pb={2}>
                <Asset name={icon} boxSize="3xl" {...iconProps} />
              </Center>
            )}
            {title && (
              <AlertDialogHeader
                textAlign="center"
                color="brand.textPrimary"
                {...headerProps}
              >
                {title}
              </AlertDialogHeader>
            )}
            {children && (
              <AlertDialogBody p={0} color="brand.textPrimary">
                {children}
              </AlertDialogBody>
            )}
            {footer && (
              <AlertDialogFooter width="100%">
                <HStack spacing={4} width="100%" justifyContent="center">
                  {footer}
                </HStack>
              </AlertDialogFooter>
            )}
          </Frame>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
