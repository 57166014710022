/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef, useState } from 'react';
import { ChakraProps } from '@chakra-ui/system';
import {
  Textarea as BaseTextarea,
  TextareaProps as BaseProps,
  useStyleConfig,
} from '@chakra-ui/react';

export interface TextareaProps extends BaseProps {
  autoResize?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ variant = 'nes', autoResize, ...props }, ref) => {
    const styles = useStyleConfig('Input', {
      variant,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any) as ChakraProps;

    const [height, setHeight] = useState('auto');

    return (
      <BaseTextarea
        w="100%"
        borderRadius={0}
        {...props}
        height={autoResize ? height : props.height}
        onChange={(event) => {
          props?.onChange?.(event);

          if (autoResize) {
            setHeight(`${event.target.scrollHeight}px`);
          }
        }}
        ref={ref}
        sx={styles}
      />
    );
  }
);
