/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { UseCommandOptions, UseQueryOptions } from '@musubi/react';
import { frontendDndSchema } from '@time-neko/frontend/domain/dnd/schema';

export interface UseDndHookProps {
  queryOptions?: {
    isInstallRequired?: UseQueryOptions<undefined, boolean>;
  };
  mutationOptions?: {
    install?: UseCommandOptions<undefined, undefined>;
  };
}

export function useDnd({
  queryOptions,
  mutationOptions,
}: UseDndHookProps = {}) {
  const isSupportedQuery = frontendDndSchema.isDndSupported.useQuery();

  const isInstallRequiredQuery =
    frontendDndSchema.isDndInstallRequired.useQuery({
      ...queryOptions?.isInstallRequired,
    });

  const installMutation = frontendDndSchema.installDnd.useCommand({
    invalidateQueries: [isInstallRequiredQuery.key],
    ...mutationOptions?.install,
  });

  const isLoading =
    isSupportedQuery.isLoading || isInstallRequiredQuery.isLoading;

  return {
    isLoading,
    isSupportedQuery,
    isInstallRequiredQuery,
    installMutation,
  };
}
