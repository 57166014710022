/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  ColorModeProvider as ChakraColorModeProvider,
  CSSReset,
  PortalManager,
  theme as defaultTheme,
  ThemeProvider as ChakraThemeProvider,
  ToastProvider,
  useConst,
  useTheme as useChakraTheme,
} from '@chakra-ui/react';
import createCache, { EmotionCache } from '@emotion/cache';
import { CacheProvider as DefaultCacheProvider, Global } from '@emotion/react';
import { ComponentType, PropsWithChildren, Provider, useMemo } from 'react';
import { animations } from './styles/animations';
import { pixelBorders } from './styles/pixelBorders';
import { scrollbar } from './styles/scrollbar';
import { styles } from './styles/styles';
import { nesCss } from './styles/nesCss';
import { createTheme, CreateThemeParams } from './theme';
import { StorageManager, Theme } from './types';
import { windowsClipFix } from './styles/windowsClipFix';
import { ColorModeController } from './ColorModeController';
import { usePrefersColorMode } from './hooks/usePrefersColorMode';
import { useMount } from 'react-use';
import { logger } from '@time-neko/shared/logger';

const storageManager: StorageManager = {
  set: () => {
    // No op
  },
  get: () => {
    // No op

    return undefined;
  },
  type: 'localStorage',
  ssr: false,
};

export const useTheme = () => useChakraTheme() as Theme;

export type ThemeProviderProps = Pick<CreateThemeParams, 'numberStepperIcon'> &
  DefaultColorModeProviderProps & {
    emotionCache?: EmotionCache;
    CacheProvider?: Provider<EmotionCache>;

    applyWindowsClipFix?: boolean;
    useColorModeController?: boolean;
  } & (
    | DefaultColorModeProviderProps
    | { ColorModeProvider?: ComponentType<PropsWithChildren> }
  );

export interface DefaultColorModeProviderProps {
  colorModeStorage?: StorageManager;
}

const DefaultColorModeProvider = ({
  children,
  colorModeStorage = storageManager,
}: PropsWithChildren<DefaultColorModeProviderProps>) => {
  const colorMode = usePrefersColorMode();

  return (
    <ChakraColorModeProvider
      colorModeManager={colorModeStorage}
      options={{
        initialColorMode: 'system',
      }}
      value={colorMode}
    >
      {children}
    </ChakraColorModeProvider>
  );
};

export const ThemeProvider = ({
  children,
  numberStepperIcon,
  emotionCache,
  applyWindowsClipFix = false,
  useColorModeController = true,
  CacheProvider = DefaultCacheProvider,
  ...props
}: PropsWithChildren<ThemeProviderProps>) => {
  const cache = useConst(
    emotionCache ??
      createCache({
        key: 'chakra',
      })
  );

  const colorMode = usePrefersColorMode();

  const ColorModeProvider =
    'ColorModeProvider' in props && props.ColorModeProvider
      ? props.ColorModeProvider
      : DefaultColorModeProvider;

  const theme = useMemo(
    () => createTheme({ numberStepperIcon, colorMode }) as Theme,
    [colorMode, numberStepperIcon]
  );

  const globalStyles = useMemo(() => {
    const globalStyles = [scrollbar(theme)];

    if (applyWindowsClipFix) {
      globalStyles.push(windowsClipFix);
    }

    return globalStyles;
  }, [theme, applyWindowsClipFix]);

  useMount(() => {
    logger.debug('Chakra theme', defaultTheme);
    logger.debug('App theme', theme);
  });

  return (
    <CacheProvider value={cache}>
      <ChakraThemeProvider theme={theme}>
        <CSSReset />
        <ColorModeProvider {...props}>
          <Global
            styles={[
              ...globalStyles,
              styles(theme.colors.brand.bg),
              pixelBorders,
              animations(theme),
              nesCss,
            ]}
          />
          {useColorModeController && <ColorModeController />}

          <PortalManager zIndex={999}>
            <ToastProvider />
            {children}
          </PortalManager>
        </ColorModeProvider>
      </ChakraThemeProvider>
    </CacheProvider>
  );
};
