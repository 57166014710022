/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { FrontendIntegrationSectionDefinition } from '../types';
import { frontendIntegrationsSchema } from '@time-neko/frontend/domain/integrations/schema';

export function useApiAuthState({
  type,
  comingSoon,
}: FrontendIntegrationSectionDefinition) {
  const { isLoading, data, setQueryData, error } =
    frontendIntegrationsSchema.getApiToken.useQuery({
      variables: {
        type,
      },
      onSuccess: (result) => {
        setQueryData(result ?? null);
      },
      enabled: !comingSoon,
    });

  frontendIntegrationsSchema.apiAuthorized.useEvent(
    ({ payload }) => {
      if (payload.type === type) {
        setQueryData(payload.token);
      }
    },
    [type, setQueryData]
  );

  frontendIntegrationsSchema.apiUnauthorized.useEvent(
    ({ payload }) => {
      if (payload.type === type) {
        setQueryData(null);
      }
    },
    [type, setQueryData]
  );

  return {
    error,
    loading: isLoading,
    token: data ?? null,
    isAuthorized: Boolean(data),
  };
}
