/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { z } from 'zod';
import { NotionPropertySchema } from './property.types';

export enum NotionAutomationTrigger {
  TaskCompleted = 'TaskCompleted',
}

export const NotionDatabaseAutomationSchema = NotionPropertySchema.extend({
  trigger: z
    .nativeEnum(NotionAutomationTrigger)
    .default(NotionAutomationTrigger.TaskCompleted),
});

export type NotionDatabaseAutomation = z.infer<
  typeof NotionDatabaseAutomationSchema
>;
