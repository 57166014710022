/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { ReactNode } from 'react';
import PlanYourDay from './assets/PlanYourDay.png';
import LongBreak from './assets/LongBreak.png';
import BreakWorkTransition from './assets/BreakWorkTransition.mp4';
import { composeMedia } from '@time-neko/frontend/assets';
import { VideoControls } from '@time-neko/frontend/ui';

export interface Feature {
  title: ReactNode;
  description: ReactNode;
  image: ReactNode;
}

const PlanYourDayImage = composeMedia({
  type: 'img',
  assetMap: {
    light: PlanYourDay,
    dark: PlanYourDay,
  },
});

const LongBreakImage = composeMedia({
  type: 'img',
  assetMap: {
    light: LongBreak,
    dark: LongBreak,
  },
});

const BreakWorkTransitionVideo = composeMedia({
  type: 'video',
  assetMap: {
    light: BreakWorkTransition,
    dark: BreakWorkTransition,
  },
});

export const features: Feature[] = [
  {
    title: 'Plan your day',
    description: `Start your day by creating a list of tasks that you want to finish.

Drag task that you are currently working on to the top to mark it as an active task.`,
    image: <PlanYourDayImage height="auto" width="100%" />,
  },
  {
    title: 'Focus',
    description: `Pick a task and start the timer. 25 minutes is a good time to stay focused. during this time commit to the task and avoid distractions. After that, take a short 5 minute break.`,
    image: (
      <VideoControls
        pb={2}
        minHeight={350}
        video={<BreakWorkTransitionVideo width="100%" height="auto" />}
      />
    ),
  },
  {
    title: 'Take a longer break',
    description: `After 4 focus sessions take a longer break. 15 minutes is a good time to rest and recharge.`,
    image: <LongBreakImage height="auto" width="100%" />,
  },
];
