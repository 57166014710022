/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { theme as chakraTheme } from '@chakra-ui/theme';
import {
  Theme,
  ThemeAnimations,
  ThemeOpacities,
  ThemeVariantParams,
} from './types';
import { ColorMode, extendTheme } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { makeInputSize } from './components/makeInputSize';
import { shadows } from './themeParts/shadows';
import { ColorSchemeType, createColors } from './themeParts/colors';
import { sizes } from './themeParts/sizes';
import {
  createNesColorSchemeFromScheme,
  createNesTheme,
  createRawNesColorFromScheme,
} from './themeParts/nes';
import { fadeIn, flyUpDown } from './themeParts/animations';
import { getBorderImageSource } from './themeParts/borderImageSource';
import {
  FormControlStyles,
  FormControlVariant,
} from './components/formControl';
import { createCustomIconButtonComponent } from './components/customIconButton';
import { cssTransition } from './cssTransitons';

export interface CreateThemeParams {
  colorMode: ColorMode;
  numberStepperIcon?: ReactNode;
}

export function createTheme({
  colorMode,
  numberStepperIcon,
}: CreateThemeParams) {
  {
    const { textColor, colors } = createColors(colorMode);
    const {
      nesVariant,
      nesVariants,
      buttonDefaults,
      nesBase,
      cursors,
      inputVariants,
    } = createNesTheme(colorMode, colors);

    return extendTheme({
      sizes,
      colors,
      breakpoints: {
        xs: '320px',
        sm: '426px',
        md: '768px',
        lg: '960px',
        xl: '1200px',
        '2xl': '1536px',
        '3xl': '2048px',
      },
      cursors,
      createColorScheme: (colorScheme: ColorSchemeType) =>
        createNesColorSchemeFromScheme(colorScheme, colors, colorMode),
      createRawColorScheme: (colorScheme: ColorSchemeType) =>
        createRawNesColorFromScheme(colorScheme, colors, colorMode),
      getBorderImageSource,
      zIndices: {
        ...chakraTheme.zIndices,
        tooltip: 9000,
      },
      opacities: {
        disabled: 0.5,
        normal: 1,
      } as ThemeOpacities,
      shadows,
      animations: {
        fadeIn,
        flyUpDown,
      } as ThemeAnimations,
      fonts: {
        body: 'PixelFont',
        heading: 'PixelFont',
        mono: 'PixelFont',
      },
      cssTransition,
      components: {
        ExternalLink: {
          defaultProps: {
            variant: 'nes',
          },
          variants: {
            nes: {
              outline: 'none',
              '&:focus-visible': {
                boxShadow: shadows.focusVisible,
              },
            },
          },
        },
        Accordion: {
          defaultProps: {
            variant: 'nes',
          },
          variants: {
            nes: {
              button: {
                ...nesVariants['nes'],
                border: 'none',
                borderImage: 'none',

                '*:focus-visible': {
                  boxShadow: shadows.focusVisible,
                },

                '&::after': {
                  display: 'none',
                },
              },
            },
          },
        },
        Divider: {
          baseStyle: {
            borderColor: 'brand.bgReverse',
          },
        },
        CustomFormControl: {
          variants: {
            [FormControlVariant.inline]: {
              control: {
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                flexDirection: 'row',
                margin: '0 auto',
              },
              formLabel: {
                whiteSpace: 'nowrap',
                mb: 0,
              },
              labelStack: {
                flex: 0,
              },
            } as FormControlStyles,
            [FormControlVariant.default]: {},
          },
        },
        Frame: {
          baseStyle: {
            ...nesVariants.nes,
            borderImageSource: getBorderImageSource(colors.brand.bgReverse),

            '&, &:focus, &:active': {
              boxShadow: 'none',
            },

            '&::after, &::before, &:hover::after,  &:focus::after, &:active::after':
              {
                boxShadow: 'none',
                borderImage: 'none',
                border: 'none',
                pointerEvents: 'none',
              },
          },
        },
        Kbd: {
          baseStyle: {
            ...nesVariants['nes'],
            p: 1.5,

            '&::after': {
              boxShadow: 'none',
            },
          },
        },
        Editable: {
          variants: {
            nes: {
              input: {
                ...nesVariants.nes,
              },
            },
          },
          defaultProps: {
            variant: 'nes',
          },
        },
        Checkbox: {
          defaultProps: {
            colorScheme: 'brand.primaryScheme',
            variant: 'nes',
          },
          variants: {
            nes: {
              control: {
                cursor: cursors.pointer,
              },
              label: {
                cursor: cursors.pointer,
              },
              container: {
                ...nesVariants.nes,

                borderColor: 'transparent',
                outline: 'none',
                boxShadow: 'none',

                '& input': {
                  cursor: cursors.pointer,
                },

                '&::after': {
                  boxShadow: 'none',
                },

                '& .chakra-checkbox__control:focus, & .chakra-checkbox__control[data-focus]':
                  {
                    boxShadow: shadows.focusVisible,
                    borderRadius: 0,
                  },
              },
            },
          },
        },
        Modal: {
          baseStyle: {
            dialog: {
              ...nesBase,
              backgroundColor: colors.brand.bgSecondary,
              borderRadius: 0,

              '&::after': {
                display: 'none',
              },
            },
          },
        },
        Menu: {
          variants: {
            nes: {
              list: {
                ...nesBase,

                backgroundColor: colors.brand.bgSecondary,
                borderRadius: 0,
                boxShadow: shadows['dark-lg'],
              },
              item: {
                cursor: cursors.pointer,
                backgroundColor: colors.brand.bgSecondary,
                _focus: {
                  bg: 'brand.primary !important',
                },
              },
            },
          },
          defaultProps: {
            variant: 'nes',
          },
          baseStyle: {
            list: {
              zIndex: 100,
            },
            command: {
              color: 'brand.textSecondary',
            },
          },
        },
        Tabs: {
          variants: {
            nes: {
              tablist: {
                '& button.chakra-button, & .chakra-tabs__tab': {
                  ...nesVariants['nes-outlined'],
                  borderImage: 'none',
                  mr: 2,
                  borderRadius: 0,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderBottom: 'none',
                  cursor: cursors.pointer,

                  '&, &:focus, button:focus': {
                    boxShadow: 'none',
                  },

                  '&::after, &::before, &:hover::after,  &:focus::after, &:active::after':
                    {
                      boxShadow: 'none',
                      borderImage: 'none',
                      border: 'none',
                    },

                  '&::after': {
                    content: '""',
                    width: '100%',
                    height: 1,
                    background: 'black',
                    position: 'absolute',
                    top: '100%',
                    transition: 'none',
                  },

                  '&[aria-selected=true]': {
                    backgroundColor: 'transparent',

                    '&::after': {
                      backgroundColor: 'brand.primary',
                    },
                  },
                },
              },
            },
          },
          defaultProps: {
            variant: 'nes',
          },
        },
        Button: {
          ...buttonDefaults,
        },
        Tooltip: {
          variants: {
            ...nesVariants,
            'nes-balloon': {
              '&.nes-balloon': {
                backgroundColor: colors.brand.bgSecondary,
                boxShadow: 'none',
                color: textColor,
              },
            },
            nes: {
              ...nesVariants.nes,
              boxShadow: 'none',
              pt: 2,
              pb: 2,
              color: textColor,

              '&::after': {
                display: 'none',
              },
            },
          },
          defaultProps: {
            variant: 'nes-balloon',
          },
        },
        Textarea: {
          variants: nesVariants,
          defaultProps: {
            variant: 'nes',
          },
        },
        Input: {
          variants: inputVariants,
          defaultProps: {
            variant: 'nes',
          },
          baseStyle: {
            color: 'brand.textPrimary',
          },
          sizes: {
            xs: makeInputSize({
              fontSize: 'xs',
              h: 'xs',
              px: 4,
              borderRadius: 'xs',
            }),
            sm: makeInputSize({
              fontSize: 'sm',
              h: 'sm',
              px: 4,
              borderRadius: 'sm',
            }),
            md: makeInputSize({
              fontSize: 'sm',
              h: 'md',
              px: 4,
              borderRadius: 'md',
            }),
            lg: makeInputSize({
              fontSize: 'md',
              h: 'lg',
              px: 4,
              borderRadius: 'lg',
            }),
          },
        },
        Select: {
          variants: {
            nes: {
              field: nesVariants.nes,
            },
            'nes-outlined': {
              field: nesVariants['nes-outlined'],
            },
            'nes-ghost': {
              field: nesVariants['nes-ghost'],
            },
          },
          defaultProps: {
            variant: 'nes',
          },
          sizes: {
            xs: makeInputSize({
              fontSize: 'xs',
              h: 'xs',
              px: 4,
              borderRadius: 'xs',
            }),
            sm: makeInputSize({
              fontSize: 'sm',
              h: 'sm',
              px: 4,
              borderRadius: 'sm',
            }),
            md: makeInputSize({
              fontSize: 'sm',
              h: 'md',
              px: 4,
              borderRadius: 'md',
            }),
            lg: makeInputSize({
              fontSize: 'md',
              h: 'lg',
              px: 4,
              borderRadius: 'lg',
            }),
          },
        },
        NumberInput: {
          variants: {
            nes: {
              field: {
                ...nesVariant,
                color: 'brand.textPrimary',
                '& .chakra-select': {
                  '&, &:active, &:focus': {
                    borderColor: 'transparent',
                  },
                },
              },
              stepper: {},
            },
          },
          defaultProps: {
            variant: 'nes',
          },
          sizes: {
            xs: makeInputSize({
              fontSize: 'xs',
              h: 'xs',
              px: 4,
              borderRadius: 'xs',
            }),
            sm: makeInputSize({
              fontSize: 'sm',
              h: 'sm',
              px: 4,
              borderRadius: 'sm',
            }),
            md: makeInputSize({
              fontSize: 'sm',
              h: 'md',
              px: 4,
              borderRadius: 'md',
            }),
            lg: makeInputSize({
              fontSize: 'md',
              h: 'lg',
              px: 4,
              borderRadius: 'lg',
            }),
          },
        },
        NumberDecrementStepper: {
          defaultProps: {
            children: numberStepperIcon,
          },
        },
        IconButton: {
          ...buttonDefaults,
        },
        CustomIconButton: createCustomIconButtonComponent(),
        Container: {
          baseStyle: {
            bg: 'brand.bg',
          },
        },
        Badge: {
          variants: {
            nes: {
              ...nesVariant,
              boxShadow: 'none',
              padding: 0,
              boxSize: 6,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              '&::after, &:active, &:hover': {
                boxShadow: 'none !important',
              },
            },
            'nes-badge': ({ sx, theme, ...rest }: ThemeVariantParams) => {
              const backgroundColor =
                rest?.backgroundColor ??
                sx?.backgroundColor ??
                theme.colors.brand.bgSecondary;

              return {
                ...sx,
                backgroundColor: 'transparent',
                position: 'relative',
                verticalAlign: 'top',
                display: 'inline-block',
                whiteSpace: 'nowrap',

                '& .content': {
                  backgroundColor,
                  boxShadow: `0 0.5em ${backgroundColor}, 0 -0.5em ${backgroundColor}, 0.5em 0 ${backgroundColor}, -0.5em 0 ${backgroundColor}`,
                  w: '100%',
                  d: 'inline-block',
                  textAlign: 'center',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                },
              };
            },
          },
          defaultProps: {
            variant: 'nes',
          },
        },
      },
      styles: {
        global: {
          body: {
            fontFamily: 'PixelFont',
          },

          '*': {
            outline: 'none !important',
          },

          '*:focus-visible:not(.chakra-menu__menuitem)': {
            boxShadow: shadows.focusVisible,
          },

          'html body input': {
            color: textColor,
          },

          '.path-inherit': {
            fill: 'inherit',
          },

          '#root .is-rounded': {
            borderImageWidth: '4 !important',
          },
        },
      },
    }) as Theme;
  }
}
