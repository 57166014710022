/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import RetryBlack from '../../assets/retry-black.png';
import RetryWhite from '../../assets/retry-white.png';
import { composeMedia } from '../composeMedia';
import { BaseMediaProps } from '../types';

export type RetryIconProps = BaseMediaProps;

export const RetryIcon = composeMedia<RetryIconProps>({
  assetMap: {
    dark: RetryBlack,
    light: RetryWhite,
  },
  type: 'img',
  alt: '',
});
