/* eslint-disable @typescript-eslint/no-non-null-assertion */
/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ColorMode, theme as chakraTheme } from '@chakra-ui/react';
import { getBorderImageSource } from './borderImageSource';
import { shadows } from './shadows';
import {
  BrandColors,
  ColorScheme,
  ColorSchemeType,
  danger,
  primaryScheme,
} from './colors';
import { omit } from '@fxts/core';
import { sizes } from './sizes';
import { ThemeColors, ThemeCursors } from '../types';
import { getContrast } from '@time-neko/frontend/colors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NesThemePart = Record<string, any>;

export interface NesThemeResult {
  nesBase: NesThemePart;
  nesVariant: NesThemePart;
  nesVariants: {
    nes: NesThemePart;
    'nes-outlined': NesThemePart;
    'nes-ghost': NesThemePart;
  };
  buttonDefaults: NesThemePart;
  cursors: ThemeCursors;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputVariants: Record<string, any>;
}

function resolveColorKeyFromColorScheme(
  colorScheme: 'primary' | 'secondary' | 'danger' | 'success'
) {
  const colorMap: Record<ColorSchemeType, keyof BrandColors> = {
    secondary: 'secondaryScheme',
    primary: 'primaryScheme',
    danger: 'dangerScheme',
    success: 'successScheme',
  };

  return colorMap[colorScheme];
}

export function createRawNesColorFromScheme(
  colorScheme: ColorSchemeType,
  colors: ThemeColors,
  colorMode: ColorMode
) {
  const colorKeyToUse = resolveColorKeyFromColorScheme(colorScheme);

  return createRawNesColorScheme(
    colors.brand[colorKeyToUse] as typeof chakraTheme.colors.gray,
    colorMode
  );
}

export function createNesColorSchemeFromScheme(
  colorScheme: ColorSchemeType,
  colors: ThemeColors,
  colorMode: ColorMode
) {
  const colorKeyToUse = resolveColorKeyFromColorScheme(colorScheme);

  return createNesColorScheme(
    colors.brand[colorKeyToUse] as typeof chakraTheme.colors.gray,
    colorMode
  );
}

export function createRawNesColorScheme(
  color: typeof chakraTheme.colors.gray,
  colorMode: ColorMode
): ColorScheme {
  const colorScheme = {
    surface: color['600'],
    shadowColor: color['700'],
    darkerShadowColor: color['700'],
    borderColor:
      colorMode === 'dark'
        ? chakraTheme.colors.gray['800']
        : chakraTheme.colors.black,
  };

  const contrast = getContrast(colorScheme.surface);

  return {
    ...colorScheme,
    contrast,
  };
}

export function createNesColorScheme(
  color: typeof chakraTheme.colors.gray,
  colorMode: ColorMode
) {
  const colorScheme = createRawNesColorScheme(color, colorMode);

  return {
    styles: {
      backgroundColor: colorScheme.surface,
      borderImageSource: getBorderImageSource(colorScheme.borderColor),

      '&:active': {
        boxShadow: `inset 4px 4px ${colorScheme.shadowColor}`,
      },

      '&::after': {
        position: 'absolute',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        content: '""',
        boxShadow: `inset -3px -3px ${colorScheme.shadowColor}`,
      },

      '&:hover::after': {
        boxShadow: `inset -4px -4px ${colorScheme.darkerShadowColor}`,
      },

      '& .text': {
        color: colorScheme.contrast,
      },
    },
  };
}

export function createNesTheme(
  colorMode: ColorMode,
  colors: ThemeColors
): NesThemeResult {
  const nesBase = {
    border: '2px solid',
    borderRadius: 0,
    position: 'relative',
    borderImageSource: getBorderImageSource(colors.brand.borderColor),
    borderImageSlice: '2',
    borderImageWidth: '3',
    borderImageRepeat: 'repeat',
    borderImageOutset: '2',
    backgroundClip: 'padding-box',
    userSelect: 'all',
    backgroundColor: colors.brand.itemBg,
  };

  const nesVariant = {
    ...nesBase,

    '&:focus': {
      boxShadow: 'none',
    },

    '&::placeholder': {
      color: colorMode === 'dark' ? 'gray.400' : undefined,
    },

    '&::after': {
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      content: '""',
      boxShadow: 'inset -3px -3px #adafbc',
    },

    '&:hover::after': {
      boxShadow: shadows.hover,
    },

    '&:focus-visible': {
      boxShadow: shadows.focusVisible,
    },

    '&:active': {
      boxShadow: shadows.active,
    },

    '&[aria-invalid=true]': {
      boxShadow: `inset -4px -4px ${danger}`,
    },

    '&[aria-selected=true]': {
      background: primaryScheme['500'],
    },

    ...(colorMode === 'dark'
      ? createNesColorScheme(chakraTheme.colors.gray, colorMode).styles
      : {}),
  };

  const nesOutlineVariant = {
    ...nesVariant,
    bg: 'transparent',
  };

  const nesGhostVariant = {
    ...nesBase,
    borderRadius: 0,
    backgroundColor: 'transparent',
    borderImage: 'none',
    border: 'none',

    '&:focus': {
      boxShadow: 'none',
    },
  };

  const nesVariants = {
    nes: nesVariant,
    'nes-outlined': nesOutlineVariant,
    'nes-ghost': nesGhostVariant,
  };

  const buttonNesVariant = {
    ...nesVariants.nes,
  };

  const buttonDefaults = {
    variants: {
      ...nesVariants,
      nes: buttonNesVariant,
      'nes-outlined': {
        ...nesVariants['nes-outlined'],
        borderRadius: 0,

        '&:focus-visible': {
          boxShadow: shadows.focusVisible,
        },
      },
      'nes-ghost': {
        ...nesVariants['nes-ghost'],

        '&:focus-visible': {
          boxShadow: shadows.focusVisible,
        },
      },
    },
    defaultProps: {
      variant: 'nes',
    },
    sizes: {
      xs: {
        ...omit(['minW'], chakraTheme.components.Button.sizes!.xs),
        h: sizes.xs,
        minW: sizes.xs,
      },
      sm: {
        ...omit(['minW'], chakraTheme.components.Button.sizes!.sm),
        h: sizes.sm,
        minW: sizes.sm,
      },
      md: {
        ...omit(['minW'], chakraTheme.components.Button.sizes!.md),
        h: sizes.md,
        minW: sizes.md,
        fontSize: 'md',
      },
      lg: {
        ...omit(['minW'], chakraTheme.components.Button.sizes!.lg),
        h: sizes.lg,
        minW: sizes.lg,
        fontSize: 'md',
      },
      xl: {
        fontSize: 'md',
        h: sizes.xl,
        minW: sizes.xl,
        px: sizes.xl,
      },
    },
  };

  const cursors: ThemeCursors = {
    pointer:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer',
  };

  const inputVariants = Object.entries(nesVariants).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        [key]: {
          field: value,
        },
      };
    },
    {}
  );

  return {
    nesBase,
    nesVariant,
    nesVariants,
    buttonDefaults,
    cursors,
    inputVariants,
  };
}
