/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { TaskSchema } from '@time-neko/shared/domain/tasks';
import {
  PomodoroSettingsSchema,
  PomodoroStates,
} from '@time-neko/shared/domain/pomodoro';
import { SiteBlockedSchema } from '@time-neko/shared/domain/sites-blocking';
import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';
import { BaseEntitySchema } from '@time-neko/shared/common-types';

export const DayStatisticsBlockedSiteEntrySchema = SiteBlockedSchema.omit({
  url: true,
  date: true,
}).extend({
  url: z.string(),
  date: z.string(),
});

export const PomodoroCycleSchema = z.object({
  start: z.coerce.date(),
  end: z.coerce.date(),
  stateFrom: z.nativeEnum(PomodoroStates),
  stateTo: z.nativeEnum(PomodoroStates),
  /**
   * Stores count of all states in "stateFrom" that occurred until now
   * */
  cycleCount: z.number(),
  durationSeconds: z.number(),
  durationSettings: PomodoroSettingsSchema.pick({
    workDurationSeconds: true,
    longBreakDurationSeconds: true,
    shortBreakDurationSeconds: true,
  }),
});

const DayStatisticsCompletedTaskSchema = TaskSchema.pick({
  id: true,
  title: true,
  description: true,
});

export type DayStatisticsCompletedTask = z.infer<
  typeof DayStatisticsCompletedTaskSchema
>;

export const DayStatisticsSchema = BaseEntitySchema.extend({
  workCount: z.number(),
  breakCount: z.number(),
  longBreakCount: z.number(),
  workDurationSeconds: z.number(),
  breakDurationSeconds: z.number(),
  longBreakDurationSeconds: z.number(),
  date: z.coerce.date(),
  blockedSites: z.array(DayStatisticsBlockedSiteEntrySchema),
  pomodoroCycles: z.array(PomodoroCycleSchema),
  completedTasks: z.array(DayStatisticsCompletedTaskSchema),
});

export type DayStatistics = z.infer<typeof DayStatisticsSchema>;

export type DayStatisticsBlockedSiteEntry = z.infer<
  typeof DayStatisticsBlockedSiteEntrySchema
>;

export type PomodoroCycle = z.infer<typeof PomodoroCycleSchema>;

export const statisticSchema = defineSchema({
  commands: {},
  events: {
    dayStatisticsChanged: operation.event.withPayload(DayStatisticsSchema),
  },
  queries: {
    getDayStatistics: operation.query.withResult(DayStatisticsSchema),
  },
});
