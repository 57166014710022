/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { keyframes } from '@chakra-ui/system';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const flyUpDown = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-100%);
  }

  51% {
    opacity: 0;
  }

  52% {
    transform: translateY(100%);
    opacity: 0;
  }

  59% {
    transform: translateY(100%);
    opacity: 0;
  }

  60% {
    opacity: 1
  }


  100% {
    transform: translateY(0);
  }
`;
