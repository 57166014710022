/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { ReactNode, useCallback } from 'react';
import {
  Box,
  HStack,
  Popover as ChakraPopover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps as BaseProps,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { Frame, Text } from '../../atoms';
import { IconButton } from '../iconButton/IconButton';
import { Tooltip } from '../tooltip/Tooltip';
import { useToggle } from 'react-use';

export interface PopoverBag {
  close: () => void;
}

export type PopoverFn = (bag: PopoverBag) => ReactNode;

export interface PopoverProps extends Omit<BaseProps, 'isOpen'> {
  content?: ReactNode;
  footer?: ReactNode | PopoverFn;
  header?: ReactNode;
  triggerElement: ReactNode;
  hideCloseButton?: boolean;
  hideArrow?: boolean;
  isDisabled?: boolean;
}

export function Popover({
  header,
  footer,
  triggerElement,
  content,
  hideCloseButton = false,
  hideArrow = false,
  isDisabled = false,
  ...props
}: PopoverProps) {
  const [open, toggle] = useToggle(false);

  const handleClose = useCallback(() => {
    toggle(false);

    props?.onClose?.();
  }, [props, toggle]);

  const handleOpen = useCallback(() => {
    if (isDisabled) {
      return;
    }

    toggle(true);

    props?.onOpen?.();
  }, [isDisabled, props, toggle]);

  return (
    <ChakraPopover
      arrowSize={10}
      arrowPadding={30}
      {...props}
      isOpen={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <PopoverTrigger>{triggerElement}</PopoverTrigger>
      <Portal>
        <PopoverContent
          border="none"
          borderRadius={0}
          minWidth={300}
          maxWidth="calc(100vw - 50px)"
          width="auto"
          bg="transparent"
        >
          {!hideArrow && <PopoverArrow bg="brand.bgReverse" />}
          <Frame py={3}>
            <HStack alignItems="center" px={3} width="100%">
              {header && (
                <Box flex={1}>
                  {
                    <Text fontWeight="bold" fontSize="md">
                      {header}
                    </Text>
                  }
                </Box>
              )}
              {!hideCloseButton && (
                <Tooltip
                  shouldWrapChildren={false}
                  placement="top-start"
                  label="Close"
                >
                  <IconButton
                    onClick={handleClose}
                    variant="nes-ghost"
                    aria-label="Close"
                  >
                    <Text>x</Text>
                  </IconButton>
                </Tooltip>
              )}
            </HStack>

            {content && <PopoverBody py={6}>{content}</PopoverBody>}
            {footer && (
              <Box px={3}>
                {typeof footer === 'function'
                  ? footer({ close: handleClose })
                  : footer}
              </Box>
            )}
          </Frame>
        </PopoverContent>
      </Portal>
    </ChakraPopover>
  );
}
