/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { RouterState } from './types';
import createContext from 'zustand/context';
import create, { StoreApi } from 'zustand';
import { PropsWithChildren, useEffect, useMemo } from 'react';

export type InternalRouteState = RouterState & {
  setState: (state: RouterState) => void;
};

const { Provider, useStore } = createContext<StoreApi<InternalRouteState>>();

export const useRouterContext = useStore;

const createStore = (state: RouterState) =>
  create<InternalRouteState>((set) => ({
    ...state,
    setState: (state: RouterState) => set(state),
  }));

function Watcher(props: RouterState) {
  const store = useStore();

  useEffect(() => {
    store.setState(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return null;
}

export function RouterProvider({
  children,
  ...params
}: PropsWithChildren<RouterState>) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => () => createStore(params), []);

  return (
    <Provider createStore={store}>
      <Watcher {...params} />
      {children}
    </Provider>
  );
}
