/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export type ExcludesFalse = <T>(x: T | false | null | undefined) => x is T;

export const ExcludeFalsy: ExcludesFalse = Boolean as unknown as ExcludesFalse;
