/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { paramRoute } from './paramRoute';
import { ReleaseInfoRouteParams } from './appRoutes.types';
import { nestedRoute } from './nestedRoute';

export const appRoutes = {
  timer: () => '/timer',
  onboarding: () => '/onboarding',
  breakWindow: () => '/timer/break',
  tasks: () => '/tasks',
  settings: () => '/settings',
  pomodoroSettings: () => '/settings/pomodoro',
  manageTrello: nestedRoute({
    path: 'trello',
    fullPath: '/settings/trello',
  }),
  manageNotion: nestedRoute({
    path: 'notion',
    fullPath: '/settings/notion',
  }),
  hiddenAudioPlayer: () => '/hidden-audio-player',
  nextPomodoroState: () => '/next-pomodoro-state',
  siteBlocked: () => '/site-blocked',
  networkStatusCheck: () => '/network-status-check',
  feedback: () => '/feedback',
  releaseInfo: paramRoute<ReleaseInfoRouteParams>('/release-info/:version'),
};
