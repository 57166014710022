/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { CacheSetOptions, CacheStorage } from './types';
import { InMemoryCache } from './InMemory.cache';

export interface MemoFnArgs extends CacheSetOptions {
  cache?: CacheStorage<any>;
}

export type MemoizedFn<T extends (...args: any[]) => any> = T & {
  cache: CacheStorage<any>;
};

export function memoFn<T extends (...args: any[]) => any>(
  fn: T,
  { cache = new InMemoryCache<any>(), ...setOptions }: MemoFnArgs = {}
): MemoizedFn<T> {
  const memoFn = (...args: Parameters<T>) => {
    const key = args.length ? JSON.stringify(args) : 'undefined';
    let result = cache.get(key);

    if (!result) {
      result = fn(...args);

      if (result instanceof Promise) {
        result.then((actualResult) => {
          cache.set(key, actualResult, setOptions);
        });
      } else {
        cache.set(key, result, setOptions);
      }
    }

    return result as ReturnType<T>;
  };

  memoFn.cache = cache;

  return memoFn as MemoizedFn<T>;
}
