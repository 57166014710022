/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useCallback, useMemo } from 'react';
import {
  Button,
  ButtonGroup,
  CenterContainer,
  Heading,
  HStack,
  Text,
  Tooltip,
  VStack,
} from '@time-neko/frontend/ui';
import { Asset } from '@time-neko/frontend/assets';
import { chakra, ChakraProps } from '@chakra-ui/system';
import { useDomain } from '@time-neko/frontend/providers/domain';
import type {
  BlockedSiteQueryParams,
  BlockedSiteRule,
} from '@time-neko/shared/domain/sites-blocking';
import type { PomodoroStates } from '@time-neko/shared/domain/pomodoro';

const Strong = chakra.strong;
const Footer = chakra.footer;

export interface SiteBlockedProps extends BlockedSiteQueryParams, ChakraProps {
  onOpenSettings?: () => unknown;
  blockedSites?: BlockedSiteRule[];
  pomodoroState?: PomodoroStates;
}

export const SiteBlocked = ({
  goBackUrl,
  ruleId,
  onOpenSettings,
  blockedSites,
  pomodoroState,
  blockedUrl,
  ...props
}: SiteBlockedProps) => {
  const blockedSiteRule = useMemo(
    () => blockedSites?.find((rule) => rule.id === ruleId),
    [blockedSites, ruleId]
  );

  const openSettings = useDomain('openSettings');
  const appName = useDomain('appName');

  const goBack = useCallback(() => {
    if (goBackUrl) {
      window.location.href = goBackUrl;
    } else {
      window.close();
    }
  }, [goBackUrl]);

  return (
    <CenterContainer
      className="site-blocked-container"
      backgroundColor={pomodoroState ? `brand.${pomodoroState}` : 'brand.bg'}
      justifyContent="center"
      {...props}
      sx={{
        '& *:not(.button-content):not(button), & .open-settings .button-content':
          {
            color: 'white',
          },
        '.button-content': {
          color: 'brand.textPrimary',
        },
        ...props.sx,
      }}
    >
      <HStack
        spacing={4}
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Asset name="AngryCat" boxSize="120px" />
        <VStack justifyContent="center" alignItems="center">
          <Heading>Not so fast</Heading>

          <VStack>
            <Text>
              {blockedSiteRule
                ? `You've blocked this URL:`
                : 'This page is blocked.'}
            </Text>
            {blockedSiteRule && (
              <Tooltip label={blockedSiteRule.url}>
                <Strong
                  whiteSpace="nowrap"
                  minWidth="200px"
                  maxWidth="25vw"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  mx={1}
                >
                  {blockedSiteRule.url}
                </Strong>
              </Tooltip>
            )}
          </VStack>
          <ButtonGroup pt={2}>
            <Button onClick={goBack}>{goBackUrl ? 'Go back' : 'Close'}</Button>
          </ButtonGroup>
        </VStack>
      </HStack>
      <Footer position="absolute" bottom={2} left={2}>
        <HStack spacing={2} alignItems="center">
          <Text>This site is blocked by {appName}.</Text>
          <Button
            className="open-settings"
            onClick={onOpenSettings ?? openSettings}
            padding={0}
            variant="nes-ghost"
          >
            Open settings
          </Button>
        </HStack>
      </Footer>
    </CenterContainer>
  );
};
