/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import './mp3.d';
import click from './click.mp3';
import { ReactNode, useCallback, useMemo } from 'react';

const sounds = {
  click,
};

export type InteractionSoundType = keyof typeof sounds;

export interface InteractionSoundProps {
  sound?: InteractionSoundType;
  children: (props: { play: () => void }) => ReactNode;
}

export function InteractionSound({ sound, children }: InteractionSoundProps) {
  const path = sound ? sounds[sound] : undefined;
  const audio = useMemo(() => (path ? new Audio(path) : undefined), [path]);

  const play = useCallback(() => {
    void audio?.play();
  }, [audio]);

  return children({ play });
}
