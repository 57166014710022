/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { MenuItemProperties, Stack, Text } from '@time-neko/frontend/ui';
import { Asset } from '@time-neko/frontend/assets';
import { keyboardShortcuts } from '@time-neko/shared/utils';
import { useMemo } from 'react';
import { useDomain } from '@time-neko/frontend/providers/domain';
import { useAsyncSettingErrors } from '@time-neko/frontend/domain/settings/hooks';

export interface SettingsMenuItemsProps {
  onOpenSettings?: () => unknown;
  platform: NodeJS.Platform;
}

export function useSettingsMenuItems({
  platform,
  onOpenSettings,
}: SettingsMenuItemsProps) {
  const { errorsQuery } = useAsyncSettingErrors();

  const openSettings = useDomain('openSettings');

  return useMemo(
    () => ({
      openSettings: {
        renderAs: 'item',
        keyboardShortcut: keyboardShortcuts.openSettings(platform)?.display,
        label: (
          <Stack as="span" spacing={0}>
            <Text as="span">Settings</Text>
            {errorsQuery.data?.length && (
              <Text as="span" fontSize="xs" color="brand.danger">
                There are application errors, please check.
              </Text>
            )}
          </Stack>
        ),
        icon: (
          <Asset
            containerProps={{
              display: 'block',
            }}
            sx={{
              '& path, & rect': {
                fill: 'brand.iconPrimary',
              },
            }}
            boxSize="sm"
            name="Settings"
          />
        ),
        onClick: onOpenSettings ?? openSettings,
        id: 'open_settings',
        className: 'open-settings',
      } as MenuItemProperties,
    }),
    [errorsQuery.data, openSettings, platform, onOpenSettings]
  );
}
