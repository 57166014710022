/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { createStore, StoreApi, useStore } from 'zustand';
import { FrontendIntegrationSectionDefinition } from '@time-neko/frontend/domain/integrations/web';
import { createContext, PropsWithChildren, useContext } from 'react';
import { IntegrationType } from '@time-neko/shared/domain/integrations';

export interface FrontendIntegrationDefinitionsStore {
  definitions: FrontendIntegrationSectionDefinition[];
}

const Context = createContext<StoreApi<FrontendIntegrationDefinitionsStore>>(
  {} as never
);

export function useIntegrationDefinitions() {
  const store = useContext(Context);

  return useStore(store, (s) => s.definitions);
}

export function useIntegrationDefinition(type?: IntegrationType) {
  const store = useContext(Context);

  return useStore(store, (s) =>
    type ? s.definitions.find((d) => d.type === type) : undefined
  );
}

export function FrontendIntegrationsDefinitionsProvider({
  children,
  definitions,
}: PropsWithChildren<FrontendIntegrationDefinitionsStore>) {
  const store = createStore<FrontendIntegrationDefinitionsStore>(() => ({
    definitions,
  }));

  return <Context.Provider value={store}>{children}</Context.Provider>;
}
