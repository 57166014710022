/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { DialogHookProps } from './types';
import { isReactNode } from '@time-neko/frontend/common';

export function isDialogHookProps(item: unknown): item is DialogHookProps {
  const properties: Array<keyof DialogHookProps> = [
    'iconProps',
    'title',
    'body',
    'footer',
    'icon',
    'iconProps',
  ];

  return Boolean(
    !isReactNode(item) &&
      typeof item === 'object' &&
      item !== null &&
      properties.some((prop) => prop in item)
  );
}
