/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useCallback } from 'react';
import { AssetName, assetsMap } from './assets';
import { ChakraProps } from '@chakra-ui/system';
import { Box } from '@chakra-ui/react';
import { assetDefinitions } from './assetDefinitions';

export interface AssetProps extends Partial<Omit<ChakraProps, 'name'>> {
  name: AssetName;
  className?: string;
  containerProps?: ChakraProps;
}

export const Asset = ({
  name,
  className,
  css,
  containerProps,
  boxSize,
  width,
  height,
  ...props
}: AssetProps) => {
  const Component = assetsMap[name];
  const definition = assetDefinitions[name];

  const getSize = useCallback(() => {
    const sizeDefinition = definition?.sizes?.[boxSize as string];

    if (!sizeDefinition) {
      return {
        width: width ?? boxSize,
        height: height ?? boxSize,
      };
    }

    return sizeDefinition;
  }, [boxSize, definition, height, width]);

  const size = getSize();

  const fill = props.fill ?? 'brand.iconPrimary';

  if (!Component) {
    return null;
  }

  return (
    <Box
      as="span"
      display="inline-block"
      className={className}
      {...containerProps}
    >
      <Component
        preserveAspectRatio="xMidYMid meet"
        {...props}
        width={size.width}
        height={size.height}
        fill={fill}
        display={props.display ?? 'inherit'}
      />
    </Box>
  );
};
