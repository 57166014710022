/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { AppError } from './App.error';
import { castAsError } from '@time-neko/shared/utils';

type MessageCreator = (error: Error) => string | null;

const messagesCreators: MessageCreator[] = [
  (error) => {
    if (AppError.isAppError(error)) {
      return error.message;
    }

    return null;
  },

  (error) => {
    if (error.message.startsWith('The user aborted a request')) {
      return 'Operation was aborted, please try again.';
    }

    return null;
  },
];

export function getUserFriendlyError(
  error: unknown,
  genericMessage = 'Something went wrong.'
) {
  const err = castAsError(error);

  for (const messageCreator of messagesCreators) {
    const msg = messageCreator(err);

    if (msg) {
      return msg;
    }
  }

  return genericMessage;
}
