/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { websiteRoutes } from '@time-neko/shared/routes';

export function getPrivacyLinks(websiteUrl: string) {
  return [
    {
      label: 'Privacy Policy',
      url: `${websiteUrl}${websiteRoutes.privacyPolicy()}`,
    },
    {
      label: 'Terms of Service',
      url: `${websiteUrl}${websiteRoutes.termsOfService()}`,
    },
  ];
}
