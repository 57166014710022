/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Button, ButtonGroupProps, VStack } from '@time-neko/frontend/ui';

import { downloadablePlatforms } from '@time-neko/shared/domain/updates';
import { appPlatformLabels } from '@time-neko/shared/domain/types';
import { websiteRoutes } from '@time-neko/shared/routes';

export function DownloadButtons(props: ButtonGroupProps) {
  return (
    <VStack {...props} spacing={4}>
      {downloadablePlatforms.map((platform) => (
        <Button
          key={platform}
          height={{
            base: '2xl',
            sm: 'md',
          }}
          minWidth="auto"
          whiteSpace={{
            base: 'normal',
            sm: 'nowrap',
          }}
          as="a"
          target="_blank"
          href={`${process.env.NX_WEBSITE_URL}${websiteRoutes.download({
            platform,
          })}`}
          width="100%"
        >
          Download {appPlatformLabels[platform]}
        </Button>
      ))}
    </VStack>
  );
}
