/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren, useEffect } from 'react';
import { ChakraProps } from '@chakra-ui/system';
import { CenterContainer, Frame } from '@time-neko/frontend/ui';
import { usePageTitle } from '@time-neko/frontend/html';

export interface WebAppContainerProps
  extends PropsWithChildren<Omit<ChakraProps, 'maxHeight' | 'maxWidth'>> {
  frameProps?: ChakraProps;
  pageTitle?: string;
  pageTitlePrefix?: string;
  maxFrameWidth?: number | string;
  maxFrameHeight?: number | string;
}

export function WebAppContainer({
  children,
  maxFrameWidth = 500,
  maxFrameHeight = 600,
  frameProps,
  pageTitle,
  pageTitlePrefix,
  ...props
}: WebAppContainerProps) {
  const title = usePageTitle(pageTitle, pageTitlePrefix);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <CenterContainer
      transition="background .3s"
      background="inherit"
      minHeight="500px"
      maxHeight="var(--chakra-vh)"
      className="web-app-container"
      {...props}
      sx={{
        ...props.sx,

        '& .web-app-container-frame': {
          borderWidth: {
            base: '0',
            md: '2px',
          },
        },
      }}
    >
      <Frame
        className="web-app-container-frame"
        maxWidth={{
          base: '100%',
          md: maxFrameWidth,
        }}
        maxHeight={{
          base: '100%',
          md: maxFrameHeight,
        }}
        width="100%"
        height="100%"
        {...frameProps}
      >
        {children}
      </Frame>
    </CenterContainer>
  );
}
