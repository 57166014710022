/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef, PropsWithChildren } from 'react';
import { chakra, ChakraProps } from '@chakra-ui/system';
import { Asset } from '@time-neko/frontend/assets';
import type { IconProps } from '@chakra-ui/icons';
import { useStyleConfig } from '@chakra-ui/react';

const A = chakra.a;

export interface ExternalLinkProps extends ChakraProps {
  href?: string;
  target?: string;
  iconProps?: Omit<IconProps, 'name'>;
  variant?: string;
}

export const ExternalLink = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<ExternalLinkProps>
>(
  (
    {
      href,
      target,
      color = 'brand.textPrimary',
      children,
      iconProps,
      variant,
      ...props
    },
    ref
  ) => {
    const styles = useStyleConfig('ExternalLink', { variant });

    return (
      <A
        href={href}
        target={target}
        color={color}
        {...props}
        sx={{
          ...styles,
          ...props.sx,
        }}
        ref={ref}
      >
        <Asset
          mr={1}
          name="External"
          sx={{
            '*': {
              fill: color,
            },
          }}
          {...iconProps}
        />
        {children}
      </A>
    );
  }
);
