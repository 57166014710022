/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from '@time-neko/frontend/router';

export function useQueryParam<V extends string>(
  paramName: string,
  initialValue?: V
) {
  const navigate = useNavigate();
  const location = useLocation();

  const getValueFromSearch = useCallback(() => {
    const urlValue = new URLSearchParams(location.search).get(paramName);

    return (urlValue as V) || initialValue;
  }, [initialValue, location.search, paramName]);

  const [value, setValue] = useState<V | null | undefined>(
    getValueFromSearch()
  );

  const setValueInLocation = useCallback(
    (value: V | null | undefined) => {
      const search = new URLSearchParams(location.search);

      search.set(paramName, value as string);

      navigate({
        hash: location.hash,
        pathname: location.pathname,
        search: search.toString(),
      });
    },
    [location.hash, location.pathname, location.search, navigate, paramName]
  );

  useEffect(() => {
    setValue(getValueFromSearch());
  }, [getValueFromSearch]);

  return [value, setValueInLocation] as const;
}
