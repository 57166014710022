/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { frontendOnboardingSchema } from '@time-neko/frontend/domain/onboarding/schema';
import { useEffect, useState } from 'react';
import { useNavigate } from '@time-neko/frontend/router';
import { appRoutes } from '@time-neko/shared/routes';
import { logger } from '@time-neko/shared/logger';

const log = logger.getChild('useOnboardingRedirect');

export function useOnboardingRedirect() {
  const navigate = useNavigate();
  const [didRedirect, setDidRedirect] = useState(false);

  const onboardingState =
    frontendOnboardingSchema.getOnboardingState.useQuery();

  frontendOnboardingSchema.onboardingCompleted.useEvent(
    (event) => {
      onboardingState.setQueryData(event.payload);
    },
    [onboardingState.setQueryData]
  );

  useEffect(() => {
    if (
      !onboardingState.isLoading &&
      !onboardingState.data?.completedAt &&
      !didRedirect
    ) {
      setDidRedirect(true);

      log.debug('Redirecting to onboarding', onboardingState.data);
      navigate(appRoutes.onboarding());
    }
  }, [navigate, onboardingState, didRedirect]);

  return {
    isLoading: onboardingState.isLoading,
  };
}
