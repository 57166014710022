/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { theme as chakraTheme } from '@chakra-ui/theme';

export const sizes = {
  ...chakraTheme.space,
  '3xs': '0.25rem',
  '2xs': '0.5rem',
  xs: '0.75rem',
  sm: '1rem',
  '2sm': '1.5rem',
  md: '2rem',
  lg: '2.5rem',
  xl: '3rem',
  '2xl': '4rem',
  '3xl': '6rem',
  '4xl': '8rem',
  '5xl': '10rem',
  '6xl': '12rem',
  '7xl': '14rem',
  '8xl': '16rem',
};
