/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  Button,
  IconButton,
  List,
  ListItem,
  ListProps,
} from '@chakra-ui/react';
import { ReactNode, useCallback } from 'react';
import { Text } from '../../atoms/text/Text';
import { Asset } from '@time-neko/frontend/assets';
import { ButtonProps } from '../../atoms';

export interface RenderItemBag<T> {
  item: T;
  index: number;
  removeBtn: ReactNode;
}

export interface ManagableListProps<T> extends ListProps {
  items: T[];
  render: (bag: RenderItemBag<T>) => ReactNode;
  onAdd?: () => unknown;
  onRemove?: (index: number, item: T) => unknown;
  addItemText?: string;
  addItemProps?: Omit<ButtonProps, 'children' | 'onClick'>;
}

export function ManagableList<T>({
  items,
  render,
  onAdd,
  onRemove,
  addItemText = 'Add item',
  addItemProps,
  ...props
}: ManagableListProps<T>) {
  const handleRemove = useCallback(
    (index: number, item: T) => {
      if (onRemove) {
        onRemove(index, item);
      }
    },
    [onRemove]
  );

  return (
    <List {...props}>
      {items.map((item, index) =>
        render({
          item,
          index,
          removeBtn: (
            <IconButton
              onClick={() => handleRemove(index, item)}
              aria-label="Remove item"
            >
              <Text>-</Text>
            </IconButton>
          ),
        })
      )}
      <ListItem textAlign="center">
        <Button
          leftIcon={<Asset name="Plus" boxSize="sm" />}
          onClick={onAdd}
          {...addItemProps}
        >
          <Text>{addItemText}</Text>
        </Button>
      </ListItem>
    </List>
  );
}
