/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { SystemStyleObject } from '@chakra-ui/styled-system';

export enum FormControlVariant {
  inline = 'inline',
  default = 'default',
}

export interface FormControlStyles {
  control?: SystemStyleObject;
  labelStack?: SystemStyleObject;
  formLabel?: SystemStyleObject;
}
