/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren } from 'react';
import { chakra, ChakraProps } from '@chakra-ui/system';

export interface EmojiProps extends ChakraProps {
  label?: string;
}

const Span = chakra.span;

export const Emoji = ({
  children,
  label = 'Emoji',
  ...props
}: PropsWithChildren<EmojiProps>) => {
  return (
    <Span role="img" aria-label={label} {...props}>
      {children}
    </Span>
  );
};
