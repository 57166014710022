/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Box,
  Button,
  Checkbox,
  DropdownMenu,
  Editable,
  IconButton,
  ListItem,
  NumberInput,
  NumberInputField,
  Stack,
} from '@time-neko/frontend/ui';
import {
  isTaskActive,
  TaskSource,
  taskSourceIntegrationMap,
  TaskState,
} from '@time-neko/shared/domain/tasks';
import { useTaskListItem } from './useTaskListItem';
import { TaskTitle } from './TaskTitle';
import { Asset } from '@time-neko/frontend/assets';
import { TaskItemDragStrategy, TaskListItemProps } from './TaskListItem.types';
import { useTheme } from '@time-neko/frontend/providers/theme';
import { useDrag, useDrop } from 'react-dnd';
import { TaskDragPayload, taskItemName } from '../shared';
import {
  DragEventHandler,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntegrationDefinition } from '@time-neko/frontend/domain/integrations/providers';
import { CSSTransition } from 'react-transition-group';
import { useMergeRefs } from '@chakra-ui/hooks';
import { useUnmount } from 'react-use';

const handleDragOver: DragEventHandler = (event) => {
  event.preventDefault();
};
export function TaskListItem({
  task,
  onTaskChange,
  arrIndex,
  isDragDisabled,
  isDisabled,
  menu,
  dragStrategy,
  onTaskDrag,
  onTaskDrop,
  onTransitionEnd,
  children,
  in: inProp,
  onExiting,
  onExited,
  exit,
  containerRef: containerRefProp,
  onHeightChange,
  highlighIfActive,
  ...props
}: PropsWithChildren<TaskListItemProps>) {
  const isActive = highlighIfActive && isTaskActive(task);

  const integrationType = task.source
    ? taskSourceIntegrationMap[task.source as TaskSource]
    : undefined;
  const integrationDefinition = useIntegrationDefinition(integrationType);

  const containerRef = useRef<HTMLElement>();
  const resizeObserverRef = useRef(
    new ResizeObserver(() => {
      const height = containerRef.current?.clientHeight ?? 0;

      setHeight(height);
    })
  );
  const mergedContainerRef = useMergeRefs(containerRef, containerRefProp);

  const [height, setHeight] = useState(0);

  const [{ isDragging }, dragRef, preview] = useDrag<
    TaskDragPayload,
    unknown,
    { isDragging: boolean }
  >({
    type: taskItemName,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      if (isDragDisabled) {
        return false;
      }

      navigator.vibrate?.([100]);

      return true;
    },
    end: (item) => {
      onTaskDrop?.(item.task);
    },
    item: () => ({
      task,
      element: containerRef.current,
    }),
  });

  const [, dropRef] = useDrop<TaskDragPayload>({
    accept: taskItemName,
    hover: (item, monitor) => {
      const dragIndex = item.task.index ?? 0;
      const hoverIndex = task.index ?? 0;

      if (
        !item.element ||
        dragIndex === hoverIndex ||
        !task ||
        !containerRef.current
      ) {
        return;
      }

      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const rect = containerRef.current.getBoundingClientRect();
      const hoverMiddleY = (rect.bottom - rect.top) / 2;
      const hoverClientY = clientOffset.y - rect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onTaskDrag?.(dragIndex, hoverIndex);

      item.task.index = hoverIndex;
    },
  });

  const {
    title,
    duration,
    handleTaskChange,
    handleTitleChange,
    handleDurationChange,
    isDone,
  } = useTaskListItem({
    task,
    onTaskChange,
  });

  const taskMenu = menu?.(task, integrationDefinition);

  const theme = useTheme();

  useUnmount(() => {
    resizeObserverRef.current.disconnect();
  });

  useEffect(() => {
    onHeightChange?.(height);
  }, [height, onHeightChange]);

  return (
    <CSSTransition
      key={task.id}
      className="task-list-item"
      nodeRef={containerRef}
      in={inProp}
      timeout={510}
      onExiting={onExiting}
      onExited={onExited}
      exit={exit}
    >
      <ListItem
        overflow="hidden"
        id={`task-${task.index}`}
        {...props}
        style={
          {
            '--height': `${height}px`,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any
        }
        display="flex"
        alignItems="center"
        background={
          isDragging
            ? 'brand.primary'
            : isActive
            ? 'brand.bgSecondary'
            : undefined
        }
        onDragOver={handleDragOver}
        px={2}
        py={2}
        ref={(element) => {
          if (element) {
            mergedContainerRef?.(element);
            setHeight(element.clientHeight);
            dropRef(element);
            resizeObserverRef.current.observe(element);

            if (dragStrategy === TaskItemDragStrategy.ListItem) {
              dragRef(element);
            } else {
              preview(element);
            }
          }
        }}
        onTransitionEnd={(event) => onTransitionEnd?.(task, event)}
        sx={{
          ...props.sx,

          outline: 'none',

          '&: focus': {
            boxShadow: theme.shadows.focusVisible,
          },
        }}
      >
        {children}
        <Checkbox
          isDisabled={isDisabled}
          className="task-state-checkbox"
          onChange={handleTaskChange(
            'state',
            isDone ? TaskState.Todo : TaskState.Completed
          )}
          defaultChecked={isDone}
          boxSize="md"
          mr={2}
        />
        <Editable
          display="flex"
          isDisabled={isDisabled}
          maxWidth={{
            base: '50%',
            sm: integrationDefinition ? '60%' : '70%',
          }}
          className="task-title-editable task-title"
          onSubmit={handleTitleChange}
          color="brand.textPrimary"
          value={title}
          displayValue={title}
          ml={2}
          inputProps={{
            height: 'md',
            width: '100%',
          }}
          ContentComponent={TaskTitle}
          contentComponentProps={{
            taskTitle: title,
            isDone,
          }}
          hideControls
        />
        <Stack
          direction="row"
          spacing={{
            xs: 2,
            sm: 4,
          }}
          flex={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          {integrationDefinition && (
            <Box
              ml={2}
              sx={{
                '& svg': {
                  boxSize: '2sm',
                  mt: 1,
                },
              }}
            >
              {integrationDefinition.icon}
            </Box>
          )}
          <NumberInput
            onChange={handleDurationChange}
            value={Number.isNaN(duration) ? 0 : duration}
          >
            <NumberInputField
              boxSize="md"
              className="task-estimation"
              padding="0"
              textAlign="center"
            />
          </NumberInput>
          {taskMenu && (
            <DropdownMenu
              menuButtonProps={{
                as: Button,
                boxSize: 'md',
                variant: 'nes-ghost',
                className: 'task-menu-button',
                right: 1,
              }}
              menuProps={{
                closeOnSelect: false,
              }}
              menuListProps={{
                minWidth: '250px',
              }}
              items={taskMenu}
            >
              <Asset
                position="relative"
                top="2px"
                height="2sm"
                width="sm"
                name="Ellipsis"
              />
            </DropdownMenu>
          )}
          {dragStrategy === TaskItemDragStrategy.Handle && (
            <IconButton
              onDragOver={handleDragOver}
              aria-label="Drag task"
              variant="nes-ghost"
              ref={dragRef}
            >
              <Asset name="Squares" boxSize="sm" />
            </IconButton>
          )}
        </Stack>
      </ListItem>
    </CSSTransition>
  );
}
