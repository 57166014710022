/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */
import { createReactMusubi } from '@musubi/react';
import { updatesSchema } from '@time-neko/shared/domain/updates';

export const frontendUpdatesSchema = createReactMusubi(updatesSchema);
