/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren } from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';

export interface CenterContainerProps extends ContainerProps {
  className?: string;
}

export const CenterContainer = ({
  children,
  ...props
}: PropsWithChildren<CenterContainerProps>) => {
  return (
    <Container
      pl={0}
      pr={0}
      height="var(--chakra-vh)"
      centerContent
      width="100%"
      maxW="100%"
      display="flex"
      justifyContent="center"
      {...props}
    >
      {children}
    </Container>
  );
};
