/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { useInterval } from 'react-use';

export function useBackgroundIntervalEffect(effect: () => void, delay: number) {
  useInterval(() => {
    if (document.visibilityState === 'hidden') {
      effect();
    }
  }, delay);
}
