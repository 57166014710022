/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { UseSettingsFormProps } from './SettingsForm.types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from '@time-neko/frontend/router';
import { useDialog } from '@time-neko/frontend/providers/dialog';
import { useForm } from 'react-hook-form';
import {
  AppSettings,
  AppSettingsSchema,
  AsyncSettingError,
  settingsSchema,
} from '@time-neko/shared/domain/settings';
import { useAsyncSettingErrors } from '@time-neko/frontend/domain/settings/hooks';
import { useErrorsStorage } from '@time-neko/frontend/errors/hooks';
import {
  asyncErrorsResolver,
  AsyncErrorsState,
} from './errorsResolvers/asyncErrors.resolver';
import { appRoutes } from '@time-neko/shared/routes';
import { unsavedChangesDialog } from '@time-neko/frontend/providers/dialog/presets';
import { OperationSchemaOperations } from '@musubi/core';
import { zodResolver } from '@hookform/resolvers/zod';
import { frontendSettingsSchema } from '@time-neko/frontend/domain/settings/schema';
import { useEvent } from 'react-use';
import { logger } from '@time-neko/shared/logger';
import { useAutoSwitch } from '@time-neko/frontend/common';
import { useToast } from '@time-neko/frontend/toast';
import { getUserFriendlyError } from '@time-neko/shared/errors';

const log = logger.getChild('useSettingsForm');

export function useSettingsForm({
  schema = AppSettingsSchema,
  onClose,
}: UseSettingsFormProps) {
  const toast = useToast();

  const [didSubmit, setDidSubmit] = useAutoSwitch(false, 2000);

  const forceCloseRef = useRef(false);

  const { isLoading: queryLoading, data: settings } =
    frontendSettingsSchema.getSettings.useQuery();

  const resolver = useMemo(() => zodResolver(schema), [schema]);

  const scrollContainerRef = useRef<HTMLDivElement>();

  const navigate = useNavigate();

  const { showDialog } = useDialog();

  const [didFill, setDidFill] = useState(false);

  const form = useForm<AppSettings>({
    resolver: didFill ? resolver : undefined,
    shouldUnregister: false,
    criteriaMode: 'all',
    defaultValues: settings,
    shouldFocusError: true,
  });

  const { errorsQuery, removeErrorMutation } = useAsyncSettingErrors();

  const [{ errors }] = useErrorsStorage<
    Error | AsyncSettingError,
    AsyncErrorsState
  >(
    useMemo(
      () => ({
        state: {
          asyncErrors: errorsQuery.data ?? [],
        },
        resolvers: [asyncErrorsResolver],
      }),
      [errorsQuery.data]
    )
  );

  const fillForm = useCallback(
    (values?: AppSettings) => {
      // Prevents unsaved changes' dialog from showing after user submits form by pressing "Enter"
      if (didFill) {
        return;
      }

      setDidFill(true);

      form.reset(values);
    },
    [didFill, form]
  );

  const setSettingsMutation = frontendSettingsSchema.setSettings.useCommand({
    invalidateQueries: [
      ['getSettings' as OperationSchemaOperations<typeof settingsSchema>],
      ['getSetting' as OperationSchemaOperations<typeof settingsSchema>],
      [
        'getAsyncSettingErrors' as OperationSchemaOperations<
          typeof settingsSchema
        >,
      ],
    ],
    onError: (error) => {
      toast.show({
        status: 'error',
        description: getUserFriendlyError(error),
      });
    },
  });

  const handleSubmit = useCallback(
    async (values: AppSettings) => {
      log.debug('handleSubmit', values);

      form.reset(values);

      await setSettingsMutation.mutateAsync(values);

      setDidSubmit(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form.reset, setSettingsMutation]
  );

  const handleClose = useCallback(() => {
    forceCloseRef.current = true;

    const handleClose = () => {
      if (onClose) {
        onClose();
      } else {
        navigate(appRoutes.timer());
      }

      setTimeout(() => {
        forceCloseRef.current = false;
      }, 250);
    };

    if (form.formState.isDirty) {
      showDialog(
        unsavedChangesDialog({
          onClose: handleClose,
          onCancel: () => {
            forceCloseRef.current = false;
          },
        })
      );
    } else {
      handleClose();
    }
  }, [form.formState.isDirty, navigate, onClose, showDialog]);

  useEvent('beforeunload', (e) => {
    if (form.formState.isDirty && !forceCloseRef.current) {
      e.preventDefault();
      e.returnValue = '';

      handleClose();
    }
  });

  useEffect(() => {
    if (settings) {
      fillForm(settings as AppSettings);
    }
  }, [fillForm, settings]);

  return {
    scrollContainerRef,
    form,
    removeErrorMutation,
    errors,
    queryLoading,
    settings,
    setSettingsMutation,
    handleSubmit,
    handleClose,
    didSubmit,
  };
}
