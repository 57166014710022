/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { z } from 'zod';

export const ListReleasesQuerySchema = z.object({
  perPage: z.number().int().positive().default(10),
  page: z.number().int().positive().default(1),
});

export type ListReleasesQuery = z.infer<typeof ListReleasesQuerySchema>;

export const GetReleaseParamsSchema = z.object({
  version: z.string().nonempty(),
});

export type GetReleaseParams = z.infer<typeof GetReleaseParamsSchema>;
