/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Time } from '@time-neko/shared/time';
import { Duration } from '../types';

/**
 * Possible pomodoro durations
 * */
export const durations: Duration[] = [
  180, 300, 600, 900, 1200, 1500, 1800, 2100, 2400, 2700, 3000, 3300, 3600,
].map((duration) => ({
  time: Time.fromSeconds(duration),
  seconds: duration,
}));
