/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useRouterContext } from '../Router.provider';
import { RouteParams } from '@time-neko/shared/routes';

export const useParams = <TParams extends RouteParams>() => {
  const useParams = useRouterContext((store) => store.useParams);

  return useParams() as TParams;
};
