/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useBreakpointValue, useMediaQuery } from '@chakra-ui/media-query';
import { useTheme } from '@time-neko/frontend/providers/theme';

export function useIsSmallMobile() {
  const theme = useTheme();

  const query = `(max-width: ${theme.breakpoints.sm})`;

  const res = useMediaQuery(query);

  return res[0];
}

export function useIsLargerMobile() {
  return useBreakpointValue({
    xs: true,
    md: false,
  });
}
