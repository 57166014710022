/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { useCallback } from 'react';
import { Task, tasksSchema } from '@time-neko/shared/domain/tasks';
import { OperationSchemaOperations } from '@musubi/core';
import { frontendTasksSchema } from '@time-neko/frontend/domain/tasks/schema';

export const useTasksRemoval = () => {
  const removeTasksMutation = frontendTasksSchema.deleteTasks.useCommand({
    invalidateQueries: [
      ['countTasksByState' as OperationSchemaOperations<typeof tasksSchema>],
      ['getTasks' as OperationSchemaOperations<typeof tasksSchema>],
    ],
  });

  const removeTasks = useCallback(
    async (tasks: Array<Task | string>) => {
      const ids = tasks.map((task) =>
        typeof task === 'string' ? task : task.id
      );

      await removeTasksMutation.mutateAsync({
        ids,
      });
    },
    [removeTasksMutation]
  );

  return {
    removeTasks,
    loading: removeTasksMutation.isLoading,
    error: removeTasksMutation.error,
  };
};
