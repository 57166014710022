/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  Alert,
  Box,
  ButtonGroup,
  Flex,
  HStack,
  VStack,
} from '@time-neko/frontend/ui';
import {
  PomodoroTimer,
  PomodoroTimerProps,
} from '../../pomodoroTimer/PomodoroTimer';
import {
  TimerEditButtons,
  TimerEditProps,
} from '../../buttons/timerEditButtons/TimerEditButtons';
import {
  PomodoroControl,
  PomodoroControlProps,
} from '../../buttons/pomodoroControl/PomodoroControl';
import { ReactNode } from 'react';
import {
  PomodoroStateText,
  PomodoroStateTextProps,
} from '../../pomodoroStateText/PomodoroStateText';
import { ChakraProps } from '@chakra-ui/system';
import { useIsSmallMobile } from '@time-neko/frontend/responsive';

export interface PomodoroTimerSectionProps
  extends Omit<PomodoroTimerProps, 'innerStackProps'>,
    Pick<TimerEditProps, 'onChange' | 'remainingSeconds'>,
    ChakraProps {
  controlProps: Omit<PomodoroControlProps, 'pomodoro'>;
  additionalControls?: ReactNode;
  stateTextProps?: Pick<PomodoroStateTextProps, 'rightAddon'>;
}

export function PomodoroTimerSection({
  editableProps,
  hidePomodoroStateOnEdit,
  hideStateText,
  onChange,
  pomodoro,
  pomodoroSettings,
  isEdit,
  remainingSeconds,
  controlProps,
  additionalControls,
  stateTextProps,
  ...props
}: PomodoroTimerSectionProps) {
  const isMobile = useIsSmallMobile();

  if (isMobile) {
    return (
      <VStack width="90%">
        <Alert
          title={
            !PomodoroStateText.shouldHide({
              hideStateText,
              hidePomodoroStateOnEdit,
              isEdit,
            }) ? (
              <PomodoroStateText
                pomodoro={pomodoro}
                isEdit={isEdit}
                hideStateText={hideStateText}
                textProps={{
                  fontSize: 'lg',
                  color: 'brand.textPrimary',
                }}
                hidePomodoroStateOnEdit={hidePomodoroStateOnEdit}
                {...stateTextProps}
              />
            ) : null
          }
          sx={{
            '&.alert.nes-container': {
              bg: 'brand.bg',
              mb: 6,
              pb: 0,
              pt: isEdit ? 6 : 3,
              display: 'flex',
            },

            '& .title': {
              mb: '6 !important',
            },

            '& .text, & .heading': {
              color: 'brand.textPrimary',
            },

            '& .move-to-next-state *, & .editable-controls *': {
              fill: 'brand.iconPrimary',
            },
          }}
          width="100%"
        >
          <VStack
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <PomodoroTimer
              pomodoroSettings={pomodoroSettings}
              pomodoro={pomodoro}
              isEdit={isEdit}
              hideStateText
              hidePomodoroStateOnEdit={hidePomodoroStateOnEdit}
              editableProps={editableProps}
              innerStackProps={{
                justifyContent: 'center',
              }}
              stateTextProps={stateTextProps}
            />
            {isEdit && (
              <Flex
                direction={{
                  base: 'column',
                  md: 'row',
                }}
                width="100%"
                pb={4}
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                sx={{
                  '& button': {
                    width: {
                      base: '100%',
                      md: '45%',
                    },
                    mt: '4 !important',
                    mx: 2,
                  },

                  '& .add': {
                    order: -1,
                  },
                }}
              >
                <TimerEditButtons
                  remainingSeconds={remainingSeconds}
                  onChange={onChange}
                />
              </Flex>
            )}
          </VStack>
        </Alert>
        {!isEdit && (
          <HStack alignItems="center" spacing={6}>
            <PomodoroControl
              {...controlProps}
              isRunning={pomodoro?.isRunning}
            />
            {additionalControls}
          </HStack>
        )}
      </VStack>
    );
  }

  return (
    <Box width={isEdit ? '100%' : '50%'} mt="0 !important">
      <VStack
        margin="0 auto"
        justifyContent="center"
        alignItems="center"
        width={
          isEdit
            ? '100%'
            : {
                base: '200px',
                sm: '180px',
              }
        }
        height={{
          base: 'auto',
          sm: '100%',
        }}
      >
        <PomodoroTimer
          pomodoroSettings={pomodoroSettings}
          pomodoro={pomodoro}
          isEdit={isEdit}
          hideStateText={hideStateText}
          hidePomodoroStateOnEdit={hidePomodoroStateOnEdit}
          editableProps={editableProps}
          stateTextProps={stateTextProps}
        />

        <ButtonGroup
          width={{
            base: '100%',
            sm: '100%',
          }}
          className="timer-box-button-group"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          size="md"
          sx={
            !isEdit
              ? props.sx
              : {
                  '& button': {
                    maxW: '80px',
                  },
                  ...props.sx,
                }
          }
        >
          {isEdit ? (
            <TimerEditButtons
              remainingSeconds={remainingSeconds}
              onChange={onChange}
            />
          ) : (
            <>
              <PomodoroControl
                {...controlProps}
                isRunning={pomodoro?.isRunning}
              />
              {additionalControls}
            </>
          )}
        </ButtonGroup>
      </VStack>
    </Box>
  );
}
