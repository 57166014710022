/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { pipe } from '@fxts/core';
import { removeWhitespace } from '@time-neko/shared/utils';

export function removeProtocol(url: string) {
  const protocol = url.split('://')[0];

  return url.replace(`${protocol}://`, '');
}

export const hasProtocol = (url: string) =>
  url.startsWith('http://') || url.startsWith('https://');

export const trimUrl = (url: string) =>
  url.replace('www.', '').replace(/\/$/, '');

export const normalizeUrl = (url: string) =>
  pipe(url, removeWhitespace, trimUrl);

export const maybeAddProtocol = (url: string) =>
  hasProtocol(url) ? url : `http://${url}`;
