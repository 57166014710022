/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Nullable } from '@time-neko/shared/common-types';
import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';
import { AppPlatform, ErrorSchema } from '@time-neko/shared/domain/types';

export enum IntegrationType {
  Trello = 'Trello',
  Notion = 'Notion',
}

export const ProviderInfoSchema = z.object({
  type: z.nativeEnum(IntegrationType),
});

export const ApiAuthorizedResultSchema = ProviderInfoSchema.extend({
  token: z.string(),
});

export type ProviderInfo = z.infer<typeof ProviderInfoSchema>;

export enum IntegrationSubscriptionTopics {
  ApiUnauthorized = 'ApiUnauthorized',
}

export const ApiAuthorizationErrorSchema = z.object({
  error: ErrorSchema,
  type: z.nativeEnum(IntegrationType),
});

export const integrationsSchema = defineSchema({
  queries: {
    getApiToken: operation.query
      .withPayload(ProviderInfoSchema)
      .withResult(z.string().nullable()),
  },
  events: {
    apiAuthorized: operation.event.withPayload(ApiAuthorizedResultSchema),
    apiUnauthorized: operation.event.withPayload(ProviderInfoSchema),
    apiAuthError: operation.event.withPayload(ApiAuthorizationErrorSchema),
  },
  commands: {
    authorizeApi: operation.command.withPayload(ProviderInfoSchema),
    unauthorizeApi: operation.command.withPayload(ProviderInfoSchema),
  },
});

export type ApiAuthorizedResult = z.infer<typeof ApiAuthorizedResultSchema>;

export type ApiAuthorizationError = z.infer<typeof ApiAuthorizationErrorSchema>;

export interface AuthorizeIntegrationResult {
  token: string;
}

export interface IntegrationAuth {
  getUserToken(): Promise<Nullable<string>>;

  isAuthorized(): Promise<boolean>;

  unAuthorize(): Promise<void>;

  getAuthorizationUrl(): Promise<string>;

  readonly type: IntegrationType;

  authorize(result: string): Promise<AuthorizeIntegrationResult | undefined>;
}

export const IntegrationAuthStateSchema = z.object({
  platform: z.nativeEnum(AppPlatform),
  appId: z.string().optional(),
});

export type IntegrationAuthState = z.infer<typeof IntegrationAuthStateSchema>;
