/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

// eslint-disable-next-line no-restricted-imports
// eslint-disable-next-line no-restricted-imports
import {
  ChakraProps,
  useToast as BaseUseToast,
  UseToastOptions,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { Toast } from '@time-neko/frontend/ui';

export interface ShowToastOptions extends Omit<UseToastOptions, 'variant'> {
  sx?: ChakraProps['sx'];
}

export function useToast() {
  const baseToast = BaseUseToast();

  const show = useCallback(
    (options: ShowToastOptions) => {
      const mergedOptions: UseToastOptions = {
        ...options,
        title: options.title ?? options.status?.toUpperCase() ?? '',
        position: options.position ?? 'top-left',
      };

      return baseToast({
        ...mergedOptions,
        render: (props) => <Toast {...props} {...mergedOptions} />,
      });
    },
    [baseToast]
  );

  return {
    show,
    ...baseToast,
  };
}
