/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentType,
  KeyboardEventHandler,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ChakraProps } from '@chakra-ui/system';
import {
  HStack,
  useControllableState,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react';
import { Text, TextProps } from '../../atoms/text/Text';
import { useKey } from 'react-use';
import { Key } from 'ts-key-enum';
import { EditableControls } from './EditableControls';
import { useTheme } from '@time-neko/frontend/providers/theme';
import classNames from 'classnames';
import { Textarea, TextareaProps } from '../../atoms';

export type EditableContentComponentProps = TextProps;

export interface EditableProps<
  CustomComponentProps extends EditableContentComponentProps = EditableContentComponentProps
> extends ChakraProps {
  onEditChange?: (isEdit: boolean) => void;
  isEdit?: boolean;
  onCancel?: () => void;
  onSubmit?: (value: string) => void;
  formatValueOnChange?: (value: string) => string;
  onChange?: (value: string) => void;
  ContentComponent?: ComponentType<PropsWithChildren<CustomComponentProps>>;
  contentComponentProps?: Omit<
    CustomComponentProps,
    keyof EditableContentComponentProps
  >;
  inputProps?: Omit<TextareaProps, 'value' | 'defaultValue' | 'onChange'>;
  displayValue?: string;
  value?: string;
  isDisabled?: boolean;
  className?: string;
  hideControls?: boolean;
  shouldHandleOutsideClick?: (event: Event) => boolean;
}

const DefaultContentComponent = ({
  children,
  ...props
}: EditableContentComponentProps) => {
  return <Text {...props}>{children}</Text>;
};

export function Editable<
  CustomComponentProps extends EditableContentComponentProps = EditableContentComponentProps
>({
  onEditChange,
  onCancel,
  onSubmit,
  ContentComponent = DefaultContentComponent,
  isEdit: isEditProp,
  displayValue,
  value,
  inputProps,
  formatValueOnChange,
  onChange,
  isDisabled,
  hideControls = false,
  shouldHandleOutsideClick,
  contentComponentProps,
  ...props
}: EditableProps<CustomComponentProps>) {
  const theme = useTheme();

  const containerRef = useRef<HTMLDivElement>();
  const [isEdit, setIsEdit] = useControllableState({
    onChange: onEditChange,
    value: isEditProp,
    defaultValue: false,
  });

  const [internalValue, setInternalValue] = useState(value);

  const handleSubmit = useCallback(() => {
    onSubmit?.(internalValue ?? '');

    setIsEdit(false);
  }, [internalValue, onSubmit, setIsEdit]);

  const handleCancel = useCallback(() => {
    onCancel?.();

    setIsEdit(false);
    setInternalValue(value);
  }, [onCancel, setIsEdit, value]);

  const handleKeyDown = useCallback<KeyboardEventHandler>(
    (event) => {
      if ([Key.Enter, ' '].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();

        setIsEdit(true);
      }
    },
    [setIsEdit]
  );

  const handleContentClick = useCallback(() => {
    if (isDisabled) {
      return;
    }

    setIsEdit(true);
  }, [isDisabled, setIsEdit]);

  useKey(
    Key.Escape,
    (event) => {
      if (isEdit) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();

        handleCancel();
      }
    },
    undefined,
    [isEdit, handleCancel]
  );

  useKey(
    Key.Enter,
    () => {
      if (isEdit) {
        handleSubmit();
      }
    },
    undefined,
    [isEdit, handleSubmit]
  );

  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  useOutsideClick({
    ref: containerRef as MutableRefObject<HTMLDivElement>,
    enabled: !isDisabled,
    handler: (event) => {
      if (shouldHandleOutsideClick && !shouldHandleOutsideClick(event)) {
        return;
      }

      handleCancel();
    },
  });

  return (
    <VStack
      {...props}
      className={classNames(props.className, 'editable')}
      alignItems="center"
      justifyContent="center"
      ref={containerRef as MutableRefObject<HTMLDivElement>}
      sx={{
        ...props.sx,
        '& .editable-text': {
          outline: 'none',
          '&:focus': {
            boxShadow: theme.shadows.focusVisible,
          },
        },
      }}
    >
      {!isEdit && (
        <ContentComponent
          onKeyDown={handleKeyDown}
          tabIndex={0}
          className="editable-text"
          onClick={handleContentClick}
          {...(contentComponentProps as any)}
        >
          {displayValue}
        </ContentComponent>
      )}
      {isEdit && (
        <HStack width="100%" alignItems="center">
          <Textarea
            ref={(input) => {
              input?.focus();
            }}
            resize="none"
            height="auto"
            value={internalValue}
            onChange={(event) => {
              const newInternalValue = formatValueOnChange
                ? formatValueOnChange(event.target.value)
                : event.target.value;

              onChange?.(newInternalValue);

              setInternalValue(newInternalValue);
            }}
            {...inputProps}
          />
          {!hideControls && (
            <EditableControls
              onCancel={handleCancel}
              onConfirm={handleSubmit}
            />
          )}
        </HStack>
      )}
    </VStack>
  );
}
