/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ListItemProps, MenuItemProperties } from '@time-neko/frontend/ui';
import { UseTaskListItemProps } from './useTaskListItem';
import { Task } from '@time-neko/shared/domain/tasks';
import { Ref, TransitionEvent } from 'react';
import { FrontendIntegrationSectionDefinition } from '@time-neko/frontend/domain/integrations/web';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

export enum TaskItemDragStrategy {
  ListItem = 'ListItem',
  Handle = 'Handle',
}

export interface TaskListItemProps
  extends Omit<ListItemProps, 'onTransitionEnd'>,
    UseTaskListItemProps,
    Pick<CSSTransitionProps, 'in' | 'onExiting' | 'onExited'> {
  arrIndex: number;
  isDragDisabled?: boolean;
  isDisabled?: boolean;
  menu?: (
    task: Task,
    integrationDefinition?: FrontendIntegrationSectionDefinition
  ) => MenuItemProperties[];
  dragStrategy?: TaskItemDragStrategy;
  isDragMode?: boolean;
  onTaskDrag?: (oldIndex: number, newIndex: number) => unknown;
  onTaskDrop?: (task: Task) => unknown;
  exit?: boolean;
  onTransitionEnd?: (
    task: Task,
    event: TransitionEvent<HTMLLIElement>
  ) => unknown;
  containerRef?: Ref<HTMLElement>;
  onHeightChange?: (height: number) => unknown;
  highlighIfActive?: boolean;
}
