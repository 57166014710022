/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { IconButton, IconButtonProps } from '../../iconButton/IconButton';
import { Text } from '../../../atoms';
import { HStack } from '@chakra-ui/react';
import { ChakraProps } from '@chakra-ui/system';
import { ReactNode } from 'react';

export interface TitleBarControlsProps {
  minimizeButtonProps?: Partial<IconButtonProps>;
  closeButtonProps?: Partial<IconButtonProps>;
  isMinimizable?: boolean;
  isClosable?: boolean;
  variant?: 'standard' | 'mac';
  additionalEndContent?: ReactNode;
  additionalStartContent?: ReactNode;
}

export function TitleBarControls({
  minimizeButtonProps,
  closeButtonProps,
  isMinimizable,
  isClosable,
  variant,
  additionalEndContent,
  additionalStartContent,
}: TitleBarControlsProps) {
  const isMacVariant = variant === 'mac';

  const containerProps: ChakraProps | undefined = isMacVariant
    ? {
        sx: {
          '&:not(:hover)': {
            '.text': {
              display: 'none',
            },
          },
        },
      }
    : undefined;

  const sharedButtonProps: Partial<IconButtonProps> | undefined = isMacVariant
    ? {
        variant: 'nes',
        boxSize: 'sm',

        sx: {
          '.text': {
            fontSize: 'xs',
          },

          '.border': {
            fill: 'brand.bgReverse',
          },
        },
      }
    : undefined;

  const localCloseButtonProps: Partial<IconButtonProps> | undefined =
    isMacVariant
      ? {
          ...sharedButtonProps,
          colorScheme: 'danger',
        }
      : {
          padding: 0,
        };

  const localMinimizeButtonProps: Partial<IconButtonProps> | undefined =
    isMacVariant
      ? {
          ...sharedButtonProps,
          variant: 'nes',
          colorScheme: 'secondary',
          sx: {
            ...localCloseButtonProps?.sx,

            '.text': {
              position: 'relative',
              bottom: '4.5px',
              left: '1.5px',
            },
          },
        }
      : {
          padding: 0,
        };

  return (
    <HStack
      key="window-controls"
      width="20%"
      order={isMacVariant ? 0 : 2}
      spacing={isMacVariant ? 0 : 2}
      alignItems="center"
      justifyContent={isMacVariant ? 'flex-start' : 'flex-end'}
      {...containerProps}
    >
      {additionalStartContent}
      {isMinimizable && (
        <IconButton
          className="minimize"
          aria-label="Minimize window"
          variant="nes-ghost"
          {...localMinimizeButtonProps}
          {...minimizeButtonProps}
        >
          <Text>_</Text>
        </IconButton>
      )}
      {isClosable && (
        <IconButton
          className="close"
          aria-label="Close window"
          variant="nes-ghost"
          position="relative"
          {...localCloseButtonProps}
          {...closeButtonProps}
        >
          <Text
            position="relative"
            left={0.5}
            color={isMacVariant ? 'black !important' : undefined}
          >
            X
          </Text>
        </IconButton>
      )}
      {additionalEndContent}
    </HStack>
  );
}
