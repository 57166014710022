/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import createContext from 'zustand/context';
import type { StoreApi } from 'zustand';
import create from 'zustand';
import { PropsWithChildren } from 'react';
import { MenuItemsState } from '../../shared/menu.types';

const { Provider, useStore } = createContext<StoreApi<MenuItemsState>>();

export const useMenuItemsState = useStore;

const createStore = () =>
  create<MenuItemsState>((set) => ({
    highlightedItemId: undefined,
    highlightItem: (id) => set({ highlightedItemId: id }),
  }));

export function MenuItemsStateProvider({
  children,
}: PropsWithChildren<unknown>) {
  return <Provider createStore={createStore}>{children}</Provider>;
}
