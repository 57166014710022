/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ItemMenuItemProps } from '../../../shared/menu.types';
import { MenuItem, MenuItemProps } from '@chakra-ui/react';
import { Text } from '../../../atoms/text/Text';
import { ChakraProps } from '@chakra-ui/system';
import { useShouldShowKeyboardShortcut } from '@time-neko/frontend/keyboard-shortcuts';

export interface PlainMenuItemProps extends ChakraProps {
  item: Omit<ItemMenuItemProps, 'renderAs'>;
}

export function PlainMenuItem({
  item: { keyboardShortcut, rightAddon, id, label, ...rest },
  ...props
}: PlainMenuItemProps) {
  const showShortcut = useShouldShowKeyboardShortcut();

  return (
    <MenuItem
      command={showShortcut ? keyboardShortcut : undefined}
      id={id}
      bgColor="inherit"
      {...(rest as MenuItemProps)}
      {...props}
    >
      <Text color={rest.color}>{label}</Text>
      {rightAddon}
    </MenuItem>
  );
}
