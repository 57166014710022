/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import type { Options } from '@popperjs/core';
import { Menu, Portal } from '@chakra-ui/react';
import {
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useMount } from 'react-use';
import { usePopper } from 'react-popper';
import { findScrollContainer, useScrollLock } from '@time-neko/frontend/common';
import create from 'zustand';

export interface ContextMenuBag {
  onContextMenu: (event: MouseEvent<HTMLElement>) => unknown;
  open: boolean;
}

export interface ContextMenuProps
  extends Pick<Partial<Options>, 'placement' | 'strategy'> {
  menu: ReactNode;
  children: (bag: ContextMenuBag) => ReactNode;
  id: string;
}

interface ContextMenuState {
  menus: Record<string, boolean>;
  toggleMenu: (id: string) => void;
  toggleOff: (id: string) => void;
}

const useContextMenuState = create<ContextMenuState>((set) => ({
  menus: {},
  toggleMenu: (id) => {
    set((state) => {
      state.menus[id] = !state.menus[id];

      return state;
    });
  },
  toggleOff: (id) => {
    set((state) => {
      state.menus[id] = false;

      return state;
    });
  },
}));

export const ContextMenu = ({
  menu,
  children,
  id,
  placement = 'auto',
  strategy = 'absolute',
}: ContextMenuProps) => {
  const { menus, toggleMenu, toggleOff } = useContextMenuState();

  const [container, setContainer] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);

  const open = useMemo(() => {
    return Boolean(menus[id]);
  }, [id, menus]);

  const toggleOpen = useCallback(() => {
    toggleMenu(id);
  }, [id, toggleMenu]);

  const { styles, attributes } = usePopper(container, popperElement, {
    placement,
    strategy,
  });

  const toggleContextMenu: MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      setContainer(event.target as HTMLElement);
      setScrollElement(findScrollContainer(event.target as HTMLElement));

      toggleMenu(id);
    },
    [toggleMenu, id]
  );

  useScrollLock(open, scrollElement);

  useMount(() => {
    toggleOff(id);
  });

  return (
    <>
      {open && (
        <Portal>
          <div
            ref={setPopperElement}
            style={styles.popper}
            className="context-menu"
            {...attributes.popper}
          >
            <Menu
              closeOnSelect={false}
              placement="auto"
              strategy="absolute"
              preventOverflow
              isLazy
              isOpen={open}
              onClose={() => toggleOpen()}
            >
              {menu}
            </Menu>
          </div>
        </Portal>
      )}
      {children({ onContextMenu: toggleContextMenu, open })}
    </>
  );
};
