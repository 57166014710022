/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Constructor } from '@time-neko/shared/common-types';
import { isObject } from '@fxts/core';

export function isGenericError(e: unknown): e is Error {
  return e instanceof Error;
}

export function isError<E>(value: unknown, e: Constructor<E>): value is E {
  if (!value || !isObject(value)) {
    return false;
  }

  return (
    value instanceof e ||
    ('name' in (value as object) && (value as { name: string }).name === e.name)
  );
}
