/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import './audio.d';
import audioData from './audio/audios.json';

export { audioData };

export const audios = {
  'Achievment 1': () => import('./audio/achievement-01.wav'),
  'Achievment 2': () => import('./audio/achievement-00.wav'),
  Lose: () => import('./audio/lose-01.wav'),
  Victory: () => import('./audio/win-00.wav'),
} as const;

export type AudioName = keyof typeof audios;

export function resolveAudioPath(name: AudioName) {
  return audios[name]().then((mod) => mod.default);
}

export const audioNames = Object.keys(audios) as unknown as keyof typeof audios;
