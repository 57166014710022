/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';

export type TrelloId = z.infer<typeof TrelloIdSchema>;

const TrelloIdSchema = z.string();

export enum TrelloEntity {
  Member = 'Member',
  Card = 'Card',
  Board = 'Board',
  Organization = 'Organization',
}

const TrelloBoardSchema = z.object({
  id: TrelloIdSchema,
  closed: z.boolean(),
  url: z.string().url(),
  name: z.string(),
});

export type TrelloBoard = z.infer<typeof TrelloBoardSchema>;

export const TrelloMemberSchema = z.object({
  id: TrelloIdSchema,
  avatarUrl: z.string().url(),
  fullName: z.string(),
  idMemberReferrer: z.string(),
  url: z.string().url(),
  email: z.string().email().nullable(),
  idBoards: z.array(TrelloIdSchema),
});

export type TrelloMember = z.infer<typeof TrelloMemberSchema>;

export const TrelloCardSchema = z.object({
  id: TrelloIdSchema,
  desc: z.string(),
  closed: z.boolean(),
  due: z.string().optional().nullable(),
  url: z.string().url(),
  shortUrl: z.string().url(),
  name: z.string(),
  email: z.string().email().optional().nullable(),
  idList: TrelloIdSchema,
  idBoard: TrelloIdSchema,
});

export type TrelloCard = z.infer<typeof TrelloCardSchema>;

export const UpdateCardInputSchema = TrelloCardSchema.pick({
  id: true,
}).merge(
  TrelloCardSchema.partial().omit({
    id: true,
  })
);

export type UpdateCardInput = z.infer<typeof UpdateCardInputSchema>;

export const TrelloListSchema = z.object({
  id: TrelloIdSchema,
  name: z.string(),
  closed: z.boolean(),
});

export type TrelloList = z.infer<typeof TrelloListSchema>;

export const TrelloBoardSettingsSchema = z.object({
  id: TrelloIdSchema.optional(),
  boardId: TrelloIdSchema.optional(),
  listIds: z.array(TrelloIdSchema).optional(),
  doneListId: TrelloIdSchema.optional(),
});

export type TrelloBoardSettings = z.infer<typeof TrelloBoardSettingsSchema>;

export const TrelloSettingsSchema = z.object({
  boards: z.array(TrelloBoardSettingsSchema).optional(),
  userToken: z.string().optional(),
  member: TrelloMemberSchema.optional(),
});

export type TrelloSettings = z.infer<typeof TrelloSettingsSchema>;

export interface TrelloTaskMeta {
  orgListId: string;
  boardId: string;
}

export const GetTrelloBoardListsArgsSchema = z.object({
  boardId: TrelloIdSchema,
});

export type GetTrelloBoardListsArgs = z.infer<
  typeof GetTrelloBoardListsArgsSchema
>;

export const trelloSchema = defineSchema({
  commands: {
    saveTrelloBoards: operation.command.withPayload(
      TrelloSettingsSchema.pick({ boards: true })
    ),
    updateTrelloCard: operation.command.withPayload(UpdateCardInputSchema),
  },
  queries: {
    getTrelloCards: operation.query.withResult(z.array(TrelloCardSchema)),
    getTrelloBoards: operation.query.withResult(z.array(TrelloBoardSchema)),
    getTrelloBoardLists: operation.query
      .withPayload(GetTrelloBoardListsArgsSchema)
      .withResult(z.array(TrelloListSchema)),
  },
  events: {},
});
