/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { AppSettings } from '@time-neko/shared/domain/settings';
import { UseQueryResult } from '@tanstack/react-query';
import { UseQueryOptions } from '@musubi/react';
import { frontendSettingsSchema } from '@time-neko/frontend/domain/settings/schema';

export type GetSettingHookParams = Omit<
  UseQueryOptions<AppSettings, AppSettings>,
  'variables'
>;

export function useGetSetting<Key extends keyof AppSettings>(
  key: Key,
  args?: GetSettingHookParams
) {
  return frontendSettingsSchema.getSetting.useQuery({
    variables: {
      key,
    },
    ...args,
  }) as UseQueryResult<AppSettings[Key]>;
}
