/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { z } from 'zod';

export const ErrorSchema = z.object({
  message: z.string(),
  name: z.string(),
  stack: z.string().optional(),
});

export const ErrorPayloadSchema = z.object({
  error: ErrorSchema,
});

export type ErrorPayload = z.infer<typeof ErrorPayloadSchema>;
