/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import create from 'zustand';

interface SharedAudioElementStore {
  element?: HTMLAudioElement;
  setElement: (element?: HTMLAudioElement) => void;
}

export const useSharedAudioElement = create<SharedAudioElementStore>((set) => ({
  element: undefined,
  setElement: (element) => set({ element }),
}));
