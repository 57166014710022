/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Observable } from 'rxjs';

type LoggerFn = (...args: any[]) => void;

export interface LogPayload {
  level: LoggerLevel;
  args: any[];
}

export enum LoggerLevel {
  Error = 'error',
  Warn = 'warn',
  Info = 'info',
  Debug = 'debug',
  Log = 'log',
  Trace = 'trace',
}

export interface Logger {
  error: LoggerFn;
  warn: LoggerFn;
  info: LoggerFn;
  debug: LoggerFn;
  trace: LoggerFn;
  log: LoggerFn;
  log$?: Observable<LogPayload>;
  setLevel: (level: LoggerLevel) => void;
  getChild: (name: string) => Logger;
}
