/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useDialog } from '@time-neko/frontend/providers/dialog';
import { useCallback } from 'react';
import { useDomain } from '@time-neko/frontend/providers/domain';
import { CloseButton } from '@time-neko/frontend/ui';

export interface RequestPermissionParams {
  onDenied?: () => unknown;
}

function shouldShowDialog() {
  return process.env.NX_HIDE_NOTIFICATIONS_PERMISSION_DIALOG !== 'true';
}

export function useNotificationPermissionRequest() {
  const notifications = useDomain('notifications');

  const { showDialog, closeDialog } = useDialog();

  const handlePermissionRequest = useCallback(
    async (onDenied?: RequestPermissionParams['onDenied']) => {
      const result = await notifications?.requestPermission?.();
      const boolResult = Boolean(result);

      notifications?.setPermissionGranted(boolResult);

      if (!result) {
        onDenied?.();
      }

      return boolResult;
    },
    [notifications]
  );

  const requestPermissionIfNotGranted = useCallback(
    async (props?: RequestPermissionParams) => {
      if (notifications) {
        switch (notifications.permissionState) {
          case 'granted':
            return true;

          case 'default': {
            if (shouldShowDialog()) {
              showDialog({
                icon: 'SmartCat',
                title: 'Notification permissions',
                body: `Hey there, we'll need your notifications permission for that! Please click "Allow" to continue.`,
              });
            }

            const result = await handlePermissionRequest(props?.onDenied);

            closeDialog();

            return result;
          }

          case 'denied':
            props?.onDenied?.();

            if (shouldShowDialog()) {
              showDialog({
                icon: 'SmartCat',
                title: 'Notification permissions',
                body: `Sorry, it looks like you've denied our notifications permission. If you would like to receive notifications, you might need to change your settings.`,
                footer: (bag) => <CloseButton onClose={bag.onClose} />,
              });
            }

            return false;

          default:
            return false;
        }
      }

      return false;
    },
    [closeDialog, handlePermissionRequest, notifications, showDialog]
  );

  return {
    requestPermissionIfNotGranted,
    permissionGranted: notifications?.permissionState === 'granted',
  };
}
