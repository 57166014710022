/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useMemo } from 'react';
import {
  SettingsForm,
  SettingsFormProps,
} from '@time-neko/frontend/domain/settings';
import { WebAppContainer } from '../../../components/WebAppContainer';
import { areWebNotificationsSupported } from '@time-neko/frontend/support';
import { useTrackingSettingsFields } from '@time-neko/frontend/domain/tracking/hooks';
import { privacyLinkSettingsItem } from '@time-neko/frontend/domain/tracking/web';
import { Path } from 'react-hook-form';
import { AppSettings } from '@time-neko/shared/domain/settings';
import { useConst } from '@chakra-ui/hooks';

import { useVersionSettingsItem } from '@time-neko/frontend/domain/updates/web';
import { usePomodoroSettingsFields } from '@time-neko/frontend/domain/pomodoro/hooks';
import { useIsSmallMobile } from '@time-neko/frontend/responsive';
import { PomodoroReminderSettings } from '@time-neko/frontend/domain/pomodoro/web';

export function SettingsFormView() {
  const isMobile = useIsSmallMobile();

  const versionItem = useVersionSettingsItem({
    disableUpdateCheck: true,
  });
  const pomodoroFields = usePomodoroSettingsFields({
    PomodoroReminderSettingsComponent: PomodoroReminderSettings,
  });
  const trackingFields = useTrackingSettingsFields();

  const fieldsToSkip = useConst(() => {
    const fields: Path<AppSettings>[] = [
      'pomodoroSettings.openFullWindowOnBreak',
      'pomodoroSettings.showOverlayBeforeBreak',
      'pomodoroSettings.breakWindowAsOverlay',
    ];

    // TODO Check if this is right
    if (!areWebNotificationsSupported()) {
      fields.push('pomodoroSettings.showNotificationOnStateChange');
    }

    return fields;
  });

  const fields = useMemo<SettingsFormProps['fields']>(() => {
    return [
      ...pomodoroFields,
      ...trackingFields,
      versionItem,
      privacyLinkSettingsItem,
    ];
  }, [pomodoroFields, versionItem, trackingFields]);

  return (
    <WebAppContainer
      sx={{
        height: {
          base: 'calc(var(--chakra-vh) - 60px)',
          md: 'var(--chakra-vh)',
        },
      }}
      maxFrameWidth={700}
      maxFrameHeight={700}
    >
      <SettingsForm
        hideActionFooterText={isMobile}
        skippedFieldNames={fieldsToSkip}
        containerStyles={{
          width: '100%',
          height: '100%',
        }}
        fields={fields}
        titleBarProps={{
          hideWindowControls: true,
          canGoBack: !isMobile,
        }}
      />
    </WebAppContainer>
  );
}
