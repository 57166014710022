/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useCallback, useState } from 'react';
import { useEvent } from 'react-use';
import { Maybe } from '@time-neko/shared/common-types';

export function useVideoControls(video: Maybe<HTMLVideoElement>) {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = useCallback(() => {
    if (isPlaying) {
      video?.pause();
    } else {
      void video?.play();
    }
  }, [isPlaying, video]);

  const stop = useCallback(() => {
    if (video) {
      video.pause();
      video.currentTime = 0;
    }
  }, [video]);

  useEvent(
    'play',
    () => {
      setIsPlaying(true);
    },
    video
  );

  useEvent(
    'pause',
    () => {
      setIsPlaying(false);
    },
    video
  );

  return {
    isPlaying,
    togglePlay,
    stop,
  };
}
