/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { MusubiClient } from '@musubi/core';
import { pomodoroSchema } from './types';
import { logger } from '@time-neko/shared/logger';
import { PowerSaveBlocker } from '@time-neko/shared/domain/types';

interface Dependencies {
  localClient: MusubiClient<typeof pomodoroSchema>;
  powerSaveBlocker?: PowerSaveBlocker;
}

export function preventSleepOnPomodoro({
  localClient,
  powerSaveBlocker,
}: Dependencies) {
  if (!powerSaveBlocker) {
    return undefined;
  }

  const log = logger.getChild('preventSleepOnPomodoro');

  return localClient
    .observeEvent('timerStartedOrStopped')
    .subscribe(async ({ payload }) => {
      if (payload.isRunning) {
        log.debug('Prevent sleep');
        await powerSaveBlocker.start();
      } else {
        log.debug('Allow sleep');
        await powerSaveBlocker.stop();
      }
    });
}
