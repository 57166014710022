/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { createMultiStyleConfigHelpers } from '@chakra-ui/system';
import {
  createRawNesColorFromScheme,
  createRawNesColorScheme,
} from '../themeParts/nes';
import { ColorScheme, ColorSchemeType } from '../themeParts/colors';
import { Theme } from '../types';
import { theme as chakraTheme } from '@chakra-ui/theme';

export function createCustomIconButtonComponent() {
  const helpers = createMultiStyleConfigHelpers([
    'base',
    'textContainer',
    'container',
  ]);

  return helpers.defineMultiStyleConfig({
    defaultProps: {
      variant: 'nes',
    },
    variants: {
      'nes-ghost': {
        base: {
          display: 'none',
        },
      },
      nes: (props) => {
        const theme = props.theme as Theme;

        const getDefaultColorScheme = () =>
          props.colorMode === 'dark'
            ? createRawNesColorScheme(chakraTheme.colors.gray, props.colorMode)
            : ({
                surface: theme.colors.brand.itemBg,
                borderColor: theme.colors.brand.borderColor,
                shadowColor: theme.shadows.base,
                darkerShadowColor: theme.shadows.hover,
                contrast: theme.colors.brand.textPrimary,
              } as ColorScheme);

        const colorScheme = props.colorScheme
          ? createRawNesColorFromScheme(
              props.colorScheme as ColorSchemeType,
              props.theme.colors,
              props.colorMode
            )
          : getDefaultColorScheme();

        return {
          container: {
            '--scale': '0.5',
            '--active-scale': '1.2',

            '&': {
              backgroundColor: 'transparent !important',
              border: 'none',
              borderRadius: 0,
              padding: 0,
              position: 'relative',
              boxShadow: 'none !important',

              '&::after, &::before': {
                display: 'none !important',
              },

              '&:focus-visible': {
                boxShadow: `${theme.shadows.focusVisible} !important`,
              },
            },

            '&:not(:active)': {
              '& .shadow-left, & .shadow-top': {
                display: 'none',
              },
            },

            '& .top-left': {
              transformOrigin: 'left',
            },

            '&top': {
              transformOrigin: 'top',
            },

            '&:hover, &:active': {
              '& .shadow-right': {
                transform: 'scaleX(var(--active-scale)) translateX(-4.7px)',
              },

              '& .shadow-left': {
                transform: 'scaleX(var(--active-scale)) translateX(-1px)',
              },

              '& .shadow-top': {
                transform: 'scaleY(var(--active-scale)) translateY(-1px)',
              },

              '& .shadow-bottom': {
                transform: 'scaleY(var(--active-scale)) translateY(-4.7px)',
              },
            },
          },
          base: {
            '& .content': {
              fill: colorScheme.surface,
            },

            '& .shadow': {
              fill: colorScheme.shadowColor,
            },

            '&. .border': {
              fill: colorScheme.borderColor,
            },
          },
          textContainer: {
            left: -0.5,
            top: -0.2,
            position: 'absolute',

            '* .text': {
              color: colorScheme.contrast,
            },

            'svg *': {
              fill: colorScheme.contrast,
            },
          },
        };
      },
    },
  });
}
