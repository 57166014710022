/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { frontendTasksSchema } from '@time-neko/frontend/domain/tasks/schema';

export function useGroupedTasksCount() {
  const getCountQuery = frontendTasksSchema.countTasksByState.useQuery({
    refetchOnWindowFocus: true,
  });

  frontendTasksSchema.tasksUpdated.useEvent(() => {
    void getCountQuery.refetch();
  }, [getCountQuery.refetch]);

  frontendTasksSchema.tasksDeleted.useEvent(() => {
    void getCountQuery.refetch();
  }, [getCountQuery.refetch]);

  frontendTasksSchema.taskCreated.useEvent(() => {
    void getCountQuery.refetch();
  }, [getCountQuery.refetch]);

  return {
    count: getCountQuery.data,
    getCount: getCountQuery.refetch,
  };
}
