/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { SettingsFields } from '@time-neko/frontend/domain/settings/field-definitions';

const fields: SettingsFields[] = [
  {
    renderAs: 'section',
    title: 'Privacy',
    fields: [
      {
        renderAs: 'yesNo',
        name: 'privacySettings.trackingEnabled',
        label: 'Provide anonymous usage statistics',
        helperText:
          'Provide anonymous usage information that is used to improve the app.',
        defaultValue: true,
      },
    ],
  },
];

export function useTrackingSettingsFields(): SettingsFields[] {
  return fields;
}
