/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Button, Text } from '@time-neko/frontend/ui';

import { DialogHookProps } from '@time-neko/frontend/providers/dialog';

export interface UnsavedChangesDialogProps {
  onClose: () => unknown;
  onCancel?: () => unknown;
}

export function unsavedChangesDialog(
  props: UnsavedChangesDialogProps
): DialogHookProps {
  return {
    title: <Text>Close window</Text>,
    body: (
      <Text>
        Are you sure you want to close window? All unsaved changes will be lost!
      </Text>
    ),
    footer: (bag) => (
      <>
        <Button
          onClick={() => {
            props.onCancel?.();

            bag.onClose();
          }}
          ref={bag.leastDestructiveRef}
        >
          <Text>Cancel</Text>
        </Button>
        <Button
          colorScheme="danger"
          onClick={() => {
            props.onClose();
            bag.onClose();
          }}
        >
          <Text>Close</Text>
        </Button>
      </>
    ),
  };
}
