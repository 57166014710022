/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { isProduction } from '@time-neko/shared/utils';
import { Jsonable } from '@time-neko/shared/common-types';

export interface AppErrorParams {
  title?: string;
  name?: string;
  originalError?: Error;
}

export class AppError extends Error implements Jsonable {
  readonly title?: string;

  readonly originalError?: Error;

  readonly isAppError = true;

  constructor(
    message: string,
    { title, name, originalError }: AppErrorParams = {}
  ) {
    super(message);

    this.title = title;
    this.originalError = originalError;

    if (name) {
      this.name = name;
    }
  }

  static fromError(
    error: Error,
    params: Omit<AppErrorParams, 'originalError'> & { message?: string } = {}
  ) {
    if (error instanceof AppError) {
      return error;
    }

    return new AppError(params.message ?? error.message, {
      ...params,
      originalError: error,
    });
  }

  static unknown() {
    return new this('Unknown error');
  }

  static isAppError(error: unknown): error is AppError {
    return Boolean(
      error instanceof AppError ||
        (typeof error === 'object' &&
          error &&
          'isAppError' in error &&
          error.isAppError)
    );
  }

  toJSON() {
    return {
      ...this,
      name: this.name,
      title: this.title,
      message: this.message,
      stack: isProduction() ? undefined : this.stack,
      originalError: this.originalError,
      isAppError: this.isAppError,
    };
  }
}
