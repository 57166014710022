/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { z } from 'zod';
import type {
  DatabaseObjectResponse,
  PageObjectResponse,
} from '@notionhq/client/build/src/api-endpoints';
import { defineSchema, operation } from '@musubi/core';
import { getOperatorsForProperty } from './operators';
import { NotionDatabaseFilterOperator } from './operators.types';
import { NotionDatabaseAutomationSchema } from './automation.types';
import { NotionPropertySchema } from './property.types';

export type NotionDatabaseProperty =
  DatabaseObjectResponse['properties'][string];

export type NotionDatabasePropertyType = NotionDatabaseProperty['type'];

export type NotionPageProperty = PageObjectResponse['properties'][string];

export interface NotionTaskMeta {
  page: PageObjectResponse;
  databaseId: string;
  oldPropertiesBeforeAutomation?: Record<string, NotionPageProperty>;
}

export const NotionDatabaseFilterSchema = NotionPropertySchema.extend({
  propertyId: z.string(),
  value: z.string(),
  operator: z.nativeEnum(NotionDatabaseFilterOperator),
}).refine((data) => {
  return (
    Boolean(data.type) &&
    getOperatorsForProperty(data.type).includes(data.operator)
  );
}, 'Invalid filter operator selected');

export type NotionDatabaseFilter = z.infer<typeof NotionDatabaseFilterSchema>;

export const NotionDatabaseSettingsSchema = z.object({
  databaseId: z.string().optional(),
  filters: z.array(NotionDatabaseFilterSchema).optional(),
  automations: z.array(NotionDatabaseAutomationSchema).optional(),
});

export type NotionDatabaseSettings = z.infer<
  typeof NotionDatabaseSettingsSchema
>;

export const NotionSettingsSchema = z.object({
  token: z.string().optional(),
  databases: z.array(NotionDatabaseSettingsSchema).optional(),
});

export type NotionSettings = z.infer<typeof NotionSettingsSchema>;

export const notionSchema = defineSchema({
  events: {},
  commands: {},
  queries: {
    listNotionDatabases: operation.query.withResult<DatabaseObjectResponse[]>(),
  },
});
