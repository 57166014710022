/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { FormController, FormControllerProps } from '@time-neko/frontend/ui';
import { FormControlVariant } from '@time-neko/frontend/providers/theme';
import { FieldValues } from 'react-hook-form';

export const SettingsFormController = <FormState extends FieldValues>({
  children,
  label,
  ...props
}: FormControllerProps<FormState>) => {
  return (
    <FormController
      {...props}
      variant={FormControlVariant.inline}
      label={label}
      minWidth={{
        base: 'auto',
        lg: '450px',
      }}
      display="flex"
      alignItems={{
        base: 'flex-start',
        sm: 'baseline',
      }}
      flexWrap="wrap"
      formLabelProps={{
        margin: 0,
      }}
      contentBoxProps={{
        width: {
          base: '100%',
          sm: 'auto',
        },
      }}
      labelStackProps={{
        sx: {
          flex: 1,
          width: {
            base: '100%',
            sm: undefined,
          },
        },
      }}
      width="100%"
      sx={{
        justifyContent: 'space-between',
        flexDirection: {
          base: 'column',
          sm: 'row',
        },
      }}
    >
      {children}
    </FormController>
  );
};
