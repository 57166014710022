/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Text } from '../../atoms/text/Text';
import { Button, ButtonProps } from '../../atoms';

export interface SubmitButtonProps extends ButtonProps {
  id?: string;
  text?: string;
  didSubmit?: boolean;
}

export function SubmitButton({
  id,
  children,
  didSubmit,
  ...props
}: SubmitButtonProps) {
  return (
    <Button
      transition="all .3s"
      colorScheme={didSubmit ? 'success' : 'primary'}
      id={id}
      type={didSubmit ? 'button' : 'submit'}
      minWidth="150px"
      width="auto"
      {...props}
    >
      <Text>{didSubmit ? 'Saved!' : children ?? 'Save'}</Text>
    </Button>
  );
}
