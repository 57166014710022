/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */
import { frontendUpdatesSchema } from '@time-neko/frontend/domain/updates/schema';

export function useUpdateStateQuery() {
  const updateStateQuery = frontendUpdatesSchema.getUpdateState.useQuery();

  frontendUpdatesSchema.updateStateChanged.useEvent(
    async ({ payload }) => {
      updateStateQuery.setQueryData(payload);
    },
    [updateStateQuery.setQueryData]
  );

  return { updateStateQuery };
}
