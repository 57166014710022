/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export interface PlayAudioParams {
  path: string;
  closeAfter?: boolean;
  audio?: HTMLAudioElement;
}

export async function playAudio({
  path,
  closeAfter = false,
  audio: optionalAudio,
}: PlayAudioParams) {
  const audio = optionalAudio ?? new Audio();

  if (closeAfter) {
    audio.addEventListener('ended', () => {
      window.close();
    });
  }

  audio.src = path;

  await audio.play();
}
