/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { lazy, MutableRefObject, Suspense, useMemo } from 'react';
import {
  Alert,
  CenterContainer,
  Flex,
  Loading,
  Text,
  VStack,
} from '@time-neko/frontend/ui';
import {
  AppSettingsSchema,
  isAsyncSettingError,
} from '@time-neko/shared/domain/settings';
import { SettingsFormProps } from './SettingsForm.types';
import { SettingFieldsRenderer } from './renderer/SettingFieldsRenderer';
import { SettingsFields } from '@time-neko/frontend/domain/settings/field-definitions';
import { ErrorsAlertsList } from '@time-neko/frontend/errors/web';
import { SettingsFormAsyncErrorAction } from './asyncErrors/SettingsFormAsyncErrorAction';
import { useSettingsForm } from './useSettingsForm';
import { Route, Routes } from 'react-router-dom';
import { useIntegrationDefinitions } from '@time-neko/frontend/domain/integrations/providers';
import { RouteReturnType } from '@time-neko/shared/routes';
import { SettingsFormRoot } from './SettingsFormRoot';
import { FormProvider } from 'react-hook-form';

export function SettingsForm<Fields extends SettingsFields = SettingsFields>({
  fields,
  schema = AppSettingsSchema,
  customRenderer,
  TitleBar,
  onClose,
  innerContainerProps,
  titleBarProps,
  containerStyles,
  skippedFieldNames,
  hideActionFooterText,
}: SettingsFormProps<Fields>) {
  const integrations = useIntegrationDefinitions();

  const resolvedIntegrations = useMemo(() => {
    return integrations
      .filter(
        (integration) => integration.managePath && integration.ManageComponent
      )
      .map((integration) => {
        const Component = lazy(async () => ({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          default: await integration.ManageComponent!(),
        }));

        return {
          ...integration,
          Component,
        };
      });
  }, [integrations]);

  const {
    scrollContainerRef,
    form,
    removeErrorMutation,
    errors,
    queryLoading,
    setSettingsMutation,
    handleSubmit,
    handleClose,
    didSubmit,
  } = useSettingsForm({ schema, onClose });

  return (
    <FormProvider {...form}>
      <Routes>
        <Route
          path="/"
          element={
            <SettingsFormRoot
              didSubmit={didSubmit}
              onReset={() => form.reset()}
              containerStyles={containerStyles}
              titleBarProps={titleBarProps}
              onClose={handleClose}
              onBack={handleClose}
              TitleBar={TitleBar}
              isQueryLoading={queryLoading}
              isSubmitting={setSettingsMutation.isLoading}
              scrollContainerRef={
                scrollContainerRef as MutableRefObject<HTMLDivElement>
              }
              isDirty={form.formState.isDirty}
              onSubmit={form.handleSubmit(handleSubmit)}
              hideActionFooterText={hideActionFooterText}
            />
          }
        >
          <Route
            index
            element={
              <Flex
                onSubmit={form.handleSubmit(handleSubmit)}
                as="form"
                flex={1}
                alignItems="center"
                direction="column"
                ref={scrollContainerRef as MutableRefObject<HTMLDivElement>}
                height="100%"
                width="100%"
                {...innerContainerProps}
              >
                <VStack
                  width="100%"
                  sx={{
                    '.chakra-alert': {
                      mb: 6,
                    },
                  }}
                >
                  {setSettingsMutation.error && (
                    <Alert hideIcon status="error" mb={6}>
                      <Text>{setSettingsMutation.error.message}</Text>
                    </Alert>
                  )}

                  <ErrorsAlertsList
                    errors={errors ?? []}
                    getAlertPropsForError={(_, error, props) => {
                      if (!isAsyncSettingError(error)) {
                        return props;
                      }

                      return {
                        ...props,
                        isClosable: error.isClosable,
                        onClose: () => {
                          removeErrorMutation.mutate({
                            name: error.name,
                          });
                        },
                        actions: () =>
                          error.actions?.map((action) => (
                            <SettingsFormAsyncErrorAction
                              key={action.isUrl ? action.url : action.name}
                              action={action}
                              error={error}
                            />
                          )),
                      };
                    }}
                  />
                </VStack>

                <SettingFieldsRenderer
                  skippedFieldNames={skippedFieldNames}
                  fields={fields}
                  form={form}
                  customRenderer={customRenderer}
                />
              </Flex>
            }
          />
          {resolvedIntegrations.map((integration) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const path = integration.managePath!(RouteReturnType.Path);

            return (
              <Route
                key={integration.label}
                path={`${path}/*`}
                element={
                  <Suspense
                    fallback={
                      <CenterContainer>
                        <Loading boxSize="md" />
                      </CenterContainer>
                    }
                  >
                    <integration.Component />
                  </Suspense>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </FormProvider>
  );
}
