/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useMemo } from 'react';
import { MenuItemProperties } from '@time-neko/frontend/ui';
import { Asset } from '@time-neko/frontend/assets';
import { useDomain } from '@time-neko/frontend/providers/domain';

export function useFeedbackMenuItems() {
  const openFeedbackForm = useDomain('openFeedbackForm');

  return useMemo<MenuItemProperties[]>(
    () => [
      {
        renderAs: 'item',
        label: 'Feedback',
        id: 'feedback',
        placement: 'left',
        onClick: openFeedbackForm,
        icon: (
          <Asset
            boxSize="sm"
            name="Info"
            sx={{
              '&, & path': {
                fill: 'brand.iconPrimary',
              },
            }}
          />
        ),
      },
    ],
    [openFeedbackForm]
  );
}
