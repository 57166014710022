/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { getTaskDurationText } from './getTaskDurationText';
import { useMemo } from 'react';
import { frontendTasksSchema } from '@time-neko/frontend/domain/tasks/schema';

export function useActiveTask() {
  const activeTaskQuery = frontendTasksSchema.getActiveTask.useQuery();

  const duration = useMemo(
    () =>
      activeTaskQuery.data ? getTaskDurationText(activeTaskQuery.data) : '',
    [activeTaskQuery.data]
  );

  return {
    query: activeTaskQuery,
    duration,
  };
}
