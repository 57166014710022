/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef, useCallback, useMemo, useState } from 'react';
import {
  Alert,
  AlertProps,
  Button,
  ButtonGroup,
  Center,
  HStack,
  Loading,
  ProgressBar,
  Text,
  VStack,
} from '@time-neko/frontend/ui';
import { Asset } from '@time-neko/frontend/assets';
import { TimerEditButtons } from '../buttons/timerEditButtons/TimerEditButtons';
import { ChakraProps } from '@chakra-ui/system';
import { useKey } from 'react-use';
import { Key } from 'ts-key-enum';
import { percent } from '@time-neko/shared/utils';
import {
  PomodoroNextStateTrigger,
  stateChangeSoonSecondsLeft,
} from '@time-neko/shared/domain/pomodoro';
import { useNextStateNotificationTimerFlags } from './useNextStateNotificationTimerFlags';
import {
  usePomodoro,
  usePomodoroNextState,
} from '@time-neko/frontend/domain/pomodoro/hooks';

export interface NextStateNotificationAlertProps
  extends Pick<AlertProps, 'onClose'>,
    ChakraProps {}

export const NextStateNotificationAlert = forwardRef<
  HTMLDivElement,
  NextStateNotificationAlertProps
>(({ onClose, sx, ...props }, ref) => {
  const [actionDone, setActionDone] = useState(false);

  const { pomodoro, setRemainingSeconds } = usePomodoro();

  const { nextStateText, moveToNextStateMutation } = usePomodoroNextState();
  const { flagQuery, flagMutation } = useNextStateNotificationTimerFlags();

  const percentageLeft = useMemo(
    () =>
      percent(Number(pomodoro?.remainingSeconds), stateChangeSoonSecondsLeft),
    [pomodoro]
  );

  const handleTimerButtonClick = useCallback(
    async (newSeconds: number) => {
      setActionDone(true);

      await Promise.all([
        setRemainingSeconds(newSeconds),
        await flagMutation.mutateAsync(undefined),
      ]);

      onClose?.();
    },
    [flagMutation, onClose, setRemainingSeconds]
  );

  const handleNextState = useCallback(async () => {
    setActionDone(true);

    moveToNextStateMutation.mutate({
      trigger: PomodoroNextStateTrigger.NextStateNotification,
    });

    onClose?.();
  }, [moveToNextStateMutation, onClose]);

  useKey(Key.Escape, (event) => {
    event.stopPropagation();

    onClose?.();
  });

  if (actionDone) {
    return null;
  }

  if (!pomodoro) {
    return (
      <Center>
        <Loading />
      </Center>
    );
  }

  return (
    <Alert
      ref={ref}
      borderless
      className="next-state-notification"
      isClosable
      onClose={onClose}
      sx={{
        '&.nes-container.alert': {
          pt: 8,
          pb: 5,
          pl: 0,
          pr: 4,
          bg: `brand.${pomodoro.nextState}`,
        },
        ...sx,
      }}
      {...props}
    >
      <HStack spacing={4} justifyContent="center" alignItems="center">
        <Asset
          width="80px"
          height="83px"
          name="SmartCat"
          position="relative"
          bottom="5px"
        />

        <VStack justifyContent="center" alignItems="center" spacing={4}>
          <HStack>
            <Text color="white" fontSize="lg">
              Hey, {nextStateText} is starting soon!
            </Text>
          </HStack>
          <ButtonGroup flexWrap="wrap" className="buttons" spacing={4}>
            {!flagQuery.data && (
              <TimerEditButtons
                remainingSeconds={pomodoro.remainingSeconds}
                onChange={handleTimerButtonClick}
                allowSubtract={false}
              />
            )}
            <Button onClick={handleNextState}>{nextStateText}</Button>
          </ButtonGroup>
          <ProgressBar value={percentageLeft} max={100} h="sm" width="100%" />
        </VStack>
      </HStack>
    </Alert>
  );
});
