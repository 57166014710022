/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { TimeUnits } from '@time-neko/shared/common-types';

export const timeUnitsDictionaryShort = {
  [TimeUnits.Minutes]: 'Min',
  [TimeUnits.Hours]: 'Hrs',
  [TimeUnits.Seconds]: 'Sec',
};
