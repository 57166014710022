/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Flex, Text } from '@time-neko/frontend/ui';
import { ChakraProps } from '@chakra-ui/system';
import { useDayStatistics } from '../hooks/useDayStatistics';
import { maybePlural } from '@time-neko/shared/utils';

export function CompletedTasksTodayCount(props: ChakraProps) {
  const statsQuery = useDayStatistics();

  const completedTasks = statsQuery.data?.completedTasks?.length ?? 0;

  return (
    <Flex justifyContent="center" alignItems="center" {...props}>
      <Text
        className="completed-tasks-today-count"
        fontSize="xs"
        color="brand.textSecondary"
      >
        {completedTasks} {maybePlural(completedTasks, 'task', 'tasks')}{' '}
        completed today
      </Text>
    </Flex>
  );
}
