/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { BoxProps, FlexProps, HStack } from '@chakra-ui/react';
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { IconButton } from '../iconButton/IconButton';
import { Heading } from '../../atoms/heading/Heading';
import { Asset } from '@time-neko/frontend/assets';
import { useNavigateBack } from '@time-neko/frontend/router';
import { getPlatformFromNavigator } from '@time-neko/frontend/common';
import { Tooltip } from '../tooltip/Tooltip';
import { css } from '@emotion/react';
import { appRoutes } from '@time-neko/shared/routes';
import {
  TitleBarControls,
  TitleBarControlsProps,
} from './parts/TitleBarControls';
import create from 'zustand';
import { useUnmount } from 'react-use';

const useTitleBarGlobalProps = create<{
  props?: TitleBarProps;
  setProps: (props?: TitleBarProps) => void;
}>((set) => ({
  props: undefined,
  setProps: (props) => {
    set({ props });
  },
}));

export function useSetTitleBarProps(props: TitleBarProps) {
  const setProps = useTitleBarGlobalProps((s) => s.setProps);

  useEffect(() => {
    setProps(props);
  }, [props, setProps]);

  useUnmount(() => {
    setProps(undefined);
  });
}

export interface TitleBarProps extends BoxProps, TitleBarControlsProps {
  pageTitle?: ReactNode;
  onClose?: () => unknown;
  onMinimize?: () => unknown;
  hideWindowControls?: boolean;
  onGoBack?: () => unknown;
  canGoBack?: boolean;
  showButtonsOnLeftOnMac?: boolean;
  showMacButtonsOnMac?: boolean;
  buttonContainerProps?: FlexProps;
  additionalWindowControlsEndContent?: ReactNode;
  additionalWindowControlsStartContent?: ReactNode;
  oppositeWindowControlsContent?: ReactNode;
}

export const titleBarHeight = '40px';

const cssStyles = css`
  & {
    -webkit-app-region: drag;
    width: 100%;
    height: 40px;
    left: 0;
  }

  & button,
  & .no-drag {
    -webkit-app-region: no-drag;
  }
`;

export function TitleBar(passedProps: PropsWithChildren<TitleBarProps>) {
  const globalProps = useTitleBarGlobalProps((s) => s.props);

  const {
    children,
    pageTitle,
    isClosable = true,
    isMinimizable = true,
    closeButtonProps,
    minimizeButtonProps,
    hideWindowControls,
    onClose,
    onMinimize,
    onGoBack,
    canGoBack,
    showButtonsOnLeftOnMac = true,
    buttonContainerProps,
    showMacButtonsOnMac = true,
    additionalWindowControlsEndContent,
    oppositeWindowControlsContent,
    additionalWindowControlsStartContent,
    ...props
  } = {
    ...passedProps,
    ...globalProps,
  };

  const navigateBack = useNavigateBack();

  const platform = useMemo(() => getPlatformFromNavigator(), []);

  const handleBack = useCallback(() => {
    if (onGoBack) {
      onGoBack();

      return;
    }

    navigateBack(appRoutes.timer());
  }, [navigateBack, onGoBack]);

  const isMac = platform === 'darwin';
  const positionButtonsAsMac = isMac && showButtonsOnLeftOnMac;
  const showMacButtonVariant = isMac && showMacButtonsOnMac;

  const contents = {
    windowControls: !hideWindowControls && (
      <TitleBarControls
        key="controls"
        isClosable={isClosable}
        isMinimizable={isMinimizable}
        variant={showMacButtonVariant ? 'mac' : 'standard'}
        additionalEndContent={additionalWindowControlsEndContent}
        additionalStartContent={additionalWindowControlsStartContent}
        minimizeButtonProps={{
          ...minimizeButtonProps,
          onClick: onMinimize,
          containerProps: {
            order: isMac ? 1 : 0,
          },
        }}
        closeButtonProps={{
          ...closeButtonProps,
          onClick: onClose,
        }}
      />
    ),
    title: (
      <HStack
        key="title"
        height="100%"
        zIndex={10}
        spacing={2}
        alignItems="center"
        justifyContent="center"
        flex={1}
        overflow="hidden"
      >
        {canGoBack && (
          <Tooltip label="Go back">
            <IconButton
              onClick={handleBack}
              variant="nes-ghost"
              aria-label="Go back"
            >
              <Asset transform="rotate(180deg)" name="Arrow" boxSize="md" />
            </IconButton>
          </Tooltip>
        )}
        <Heading
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          size="md"
        >
          {pageTitle}
        </Heading>
      </HStack>
    ),
    // We also render it if hideWindowControls is false, in order to maintain layout balance. Without it, the title would be off-center.
    oppositeOfControls: (oppositeWindowControlsContent ||
      !hideWindowControls) && (
      <HStack
        key="opposite-of-controls"
        width="20%"
        flexDirection={isMac ? undefined : 'row-reverse'}
        height="100%"
        zIndex={10}
        justifyContent="flex-end"
        alignItems="center"
        spacing={positionButtonsAsMac ? 5 : 0}
      >
        {oppositeWindowControlsContent}
      </HStack>
    ),
  };

  const orderedContent = useMemo(() => {
    if (positionButtonsAsMac) {
      return [
        contents.windowControls,
        contents.title,
        contents.oppositeOfControls,
      ];
    }

    return [
      contents.oppositeOfControls,
      contents.title,
      contents.windowControls,
    ];
  }, [
    contents.oppositeOfControls,
    contents.title,
    contents.windowControls,
    positionButtonsAsMac,
  ]);

  return (
    <HStack
      spacing={0}
      top={0}
      width="100%"
      className="title-bar"
      height={titleBarHeight}
      as="header"
      css={cssStyles}
      alignItems="center"
      justifyContent="space-evenly"
      zIndex={10}
      px={3}
      {...props}
    >
      {orderedContent}
    </HStack>
  );
}
