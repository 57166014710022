/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import log, { LogLevelDesc } from 'loglevel';
import logPrefix from 'loglevel-plugin-prefix';
import { Logger, LoggerLevel, LogPayload } from './types';
import { isProduction, matchWildcard } from '@time-neko/shared/utils';
import { Subject } from 'rxjs';

const levelsMap = {
  [log.levels.TRACE]: LoggerLevel.Trace,
  [log.levels.DEBUG]: LoggerLevel.Debug,
  [log.levels.INFO]: LoggerLevel.Info,
  [log.levels.WARN]: LoggerLevel.Warn,
  [log.levels.ERROR]: LoggerLevel.Error,
  [log.levels.SILENT]: LoggerLevel.Trace,
};

const subjects = {
  log$: new Subject<LogPayload>(),
};

logPrefix.reg(log);
logPrefix.apply(log, {
  format: (level, name, date) => {
    return `[${date}][${level}]${name ? `[${name}]` : ''}`;
  },
});

const isDebug = process.env.NX_ENABLE_DEBUG === 'true';

export function createLogger(name?: string, parent?: typeof log): Logger {
  const logger = name ? (parent ?? log).getLogger(name) : log;

  const orgFn = logger.methodFactory;

  const filters: string[] =
    process.env.DEBUG_FILTER?.split(',').map((v) => v.toLowerCase()) ?? [];

  logger.methodFactory = (methodName, logLevel, loggerName = 'root') => {
    return (...message: any[]) => {
      const matchesFilter =
        !filters.length ||
        filters.includes((loggerName as string).toLowerCase()) ||
        filters.some((filter) => matchWildcard(filter, loggerName as string));

      if (!matchesFilter) {
        return;
      }

      subjects.log$.next({
        level: levelsMap[logLevel],
        args: message,
      });

      return orgFn(methodName, logLevel, loggerName)(...message);
    };
  };

  logger.setLevel(isDebug || !isProduction() ? 'debug' : 'info');

  return {
    log$: subjects.log$.asObservable(),
    info: logger.info,
    error: logger.error,
    debug: logger.debug,
    warn: logger.warn,
    trace: logger.trace,
    log: logger.log,
    setLevel: (level) => {
      logger.setLevel(level as LogLevelDesc);
    },
    getChild: (childName) => {
      return createLogger(name ? `${name}-${childName}` : childName);
    },
  };
}

export const logger = createLogger();
