/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export enum RecaptchaInjectStrategy {
  Inline = 'inline',
  Remote = 'remote',
}

const recaptchaCode = `/* PLEASE DO NOT COPY AND PASTE THIS CODE. */(function(){var w=window,C='___grecaptcha_cfg',cfg=w[C]=w[C]||{},N='grecaptcha';var gr=w[N]=w[N]||{};gr.ready=gr.ready||function(f){(cfg['fns']=cfg['fns']||[]).push(f);};w['__recaptcha_api']='https://www.google.com/recaptcha/api2/';(cfg['render']=cfg['render']||[]).push('onload');w['__google_recaptcha_client']=true;var d=document,po=d.createElement('script');po.type='text/javascript';po.async=true;po.src='https://www.gstatic.com/recaptcha/releases/mq0-U1BHZ5YTcoDC-CvsLPNc/recaptcha__en.js';po.crossOrigin='anonymous';po.integrity='sha384-YQa+IZb9f//sVw4RfG9FEtSszHRtCsZKru4nvZuKNVW0OaFL1FtT01EpC4YROIJP';var e=d.querySelector('script[nonce]'),n=e&&(e['nonce']||e.getAttribute('nonce'));if(n){po.setAttribute('nonce',n);}var s=d.getElementsByTagName('script')[0];s.parentNode.insertBefore(po, s);})();`;

export function getRecaptchaScript(
  strategy: RecaptchaInjectStrategy,
  siteKey: string
) {
  switch (strategy) {
    case RecaptchaInjectStrategy.Inline: {
      return <script>${recaptchaCode}</script>;
    }

    case RecaptchaInjectStrategy.Remote:
      return (
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${siteKey}`}
          async
          defer
        />
      );
  }
}

export function getRecaptchaToken(siteKey: string, action: string) {
  return new Promise<string | undefined>((resolve, reject) => {
    if (!siteKey || !window.grecaptcha) {
      resolve(undefined);
    }

    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute(siteKey, { action });

        resolve(token);
      } catch (error) {
        reject(error);
      }
    });
  });
}
