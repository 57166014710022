/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { pipe } from '@fxts/core';
import { DownloadsPageQueryParams, RawDownloadsPageQueryParams, websiteRoutes } from '@time-neko/shared/routes';
import { AppPlatform } from '@time-neko/shared/domain/types';

export function getWebsiteUrl() {
  return new URL(process.env.NX_WEBSITE_URL ?? '');
}

export function getDownloadUrl({version, platform, isUpdateError}: Omit<DownloadsPageQueryParams, 'platform'> & { platform: AppPlatform }) {
  return pipe(getWebsiteUrl(), (url) => {
    url.pathname = websiteRoutes.download({
      platform,
    });

    const queryParams = {
      version,
      isUpdateError: isUpdateError ? '1' : '0',
      platform,
    } satisfies RawDownloadsPageQueryParams;

    url.search = new URLSearchParams(queryParams as Record<string, string>).toString();

    return url.toString();
  })
}
