/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { IconButton, IconButtonProps, Tooltip } from '@time-neko/frontend/ui';

import { Asset, RetryIconProps } from '@time-neko/frontend/assets';
import { useStopTimer } from '@time-neko/frontend/domain/pomodoro/hooks';
import { useMemo, useState } from 'react';
import { frontendPomodoroSchema } from '@time-neko/frontend/domain/pomodoro/schema';
import classNames from 'classnames';

export interface StopTimerBtnProps extends Omit<IconButtonProps, 'aria-label'> {
  iconProps?: RetryIconProps;
}

export function StopTimerBtn({
  iconProps,
  onClick,
  ...props
}: StopTimerBtnProps) {
  const [isLongPress, setIsLongPress] = useState(false);
  const [isLongPressDone, setIsLongPressDone] = useState(false);

  const label = useMemo(() => {
    if (!isLongPress) {
      return 'Stop timer (long press to restart pomodoro)';
    }

    if (isLongPressDone) {
      return 'Pomodoro restarted!';
    }

    return 'Restarting pomodoro...';
  }, [isLongPress, isLongPressDone]);

  const stopTimerMutation = useStopTimer();
  const restartMutation = frontendPomodoroSchema.restartPomodoro.useCommand();

  return (
    <Tooltip label={label} maxW="300px">
      <IconButton
        boxSize="md"
        onLongPressStart={() => {
          setIsLongPress(true);
        }}
        onLongPress={() => {
          setIsLongPressDone(true);
          restartMutation.mutate(undefined);
        }}
        onLongPressReverted={() => {
          setIsLongPress(false);
          setIsLongPressDone(false);
        }}
        variant="nes"
        onClick={(event) => {
          stopTimerMutation.mutate(undefined);

          onClick?.(event);
        }}
        aria-label={label}
        {...props}
        className={classNames(props.className, 'stop-timer-btn')}
      >
        {isLongPress ? (
          <Asset
            className={classNames({ 'animation-blink': !isLongPressDone })}
            name="ArrowRepeat"
            boxSize="sm"
            {...iconProps}
          />
        ) : (
          <Asset
            className="animation-fade-in"
            name="StopRounded"
            boxSize="sm"
            {...iconProps}
          />
        )}
      </IconButton>
    </Tooltip>
  );
}
