/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useMemo } from 'react';
import { DialogMethods } from '../types';
import { useDialogState } from '../state';

export const useDialog = () => {
  const { open, setOpen, setCurrentProps: setDialogProps } = useDialogState();

  return useMemo<DialogMethods>(
    () => ({
      closeDialog: () => setOpen(false),
      showDialog: (props) => {
        setDialogProps(props);
        setOpen(true);
      },
      isOpen: open,
    }),
    [open, setDialogProps, setOpen]
  );
};
