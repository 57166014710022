/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';

export const DetectNewDayResultSchema = z.object({
  isNewDay: z.boolean(),
  storedDate: z.string(),
});

export type DetectNewDayResult = z.infer<typeof DetectNewDayResultSchema>;

export enum NewDayDetectorSubscriptionTopics {
  NewDayDetected = 'NewDayDetected',
}

export const newDaySchema = defineSchema({
  commands: {
    detectNewDay: operation.command.withResult(DetectNewDayResultSchema),
    acknowledgeNewDay: operation.command,
  },
  queries: {},
  events: {
    newDayDetected: operation.event,
  },
});
