/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { AppPlatform } from '@time-neko/shared/domain/types';
import { invertObject } from '@time-neko/shared/utils';

export enum PlatformTag {
  Desktop = 'desktop',
  Website = 'website',
  Webapp = 'web-app',
}

export const platformTagsMap = {
  [AppPlatform.Desktop]: PlatformTag.Desktop,
  [AppPlatform.Website]: PlatformTag.Website,
  [AppPlatform.Webapp]: PlatformTag.Webapp,
} as const;

export const tagsPlatform = invertObject(platformTagsMap);

export function getTagPrefixForPlatform(platform: AppPlatform) {
  const prefix = platformTagsMap[platform];

  if (!prefix) {
    throw new TypeError(`Unknown platform: ${platform}`);
  }

  return prefix;
}
