/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  Button,
  FormControl,
  IconButton,
  InputGroup,
  InputRightElement,
  Kbd,
  Loading,
  Text,
  Textarea,
} from '@time-neko/frontend/ui';
import { MutableRefObject, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { CreateTaskInput } from '@time-neko/shared/domain/tasks';
import { useDebounce } from 'react-use';
import { wait } from '@time-neko/shared/utils';
import { useCreateTask } from '@time-neko/frontend/domain/tasks/hooks';
import { Key } from 'ts-key-enum';
import { NoticePopover } from '@time-neko/frontend/domain/notices/web';
import { useKeyboardShortcut } from '@time-neko/frontend/keyboard-shortcuts';
import { useMergeRefs } from '@chakra-ui/hooks';

const INPUT_TIP_NOTICE_ID = 'add_task_input_notice';

export function AddTaskInput() {
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
    getValues,
    setFocus,
  } = useForm<CreateTaskInput>();

  const handleTaskCreated = useCallback(async () => {
    reset();

    await wait(100);

    setFocus('title', { shouldSelect: true });
  }, [reset, setFocus]);

  const { createTask, isLoading } = useCreateTask({
    onCreate: handleTaskCreated,
  });

  const titleField = register('title', {
    required: 'Enter task title',
  });

  const inputRef = useRef<HTMLInputElement>();
  const mergedInputRef = useMergeRefs(inputRef, titleField.ref);

  const shortcut = useKeyboardShortcut('addTaskAsActive');

  useDebounce(
    () => {
      if (errors.title) {
        clearErrors('title');
      }
    },
    2000,
    [errors, clearErrors]
  );

  return (
    <form onSubmit={handleSubmit(createTask)}>
      <FormControl error={errors.title?.message}>
        <InputGroup display="flex">
          <NoticePopover
            initialFocusRef={inputRef as MutableRefObject<HTMLInputElement>}
            closeOnEsc={false}
            hideCloseButton
            closeOnBlur={false}
            placement="top"
            header="Tip"
            content={
              <Text display="inline-block" lineHeight={2}>
                To add a task at the top of the list, press:
                <Kbd height="md" ml={2}>
                  {shortcut.display}
                </Kbd>
              </Text>
            }
            footer={(bag) => (
              <Button onClick={bag.close}>
                <Text>Got it</Text>
              </Button>
            )}
            triggerElement={
              <Textarea
                resize="none"
                minHeight="lg"
                maxHeight="2xl"
                isDisabled={isLoading}
                className="task-title-input"
                {...titleField}
                ref={mergedInputRef}
                onKeyDown={async (event) => {
                  if (event.key === Key.Enter) {
                    event.preventDefault();

                    await createTask({
                      ...getValues(),
                      isActive: event.shiftKey,
                    });
                  }
                }}
                placeholder="Add task..."
                pr={12}
                size="lg"
              />
            }
            noticeId={INPUT_TIP_NOTICE_ID}
          />
          <InputRightElement
            display="flex"
            h="full"
            alignItems="center"
            right={1}
          >
            {isLoading ? (
              <Loading boxSize="xs" />
            ) : (
              <IconButton
                variant="nes-ghost"
                id="create_task"
                type="submit"
                size="sm"
                aria-label="Add task"
              >
                <Text>+</Text>
              </IconButton>
            )}
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </form>
  );
}
