/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { SettingsFields } from '@time-neko/frontend/domain/settings/field-definitions';
import { PrivacyLinks } from './PrivacyLinks';

export const privacyLinkSettingsItem: SettingsFields = {
  renderAs: 'plainComponent',
  Component: PrivacyLinks,
};
