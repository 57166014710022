/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { SerializedStyles } from '@emotion/react';
import { Theme } from '../types';
import { useTheme } from '../Theme.provider';
import { useMemo } from 'react';

export function useThemedCss(fn: (theme: Theme) => SerializedStyles) {
  const theme = useTheme();

  return useMemo(() => fn(theme), [theme]);
}
