/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export function isSafari() {
  const ua = navigator.userAgent;

  return (
    ua.includes('Safari') &&
    ua.includes('AppleWebKit') &&
    !ua.includes('Chrome')
  );
}

export function isSafariMobile() {
  return isSafari() && navigator.userAgent.includes('Mobile');
}
