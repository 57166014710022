/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import CatJuggling from '../../assets/CatJuggling.gif';
import { composeMedia } from '../composeMedia';

export const CatJugglingIcon = composeMedia({
  assetMap: {
    dark: CatJuggling,
    light: CatJuggling,
  },
  type: 'img',
  alt: 'Cat Juggling',
  className: 'cat-juggling',
});
