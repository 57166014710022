/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PomodoroSettings, PomodoroState, PomodoroStates } from '../types';

export const nextStateMap: Record<
  PomodoroStates,
  (
    pomodoroState: PomodoroState,
    pomodoroSettings: PomodoroSettings
  ) => PomodoroStates
> = {
  [PomodoroStates.LongBreak]: () => PomodoroStates.Work,
  [PomodoroStates.Break]: () => PomodoroStates.Work,
  [PomodoroStates.Work]: (
    pomodoroState: PomodoroState,
    pomodoroSettings: PomodoroSettings
  ) => {
    if (
      pomodoroSettings.longBreakInterval > 0 &&
      pomodoroState.shortBreakCount + 1 >= pomodoroSettings.longBreakInterval
    ) {
      return PomodoroStates.LongBreak;
    }

    return PomodoroStates.Break;
  },
};

export const propertyDurationMapForState: Record<
  PomodoroStates,
  keyof PomodoroSettings
> = {
  [PomodoroStates.Break]: 'shortBreakDurationSeconds',
  [PomodoroStates.LongBreak]: 'longBreakDurationSeconds',
  [PomodoroStates.Work]: 'workDurationSeconds',
};
