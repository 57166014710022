/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { defineSchema, operation } from '@musubi/core';
import { z } from 'zod';
import {
  AppReleaseSchema,
  AppReleasesSchema,
  GetAppReleasesQuerySchema,
} from '@time-neko/shared/domain/updates';
import { GetReleaseParamsSchema, ListReleasesQuerySchema } from './releases';
import { UserFeedbackSchema } from '@time-neko/shared/domain/feedback';
import { AppPlatformSchema } from '@time-neko/shared/domain/types';

// Context passed from client to API
export const ApiClientContextValidator = z.object({
  platform: AppPlatformSchema,
  appVersion: z.string(),
});

export type ApiClientContext = z.infer<typeof ApiClientContextValidator>;

export const HealthCheckResultSchema = z.object({
  version: z.string(),
});

export type HealthCheckResult = z.infer<typeof HealthCheckResultSchema>;

export const apiSchema = defineSchema({
  queries: {
    healthCheck: operation.query.withResult(HealthCheckResultSchema),
    listReleases: operation.query
      .withPayload(ListReleasesQuerySchema)
      .withResult(z.array(AppReleaseSchema)),
    getRelease: operation.query
      .withPayload(GetReleaseParamsSchema)
      .withResult(AppReleaseSchema.optional().nullable()),
    getAppReleases: operation.query
      .withPayload(GetAppReleasesQuerySchema)
      .withResult(AppReleasesSchema),
  },
  events: {},
  commands: {
    sendFeedback: operation.command.withPayload(UserFeedbackSchema),
    getNotionToken: operation.command
      .withPayload(
        z.object({
          code: z.string(),
          redirectUrl: z.string().url(),
        })
      )
      .withResult(z.object({ token: z.string() })),
  },
});
