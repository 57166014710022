/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import type { Subject } from 'rxjs';
import type { OmitFunctions } from './base';
import type { ReadonlyDeep } from 'type-fest';
import { z } from 'zod';

export interface Changable {
  onChange?: () => void | Promise<void>;
}

export type Subscriber<T> = (
  handler: (entity: Readonly<T>) => void | Promise<void>
) => () => void;

export function createChangedPayloadSchema<T extends z.Schema>(schema: T) {
  return z.object({
    currentValue: schema,
    previousValue: schema,
  });
}

export interface ChangedPayload<T, P = T> {
  currentValue: T;
  previousValue: P extends object ? OmitFunctions<ReadonlyDeep<P>> : P;
}

export interface ChangeSubject<T, P = T> {
  changed$: Subject<ChangedPayload<T, P>>;
}
