/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { NotionDatabaseProperty, NotionDatabasePropertyType } from './types';
import { NotionDatabaseFilterOperator } from './operators.types';
import type { DatabaseObjectResponse } from '@notionhq/client/build/src/api-endpoints';

const selectLikeTypes = [
  'select',
  'multi_select',
  'status',
] satisfies NotionDatabasePropertyType[];

const operatorsWithoutValues = [
  NotionDatabaseFilterOperator.IsEmpty,
  NotionDatabaseFilterOperator.IsNotEmpty,
] satisfies NotionDatabaseFilterOperator[]

const textOperators: NotionDatabaseFilterOperator[] = [
  NotionDatabaseFilterOperator.Contains,
  NotionDatabaseFilterOperator.DoesNotContain,
  NotionDatabaseFilterOperator.EndsWith,
  NotionDatabaseFilterOperator.StartsWith,
  NotionDatabaseFilterOperator.Equals,
  NotionDatabaseFilterOperator.DoesNotEqual,
  NotionDatabaseFilterOperator.IsEmpty,
  NotionDatabaseFilterOperator.IsNotEmpty,
];

const selectOperators: NotionDatabaseFilterOperator[] = [
  NotionDatabaseFilterOperator.Equals,
  NotionDatabaseFilterOperator.DoesNotEqual,
  NotionDatabaseFilterOperator.IsEmpty,
  NotionDatabaseFilterOperator.IsNotEmpty,
];

const numberOperators: NotionDatabaseFilterOperator[] = [
  NotionDatabaseFilterOperator.Equals,
  NotionDatabaseFilterOperator.DoesNotEqual,
  NotionDatabaseFilterOperator.GreaterThan,
  NotionDatabaseFilterOperator.LessThan,
  NotionDatabaseFilterOperator.GreaterThanOrEqualTo,
  NotionDatabaseFilterOperator.LessThanOrEqualTo,
  NotionDatabaseFilterOperator.IsEmpty,
  NotionDatabaseFilterOperator.IsNotEmpty,
];

const operatorsForDatabaseProperty: Record<
  NotionDatabasePropertyType,
  NotionDatabaseFilterOperator[]
> = {
  url: textOperators,
  email: textOperators,
  rich_text: textOperators,
  title: textOperators,
  checkbox: selectOperators,
  select: selectOperators,
  multi_select: selectOperators,
  number: numberOperators,
  status: selectOperators,
  created_time: numberOperators,
  last_edited_time: numberOperators,
  date: numberOperators,
  created_by: [],
  files: [],
  formula: [],
  last_edited_by: [],
  people: [],
  phone_number: [],
  relation: [],
  rollup: [],
};

export function getOperatorsForProperty(property: NotionDatabasePropertyType) {
  return operatorsForDatabaseProperty[property];
}

export function isPropertySupported(property: NotionDatabasePropertyType) {
  return operatorsForDatabaseProperty[property]?.length > 0;
}

export function getSupportedProperties(
  properties: DatabaseObjectResponse['properties']
) {
  return Object.values(properties).filter((p) => isPropertySupported(p.type));
}

export function isSelectLike(
  type: NotionDatabasePropertyType
): type is typeof selectLikeTypes[number] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return selectLikeTypes.includes(type as any);
}

export function listOptions(property: NotionDatabaseProperty) {
  if(!isSelectLike(property.type) ) {
    return null
  }

  switch(property.type) {
    case 'select':
      return property.select.options;

    case 'multi_select':
      return property.multi_select.options;

    case 'status':
      return property.status.options;

    default:
      return null
  }
}

export function isOperatorWithoutValue(
  operator: NotionDatabaseFilterOperator
): operator is typeof operatorsWithoutValues[number] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return operatorsWithoutValues.includes(operator as any);
}
