/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ToastProps as BaseProps, UseToastOptions } from '@chakra-ui/react';
import { Alert } from '../alert/Alert';
import { Text } from '../../atoms';
import { ChakraProps } from '@chakra-ui/system';

export type ToastProps = Pick<BaseProps, 'render' | 'onClose'> &
  Pick<UseToastOptions, 'status' | 'title' | 'description' | 'isClosable'> &
  Pick<ChakraProps, 'sx'>;

export function Toast({
  onClose,
  status,
  title,
  description,
  isClosable,
  sx,
}: ToastProps) {
  return (
    <Alert
      isClosable={isClosable}
      status={status}
      onClose={onClose}
      title={title}
      hideIcon
      isCentered
      sx={sx}
    >
      <Text as="p" display="block" color="white">
        {description}
      </Text>
    </Alert>
  );
}
