/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren, useCallback, useState } from 'react';
import { DropdownMenuProps } from './DropdownMenu.types';
import { Menu, MenuButton, Portal } from '@chakra-ui/react';
import { MenuItemsRenderer } from '../menuItemsRenderer/MenuItemsRenderer';
import classNames from 'classnames';
import { MenuItemsStateProvider } from '../menuItemsRenderer/MenuItemsState.provider';
import { useControllableState } from '../../index';

export function DropdownMenu({
  menuButtonProps,
  menuProps,
  children,
  menuListProps,
  usePortal = true,
  ...props
}: PropsWithChildren<DropdownMenuProps>) {
  const [menuOpen, setMenuOpen] = useControllableState({
    defaultValue: false,
    value: menuProps?.isOpen,
    onChange: menuProps?.onOpenChange,
  });

  const [overflow, setOverflow] = useState<'hidden' | 'auto'>('auto');

  const contents = (
    <MenuItemsRenderer
      menuListProps={menuListProps}
      {...props}
      scrollContainerProps={{
        overflow,
        ...props.scrollContainerProps,
      }}
    />
  );

  const handleOpen = useCallback(() => {
    setOverflow('auto');

    menuProps?.onOpen?.();
    setMenuOpen(true);
  }, [menuProps, setMenuOpen]);

  const handleClose = useCallback(() => {
    setOverflow('hidden');

    menuProps?.onClose?.();
    setMenuOpen(false);
  }, [menuProps, setMenuOpen]);

  return (
    <MenuItemsStateProvider>
      <Menu
        autoSelect={false}
        {...menuProps}
        onOpen={handleOpen}
        onClose={handleClose}
        isOpen={menuOpen}
      >
        <MenuButton
          {...menuButtonProps}
          className={classNames('menu-btn', menuButtonProps?.className)}
        >
          {children}
        </MenuButton>
        {usePortal ? <Portal>{contents}</Portal> : contents}
      </Menu>
    </MenuItemsStateProvider>
  );
}
