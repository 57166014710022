/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/**
 * `percent` returns the percentage of `value` out of `total`
 * @param {number} value - The value to be converted to a percentage.
 * @param {number} total - The total number of items in the collection.
 */
export const percent = (value: number, total: number) => (value * 100) / total;
