/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { BreakKey, BreakSuggestion } from './types';
import { PomodoroStates } from '@time-neko/shared/domain/pomodoro';

export const breaks: BreakSuggestion[] = [
  {
    key: BreakKey.Workout,
    text: 'Do a light workout.',
    supportedPomodoroStates: [PomodoroStates.Break],
  },
  {
    key: BreakKey.Stretch,
    text: 'Stretch a little.',
    supportedPomodoroStates: [PomodoroStates.Break],
  },
  {
    key: BreakKey.SmellFlowers,
    text: 'Smell some flowers.',
    supportedPomodoroStates: [PomodoroStates.LongBreak],
  },
  {
    key: BreakKey.TakeANap,
    text: 'Take a nap or meditate.',
    supportedPomodoroStates: [PomodoroStates.LongBreak],
  },
  {
    key: BreakKey.LookAtTrees,
    text: 'Look at trees.',
    supportedPomodoroStates: [PomodoroStates.Break],
  },
];
