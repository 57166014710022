/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export interface FileSizeValidationParams<V> {
  maxSizeInBytes: number;
  extractFileSize: (value: V) => number;
}

export function makeFileSizeValidator<V>(params: FileSizeValidationParams<V>) {
  const getMessage = () => ({
    message: `File size must be less than ${
      params.maxSizeInBytes / 1_000_000
    } MB`,
  });

  const validator = (value: V) => {
    const fileSize = params.extractFileSize(value);

    if (fileSize > params.maxSizeInBytes) {
      return false;
    }

    return value;
  };

  return {
    validator,
    getMessage,
  };
}
