/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ChakraProps } from '@chakra-ui/system';

export const makeInputSize = (styles: ChakraProps) => ({
  addon: styles,
  field: styles,
});
