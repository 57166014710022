/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export const webAppChannelName = 'web-app';

export const getWebAppBroadcastChannel = () =>
  new BroadcastChannel(webAppChannelName);
