/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ChakraProps } from '@chakra-ui/system';
import { Box } from '@chakra-ui/react';
import classNames from 'classnames';

export type AbsoluteBackgroundProps = ChakraProps & { className?: string };

export function AbsoluteBackground(props: AbsoluteBackgroundProps) {
  return (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      left={0}
      userSelect="none"
      pointerEvents="none"
      {...props}
      className={classNames('absolute-background', props.className)}
    />
  );
}
