/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { HStack, IconButton } from '@time-neko/frontend/ui';
import { ChakraProps } from '@chakra-ui/system';
import { MaybePromise } from '@time-neko/shared/common-types';
import { ShortcutTooltip } from '@time-neko/frontend/keyboard-shortcuts/web';
import { Asset } from '@time-neko/frontend/assets';
import { memo } from 'react';

export interface PomodoroControlProps extends ChakraProps {
  isRunning?: boolean;
  onToggle: () => MaybePromise<void>;
}

export const PomodoroControl = memo(
  ({ isRunning, onToggle, ...props }: PomodoroControlProps) => (
    <HStack spacing={3}>
      <ShortcutTooltip
        label={isRunning ? 'Pause timer' : 'Start timer'}
        keyboardKey="togglePomodoro"
      >
        <IconButton
          transition="all .3s"
          boxSize="xl"
          aria-label="Toggle timer"
          variant="nes"
          id="control"
          colorScheme="secondary"
          onClick={onToggle}
          clickSound="click"
          {...props}
        >
          <Asset
            position="relative"
            left="1px"
            boxSize="2sm"
            name={isRunning ? 'PauseRounded' : 'Play'}
            sx={{
              '*': {
                fill: 'brand.iconPrimary',
              },
            }}
          />
        </IconButton>
      </ShortcutTooltip>
    </HStack>
  )
);
