/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { Routes, RoutesProps, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { css } from '@emotion/react';
import { Theme, useThemedCss } from '@time-neko/frontend/providers/theme';
import { Box } from '@time-neko/frontend/ui';

const styles = (theme: Theme) => css`
  .router-fade-in-enter {
    opacity: 0;
  }

  .router-fade-in-enter-active {
    opacity: 1;
    transition: ${theme.cssTransition('0.3s', ['opacity'])};
  }

  .router-fade-in-exit {
    opacity: 1;
  }

  .router-fade-in-exit-active {
    opacity: 0;
    transition: ${theme.cssTransition('0.3s', ['opacity'])};
  }
`;

export function AnimatedRoutes({ children, ...props }: RoutesProps) {
  const location = useLocation();

  const themedStyles = useThemedCss(styles);

  return (
    <Box css={themedStyles}>
      <TransitionGroup>
        <CSSTransition
          mountOnEnter
          unmountOnExit
          key={location.pathname}
          classNames="router-fade-in"
          timeout={300}
        >
          <Routes {...props}>{children}</Routes>
        </CSSTransition>
      </TransitionGroup>
    </Box>
  );
}
