/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { BaseMediaProps } from '../types';
import { composeMedia } from '../composeMedia';
import '../../assets/pointer-white.png';
import '../../assets/pointer-black.png';
import ArrowWhite from '../../assets/pointer-white.png';
import ArrowBlack from '../../assets/pointer-black.png';

type Direction = 'up' | 'left' | 'right' | 'down';

export interface ArrowIconProps extends BaseMediaProps {
  iconDirection?: Direction;
}

const directionTransforms: Record<Direction, number> = {
  up: 0,
  down: 180,
  right: -90,
  left: 90,
};

const getTransform = (direction: Direction) => {
  if (!direction) {
    return 0;
  }

  return directionTransforms[direction];
};

export const ArrowIcon = composeMedia<ArrowIconProps>({
  assetMap: {
    dark: ArrowWhite,
    light: ArrowBlack,
  },
  type: 'img',
  alt: '',
  additionalProps: (props) => ({
    transform: props.iconDirection
      ? `rotate(${getTransform(props.iconDirection)}deg)`
      : undefined,
  }),
});
