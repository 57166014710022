/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
import { Transition } from '@time-neko/frontend/providers/theme';
import { CSSTransition } from 'react-transition-group';
import {
  TransitionProps,
  TransitionStatus,
} from 'react-transition-group/Transition';
import { MutableRefObject, ReactNode, useRef } from 'react';
import { isFunction } from '@time-neko/shared/type-guards';

type BaseProps<T extends HTMLElement = HTMLElement> = Omit<
  TransitionProps<T>,
  'children' | 'nodeRef'
> & {
  children:
    | ReactNode
    | ((
        ref: MutableRefObject<T>,
        status: TransitionStatus,
        childProps?: Record<string, unknown>
      ) => ReactNode);
};

export type WebTransitionProps<T extends HTMLElement = HTMLElement> =
  BaseProps<T> &
    (
      | { classNames: string | CSSTransitionClassNames }
      | { transition: Transition }
    );
export function WebTransition<T extends HTMLElement = HTMLElement>({
  children,
  ...props
}: WebTransitionProps<T>) {
  const nodeRef = useRef<T>();

  const classNames =
    'transition' in props ? `transition-${props.transition}` : props.classNames;

  return (
    <CSSTransition
      {...(props as any)}
      nodeRef={nodeRef as MutableRefObject<HTMLElement>}
      classNames={classNames}
    >
      {(status, childProps) =>
        isFunction(children)
          ? children(nodeRef as MutableRefObject<T>, status, childProps)
          : children
      }
    </CSSTransition>
  );
}
