/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { composeMedia } from '../composeMedia';
import CatIdle from '../../assets/CatIdle.gif';

export const CatIdleIcon = composeMedia({
  assetMap: {
    dark: CatIdle,
    light: CatIdle,
  },
  type: 'img',
  alt: 'Cat Idle',
  className: 'cat-idle',
});
