/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { AppPlatform } from '@time-neko/shared/domain/types';
import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';
import { makeFileSizeValidator } from '@time-neko/shared/validation';

export enum FeedbackRating {
  Positive = 'Positive',
  Neutral = 'Neutral',
  Negative = 'Negative',
}

export enum UserFeedbackKind {
  Feedback = 'Feedback',
  BugReport = 'BugReport',
}

export const userFeedbackValidationConfig = {
  maxAttachments: 3,
  maxMessageLength: 500,
  maxAttachmentSize: 5_000_000,
} as const;

export type UserFeedbackAsset = z.infer<typeof UserFeedbackAssetSchema>;

const userFeedbackAssetFileValidator = makeFileSizeValidator<string>({
  extractFileSize: (value) => {
    if (!value) {
      return 0;
    }

    try {
      const rawData = value.split(',')[1];

      return rawData.length - (rawData.length / 8) * 2;
    } catch {
      return 0;
    }
  },
  maxSizeInBytes: userFeedbackValidationConfig.maxAttachmentSize,
});
export const UserFeedbackAssetSchema = z.object({
  fileName: z.string(),
  base64: z
    .string()
    .refine(
      userFeedbackAssetFileValidator.validator,
      userFeedbackAssetFileValidator.getMessage
    ),
});

export const UserFeedbackSchema = z.object({
  email: z.string().email().optional(),
  message: z.string().min(1).max(1000),
  name: z.string().min(1).max(100).optional(),
  rating: z.nativeEnum(FeedbackRating).optional(),
  attachments: z.array(UserFeedbackAssetSchema).optional(),
  platform: z.nativeEnum(AppPlatform),
  kind: z.nativeEnum(UserFeedbackKind),
  appVersion: z.string().min(1).max(100),
  recaptchaToken: z.string().min(1).max(1000).optional(),
});

export type UserFeedback = z.infer<typeof UserFeedbackSchema>;

export const feedbackSchema = defineSchema({
  commands: {
    sendFeedback: operation.command.withPayload(UserFeedbackSchema),
  },
  events: {},
  queries: {},
});
