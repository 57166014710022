/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */
import { createReactMusubi } from '@musubi/react';
import { integrationsSchema } from '@time-neko/shared/domain/integrations';

export const frontendIntegrationsSchema = createReactMusubi(integrationsSchema);
