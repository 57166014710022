/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { useCallback, useEffect, useState } from 'react';
import { useSpring, useTransform } from 'framer-motion';

type Phase = 'top' | 'right' | 'bottom' | 'left';

const transformer = (value: number) => `${value}%`;
const FINISHED_SPRING_VALUE = 100;

const phaseOrder: Phase[] = ['top', 'right', 'bottom', 'left'];
const revertPhaseOrder: Phase[] = [...phaseOrder].reverse();

export interface UseLongPressIndicatorProps {
  onLongPress?: () => void;
  onLongPressReverted?: () => void;
  onLongPressStart?: () => void;
}

export function useLongPressIndicator({
  onLongPressReverted,
  onLongPress,
  onLongPressStart,
}: UseLongPressIndicatorProps = {}) {
  const [isLongPress, setIsLongPress] = useState(false);
  const [isRevert, setIsRevert] = useState(false);

  const topSpring = useSpring(0);
  const rightSpring = useSpring(0);
  const bottomSpring = useSpring(0);
  const leftSpring = useSpring(0);

  const [phase, setPhase] = useState<Phase>('top');
  const getSpringForPhase = useCallback(
    (phase: Phase) => {
      switch (phase) {
        case 'top':
          return topSpring;

        case 'right':
          return rightSpring;

        case 'bottom':
          return bottomSpring;

        case 'left':
          return leftSpring;

        default:
          return null;
      }
    },
    [bottomSpring, leftSpring, rightSpring, topSpring]
  );
  const getNextPhase = useCallback(() => {
    const source = isRevert ? revertPhaseOrder : phaseOrder;

    const index = source.indexOf(phase);

    if (index === -1) {
      return null;
    }

    return source[index + 1];
  }, [isRevert, phase]);

  const longPressHandler = useCallback(() => {
    setIsLongPress(true);

    onLongPressStart?.();

    topSpring.set(FINISHED_SPRING_VALUE);
  }, [onLongPressStart, topSpring]);

  const startRevert = useCallback(() => {
    setIsRevert(true);
  }, []);

  const advance = useCallback(() => {
    const nextPhase = getNextPhase();

    if (!nextPhase) {
      if (!isRevert) {
        onLongPress?.();
      } else {
        onLongPressReverted?.();
        setIsRevert(false);
        setIsLongPress(false);
      }

      return;
    }

    const spring = getSpringForPhase(nextPhase);

    if (!spring) {
      return;
    }

    spring.set(isRevert ? 0 : FINISHED_SPRING_VALUE);
    setPhase(nextPhase);
  }, [
    getNextPhase,
    getSpringForPhase,
    isRevert,
    onLongPress,
    onLongPressReverted,
  ]);

  const handleSpringChange = useCallback(
    (value: number) => {
      const expectedFinishValue = isRevert ? 0 : FINISHED_SPRING_VALUE;

      if (value === expectedFinishValue) {
        advance();
      }
    },
    [advance, isRevert]
  );

  useEffect(() => {
    const springs = [topSpring, rightSpring, bottomSpring, leftSpring];

    const subs = springs.map((spring) => spring.onChange(handleSpringChange));

    return () => {
      subs.forEach((sub) => {
        sub();
      });
    };
  }, [
    bottomSpring,
    isRevert,
    leftSpring,
    rightSpring,
    topSpring,
    handleSpringChange,
  ]);

  useEffect(() => {
    if (isRevert) {
      const currentSpring = getSpringForPhase(phase);

      if (currentSpring) {
        currentSpring.stop();
        currentSpring.set(0);
      }
    }
  }, [getSpringForPhase, isRevert, phase]);

  return {
    topSpring: useTransform(topSpring, transformer),
    rightSpring: useTransform(rightSpring, transformer),
    bottomSpring: useTransform(
      bottomSpring,
      (value) => `-${FINISHED_SPRING_VALUE - value}%`
    ),
    leftSpring: useTransform(
      leftSpring,
      (value) => `${FINISHED_SPRING_VALUE - value}%`
    ),
    isLongPress,
    phase,
    longPressHandler,
    startRevert,
  };
}
