/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { HStack, StackProps, Text, VStack } from '@time-neko/frontend/ui';
import {
  PomodoroStateText,
  PomodoroStateTextProps,
} from '../pomodoroStateText/PomodoroStateText';

import { Timer, TimerProps } from '../timerBox/parts/Timer';
import {
  isLongBreakDisabled,
  PomodoroSettings,
  PomodoroState,
} from '@time-neko/shared/domain/pomodoro';

export interface PomodoroTimerProps extends Pick<TimerProps, 'editableProps'> {
  isEdit: boolean;
  hideStateText?: boolean;
  hidePomodoroStateOnEdit?: boolean;
  pomodoro?: PomodoroState;
  pomodoroSettings?: PomodoroSettings;
  innerStackProps?: StackProps;
  stateTextProps?: Pick<PomodoroStateTextProps, 'rightAddon'>;
}

export function PomodoroTimer({
  editableProps,
  hidePomodoroStateOnEdit,
  hideStateText,
  isEdit,
  pomodoro,
  pomodoroSettings,
  innerStackProps,
  stateTextProps,
}: PomodoroTimerProps) {
  return (
    <VStack
      width="100%"
      spacing={0}
      direction="column"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {!isEdit && (
        <HStack
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          {...innerStackProps}
        >
          {!isLongBreakDisabled(pomodoroSettings?.longBreakInterval) && (
            <Text
              height="3px"
              zIndex={2}
              className="pomodoro-count"
              color="white"
            >
              {pomodoro?.shortBreakCount}/{pomodoroSettings?.longBreakInterval}
            </Text>
          )}
          <PomodoroStateText
            isEdit={isEdit}
            hideStateText={hideStateText}
            hidePomodoroStateOnEdit={hidePomodoroStateOnEdit}
            pomodoro={pomodoro}
            {...stateTextProps}
          />
        </HStack>
      )}

      <HStack alignItems="center" justifyContent="center" width="100%">
        <Timer
          remainingSeconds={pomodoro?.remainingSeconds ?? 0}
          clockString={pomodoro?.clockString ?? ''}
          isEdit={isEdit}
          editableProps={{
            mb: isEdit ? 8 : 0,
            maxWidth: '240px',
            fontSize: '2xl',
            ...editableProps,
          }}
        />
      </HStack>
    </VStack>
  );
}
