/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PomodoroStates } from '@time-neko/shared/domain/pomodoro';
import { defineSchema, operation } from '@musubi/core';
import { z } from 'zod';

export enum BreakKey {
  Workout = 'Workout',
  Stretch = 'Stretch',
  TakeANap = 'TakeANap',
  LookAtTrees = 'LookAtTrees',
  SmellFlowers = 'SmellFlowers',
}

export type BreakSuggestion = z.infer<typeof BreakSuggestionSchema>;

export interface IgnoredBreak {
  ignoredOn: string;
}

export const BreakSuggestionSchema = z.object({
  key: z.nativeEnum(BreakKey),
  text: z.string(),
  supportedPomodoroStates: z.array(z.nativeEnum(PomodoroStates)),
});

export const breakSchema = defineSchema({
  queries: {
    getBreakSuggestion: operation.query.withResult(
      BreakSuggestionSchema.optional().nullable()
    ),
  },
  commands: {},
  events: {
    breakSuggestionChanged: operation.event.withPayload(
      BreakSuggestionSchema.optional()
    ),
  },
});
