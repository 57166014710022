/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { Box, BoxProps } from '@time-neko/frontend/ui';
import {
  OnboardingStep,
  useOnboarding,
  UseOnboardingParams,
} from '@time-neko/frontend/domain/onboarding/hooks';
import { Welcome } from './Welcome';
import { OnboardingFeatures } from './OnboardingFeatures';

export type OnboardingProps = UseOnboardingParams & BoxProps;

export function Onboarding({ onCompleted, ...props }: OnboardingProps) {
  const { step, skip, advance } = useOnboarding({ onCompleted });

  return (
    <Box
      w="100%"
      h="100%"
      px={6}
      pb={step === OnboardingStep.Welcome ? 6 : 0}
      {...props}
    >
      {step === OnboardingStep.Welcome && (
        <Welcome onNext={advance} onSkip={skip} />
      )}
      {step === OnboardingStep.Features && (
        <OnboardingFeatures onSkip={skip} onNext={advance} />
      )}
    </Box>
  );
}
