/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { FieldValues, UseFormReturn, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import { logger } from '@time-neko/shared/logger';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import fastDeepEqual from 'fast-deep-equal';
import clone from 'lodash.clonedeep';

export interface UseAutoSaveProps<T extends FieldValues> {
  onSubmit: SubmitHandler<T>;
  form: UseFormReturn<T>;
  ms?: number;
  enabled?: boolean;
}

const log = logger.getChild('useAutoSave');

export function useAutoSave<T extends FieldValues>({
  form: { handleSubmit, formState, control },
  onSubmit,
  ms = 1000,
  enabled = true,
}: UseAutoSaveProps<T>) {
  const onSubmitRef = useRef(onSubmit);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(() => {
      handleSubmit(onSubmitRef.current)();
    }, ms),
    []
  );

  const data = useWatch({
    control,
    exact: false,
  });
  const previousData = useRef(data);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const isEqual = fastDeepEqual(data, previousData.current);
    const changed = !isEqual;

    log.debug({
      isEqual,
      changed,
      data,
      previousData: previousData.current,
      isDirty: formState.isDirty,
    });

    if (changed && formState.isDirty) {
      previousData.current = clone(data);

      log.debug('Triggered auto save!');
      debouncedSave();
    }
  }, [data, formState.isDirty, debouncedSave, enabled]);

  useEffect(() => {
    onSubmitRef.current = onSubmit;
  }, [onSubmit]);
}
