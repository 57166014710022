/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { theme as chakraTheme } from '@chakra-ui/theme';
import { ThemeShadows } from '../types';

export type Shadows = ThemeShadows & { focusDarker: string };

export const shadowColor = chakraTheme.colors.gray['600'];

export const darkerShadowColor = chakraTheme.colors.gray['700'];

export const shadows: Shadows = {
  ...chakraTheme.shadows,
  active: 'inset 4px 4px #adafbc',
  focus: `0 0 0 3px ${shadowColor}`,
  focusDarker: `0 0 0 2px ${darkerShadowColor}`,
  focusVisible: `0 0 0 4px ${darkerShadowColor}`,
  hover: 'inset -4px -4px #adafbc',
  selected: `0 0 0 6px rgba(173, 175, 188, 0.3)`,
};
