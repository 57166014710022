/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseMediaProps, ColorAssetMap } from './types';
import { forwardRef, useMemo } from 'react';
import { BoxProps, Image, useColorMode, useTheme } from '@chakra-ui/react';
import { Theme } from '@time-neko/frontend/providers/theme';
import classNames from 'classnames';
import { useMediaComponent } from './providers/MediaComponent.provider';
import { chakra } from '@chakra-ui/system';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface BaseComposeIconParams<Props extends Record<string, any>> {
  assetMap: ColorAssetMap;
  additionalProps?: (
    passedProps: Props,
    theme: Theme
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => BoxProps & any;
  className?: string;
}

type ComposeIconParamsVariants =
  | {
      type: 'img';
      alt?: string;
    }
  | { type: 'video' }
  | {
      type: 'svg';
    };

export type ComposeIconParams<AdditionalProps extends Record<string, any>> =
  BaseComposeIconParams<AdditionalProps> & ComposeIconParamsVariants;

const Video = chakra('video');

export function composeMedia<Props extends BaseMediaProps>(
  params: ComposeIconParams<Props>
) {
  const Component = forwardRef<
    HTMLImageElement | HTMLVideoElement,
    Props & BoxProps
  >((props, ref) => {
    const mediaContext = useMediaComponent();
    const ImageComponent = mediaContext.ImageComponent ?? Image;
    const VideoComponent = mediaContext.VideoComponent ?? Video;

    const theme = useTheme() as Theme;
    const { colorMode: themeColorMode } = useColorMode();
    const colorMode = props.variant ?? themeColorMode ?? 'light';

    const additionalProps = useMemo(
      () =>
        params.additionalProps ? params.additionalProps(props, theme) : {},
      [props, theme]
    );

    const targetAsset = params.assetMap[colorMode];

    switch (params.type) {
      case 'img':
        return (
          <ImageComponent
            ref={ref}
            display="block"
            width={props.width}
            height={props.height}
            src={targetAsset as string}
            alt={params.alt}
            {...props}
            {...additionalProps}
            sx={{
              ...props.sx,
              ...additionalProps.sx,
              imageRendering: '-webkit-optimize-contrast',
            }}
            className={classNames(
              props.className,
              params.className,
              'app-icon-img'
            )}
          />
        );

      case 'video':
        return (
          <VideoComponent
            ref={ref as any}
            width={props.width}
            height={props.height}
            src={targetAsset as string}
            sx={{
              ...props.sx,
              ...additionalProps.sx,
            }}
            className={classNames(
              props.className,
              params.className,
              'app-video'
            )}
          />
        );

      default:
        return null;
    }
  });

  Component.displayName =
    params.type === 'img' && params.alt ? params.alt : 'Icon';

  return Component;
}
