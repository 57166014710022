/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren, ReactNode } from 'react';
import {
  Box,
  BoxProps,
  FormControl as BaseFormControl,
  FormControlProps as BaseFormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  IconButton,
  StackProps,
  useStyleConfig,
  VStack,
} from '@chakra-ui/react';
import { OmitUnderscored } from '@time-neko/shared/common-types';
import { Asset, AssetName } from '@time-neko/frontend/assets';
import { Tooltip } from '../tooltip/Tooltip';
import {
  FormControlStyles,
  FormControlVariant,
  useTheme,
} from '@time-neko/frontend/providers/theme';

export interface FormControlProps
  extends Omit<OmitUnderscored<BaseFormControlProps>, 'helperText'> {
  label?: string;
  helperText?: ReactNode;
  name?: string;
  error?: ReactNode;
  helperInTooltip?: boolean;
  helperMedia?: AssetName;
  contentBoxProps?: BoxProps;
  formLabelProps?: FormLabelProps;
  helperTextPlacement?: 'under-label' | 'end';
  labelStackProps?: StackProps;
  variant?: FormControlVariant;
  applyLabelWhiteSpaceStyles?: boolean;
  helperTextProps?: FormHelperTextProps;
}

export function FormControl({
  label,
  helperText,
  name,
  error,
  children,
  helperInTooltip,
  contentBoxProps,
  formLabelProps,
  helperTextPlacement = 'end',
  labelStackProps,
  variant = FormControlVariant.default,
  applyLabelWhiteSpaceStyles = true,
  helperTextProps,
  isDisabled,
  ...rest
}: PropsWithChildren<FormControlProps>) {
  const theme = useTheme();

  const styles = useStyleConfig('CustomFormControl', { variant }) as
    | FormControlStyles
    | undefined;

  const helperTextElement = helperText && !helperInTooltip && (
    <FormHelperText
      {...helperTextProps}
      sx={{
        pb: {
          base: helperTextPlacement === 'under-label' ? 2 : 0,
          sm: undefined,
        },
        ...helperTextProps?.sx,
      }}
    >
      {helperText}
    </FormHelperText>
  );

  return (
    <BaseFormControl
      isInvalid={Boolean(error)}
      {...rest}
      sx={{
        ...styles?.control,
        ...rest.sx,
      }}
    >
      {label && (
        <VStack
          alignItems="flex-start"
          flex={1}
          pr={helperTextPlacement === 'under-label' ? 4 : 0}
          {...labelStackProps}
          sx={{
            ...styles?.labelStack,
            ...labelStackProps?.sx,

            '& .form-control-label': applyLabelWhiteSpaceStyles
              ? {
                  whiteSpace: {
                    base: 'normal',
                    md: 'nowrap',
                  },
                }
              : {},
          }}
        >
          <FormLabel
            color="brand.textPrimary"
            htmlFor={name}
            className="form-control-label"
            {...formLabelProps}
            sx={{
              ...styles?.formLabel,
              ...formLabelProps?.sx,
            }}
            opacity={
              isDisabled ? theme.opacities.disabled : theme.opacities.normal
            }
          >
            {label}
          </FormLabel>
          {helperTextPlacement === 'under-label' && helperTextElement}
        </VStack>
      )}
      <Box
        display={helperInTooltip ? 'flex' : undefined}
        alignItems="center"
        {...contentBoxProps}
      >
        {children}
        {helperInTooltip && helperText && (
          <Tooltip label={helperText as string}>
            <IconButton
              left={1}
              variant="nes-ghost"
              aria-label="Field helper text"
            >
              <Asset
                sx={{
                  '& path': {
                    fill: 'brand.secondary',
                  },
                }}
                name="Info"
                width="23px"
                height="23px"
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {helperTextPlacement === 'end' && helperTextElement}
      {error && <FormErrorMessage width="100%">{error}</FormErrorMessage>}
    </BaseFormControl>
  );
}
