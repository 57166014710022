/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { defineSchema, operation } from '@musubi/core';
import { z } from 'zod';

export const CompleteOnboardingPayloadSchema = z.object({
  wasSkipped: z.boolean(),
});

export type OnboardingState = z.infer<typeof OnboardingStateSchema>;

export const OnboardingStateSchema = z.object({
  wasSkipped: z.boolean().optional(),
  completedAt: z.coerce.date().optional(),
});

export const onboardingSchema = defineSchema({
  queries: {
    getOnboardingState: operation.query.withResult(
      OnboardingStateSchema.optional()
    ),
  },
  events: {
    onboardingCompleted: operation.event.withPayload(OnboardingStateSchema),
  },
  commands: {
    completeOnboarding: operation.command
      .withPayload(CompleteOnboardingPayloadSchema)
      .withResult(OnboardingStateSchema),
  },
});
