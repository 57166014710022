/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';

export const NetworkStatusSchema = z.object({
  connected: z.boolean(),
});

export type NetworkStatus = z.infer<typeof NetworkStatusSchema>;

export const networkSchema = defineSchema({
  events: {
    networkStateChanged: operation.event.withPayload(NetworkStatusSchema),
  },
  queries: {},
  commands: {
    setNetworkState: operation.command.withPayload(NetworkStatusSchema),
  },
});
