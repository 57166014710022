/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { css } from '@emotion/react';
import { Theme } from '../types';

export enum Transition {
  FadeIn = 'fade-in',
  FadeInGrow = 'fade-in-grow',
  Slide = 'slide',
}

export const animations = (theme: Theme) => css`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(deg);
    }

    to {
      transform: rotate(-360deg);
    }
  }

  @keyframes blink-alt {
    0% {
      opacity: 0;
    }
    49% {
      opacity: 0;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  .animation-rotate {
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .animation-shake {
    animation-name: shake;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: ${theme.cssTransition()};
  }

  .animation-blink {
    transition: none;
    animation-name: blink-alt;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ${theme.cssTransition()};
  }

  .animation-fade-in {
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: ${theme.cssTransition()};
  }

  .transition-${Transition.FadeIn}-enter {
    opacity: 0;
  }

  .transition-${Transition.FadeIn}-enter-done {
    opacity: 1;
    transition: ${theme.cssTransition('0.3s', ['opacity'])};
  }

  .transition-${Transition.FadeIn}-exit {
    opacity: 1;
  }

  .transition-${Transition.FadeIn}-exit-done {
    opacity: 0;
    transition: ${theme.cssTransition('0.3s', ['opacity'])};
  }

  .transition-${Transition.Slide}-enter {
    transform: translateX(-100%);
    opacity: 0;
  }

  .transition-${Transition.Slide}-enter-done {
    transform: translateX(0);
    transition: ${theme.cssTransition('0.3s', ['transform', 'opacity'])};
    opacity: 1;
  }

  .transition-${Transition.Slide}-exit {
    transform: translateX(0);
    opacity: 1;
  }

  .transition-${Transition.Slide}-exit-done {
    transform: translateX(-100%);
    transition: ${theme.cssTransition('0.3s', ['transform', 'opacity'])};
    opacity: 0;
  }

  .transition-${Transition.FadeInGrow}-enter {
    height: 0;
    opacity: 0;
  }

  .transition-${Transition.FadeInGrow}-enter-done {
    height: var(--height);
    opacity: 1;
    transition: ${theme.cssTransition('0.3s', ['height', 'opacity'])};
  }

  .transition-${Transition.FadeInGrow}-exit {
    height: var(--height);
    opacity: 1;
  }

  .transition-${Transition.FadeInGrow}-exit-done {
    height: 0;
    opacity: 0;
    transition: ${theme.cssTransition('0.3s', ['height', 'opacity'])};
  }
`;
