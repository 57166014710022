/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { getPrivacyLinks } from '@time-neko/frontend/domain/tracking/common';
import { ExternalLink, HStack } from '@time-neko/frontend/ui';

export function PrivacyLinks() {
  const links = getPrivacyLinks(process.env.NX_WEBSITE_URL as string);

  return (
    <HStack spacing={6}>
      {links.map((link) => (
        <ExternalLink
          iconProps={{
            boxSize: 'sm',
          }}
          key={link.url}
          href={link.url}
          target="_blank"
        >
          {link.label}
        </ExternalLink>
      ))}
    </HStack>
  );
}
