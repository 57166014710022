/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ChakraProps } from '@chakra-ui/system';
import {
  ComponentType,
  createContext,
  PropsWithChildren,
  Ref,
  useContext,
} from 'react';

export interface MediaComponentProps<Src> extends ChakraProps {
  src: Src;
  alt?: string;
  ref?: Ref<HTMLImageElement | HTMLVideoElement>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MediaComponent<Src = any> = ComponentType<MediaComponentProps<Src>>;

export interface MediaComponentContext<Src = string> {
  ImageComponent?: MediaComponent<Src>;
  VideoComponent?: MediaComponent<Src>;
}

const Context = createContext<MediaComponentContext>({});

export const useMediaComponent = <Src,>() =>
  useContext(Context) as unknown as MediaComponentContext<Src>;

export function MediaComponentProvider({
  children,
  ...value
}: PropsWithChildren<MediaComponentContext>) {
  return <Context.Provider value={value}>{children}</Context.Provider>;
}
