/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useRouterContext } from '../Router.provider';
import { useCallback } from 'react';

export const useNavigate = () => useRouterContext((store) => store.navigate);

export function useNavigateBack() {
  const navigate = useNavigate();

  return useCallback(
    (fallbackUrl: string) => {
      if (window.history.length > 1) {
        navigate(-1);
      } else {
        navigate(fallbackUrl);
      }
    },
    [navigate]
  );
}
