/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export const timeStringToSeconds = (time: string) => {
  const timeArr = time.split(':');

  let hours = 0;
  let minutes: number;
  let seconds: number;

  const getNumbers = () => time.split(':').map((value) => parseInt(value));

  if (timeArr.length === 3) {
    [hours, minutes, seconds] = getNumbers();
  } else {
    [minutes, seconds] = getNumbers();
  }

  return hours * 3600 + minutes * 60 + seconds;
};
