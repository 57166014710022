/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useCallback, useEffect, useState } from 'react';
import { Task, TaskState } from '@time-neko/shared/domain/tasks';

const maxDuration = 99;

export interface UseTaskListItemProps {
  task: Task;
  onTaskChange?: (task: Task) => unknown;
}

export function useTaskListItem({ task, onTaskChange }: UseTaskListItemProps) {
  const [title, setTitle] = useState(task.title);
  const [duration, setDuration] = useState(task.estimatedPomodoroDuration ?? 0);
  const [isDone, setIsDone] = useState(task.state === TaskState.Completed);

  const handleTaskChange = useCallback(
    <Key extends keyof Task>(
        key: Key,
        value: ((event: unknown) => Task[Key]) | Task[Key]
      ) =>
      (event: unknown) => {
        const valueToUse =
          typeof value === 'function'
            ? (value as (event: unknown) => unknown)(event)
            : value;

        if (valueToUse === false) {
          return;
        }

        const updatedTask = {
          ...task,
          [key]: valueToUse,
        };

        setIsDone(updatedTask.state === TaskState.Completed);

        if (onTaskChange) {
          onTaskChange(updatedTask);
        }
      },
    [onTaskChange, task]
  );

  const handleTitleChange = useCallback(
    (title: string) => {
      if (!title) {
        setTitle(task.title);

        return;
      }

      setTitle(title);

      if (onTaskChange) {
        onTaskChange({
          ...task,
          title,
        });
      }
    },
    [onTaskChange, task]
  );

  const handleDurationChange = useCallback(
    (val: string) => {
      let duration = parseInt(val);

      if (Number.isNaN(duration)) {
        duration = 0;
      } else {
        duration = duration > maxDuration ? maxDuration : duration;
      }

      onTaskChange?.({
        ...task,
        estimatedPomodoroDuration: duration,
      });

      setDuration(duration > maxDuration ? maxDuration : duration);
    },
    [onTaskChange, task]
  );

  useEffect(() => {
    if (
      typeof task.estimatedPomodoroDuration === 'number' &&
      duration !== task.estimatedPomodoroDuration
    ) {
      setDuration(task.estimatedPomodoroDuration);
    }
  }, [duration, task.estimatedPomodoroDuration]);

  useEffect(() => {
    if (title !== task.title) {
      setTitle(task.title);
    }
  }, [task.title, title]);

  return {
    title,
    setTitle,
    duration,
    handleTaskChange,
    handleTitleChange,
    handleDurationChange,
    isDone,
  };
}
