/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useCallback } from 'react';
import {
  getInitialPomodoroState,
  PomodoroState,
} from '@time-neko/shared/domain/pomodoro';
import { frontendPomodoroSchema } from '@time-neko/frontend/domain/pomodoro/schema';
import { UseQueryOptions } from '@musubi/react';

export type UsePomodoroParams = Pick<
  UseQueryOptions<undefined, PomodoroState>,
  'refetchOnWindowFocus'
>;

export function usePomodoro(params: UsePomodoroParams = {}) {
  const query = frontendPomodoroSchema.getPomodoroState.useQuery({
    placeholderData: getInitialPomodoroState(),
    ...params,
  });

  const updateMutation = frontendPomodoroSchema.updatePomodoro.useCommand({
    onSuccess: ({ result }) => {
      if (!result) {
        return;
      }

      query.setQueryData(result);
    },
  });

  const update = useCallback(
    async (
      update:
        | Partial<PomodoroState>
        | ((prev: PomodoroState) => Partial<PomodoroState>)
    ) => {
      if (!query.data && typeof update === 'function') {
        throw new Error('Cannot update before query is resolved');
      }

      const payload =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        typeof update === 'function' ? update(query.data!) : update;

      return updateMutation.mutateAsync(payload);
    },
    [query, updateMutation]
  );

  const toggle = useCallback(async () => {
    await update((prev) => ({
      ...prev,
      isRunning: !prev?.isRunning,
    }));
  }, [update]);

  const startRunning = useCallback(async () => {
    await update({
      isRunning: true,
    });
  }, [update]);

  const setRemainingSeconds = useCallback(
    async (seconds: number) => {
      await update({
        remainingSeconds: seconds,
      });
    },
    [update]
  );

  const addSeconds = useCallback(
    async (seconds: number) => {
      await update((prev) => ({
        ...prev,
        remainingSeconds: prev.remainingSeconds + seconds,
      }));
    },
    [update]
  );

  const subtractSeconds = useCallback(
    async (seconds: number) => {
      await update((prev) => ({
        ...prev,
        remainingSeconds: prev.remainingSeconds - seconds,
      }));
    },
    [update]
  );

  return {
    isPlaceholderData: query.isPlaceholderData,
    pomodoro: query.data ?? null,
    update,
    loading: query.isLoading,
    toggle,
    setRemainingSeconds,
    addSeconds,
    subtractSeconds,
    startRunning,
  };
}
