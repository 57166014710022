/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { Box, CenterContainer, Loading, Text } from '@time-neko/frontend/ui';
import { create } from 'zustand';
import { WebTransition } from '@time-neko/frontend/web-transition';
import { Transition } from '@time-neko/frontend/providers/theme';

export interface SplashStore {
  isLoaded: boolean;
  setIsLoaded: (isLoaded: boolean) => void;
}

export const useSplashStore = create<SplashStore>((set) => ({
  isLoaded: false,
  setIsLoaded: (isLoaded) => set({ isLoaded }),
}));

export interface SplashProps {
  isAbsolute?: boolean;
}

function SplashContent() {
  return (
    <CenterContainer id="webAppLoading">
      <Loading boxSize="md" />
      <Text fontSize="lg" mt={6}>
        Waking up the cats...
      </Text>
    </CenterContainer>
  );
}

export function Splash({ isAbsolute }: SplashProps) {
  const isLoaded = useSplashStore((state) => state.isLoaded);

  return (
    <WebTransition<HTMLDivElement>
      in={!isLoaded}
      timeout={500}
      transition={Transition.FadeIn}
    >
      {(nodeRef) => (
        <div ref={nodeRef}>
          {!isAbsolute && <SplashContent />}
          {isAbsolute && (
            <Box
              zIndex={20}
              position="fixed"
              left={0}
              top={0}
              width="100%"
              height="100%"
            >
              <SplashContent />
            </Box>
          )}
        </div>
      )}
    </WebTransition>
  );
}
