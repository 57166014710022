/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';

import { NestedMenuGroupItemProps } from '../../shared/menu.types';
import { MenuItemsRenderer } from './MenuItemsRenderer';
import { ChakraProps } from '@chakra-ui/system';

export interface SubmenuItemRendererProps extends ChakraProps {
  item: NestedMenuGroupItemProps;
}

export const SubmenuItemRenderer = ({
  item,
  ...props
}: SubmenuItemRendererProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { label, items, placement, icon, ...rest } = item;

  return (
    <MenuItem
      closeOnSelect={false}
      {...(rest as unknown as MenuItemProps)}
      onMouseLeave={onClose}
      {...props}
    >
      <Menu isOpen={isOpen} onClose={onClose} placement={placement}>
        <MenuButton cursor="inherit" onMouseOver={onOpen}>
          <Box position="relative" top="2px" as="span" mr={3}>
            {icon}
          </Box>
          {label}
        </MenuButton>
        <Portal>
          <MenuItemsRenderer items={items} />
        </Portal>
      </Menu>
    </MenuItem>
  );
};
