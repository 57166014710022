/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export const removeWhitespace = (text: string) => text.replace(/\s/g, '');

export function matchWildcard(rule: string, text: string) {
  const regex = new RegExp(`^${rule.replace(/\*/g, '.*')}$`);

  return regex.test(text);
}

export function includesMany(str: string, matches: string[]) {
  return matches.every((match) => str.includes(match));
}
