/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PomodoroStates } from '@time-neko/shared/domain/pomodoro';
import { BreakSuggestion } from '@time-neko/shared/domain/break';
import {
  CatAndDeskIcon,
  CatIdleIcon,
  CatReadsBook,
} from '@time-neko/frontend/assets';
import { breakSuggestionMediaMap } from '@time-neko/frontend/domain/breaks';
import { ComponentType } from 'react';
import { randomArrayElement } from '@time-neko/shared/utils';

interface GetTimerBoxIconParams {
  pomodoroState?: PomodoroStates;
  breakSuggestion?: BreakSuggestion;
}

const localStorageKeys = {
  workIcon: 'workIcon',
  previousWorkIcon: 'previousWorkIcon',
};

const workIconComponents: Record<string, ComponentType> = {
  [CatAndDeskIcon.displayName!]: CatAndDeskIcon,
  [CatIdleIcon.displayName!]: CatIdleIcon,
  [CatReadsBook.displayName!]: CatReadsBook,
};

export function getTimerBoxIcon({
  pomodoroState,
  breakSuggestion,
}: GetTimerBoxIconParams): ComponentType<{ className?: string }> | null {
  if (!pomodoroState) {
    return null;
  }

  const cachedWorkIconName = localStorage.getItem(localStorageKeys.workIcon);
  const cachedWorkIcon = cachedWorkIconName
    ? workIconComponents[cachedWorkIconName]
    : null;

  if (pomodoroState === PomodoroStates.Work) {
    if (cachedWorkIcon) {
      return cachedWorkIcon;
    }

    const previousWorkIcon = localStorage.getItem(
      localStorageKeys.previousWorkIcon
    );

    const icon =
      randomArrayElement(
        [CatAndDeskIcon, CatIdleIcon, CatReadsBook],
        (icon) => icon?.displayName !== previousWorkIcon
      ) ?? null;

    if (icon?.displayName) {
      localStorage.setItem(localStorageKeys.workIcon, icon.displayName);
    }

    return icon as ComponentType<{ className?: string }>;
  }

  if (cachedWorkIconName) {
    localStorage.setItem(localStorageKeys.previousWorkIcon, cachedWorkIconName);
  }

  localStorage.removeItem(localStorageKeys.workIcon);

  return breakSuggestion ? breakSuggestionMediaMap[breakSuggestion.key] : null;
}
