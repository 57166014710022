/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  BlockedSiteRule,
  maybeAddProtocol,
} from '@time-neko/shared/domain/sites-blocking';
import {
  Checkbox,
  CheckboxGroup,
  HStack,
  IconButton,
  Image,
  Text,
  Tooltip,
  VStack,
} from '@time-neko/frontend/ui';
import { useMemo } from 'react';
import {
  pomodoroStateDictionary,
  PomodoroStates,
} from '@time-neko/shared/domain/pomodoro';

const pomodoroStates = Object.values(PomodoroStates);

export interface BlockedSiteEntryProps {
  name: string;
  value: BlockedSiteRule;
  onRemove?: () => unknown;
  onChange?: (value: BlockedSiteRule) => unknown;
}

export const BlockedSiteEntry = ({
  value,
  onRemove,
  onChange,
}: BlockedSiteEntryProps) => {
  const url = useMemo(() => new URL(maybeAddProtocol(value.url)), [value.url]);
  const faviconUrl = `https://s2.googleusercontent.com/s2/favicons?domain=${url.origin}`;

  return (
    <VStack className="blocked-site-entry" w="100%">
      <HStack w="100%" spacing={2}>
        <Image
          sx={{
            imageRendering: 'pixelated',
          }}
          src={faviconUrl}
        />
        <Text className="url">{value.url}</Text>
        <Tooltip label="Remove item" shouldWrapChildren={false}>
          <IconButton
            marginLeft="auto !important"
            variant="nes-ghost"
            onClick={onRemove}
            aria-label="Remove url"
            className="remove-entry"
          >
            <Text>X</Text>
          </IconButton>
        </Tooltip>
      </HStack>
      <VStack alignItems="flex-start" position="relative" right="2px" w="100%">
        <Text>Blocked on:</Text>
        <CheckboxGroup
          value={value.blockOnPomodoroStates}
          onChange={(blockOn) => {
            onChange?.({
              ...value,
              blockOnPomodoroStates: blockOn as PomodoroStates[],
            });
          }}
        >
          {pomodoroStates.map((pomodoroState) => (
            <Checkbox
              key={pomodoroState}
              value={pomodoroState}
              className="pomodoro-state-checkbox"
            >
              <Text whiteSpace="nowrap">
                {pomodoroStateDictionary[pomodoroState]}
              </Text>
            </Checkbox>
          ))}
        </CheckboxGroup>
      </VStack>
    </VStack>
  );
};
