/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Task, TaskState } from './types/tasks';

export const ACTIVE_TASK_INDEX = 0;

export function isTaskActive(task: Pick<Task, 'index' | 'state'>) {
  return Boolean(
    task.index === ACTIVE_TASK_INDEX && task.state === TaskState.Todo
  );
}
