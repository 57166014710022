/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useMemo } from 'react';
import { useSettingsMenuItems } from '@time-neko/frontend/domain/settings';
import { MenuItemProperties } from '@time-neko/frontend/ui';
import { useStatisticsMenu } from '@time-neko/frontend/domain/statistics';
import { getPlatformFromNavigator } from '@time-neko/frontend/common';
import { useFeedbackMenuItems } from '@time-neko/frontend/domain/feedback/hooks';
import { useIsPreview } from './useIsPreview';
import { Asset } from '@time-neko/frontend/assets';
import { useIsSmallMobile } from '@time-neko/frontend/responsive';

export function useAppMenu() {
  const isPreview = useIsPreview();
  const statisticsMenuItems = useStatisticsMenu();
  const feedbackItems = useFeedbackMenuItems();

  const settingsMenu = useSettingsMenuItems({
    platform: getPlatformFromNavigator(),
  });

  const isMobile = useIsSmallMobile();

  return useMemo<MenuItemProperties[]>(() => {
    const items: MenuItemProperties[] = [
      ...(isMobile ? [] : [settingsMenu.openSettings]),
      ...statisticsMenuItems,
      ...feedbackItems,
    ];

    if (isPreview) {
      items.unshift({
        renderAs: 'item',
        id: 'open_in_new_tab',
        label: 'Open in new tab',
        onClick: () => {
          window.open(window.location.origin, '_blank');
        },
        icon: (
          <Asset
            boxSize="sm"
            name="External"
            sx={{
              '*': {
                fill: 'brand.iconPrimary',
              },
            }}
          />
        ),
      });
    }

    return items;
  }, [isMobile, feedbackItems, isPreview, settingsMenu, statisticsMenuItems]);
}
