/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef, useMemo } from 'react';
import { Box, BoxProps, useStyleConfig, useToken } from '@chakra-ui/react';
import { useTheme } from '@time-neko/frontend/providers/theme';
import classNames from 'classnames';

export const Frame = forwardRef<HTMLElement, BoxProps>(
  ({ children, sx, borderColor, className, ...props }, ref) => {
    const styles = useStyleConfig('Frame');
    const { getBorderImageSource } = useTheme();

    const parsedBorderColor = useToken(
      'colors',
      borderColor?.toString() ?? '',
      ''
    );

    const borderStyles = useMemo(() => {
      if (!parsedBorderColor) {
        return {};
      }

      return {
        borderImageSource: getBorderImageSource(parsedBorderColor),
      };
    }, [parsedBorderColor, getBorderImageSource]);

    return (
      <Box
        ref={ref}
        padding={0}
        borderColor="transparent"
        {...props}
        className={classNames(className, 'frame')}
        sx={{
          ...styles,
          ...borderStyles,
          ...sx,
        }}
      >
        {children}
      </Box>
    );
  }
);
