/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { MutableRefObject } from 'react';
import { useEvent } from 'react-use';

export function useResizeScrollReset(
  ref: MutableRefObject<HTMLElement | undefined>
) {
  useEvent('resize', () => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  });
}
