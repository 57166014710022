/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { AppError } from './App.error';

export class RecaptchaError extends AppError {
  constructor(errorCodes: string[]) {
    super(`Recaptcha failed: ${errorCodes.join(', ')}`, {
      name: 'RecaptchaError',
    });
  }
}
