/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { HStack } from '@chakra-ui/react';
import {
  forwardRef,
  KeyboardEventHandler,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Radio, RadioProps } from '../../atoms/radio/Radio';
import classNames from 'classnames';
import { setRefValue } from '@time-neko/frontend/common';
import { ChakraProps } from '@chakra-ui/system';
import { useTheme } from '@time-neko/frontend/providers/theme';

export interface YesNoFieldProps
  extends Pick<RadioProps, 'name' | 'isChecked' | 'id'>,
    ChakraProps {
  isDisabled?: boolean;
  onChange?: (checked: boolean) => unknown;
  size?: string;
  yesLabel?: string;
  noLabel?: string;
}

export const YesNoField = forwardRef<HTMLInputElement, YesNoFieldProps>(
  (
    {
      name,
      isChecked,
      isDisabled,
      onChange,
      id,
      size = 'md',
      noLabel = 'No',
      yesLabel = 'Yes',
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    const yesRef = useRef<HTMLInputElement>();
    const noRef = useRef<HTMLInputElement>();

    const handleKeyPress = useCallback<KeyboardEventHandler>(
      (event) => {
        if (event.key === ' ') {
          const newChecked = !isChecked;
          const targetRef = newChecked ? yesRef : noRef;

          onChange?.(newChecked);

          targetRef.current?.focus();
        }
      },
      [isChecked, onChange]
    );

    useEffect(() => {
      const refValue = isChecked ? yesRef.current : noRef.current;

      if (ref) {
        setRefValue(ref, refValue);
      }
    }, [isChecked, ref]);

    return (
      <HStack
        spacing={2}
        id={id}
        alignItems="center"
        className={classNames({ isChecked })}
        {...props}
        sx={{
          ...props.sx,
          '&': {
            opacity: isDisabled
              ? theme.opacities.disabled
              : theme.opacities.normal,
          },
        }}
      >
        <input type="hidden" name={name} />
        <Radio
          isDisabled={isDisabled}
          labelProps={{
            h: size,
            fontSize: size,
          }}
          ref={yesRef as MutableRefObject<HTMLInputElement>}
          className="yes-option"
          name={name}
          label={yesLabel}
          isChecked={isChecked}
          onKeyDown={handleKeyPress}
          onChange={(event) => {
            onChange?.(event.target.checked);
          }}
        />
        <Radio
          isDisabled={isDisabled}
          labelProps={{
            h: size,
            fontSize: size,
          }}
          onKeyDown={handleKeyPress}
          ref={noRef as MutableRefObject<HTMLInputElement>}
          className="no-option"
          name={name}
          label={noLabel}
          isChecked={!isChecked}
          onChange={(event) => {
            onChange?.(!event.target.checked);
          }}
        />
      </HStack>
    );
  }
);
