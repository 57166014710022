/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Task } from '@time-neko/shared/domain/tasks';

export const getTaskDurationText = (task: Task) => {
  if (!task.estimatedPomodoroDuration) {
    return task.pomodoroSpent?.length?.toString() ?? '0';
  }

  return `${task.pomodoroSpent?.length ?? 0}/${task.estimatedPomodoroDuration}`;
};
