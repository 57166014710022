/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { frontendNoticesSchema } from '@time-neko/frontend/domain/notices/schema';

export function useIsNoticeDismissed(noticeId: string, isEnabled = true) {
  return frontendNoticesSchema.isNoticeDismissed.useQuery({
    variables: {
      noticeId,
    },
    enabled: isEnabled,
  });
}
