/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { castAsError } from '@time-neko/shared/utils';

export type TryResult<T> =
  | [error: Error, result: null]
  | [error: null, result: T];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function tryFn<Fn extends () => any>(fn: Fn): TryResult<ReturnType<Fn>> {
  try {
    const result = fn();

    return [null, result];
  } catch (error) {
    return [castAsError(error), null];
  }
}

export async function tryAsync<R>(promise: Promise<R>): Promise<TryResult<R>> {
  try {
    return [null, await promise];
  } catch (error) {
    return [castAsError(error), null];
  }
}
