/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/* eslint-disable @typescript-eslint/no-explicit-any,react-hooks/exhaustive-deps */
import {
  FormControlProps,
  NumberInput,
  NumberInputField,
  YesNoField,
} from '@time-neko/frontend/ui';
import { useCallback, useEffect, useRef } from 'react';
import { AudioSelect } from '@time-neko/frontend/domain/audio';
import { DurationField } from '@time-neko/frontend/form';
import { SettingsFormController } from '../SettingsFormController';
import { BlockedSitesField } from '@time-neko/frontend/domain/sites-blocking';
import {
  BaseSettingsFormField,
  SettingsFields,
} from '@time-neko/frontend/domain/settings/field-definitions';
import { usePrevious } from 'react-use';
import { SettingsFieldRendererProps } from '../SettingsForm.types';
import { useBreakpointValue } from '@chakra-ui/media-query';

export function SettingsFormFieldRenderer<
  Fields extends SettingsFields = SettingsFields
>({
  form,
  field,
  customRenderer,
  skippedFieldNames,
}: SettingsFieldRendererProps<Fields>) {
  if (!('name' in field)) {
    throw new TypeError('Unsupported field type');
  }

  const isSkipped = skippedFieldNames?.includes(field.name);

  const fieldPlacement = useBreakpointValue<
    FormControlProps['helperTextPlacement']
  >({
    base: 'end',
    sm: 'under-label',
  });

  const doingChangeRef = useRef(false);

  const Controller = field.FormController ?? SettingsFormController;

  const setValue = useCallback(
    (newValue: any) => form.setValue(field.name, newValue),
    [form.setValue, field.name]
  );

  const value = form.watch(field.name);
  const previousValue = usePrevious(value);

  useEffect(() => {
    if (
      !field.onChange ||
      isSkipped ||
      doingChangeRef.current ||
      value === previousValue
    ) {
      return;
    }

    doingChangeRef.current = true;

    (field as BaseSettingsFormField).onChange?.(value, setValue);

    doingChangeRef.current = false;
  }, [field.onChange, setValue, value, isSkipped, previousValue]);

  if (isSkipped) {
    return null;
  }

  return (
    <Controller
      form={form}
      name={field.name}
      label={field.label}
      helperText={field.helperText}
      helperInTooltip={field.helperInTooltip}
      rules={field.rules as any}
      helperTextPlacement={fieldPlacement}
      isDisabled={field.isDisabled}
    >
      {(props) => {
        switch (field.renderAs) {
          case 'yesNo':
            return (
              <YesNoField
                {...props.field}
                id={props.field.name}
                onChange={(checked) => props.field.onChange(checked)}
                isChecked={Boolean(props.field.value)}
                isDisabled={field.isDisabled}
              />
            );

          case 'audio':
            return <AudioSelect color="brand.textPrimary" {...props.field} />;

          case 'duration':
            return (
              <DurationField
                {...props.field}
                inputProps={{
                  isDisabled: field.isDisabled,
                }}
                width={{
                  base: '100%',
                  sm: '150px',
                }}
              />
            );

          case 'number':
            return (
              <NumberInput
                {...props.field}
                width={{
                  base: '100%',
                  sm: '150px',
                }}
              >
                <NumberInputField />
              </NumberInput>
            );

          case 'sites-blocking':
            return (
              <BlockedSitesField
                form={form}
                name={props.field.name}
                allowPathname={field.allowPathname}
              />
            );

          default:
            if (customRenderer) {
              return customRenderer(field, props);
            }

            throw new TypeError(`Unknown field type: ${field.renderAs}`);
        }
      }}
    </Controller>
  );
}
