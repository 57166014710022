/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PomodoroStates } from './types';

export const pomodoroStateDictionary = {
  [PomodoroStates.Work]: 'Work',
  [PomodoroStates.LongBreak]: 'Long Break',
  [PomodoroStates.Break]: 'Break',
};
