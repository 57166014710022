/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouterProvider, RouterState } from '@time-neko/frontend/router';

export function ReactRouterDomProvider({
  children,
}: PropsWithChildren<unknown>) {
  const navigate = useNavigate();
  const location = useLocation();

  const routerState = useMemo<RouterState>(
    () => ({
      navigate: (p) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        navigate(p as any, { preventScrollReset: true });
      },
      location: { ...location },
      useParams,
    }),
    [navigate, location]
  );

  return <RouterProvider {...routerState}>{children}</RouterProvider>;
}
