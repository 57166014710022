/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { EditableContentComponentProps, Text } from '@time-neko/frontend/ui';

export interface TaskTitleProps extends EditableContentComponentProps {
  taskTitle: string;
  isDone?: boolean;
}

export function TaskTitle({ taskTitle, isDone, ...props }: TaskTitleProps) {
  return (
    <Text
      transition="all .5s"
      textDecoration={isDone ? 'line-through' : 'none'}
      opacity={isDone ? 0.7 : 1}
      tabIndex={0}
      width="100%"
      textOverflow="ellipsis"
      overflow="hidden"
      color="brand.textPrimary"
      display="inline-block"
      {...props}
    >
      {taskTitle}
    </Text>
  );
}
