/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  Badge as BaseBadge,
  BadgeProps as BaseProps,
  useToken,
} from '@chakra-ui/react';

export function Badge({ colorScheme, ...props }: BaseProps) {
  const backgroundColor = useToken(
    'colors',
    (props.backgroundColor as string) ?? 'brand.itemBg'
  );

  return (
    <BaseBadge {...props} backgroundColor={backgroundColor}>
      <span className="content">{props.children}</span>
    </BaseBadge>
  );
}
