/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useConst } from '@chakra-ui/hooks';
import { useCallback, useState } from 'react';

export function useMetaThemeColor() {
  const element = useConst(
    document.querySelector<HTMLMetaElement>('meta[name=theme-color]')
  );

  const [color, setColor] = useState(element?.content);

  const setAttrColor = useCallback(
    (color: string) => {
      element?.setAttribute('content', color);

      setColor(color);
    },
    [element]
  );

  return [color, setAttrColor] as const;
}
