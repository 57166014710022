/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Time } from '@time-neko/shared/time';
import { frontendStatisticsSchema } from '@time-neko/frontend/domain/statistics/schema';

export function useDayStatistics() {
  const query = frontendStatisticsSchema.getDayStatistics.useQuery({
    refetchOnWindowFocus: true,
    refetchInterval: Time.fromMinutes(60).asMilliseconds,
    refetchIntervalInBackground: true,
  });

  frontendStatisticsSchema.dayStatisticsChanged.useEvent(
    ({ payload }) => {
      query.setQueryData(payload);
    },
    [query.setQueryData]
  );

  return query;
}
