/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { CacheSetOptions, CacheStorage, CacheValue } from './types';
import { isExpired } from './isExpired';

export class InMemoryCache<T> implements CacheStorage<T> {
  private readonly cache = new Map<
    keyof T,
    CacheValue<T[keyof T]> | undefined
  >();

  constructor(private readonly entriesLimit?: number) {}

  get<Key extends keyof T>(key: keyof T) {
    const result = this.cache.get(key);

    if (
      result?.options?.ttl &&
      isExpired(result.options?.ttl, result.createdOn)
    ) {
      this.delete(key);

      return undefined;
    }

    return result?.value as T[Key];
  }

  set<Key extends keyof T>(
    key: keyof T,
    value: T[Key],
    options?: CacheSetOptions
  ) {
    this.cache.set(key, {
      value,
      createdOn: new Date().getTime(),
      options,
    });

    this.maybeCleanupEntries();
  }

  has(key: string | keyof T): boolean {
    return this.cache.has(key as keyof T);
  }

  clear(): void {
    this.cache.clear();
  }

  delete(key: keyof T) {
    this.cache.delete(key);
  }

  private maybeCleanupEntries() {
    if (!this.entriesLimit) {
      return;
    }

    if (this.cache.size > this.entriesLimit) {
      const latestKey = this.findLatestEntryKey();

      if (latestKey) {
        this.cache.delete(latestKey);
      }
    }
  }

  private findLatestEntryKey(entries = Array.from(this.cache.entries())) {
    if (!entries.length) {
      return undefined;
    }

    const latestEntry = entries.reduce<
      [keyof T, CacheValue<T[keyof T]> | undefined]
    >((latestEntry, currentEntry) => {
      const [, entry] = currentEntry;

      if (!latestEntry) {
        return currentEntry;
      }

      if (
        entry?.createdOn &&
        latestEntry[1] &&
        entry.createdOn > latestEntry[1].createdOn
      ) {
        return currentEntry;
      }

      return latestEntry;
    }, entries[0]);

    return latestEntry?.[0];
  }

  get size() {
    return this.cache.size;
  }
}
