/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { composeMedia } from '../composeMedia';
import Image from '../../assets/CatSmellingFlowers.gif';

export const CatSmellingFlowers = composeMedia({
  type: 'img',
  assetMap: {
    light: Image,
    dark: Image,
  },
  className: 'cat-smelling-flowers',
});
