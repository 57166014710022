/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { defineSchema, operation } from '@musubi/core';
import { z } from 'zod';

export enum AudioName {
  Achievement1 = 'Achievment 1',
  Achievement2 = 'Achievment 2',
  Lose = 'Lose',
  Victory = 'Victory',
}

export type AudioData = z.infer<typeof AudioDataSchema>;

export const AudioDataSchema = z.object({
  name: z.nativeEnum(AudioName),
  fileName: z.string(),
});

export interface AudioPlayer<Ctx = unknown> {
  play(audio: string, ctx: Ctx): Promise<void>;
}

export const AudioNameSchema = z.string();

export const PlayAudioPayloadSchema = z.object({
  name: AudioNameSchema,
});

export type PlayAudioPayload = z.infer<typeof PlayAudioPayloadSchema>;

export const audioSchema = defineSchema({
  queries: {
    getAudios: operation.query.withResult(z.array(AudioDataSchema)),
  },
  commands: {
    playAudio: operation.command.withPayload(PlayAudioPayloadSchema),
    stopAudio: operation.command,
  },
  events: {
    failedToPlayAudio: operation.event.withPayload(PlayAudioPayloadSchema),
    playAudioRequested: operation.event.withPayload(PlayAudioPayloadSchema),
  },
});

export enum AudioOperations {
  PlayAudio = 'PlayAudio',
}
