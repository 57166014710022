/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren } from 'react';
import { useEditableTimer, UseEditableTimerProps } from './useEditableTimer';
import { TimerBox, TimerBoxProps } from '../timerBox/TimerBox';
import { useActiveTask } from '@time-neko/frontend/domain/tasks/hooks';
import { useBreakSuggestion } from '@time-neko/frontend/domain/breaks';
import { useGetSetting } from '@time-neko/frontend/domain/settings/hooks';
import { usePomodoro } from '@time-neko/frontend/domain/pomodoro/hooks';
import { NextStateBtn } from '../buttons/nextState/NextStateBtn';

export interface PomodoroTimerBoxProps
  extends Omit<
      TimerBoxProps,
      | 'valueAsSeconds'
      | 'isEdit'
      | 'pomodoro'
      | 'pomodoroSettings'
      | 'controlProps'
      | 'editableProps'
      | 'height'
    >,
    Pick<UseEditableTimerProps, 'onEditChange'> {
  height?: string;
}

export function PomodoroTimerBox({
  onEditChange,
  height = '170px',
  children,
  ...props
}: PropsWithChildren<PomodoroTimerBoxProps>) {
  const { pomodoro, toggle } = usePomodoro({
    refetchOnWindowFocus: true,
  });
  const { data: pomodoroSettings } = useGetSetting('pomodoroSettings', {
    refetchOnWindowFocus: true,
  });

  const { editableProps, valueAsSeconds } = useEditableTimer({
    onEditChange,
  });

  const { duration, query } = useActiveTask();
  const breakSuggestionQuery = useBreakSuggestion();

  const isLoading = !pomodoro || !pomodoroSettings;

  return (
    <TimerBox
      stateTextProps={{
        rightAddon: (
          <NextStateBtn
            color="white"
            className="no-drag"
            size="sm"
            variant="nes-ghost"
          />
        ),
      }}
      isLoading={isLoading}
      pomodoro={pomodoro ?? undefined}
      pomodoroSettings={pomodoroSettings}
      height={{
        base: '100%',
        sm: height,
      }}
      {...props}
      valueAsSeconds={valueAsSeconds}
      editableProps={editableProps}
      controlProps={{
        onToggle: toggle,
      }}
      imageProps={{
        breakSuggestion: breakSuggestionQuery.data ?? undefined,
        activeTask: query.data
          ? {
              ...query.data,
              durationText: duration,
            }
          : undefined,
      }}
    >
      {children}
    </TimerBox>
  );
}
