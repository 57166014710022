/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { Popover, PopoverProps } from '@time-neko/frontend/ui';
import { NoticeProps } from '@time-neko/frontend/domain/notices/common';
import {
  useDismissNotice,
  useIsNoticeDismissed,
} from '@time-neko/frontend/domain/notices/hooks';

export type NoticePopoverProps = PopoverProps & NoticeProps;

export function NoticePopover({ noticeId, ...props }: NoticePopoverProps) {
  const dismissMutation = useDismissNotice(noticeId);
  const isDismissed = useIsNoticeDismissed(noticeId);

  return (
    <Popover
      {...props}
      isDisabled={isDismissed.data}
      onClose={() => {
        dismissMutation.mutate({
          noticeId,
        });
      }}
    />
  );
}
