/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Dialog } from '@time-neko/frontend/ui';
import {
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useRef,
} from 'react';
import { useDialogState } from './state';
import { isReactNode } from '@time-neko/frontend/common';
import { isDialogHookProps } from './IsDialogHookProps';
import { omit } from '@fxts/core';

export const DialogProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { open, currentProps: dialogProps, setOpen } = useDialogState();

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const cancelRef = useRef<HTMLButtonElement>();

  return (
    <>
      {isReactNode(dialogProps) && open ? dialogProps : null}
      {isDialogHookProps(dialogProps) && (
        <Dialog
          {...omit(['maxWidth'], dialogProps as any)}
          leastDestructiveRef={cancelRef as MutableRefObject<HTMLButtonElement>}
          isOpen={Boolean(open && dialogProps)}
          onClose={closeDialog}
          closeOnOverlayClick={dialogProps.closeOnOverlayClick}
          closeOnEsc={dialogProps.closeOnEsc}
          footer={dialogProps.footer?.({
            leastDestructiveRef:
              cancelRef as MutableRefObject<HTMLButtonElement>,
            onClose: closeDialog,
          })}
          contentProps={{
            ...dialogProps?.contentProps,
            maxWidth: dialogProps?.contentProps?.maxWidth ?? '50vw',
          }}
        >
          {dialogProps.body}
        </Dialog>
      )}
      {children}
    </>
  );
};
