/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-non-null-assertion */
import { ReactNode, useMemo } from 'react';
import { FormControl, FormControlProps } from '../formControl/FormControl';
import {
  Controller,
  ControllerProps,
  FieldValues,
  get,
  Path,
  UseFormReturn,
} from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

export interface FormControllerProps<
  FormState extends FieldValues,
  Name extends Path<FormState> = Path<FormState>
> extends Omit<FormControlProps, 'name' | 'children'>,
    Pick<ControllerProps<FormState, Name>, 'rules'> {
  children: ControllerProps<FormState, Name>['render'];
  form: UseFormReturn<FormState>;
  defaultValue?: any;
  name: Name;
  extractError?: (errors: FieldErrors<FormState>, name: string) => ReactNode;
}

export function FormController<
  FormState extends FieldValues,
  Name extends Path<FormState> = Path<FormState>
>({
  children,
  defaultValue,
  form,
  rules,
  extractError,
  ...rest
}: FormControllerProps<FormState, Name>) {
  const error = useMemo(
    () =>
      extractError
        ? extractError(form.formState.errors, rest.name)
        : get(form.formState.errors, `${rest.name}.message`),
    [extractError, form.formState, rest.name]
  );

  return (
    <FormControl {...rest} error={rest.error ?? error}>
      <Controller
        name={rest.name}
        control={form.control}
        defaultValue={defaultValue}
        rules={rules}
        render={(props) => children!(props)}
      />
    </FormControl>
  );
}
