/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { frontendBreakSchema } from '@time-neko/frontend/domain/breaks/schema';

export function useBreakSuggestion() {
  const query = frontendBreakSchema.getBreakSuggestion.useQuery();

  // TODO(musubi-migration): test
  frontendBreakSchema.breakSuggestionChanged.useEvent(
    (data) => {
      query.setQueryData(data.payload ?? null);
    },
    [query.setQueryData]
  );

  return query;
}
