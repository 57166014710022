/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { theme as chakraTheme } from '@chakra-ui/theme';
import { PomodoroStates } from '@time-neko/shared/domain/pomodoro';
import { ColorMode } from '@chakra-ui/react';
import { ChakraProps } from '@chakra-ui/system';

export const danger = '#FC8181';
export const primaryScheme = chakraTheme.colors.blue;
const secondaryScheme = {
  ...chakraTheme.colors.yellow,
  600: '#FEAE34',
};
export const pomodoroColors: Record<PomodoroStates, string> = {
  [PomodoroStates.Work]: primaryScheme['400'],
  [PomodoroStates.Break]: chakraTheme.colors.green['300'],
  [PomodoroStates.LongBreak]: chakraTheme.colors.green['500'],
};

export type BrandColors = ReturnType<typeof createColors>['colors']['brand'];

export type ColorSchemeType = 'primary' | 'secondary' | 'danger' | 'success';

export type ColorSchemeFactory = (colorScheme: ColorSchemeType) => {
  styles: ChakraProps;
};

export type RawColorSchemeFactory = (
  colorScheme: ColorSchemeType
) => ColorScheme;

export interface ColorScheme {
  surface: string;
  shadowColor: string;
  darkerShadowColor: string;
  borderColor: string;
  contrast: string;
}

export function createColors(colorMode: ColorMode) {
  const textColor =
    colorMode === 'dark'
      ? chakraTheme.colors.gray['100']
      : chakraTheme.colors.gray['800'];

  const surface =
    colorMode === 'dark'
      ? chakraTheme.colors.black
      : chakraTheme.colors.gray['100'];

  const colors = {
    brand: {
      ...pomodoroColors,
      paper: '#eee6e6',
      borderColor: chakraTheme.colors.gray['900'],
      link: chakraTheme.colors.blue['500'],
      success: chakraTheme.colors.green['500'],
      successScheme: chakraTheme.colors.green,
      primary: primaryScheme['400'],
      primaryScheme: primaryScheme,
      secondary: '#FEAE34',
      secondaryScheme: secondaryScheme,
      colorModeContrast:
        colorMode === 'dark' ? '#FFFCFC' : chakraTheme.colors.white,
      textPrimary: textColor,
      textReverse:
        colorMode === 'light'
          ? chakraTheme.colors.white
          : chakraTheme.colors.black,
      textSecondary:
        colorMode === 'light'
          ? chakraTheme.colors.gray['700']
          : chakraTheme.colors.gray['400'],
      iconPrimary: textColor,
      danger,
      dangerScheme: chakraTheme.colors.red,
      warning: chakraTheme.colors.yellow['500'],
      info: primaryScheme['300'],
      bg: surface,
      bgReverse:
        colorMode === 'light'
          ? chakraTheme.colors.black
          : chakraTheme.colors.gray['100'],
      itemBg:
        colorMode === 'dark'
          ? chakraTheme.colors.gray['500']
          : chakraTheme.colors.white,
      bgSecondary:
        colorMode === 'dark'
          ? chakraTheme.colors.gray['800']
          : chakraTheme.colors.gray['200'],
    },
  };

  return { textColor, colors };
}
