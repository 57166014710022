/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { forwardRef, useCallback, useMemo } from 'react';
import {
  InputGroup,
  InputGroupProps,
  InputRightElement,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberStepper,
  Text,
} from '@time-neko/frontend/ui';
import { FormInputProps } from '../types';
import { Time } from '@time-neko/shared/time';
import { useTheme } from '@time-neko/frontend/providers/theme';

export interface DurationFieldProps
  extends FormInputProps<number>,
    Partial<Omit<InputGroupProps, 'onChange' | 'children'>> {
  inputProps?: Omit<NumberInputProps, 'value' | 'onChange'>;
}

const max = 9999;

export const DurationField = forwardRef<HTMLInputElement, DurationFieldProps>(
  (props, ref) => {
    const theme = useTheme();

    const { name, value, onChange, inputProps, ...rest } = props;

    const displayValue = useMemo(() => {
      return value ? Time.fromSeconds(value).asMinutes : '';
    }, [value]);

    const handleChange = useCallback(
      (value: string) => {
        if (!onChange) {
          return;
        }

        const parsed = parseInt(value);

        if (Number.isNaN(parsed)) {
          onChange(0);

          return;
        }

        if (parsed > max) {
          onChange(Time.fromMinutes(max).asSeconds);

          return;
        }

        onChange(Time.fromMinutes(parsed).asSeconds);
      },
      [onChange]
    );

    return (
      <InputGroup
        size="md"
        {...rest}
        opacity={
          inputProps?.isDisabled
            ? theme.opacities.disabled
            : theme.opacities.normal
        }
      >
        <NumberInput
          {...inputProps}
          value={displayValue}
          onChange={handleChange}
          precision={2}
          max={9999}
          ref={ref}
          width="100%"
        >
          <NumberInputField name={name} />
          <NumberStepper />
        </NumberInput>
        <InputRightElement
          sx={{
            '& .chakra-select__wrapper': {
              h: 'full',
            },
          }}
          h="full"
          padding={0}
          right="40px"
        >
          <Text>Min</Text>
        </InputRightElement>
      </InputGroup>
    );
  }
);
