/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { conformToMask } from 'text-mask-core';
import { trimRight } from '@time-neko/shared/utils';
import { timeStringToSeconds } from '@time-neko/shared/time';
import type { TimerEditableProps, TimerProps } from '../timerBox/parts/Timer';
import { usePomodoro } from '@time-neko/frontend/domain/pomodoro/hooks';

export interface UseEditableTimerResult
  extends Pick<TimerProps, 'editableProps'> {
  valueAsSeconds: number;
  editableProps: TimerEditableProps;
}

export interface UseEditableTimerProps {
  onEditChange?: (isEdit: boolean) => unknown;
}

export const useEditableTimer = ({
  onEditChange,
}: UseEditableTimerProps = {}): UseEditableTimerResult => {
  const { pomodoro, update, toggle, startRunning } = usePomodoro();

  const [isEdit, setIsEdit] = useState(false);

  const [wasPomodoroStopped, setWasPomodoroStopped] = useState(false);

  const [value, setValue] = useState(pomodoro?.clockString);
  const valueAsSeconds = useMemo(
    () => (value ? timeStringToSeconds(value) : 0),
    [value]
  );

  const handleSubmit = useCallback(
    async (value: string) => {
      const result = await update({
        clockString: value,
      });

      setValue(result.clockString);

      setIsEdit(false);
    },
    [update]
  );

  const handleEditChange = useCallback(
    async (isEdit: boolean) => {
      setIsEdit(isEdit);

      onEditChange?.(isEdit);

      if (isEdit && pomodoro?.isRunning) {
        await toggle();
        setWasPomodoroStopped(true);

        return;
      }

      if (!isEdit && wasPomodoroStopped) {
        await startRunning();
        setWasPomodoroStopped(false);
      }
    },
    [onEditChange, pomodoro, startRunning, toggle, wasPomodoroStopped]
  );

  useEffect(() => {
    setValue((v) => {
      return v === pomodoro?.clockString ? v : pomodoro?.clockString;
    });
  }, [pomodoro]);

  return {
    valueAsSeconds,
    editableProps: {
      value: value ?? '',
      displayValue: value ?? '',
      isEdit,
      onSubmit: handleSubmit,
      onEditChange: handleEditChange,
      formatValueOnChange: (value) => {
        const result = conformToMask(
          value,
          [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/],
          {
            guide: false,
          }
        );

        return trimRight(result.conformedValue, ':');
      },
      onChange: (value) => {
        setValue(value);
      },
    },
  };
};
