/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export function getPlatformFromNavigator(): NodeJS.Platform {
  if (process.env.NX_PLATFORM) {
    return process.env.NX_PLATFORM as NodeJS.Platform;
  }

  if (navigator.userAgent.indexOf('Mac') !== -1) return 'darwin';

  if (navigator.userAgent.indexOf('Win') !== -1) return 'win32';

  if (navigator.userAgent.indexOf('Linux') !== -1) return 'linux';

  return 'win32';
}

export const platformDisplayName: {
  [Key in NodeJS.Platform]?: string;
} = {
  darwin: 'Mac',
  linux: 'Linux',
  win32: 'Windows',
};
