/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputStepper,
} from '@chakra-ui/react';
import { Asset, assetDefinitions } from '@time-neko/frontend/assets';

export const NumberStepper = () => {
  const height = assetDefinitions.Arrow?.sizes?.sm?.height;
  const width = assetDefinitions.Arrow?.sizes?.sm?.width;

  const sizeProps = {
    width: `${width} !important`,
    height: `${height} !important`,
  };

  return (
    <NumberInputStepper>
      <NumberIncrementStepper border="none">
        <Asset name="Arrow" transform="rotate(-90deg)" {...sizeProps} />
      </NumberIncrementStepper>
      <NumberDecrementStepper border="none">
        <Asset name="Arrow" transform="rotate(90deg)" {...sizeProps} />
      </NumberDecrementStepper>
    </NumberInputStepper>
  );
};
