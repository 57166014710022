/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { CorePomodoroView } from '@time-neko/frontend/domain/core/web';
import { useAppMenu } from '../../../hooks/useAppMenu';
import { WebAppContainer } from '../../../components/WebAppContainer';
import { pomodoroStateDictionary } from '@time-neko/shared/domain/pomodoro';
import {
  usePomodoro,
  usePomodoroShortcuts,
} from '@time-neko/frontend/domain/pomodoro/hooks';
import { useIsSmallMobile } from '@time-neko/frontend/responsive';
import { TitleBar, TitleBarProps } from '@time-neko/frontend/ui';

function PomodoroTitleBar(props: TitleBarProps) {
  return <TitleBar {...props} isClosable={false} />;
}

export function PomodoroView() {
  usePomodoroShortcuts();

  const isMobile = useIsSmallMobile();

  const menuItems = useAppMenu();
  const { pomodoro } = usePomodoro();

  return (
    <WebAppContainer
      maxFrameWidth={550}
      maxFrameHeight={750}
      pageTitle={pomodoro?.clockString}
      pageTitlePrefix={
        pomodoro?.state ? pomodoroStateDictionary[pomodoro.state] : undefined
      }
      background="inherit"
    >
      <CorePomodoroView
        TitleBarComponent={PomodoroTitleBar}
        containerProps={{
          width: '100%',
          height: '100%',
        }}
        dropdownMenuProps={{
          items: menuItems,
        }}
        hideTasksList={isMobile}
      />
    </WebAppContainer>
  );
}
