/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import {
  CreateTaskInput,
  Task,
  tasksSchema,
} from '@time-neko/shared/domain/tasks';
import { useCallback } from 'react';
import { OperationSchemaOperations } from '@musubi/core';
import { frontendTasksSchema } from '@time-neko/frontend/domain/tasks/schema';
import { logger } from '@time-neko/shared/logger';

export interface CreateTaskHookProps {
  onCreate?: (task: Task) => unknown;
}

const log = logger.getChild('useCreateTask');

export function useCreateTask({ onCreate }: CreateTaskHookProps = {}) {
  const createTaskMutation = frontendTasksSchema.createTask.useCommand({
    onSuccess: ({ result: task }) => {
      if (task && onCreate) {
        onCreate(task);
      }
    },
    invalidateQueries: [
      ['getTasks' as OperationSchemaOperations<typeof tasksSchema>],
      ['countTasksByState' as OperationSchemaOperations<typeof tasksSchema>],
    ],
  });

  const createTask = useCallback(
    (input: CreateTaskInput) => {
      log.debug('Creating task', input);
      return createTaskMutation.mutateAsync(input);
    },
    [createTaskMutation]
  );

  return {
    createTask,
    isLoading: createTaskMutation.isLoading,
  };
}
