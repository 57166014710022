/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  ItemMenuItemProps,
  ItemMenuWithSubContentsProps,
} from '../../../shared/menu.types';
import { MenuItemRenderer, MenuItemRendererProps } from '../MenuItemRenderer';
import { PlainMenuItem } from './PlainMenuItem';
import { ChakraProps } from '@chakra-ui/system';

export interface MenuItemWithSubContentsProps
  extends ChakraProps,
    Pick<MenuItemRendererProps, 'highlightBackground'> {
  item: ItemMenuWithSubContentsProps;
}

export function MenuItemWithSubContents({
  item,
  highlightBackground,
  ...props
}: MenuItemWithSubContentsProps) {
  const { subContents, id, renderAs, ...rest } = item;

  return (
    <>
      <PlainMenuItem
        key={id}
        item={rest as Omit<ItemMenuItemProps, 'renderAs'>}
        {...props}
      />
      {subContents?.map((subContent) => (
        <MenuItemRenderer
          item={subContent}
          key={item.id}
          highlightBackground={highlightBackground}
          {...props}
        />
      ))}
    </>
  );
}
