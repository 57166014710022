/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { AppError } from './App.error';

export class StorageQuotaExceededError extends AppError {
  constructor(public readonly quota: number, public readonly usage: number) {
    super('Storage quota exceeded.', {
      name: 'StorageQuotaExceededError',
    });
  }
}
