/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { IconButton, IconButtonProps, Tooltip } from '@time-neko/frontend/ui';

import { Asset, AssetProps } from '@time-neko/frontend/assets';
import { OmitUnderscored } from '@time-neko/shared/common-types';
import classNames from 'classnames';
import {
  useKeyboardShortcut,
  useShouldShowKeyboardShortcut,
} from '@time-neko/frontend/keyboard-shortcuts';
import { usePomodoroNextState } from '@time-neko/frontend/domain/pomodoro/hooks';

export type NextStateBtnProps = Omit<
  OmitUnderscored<IconButtonProps>,
  'aria-label'
> & {
  iconProps?: Omit<AssetProps, 'name'>;
  iconColor?: string;
};

export function NextStateBtn({
  className,
  iconProps,
  iconColor = 'white',
  onClick,
  ...props
}: NextStateBtnProps) {
  const nextState = usePomodoroNextState();
  const key = useKeyboardShortcut('moveToNextPomodoroState');
  const shouldShowShortcut = useShouldShowKeyboardShortcut();

  return (
    <Tooltip
      placement="top-start"
      label={
        <>
          Move to <strong>{nextState.nextStateText}</strong>{' '}
          {shouldShowShortcut ? `(${key.display})` : ''}
        </>
      }
    >
      <IconButton
        variant="nes-outlined"
        className={classNames('move-to-next-state', className)}
        onClick={(event) => {
          nextState.moveToNextStateMutation.mutate(undefined);

          onClick?.(event);
        }}
        aria-label="Move to next state"
        {...props}
      >
        <Asset
          sx={{
            '& path, & rect': {
              fill: iconColor,
            },
          }}
          boxSize="sm"
          name="DoubleArrow"
          transform="rotate(90deg)"
          {...iconProps}
        />
      </IconButton>
    </Tooltip>
  );
}
