/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useCallback, useMemo } from 'react';
import { useApiAuthState } from '../hooks/useApiAuthState';
import {
  Button,
  ComingSoonBadge,
  DropdownMenu,
  HStack,
  MenuItemProperties,
  Stack,
  Text,
} from '@time-neko/frontend/ui';
import { Asset } from '@time-neko/frontend/assets';
import {
  integrationsSchema,
  IntegrationType,
} from '@time-neko/shared/domain/integrations';
import { useToast } from '@time-neko/frontend/toast';
import { FrontendIntegrationSectionDefinition } from '../types';
import { OperationSchemaOperations } from '@musubi/core';
import { frontendIntegrationsSchema } from '@time-neko/frontend/domain/integrations/schema';
import { getUserFriendlyError } from '@time-neko/shared/errors';

export interface IntegrationSectionProps {
  onStart?: () => unknown;
  onOpen?: (token: string, type: IntegrationType) => unknown;
  definition: FrontendIntegrationSectionDefinition;
}

export type IntegrationSectionComponentProps = Omit<
  IntegrationSectionProps,
  'definition'
>;

export function IntegrationSection({
  onStart,
  onOpen,
  definition,
}: IntegrationSectionProps) {
  const { label, type, icon, comingSoon } = definition;

  const {
    loading: authStateLoading,
    token,
    error,
  } = useApiAuthState(definition);

  const toast = useToast();

  const authorizeMutation = frontendIntegrationsSchema.authorizeApi.useCommand({
    variables: {
      type: type,
    },
    retry: false,
    onError: () => {
      toast.show({
        status: 'error',
        description: 'Failed to authorize',
        isClosable: true,
      });
    },
  });

  const unAuthorizeMutation =
    frontendIntegrationsSchema.unauthorizeApi.useCommand({
      invalidateQueries: [
        ['getApiToken' as OperationSchemaOperations<typeof integrationsSchema>],
      ],
      variables: {
        type: type,
      },
      onSuccess: () => {
        toast.show({
          status: 'success',
          description: 'Successfully un-authorized!',
          isClosable: true,
        });
      },
    });

  const handleBtnClick = useCallback(async () => {
    if (!token) {
      await authorizeMutation.mutateAsync({ type: type });

      if (onStart) {
        onStart();
      }

      return;
    }

    if (onOpen) {
      onOpen(token, type);
    }
  }, [token, onOpen, authorizeMutation, type, onStart]);

  const handleUnauthorize = useCallback(async () => {
    await unAuthorizeMutation.mutateAsync({
      type: type,
    });
  }, [type, unAuthorizeMutation]);

  const menuItems = useMemo<MenuItemProperties[]>(
    () => [
      {
        renderAs: 'item',
        id: `unauthorize_${type}`,
        className: `unauthorize-${type}`,
        label: 'Unauthorize',
        onClick: () => handleUnauthorize(),
      },
    ],
    [handleUnauthorize, type]
  );

  frontendIntegrationsSchema.apiAuthorized.useEvent(
    ({ payload }) => {
      if (payload.type !== type) {
        return;
      }

      toast.show({
        id: `api-authorized-${type}`,
        status: 'success',
        description: `Successfully authorized ${label.toLowerCase()}!`,
        isClosable: true,
      });
    },
    [type, toast]
  );

  frontendIntegrationsSchema.apiAuthError.useEvent(
    ({ payload }) => {
      if (payload.type !== type) {
        return;
      }

      toast.show({
        isClosable: true,
        status: 'error',
        description: `Failed to authorize ${type.toLowerCase()}: ${getUserFriendlyError(
          payload.error
        )}`,
      });
    },
    [type, toast]
  );

  return (
    <Stack spacing={2} direction="row" alignItems="center" width="100%">
      {icon}
      <Text>{label}</Text>
      <HStack
        alignItems="center"
        spacing={4}
        justifyContent="flex-end"
        flex="1"
      >
        {comingSoon && <ComingSoonBadge ml={4} marginRight="auto" />}
        {!comingSoon && (
          <>
            {error && (
              <Text maxWidth="50%" color="brand.danger">
                Failed to get integration status: {getUserFriendlyError(error)}
              </Text>
            )}
            {!error && (
              <Button
                leftIcon={
                  !token ? (
                    <Asset
                      name="External"
                      boxSize="sm"
                      sx={{
                        '*': {
                          fill: 'inherit',
                        },
                      }}
                    />
                  ) : undefined
                }
                width={token ? 150 : 197}
                className={`manage-${type}`}
                id={`manage_${type}`}
                isLoading={
                  authStateLoading ||
                  unAuthorizeMutation.isLoading ||
                  authorizeMutation.isLoading
                }
                onClick={handleBtnClick}
              >
                <Text>{token ? 'Manage' : 'Authorize'}</Text>
              </Button>
            )}
          </>
        )}
        {token && (
          <DropdownMenu
            items={menuItems}
            menuProps={{ isLazy: true }}
            menuButtonProps={{
              width: 'md',
              disabled: unAuthorizeMutation.isLoading,
              as: Button,
              className: `${type}-menu-btn`,
              variant: 'nes-ghost',
            }}
          >
            <Asset height="20px" width="7px" name="Ellipsis" />
          </DropdownMenu>
        )}
      </HStack>
    </Stack>
  );
}
