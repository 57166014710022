/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export type Platforms = typeof process.platform;

export const getBackendPlatform = (): NodeJS.Platform =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  typeof process !== 'undefined' ? process.platform : (window as any).platform;
