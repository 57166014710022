/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { frontendNoticesSchema } from '@time-neko/frontend/domain/notices/schema';
import { useIsNoticeDismissed } from './useIsNoticeDismissed';

export function useDismissNotice(noticeId: string) {
  const query = useIsNoticeDismissed(noticeId, false);

  return frontendNoticesSchema.dismissNotice.useCommand({
    onSuccess: async () => {
      query.setQueryData(true);
    },
    variables: {
      noticeId,
    },
  });
}
