/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useKeyboardShortcutHandler } from '@time-neko/frontend/keyboard-shortcuts';
import { useDomain } from '@time-neko/frontend/providers/domain';

export const useOpenSettingShortcut = () => {
  const openSettings = useDomain('openSettings');

  return useKeyboardShortcutHandler(
    'openSettings',
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      openSettings();
    },
    [openSettings]
  );
};
