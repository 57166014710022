/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { composeMedia } from '../composeMedia';
import Img from '../../assets/CatError.gif';

export const CatErrorIcon = composeMedia({
  type: 'img',
  assetMap: {
    dark: Img,
    light: Img,
  },
});
