/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { frontendPomodoroSchema } from '@time-neko/frontend/domain/pomodoro/schema';

export function usePomodoroListeners() {
  const pomodoroStateQuery = frontendPomodoroSchema.getPomodoroState.useQuery({
    enabled: false,
  });

  frontendPomodoroSchema.pomodoroUpdated.useEvent(
    ({ payload }) => {
      pomodoroStateQuery.setQueryData(payload.currentValue);
    },
    [pomodoroStateQuery.setQueryData]
  );
}
