/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { PlainSettingsComponentProps } from '@time-neko/frontend/domain/settings/field-definitions';
import {
  PomodoroReminder,
  pomodoroStateDictionary,
  PomodoroStates,
} from '@time-neko/shared/domain/pomodoro';
import { ReactNode } from 'react';
import {
  Checkbox,
  CheckboxGroup,
  FormController,
  Frame,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@time-neko/frontend/ui';

export interface PomodoroReminderSettingProps
  extends Pick<PlainSettingsComponentProps, 'form'> {
  index: number;
  value: PomodoroReminder;
  removeBtn?: ReactNode;
}

const pomodoroStates = Object.values(PomodoroStates);

export function PomodoroReminderSetting({
  removeBtn,
  form,
  index,
}: PomodoroReminderSettingProps) {
  return (
    <Frame
      width="100%"
      p={4}
      className="pomodoro-reminder-settings-item"
      data-index={index}
      sx={{
        backgroundColor: 'brand.bgSecondary',
      }}
    >
      <VStack alignItems="flex-start" width="100%" spacing={4}>
        <FormController
          width="100%"
          label="Reminder message"
          form={form}
          name={`pomodoroSettings.reminders.${index}.message`}
        >
          {(props) => (
            <InputGroup width="100%">
              <Input
                {...props.field}
                mr={12}
                placeholder="Drink some water..."
              />
              <InputRightElement>{removeBtn}</InputRightElement>
            </InputGroup>
          )}
        </FormController>
        <FormController
          form={form}
          name={`pomodoroSettings.reminders.${index}.showOnState`}
        >
          {(props) => (
            <CheckboxGroup {...props.field}>
              <VStack
                alignItems="flex-start"
                position="relative"
                width="100%"
                spacing={2}
              >
                <Text>Show reminder on:</Text>
                {pomodoroStates.map((pomodoroState) => (
                  <Checkbox
                    id={`${props.field.name}-${pomodoroState}`}
                    name={props.field.name}
                    className="pomodoro-state-checkbox"
                    key={pomodoroState}
                    value={pomodoroState}
                  >
                    <Text whiteSpace="nowrap">
                      {pomodoroStateDictionary[pomodoroState]}
                    </Text>
                  </Checkbox>
                ))}
              </VStack>
            </CheckboxGroup>
          )}
        </FormController>
      </VStack>
    </Frame>
  );
}
