/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { OmitUnderscored } from '@time-neko/shared/common-types';
import {
  Button,
  HStack,
  OptionSeparator,
  Select,
  SelectProps,
  Text,
} from '@time-neko/frontend/ui';
import { forwardRef } from 'react';
import { audioFrontendSchema } from '@time-neko/frontend/domain/audio/schema';
import { AudioName } from '@time-neko/shared/domain/audio';

export type AudioSelectProps = OmitUnderscored<SelectProps>;

const audios = Object.values(AudioName);

export const AudioSelect = forwardRef<HTMLSelectElement, AudioSelectProps>(
  (props, ref) => {
    const { mutateAsync: playAudio } =
      audioFrontendSchema.playAudio.useCommand();

    return (
      <HStack>
        <Select {...props} ref={ref}>
          <option value="">Play nothing</option>
          <OptionSeparator />
          {audios.map((audio) => (
            <option value={audio} key={audio}>
              {audio}
            </option>
          ))}
        </Select>
        {props.value && (
          <Button
            onClick={() => playAudio({ name: props.value?.toString() ?? '' })}
          >
            <Text>Preview</Text>
          </Button>
        )}
      </HStack>
    );
  }
);
