/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ComingSoonBadge, MenuItemProperties } from '@time-neko/frontend/ui';
import { Asset } from '@time-neko/frontend/assets';

export const useStatisticsMenu = (): MenuItemProperties[] => [
  {
    renderAs: 'item',
    label: 'Statistics',
    id: 'statistics',
    cursor: 'not-allowed',
    icon: (
      <Asset
        opacity={0.4}
        name="Statistics"
        boxSize="sm"
        sx={{
          '& path, & rect': {
            fill: 'brand.iconPrimary',
          },
        }}
      />
    ),
    sx: {
      '&:hover': {
        background: 'transparent',
      },

      ' .text': {
        opacity: 0.4,
      },
    },
    rightAddon: <ComingSoonBadge ml={4} />,
  },
];
