/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Time } from '@time-neko/shared/time';
import { createChangedPayloadSchema } from '@time-neko/shared/common-types';
import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';
import { DateSchema } from '@time-neko/shared/date';

export const pomodoroModule = 'PomodoroModule' as const;

export enum PomodoroNextStateTrigger {
  Manual = 'Manual',
  Scheduled = 'Scheduled',
  NextStateNotification = 'NextStateNotification',
}

export enum PomodoroRestartReason {
  Manual = 'Manual',
  NewDay = 'NewDay',
}

export enum PomodoroStates {
  Work = 'Work',
  Break = 'Break',
  LongBreak = 'LongBreak',
}

export const MoveToNextStatePayloadSchema = z.object({
  trigger: z.nativeEnum(PomodoroNextStateTrigger).optional(),
});

export type MoveToNextStatePayload = z.infer<
  typeof MoveToNextStatePayloadSchema
>;

export const PomodoroStateSchema = z.object({
  shortBreakCount: z.coerce.number(),
  remainingSeconds: z.coerce.number(),
  state: z.nativeEnum(PomodoroStates),
  start: DateSchema,
  clockString: z.string(),
  remainingPercentage: z.number(),
  isRunning: z.boolean(),
  wasRunning: z.boolean().optional(),
  stateDurationSeconds: z.number(),
  nextState: z.nativeEnum(PomodoroStates),
});

export type PomodoroState = z.infer<typeof PomodoroStateSchema>;

export const PomodoroReminderSchema = z.object({
  id: z.string(),
  message: z
    .string()
    .max(300, 'Reminder message can have up to 300 characters')
    .min(1, 'At least one character is required'),
  showOnState: z.array(z.nativeEnum(PomodoroStates)),
  isTimeSensitive: z.boolean().default(false),
});

export type PomodoroReminder = z.infer<typeof PomodoroReminderSchema>;

const PomodoroDurationSchema = z.number().min(1).max(999999);
export const PomodoroSettingsSchema = z.object({
  workDurationSeconds: PomodoroDurationSchema,
  shortBreakDurationSeconds: PomodoroDurationSchema,
  longBreakDurationSeconds: PomodoroDurationSchema,
  autoRunBreak: z.boolean().optional(),
  autoRunWork: z.boolean().optional(),
  openFullWindowOnBreak: z.boolean().optional(),
  breakWindowAsOverlay: z.boolean().optional(),
  breakSound: z.string().optional(),
  workSound: z.string().optional(),
  longBreakSound: z.string().optional(),
  showOverlayBeforeBreak: z.boolean().optional(),
  showNotificationOnStateChange: z.boolean().optional(),
  longBreakInterval: z.coerce.number().int().min(0).max(10),
  dndOnBreak: z.boolean().optional(),
  reminders: z.array(PomodoroReminderSchema).optional(),
});

export type PomodoroSettings = z.infer<typeof PomodoroSettingsSchema>;

export const PomodoroStateChangedPayloadSchema = z.object({
  newState: z.nativeEnum(PomodoroStates),
  oldState: z.nativeEnum(PomodoroStates),
  nextState: z.nativeEnum(PomodoroStates),
  trigger: z.nativeEnum(PomodoroNextStateTrigger),
  state: PomodoroStateSchema,
  duration: z.instanceof(Time),
  start: z.date(),
  cycleRestarted: z.boolean().optional(),
});

export type PomodoroStateChangedPayload = z.infer<
  typeof PomodoroStateChangedPayloadSchema
>;

export const PomodoroRestartedPayloadSchema = z.object({
  reason: z.nativeEnum(PomodoroRestartReason),
});

export type PomodoroRestartedPayload = z.infer<
  typeof PomodoroRestartedPayloadSchema
>;

export enum PomodoroUpdatePriority {
  // Update happened inside pomodoro service (e.g. timer tick)
  Low = 'Low',

  // Update triggered by external sources (e.g. user interaction)
  Normal = 'Normal',
}

export enum PomodoroUpdateTrigger {
  Scheduled = 'Scheduled',
  Other = 'Other',
}

export const PomodoroFillOptionsSchema = z.object({
  priority: z.nativeEnum(PomodoroUpdatePriority).optional(),
  trigger: z.nativeEnum(PomodoroUpdateTrigger).optional(),
});

export type PomodoroFillOptions = z.infer<typeof PomodoroFillOptionsSchema>;

export const DurationSchema = z.object({
  time: z.instanceof(Time),
  seconds: z.number(),
});

export type Duration = z.infer<typeof DurationSchema>;

export const pomodoroSchema = defineSchema({
  queries: {
    getPomodoroState: operation.query.withResult(PomodoroStateSchema),
    wasBreakNotificationFlagTimeAdded: operation.query.withResult(z.boolean()),
  },
  commands: {
    updatePomodoro: operation.command
      .withPayload(PomodoroStateSchema.partial())
      .withResult(PomodoroStateSchema),
    restartPomodoro: operation.command.withResult(PomodoroStateSchema),
    stopPomodoroTimer: operation.command.withResult(z.void()),
    moveToNextPomodoroState: operation.command.withPayload(
      MoveToNextStatePayloadSchema.optional()
    ),
    flagBreakNotificationTimeAdd: operation.command,
  },
  events: {
    pomodoroStateChanged: operation.event.withPayload(
      PomodoroStateChangedPayloadSchema
    ),
    pomodoroUpdated: operation.event.withPayload(
      createChangedPayloadSchema(PomodoroStateSchema).extend({
        trigger: z.nativeEnum(PomodoroUpdateTrigger).optional(),
      })
    ),
    timerStarted: operation.event.withPayload(PomodoroStateSchema),
    timerStopped: operation.event.withPayload(PomodoroStateSchema),
    timerStartedOrStopped: operation.event.withPayload(PomodoroStateSchema),
    anyBreakStarted: operation.event.withPayload(
      PomodoroStateChangedPayloadSchema
    ),
  },
});
