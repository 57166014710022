/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { IconDefinition } from '.';
import { AssetName } from './assets';

export type AssetDefinitions = {
  [Key in AssetName]?: IconDefinition;
};

const dotProps: IconDefinition = {
  sizes: {
    sm: {
      width: '15px',
      height: '15px',
    },
  },
};

export const assetDefinitions: AssetDefinitions = {
  Arrow: {
    sizes: {
      md: {
        width: '21px',
        height: '21px',
      },
      sm: {
        width: '14px',
        height: '14px',
      },
    },
  },
  External: {
    sizes: {
      sm: {
        width: '14px',
        height: '14px',
      },
    },
  },
  Settings: {
    sizes: {
      sm: {
        width: '17px',
        height: '17px',
      },
    },
  },
  TrashCan: {
    sizes: {
      sm: {
        width: '16px',
        height: '17px',
      },
    },
  },
  Plus: {
    sizes: {
      sm: {
        width: '10px',
        height: '10px',
      },
    },
  },
  DotFilled: dotProps,
  DotEmpty: dotProps,
};
