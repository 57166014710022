/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export function documentHasFocus(withIframe = false): boolean {
  const documentHasFocus = document.hasFocus();

  if (!withIframe) {
    return documentHasFocus;
  }

  const iframes = Array.from(
    document.querySelectorAll<HTMLIFrameElement>('iframe')
  );

  return (
    documentHasFocus ||
    iframes.some((iframe) => {
      try {
        return iframe.contentWindow?.document?.hasFocus();
      } catch {
        return false;
      }
    })
  );
}
