/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import CatAndDesk from '../../assets/CatAndDesk.gif';
import { composeMedia } from '../composeMedia';

export const CatAndDeskIcon = composeMedia({
  assetMap: {
    dark: CatAndDesk,
    light: CatAndDesk,
  },
  type: 'img',
  alt: 'Cat and desk',
  className: 'cat-and-desk',
});
