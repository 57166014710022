/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  Box,
  Information,
  TabParams,
  Tabs,
  Text,
  VStack,
} from '@time-neko/frontend/ui';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { TasksList, TasksListProps } from '../tasksList/TasksList';
import { AddTaskInput } from '../addTaskInput/AddTaskInput';
import isEqual from 'lodash.isequal';
import {
  Task,
  tasksSchema,
  TaskState,
  taskStateDictionary,
} from '@time-neko/shared/domain/tasks';
import { ChakraProps } from '@chakra-ui/system';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { OperationSchemaOperations } from '@musubi/core';
import { frontendTasksSchema } from '@time-neko/frontend/domain/tasks/schema';
import {
  useGroupedTasksCount,
  useTaskMenu,
  useTasksList,
  useTasksSync,
} from '@time-neko/frontend/domain/tasks/hooks';
import { TasksListSideAction } from './parts/TasksListSideAction';
import { useBackgroundIntervalEffect } from '@time-neko/frontend/use-background-effect';
import { Asset } from '@time-neko/frontend/assets';
import { useEvent } from 'react-use';
import { WebTransition } from '@time-neko/frontend/web-transition';
import { Transition } from '@time-neko/frontend/providers/theme';

export interface TabbedTasksListProps {
  listProps?: Omit<TasksListProps, 'tasks'>;
  footer?: ReactNode;
  sx?: ChakraProps['sx'];
}

const states = Object.values(TaskState);

export function TabbedTasksList(props: TabbedTasksListProps) {
  const { count: tasksCount } = useGroupedTasksCount();
  const { tasks, isLoading, setTaskState, setTasks, isDragRef, updateTask } =
    useTasksList();

  const [activeIndex, setActiveIndex] = useState(0);

  const activeState = useMemo(() => states[activeIndex], [activeIndex]);

  const updateTasksMutation = frontendTasksSchema.updateTasks.useCommand({
    invalidateQueries: [
      ['getTasks' as OperationSchemaOperations<typeof tasksSchema>],
    ],
  });

  const { isAtLeastOneTaskProviderConfigured, isSyncing, sync, cancelSync } =
    useTasksSync();

  const { listProps } = props;

  const handleTaskChange = useCallback(
    async (task: Task) => {
      const index = tasks.findIndex(({ id }) => task.id === id);

      if (isEqual(task, tasks[index])) {
        return;
      }

      const newTasks = [...tasks];
      newTasks[index] = task;

      setTasks(newTasks);

      await updateTask(task);
    },
    [setTasks, tasks, updateTask]
  );

  const handleTaskDragging = useCallback(
    async (tasks: Task[]) => {
      isDragRef.current = true;

      setTasks(tasks);

      isDragRef.current = false;
    },
    [isDragRef, setTasks]
  );
  const handleDragEnd = useCallback(async () => {
    await updateTasksMutation.mutateAsync(tasks);
  }, [tasks, updateTasksMutation]);

  useEffect(() => {
    setTaskState(activeState);
  }, [activeState, setTaskState]);

  useBackgroundIntervalEffect(() => {
    void sync();
  }, 60_000);

  const tabs: TabParams[] = [
    {
      buttonProps: {
        className: `tabbed-task-state-${TaskState.Todo}`,
      },
      title: (
        <>
          <Text fontWeight="normal" mr={1}>
            {taskStateDictionary[TaskState.Todo]}
          </Text>
          <Text fontWeight="bold" ml={2}>
            {tasksCount?.[TaskState.Todo] ?? 0}
          </Text>
        </>
      ),
      key: TaskState.Todo,
      content: (
        <>
          <Box mb={tasks?.length ? 6 : 0}>
            <AddTaskInput />
          </Box>
          <TasksList
            showActiveTaskHighlight
            onListDragEnd={handleTaskDragging}
            emptyContent={
              <Information
                height="100%"
                mt={{
                  base: 6,
                  sm: 0,
                }}
                title="No tasks found"
                subTitle="Use input above to add new tasks"
              />
            }
            isLoading={isLoading}
            tasks={tasks}
            {...listProps}
            itemProps={{
              onTaskChange: handleTaskChange,
              menu: useTaskMenu,
              onTaskDrop: handleDragEnd,
              ...listProps?.itemProps,
            }}
          />
        </>
      ),
    },
    {
      buttonProps: {
        className: `tabbed-task-state-${TaskState.Completed}`,
      },
      title: (
        <>
          <Text fontWeight="normal" mr={1}>
            {taskStateDictionary[TaskState.Completed]}
          </Text>
          <Text fontWeight="bold" ml={2}>
            {tasksCount?.[TaskState.Completed] ?? 0}
          </Text>
        </>
      ),
      key: TaskState.Completed,
      content: (
        <TasksList
          emptyContent={
            <Information
              height="100%"
              mt={{
                base: 6,
                sm: 0,
              }}
              title="No completed tasks found"
              subTitle="Completed tasks will appear here"
            />
          }
          isDragDisabled
          isLoading={isLoading}
          tasks={tasks}
          {...listProps}
          itemProps={{
            onTaskChange: handleTaskChange,
            menu: useTaskMenu,
            ...listProps?.itemProps,
          }}
        />
      ),
    },
  ];

  useEvent(
    'visibilitychange',
    () => {
      if (document.visibilityState === 'visible') {
        void cancelSync();
      }
    },
    document
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <WebTransition<HTMLDivElement>
        in={isSyncing}
        timeout={500}
        transition={Transition.FadeInGrow}
        mountOnEnter
      >
        {(ref) => (
          <Box ref={ref}>
            <Box
              position="absolute"
              width="100%"
              height="100%"
              backgroundColor="brand.bgSecondary"
              zIndex={8}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <VStack spacing={6}>
                <Asset name="Sync" boxSize="lg" className="animation-rotate" />
                <Text>Syncing tasks, hang on...</Text>
              </VStack>
            </Box>
          </Box>
        )}
      </WebTransition>
      <Tabs
        footer={props.footer}
        afterTabButtonsAddon={
          <TasksListSideAction
            tasksLength={tasks.length}
            activeIndex={activeIndex}
            hideSync={!isAtLeastOneTaskProviderConfigured}
          />
        }
        onChange={setActiveIndex}
        p={2}
        sx={props.sx}
        tabs={tabs}
      />
    </DndProvider>
  );
}
