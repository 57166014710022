/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { HStack } from '@chakra-ui/react';
import { ChakraProps } from '@chakra-ui/system';
import { BottomNavigationItemProps } from './types';
import { BottomNavigationItem } from './BottomNavigationItem';

export interface BottomNavigationProps extends ChakraProps {
  className?: string;
  items: BottomNavigationItemProps[];
}

export function BottomNavigation({ items, ...props }: BottomNavigationProps) {
  return (
    <HStack
      py={4}
      alignItems="center"
      justifyContent="space-around"
      spacing={4}
      width="100%"
      {...props}
    >
      {items.map((item) => {
        return <BottomNavigationItem {...item} key={item.id} />;
      })}
    </HStack>
  );
}
