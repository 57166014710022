/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { settingsSchema } from '@time-neko/shared/domain/settings';
import { pomodoroSchema } from '@time-neko/shared/domain/pomodoro';
import { audioSchema } from '@time-neko/shared/domain/audio';
import { tasksSchema, TasksStorageData } from '@time-neko/shared/domain/tasks';
import { PomodoroStorageData } from '@time-neko/backend/domain/pomodoro';
import { BreakStorageData } from '@time-neko/backend/domain/break';
import { SettingsStorageData } from '@time-neko/backend/domain/settings';
import { NewDayDetectorStorageData } from '@time-neko/backend/domain/new-day-detector';
import { AutoUpdatesStorageData } from '@time-neko/backend/domain/updates';
import { NoticesStorageData } from '@time-neko/backend/domain/notices';
import { Storage } from '@time-neko/shared/domain/types';
import { TrackingStorageData } from '@time-neko/backend/domain/tracking';
import { defineSchema, mergeSchemas, operation } from '@musubi/core';
import { breakSchema } from '@time-neko/shared/domain/break';
import { newDaySchema } from '@time-neko/shared/domain/new-day-detector';
import { noticesSchema } from '@time-neko/shared/domain/notices';
import { updatesSchema } from '@time-neko/shared/domain/updates';
import { networkSchema } from '@time-neko/shared/domain/network';
import { integrationsSchema } from '@time-neko/shared/domain/integrations';
import { trelloSchema } from '@time-neko/shared/domain/integrations/trello';
import { statisticSchema } from '@time-neko/shared/domain/statistics';
import { feedbackSchema } from '@time-neko/shared/domain/feedback';
import { dndSchema } from '@time-neko/shared/domain/dnd';
import { trackingSchema } from '@time-neko/shared/domain/tracking';
import { z } from 'zod';
import { sitesBlockingSchema } from '@time-neko/shared/domain/sites-blocking';
import { apiSchema } from '@time-neko/shared/domain/api/types';
import { HttpClientStorageData } from '@time-neko/shared/http-client';
import { notionSchema } from '@time-neko/shared/domain/integrations/notion';
import { OnboardingStorageData } from '@time-neko/backend/domain/onboarding';
import { onboardingSchema } from '@time-neko/shared/domain/onboarding';
import { MigrationsStorage } from '@time-neko/backend/domain/migrations';

export interface StorageMigrationCtx<T extends CoreStoreData> {
  storage: Storage<T>;
  appVersion: string;
}

export type StorageMigrationFn<T extends CoreStoreData> = (
  ctx: StorageMigrationCtx<T>
) => Promise<void>;

export interface StorageMigration<T extends CoreStoreData = CoreStoreData> {
  name: string;
  migration: StorageMigrationFn<T>;
}

export const coreSchema = defineSchema({
  commands: {},
  queries: {
    isModuleActive: operation.query
      .withPayload(z.object({ name: z.string() }))
      .withResult(z.boolean()),
  },
  events: {},
});

export type CoreStoreData = HttpClientStorageData &
  PomodoroStorageData &
  TasksStorageData &
  BreakStorageData &
  SettingsStorageData &
  NewDayDetectorStorageData &
  AutoUpdatesStorageData &
  NoticesStorageData &
  MigrationsStorage &
  TrackingStorageData &
  OnboardingStorageData;

export const mergedCoreSchema = mergeSchemas(
  onboardingSchema,
  sitesBlockingSchema,
  coreSchema,
  audioSchema,
  breakSchema,
  pomodoroSchema,
  newDaySchema,
  noticesSchema,
  settingsSchema,
  updatesSchema,
  networkSchema,
  integrationsSchema,
  trelloSchema,
  notionSchema,
  tasksSchema,
  statisticSchema,
  feedbackSchema,
  dndSchema,
  trackingSchema,
  apiSchema
);
