/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

export function getIntEnv(key: string, def: number) {
  const env = getEnv();

  return env[key] ? parseInt(env[key] as string, 10) : def;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getEnv(): any {
  return typeof process === 'undefined' ? {} : process.env;
}

export function getBoolEnv(key: string, def: boolean) {
  const envValue = getEnv()?.[key];

  switch (envValue) {
    case 'true':
      return true;

    case 'false':
      return false;

    default:
      return def;
  }
}

export const isProduction = () => process.env.NODE_ENV === 'production';
