/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { castAsArray } from './array';

export const trimRight = (text: string, char: string | string[]) =>
  castAsArray(char).reduce((acc, curr) => {
    try {
      return acc.replace(new RegExp(`\\${curr}+$`), '');
    } catch {
      return acc;
    }
  }, text);
