/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren, useEffect, useState } from 'react';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { ReactRouterDomProvider } from '@time-neko/frontend/router/react-router-dom';
import { ThemeProvider } from '@time-neko/frontend/providers/theme';
import { DialogProvider } from '@time-neko/frontend/providers/dialog';
import { useMount } from 'react-use';
import { registerWorker, workerError$ } from '../workerSetup';
import {
  Button,
  CenterContainer,
  Information,
  Text,
} from '@time-neko/frontend/ui';
import { WebAppDomainProvider } from './WebAppDomain.provider';
import {
  getWebAppBroadcastChannel,
  mergedWebSchema,
  webSchema,
} from '@time-neko/shared/web-app';
import {
  ExponentialBackoff,
  handleAll,
  retry,
  timeout,
  TimeoutStrategy,
} from 'cockatiel';
import { DownloadButtons } from '../components/DownloadButtons';
import { MusubiClient } from '@musubi/core';
import { MusubiProvider } from '@musubi/react';
import { createBroadcastChannelLink } from '@musubi/broadcast-channel-link';
import { CatErrorIcon } from '@time-neko/frontend/assets';
import { FrontendIntegrationsDefinitionsProvider } from '@time-neko/frontend/domain/integrations/providers';
import { FrontendIntegrationSectionDefinition } from '@time-neko/frontend/domain/integrations/web';
import { preventSleepOnPomodoro } from '@time-neko/shared/domain/pomodoro';
import { WebPowerSaveBlocker } from '../adapters/WebPowerSaveBlocker';
import {
  getUserFriendlyError,
  StorageQuotaExceededError,
} from '@time-neko/shared/errors';
import { isError } from '@time-neko/shared/type-guards';
import { logger } from '@time-neko/shared/logger';
import { Splash } from '../components/Splash';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: 'always',
      networkMode: 'offlineFirst',
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      logger.error(`Query ${query.queryKey[0]} error:`, {
        error,
        query,
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, context, mutation) => {
      logger.error(`Mutation ${mutation.mutationId} error:`, {
        error,
        variables,
        context,
        mutation,
      });
    },
  }),
});

const link = createBroadcastChannelLink(getWebAppBroadcastChannel());

const definitions: FrontendIntegrationSectionDefinition[] = [];

const powerSaveBlocker = new WebPowerSaveBlocker();

export function WebAppProvider({ children }: PropsWithChildren) {
  // TODO - Show random loading text
  const [client, setClient] = useState(
    new MusubiClient(mergedWebSchema, [link.client])
  );
  const [isReady, setIsReady] = useState(false);
  const [isWorkerSupported, setIsWorkerSupported] = useState<boolean>(true);
  const [workerError, setWorkerError] = useState<Error | null>(null);

  useEffect(() => {
    const sub = preventSleepOnPomodoro({
      localClient: client,
      powerSaveBlocker,
    });

    return () => {
      sub?.unsubscribe();
    };
  }, [client]);

  useMount(() => {
    registerWorker(client)
      .then(async (result) => {
        if (result) {
          const newLink = createBroadcastChannelLink(result.channel);
          const newClient = new MusubiClient(mergedWebSchema, [newLink.client]);

          const policy = retry(handleAll, {
            backoff: new ExponentialBackoff({
              maxDelay: 1000,
              initialDelay: 250,
            }),
            maxAttempts: 5,
          });

          const timeoutPolicy = timeout(5000, TimeoutStrategy.Aggressive);

          const state = await policy.execute(async () => {
            const c = newClient as unknown as MusubiClient<typeof webSchema>;

            const response = await timeoutPolicy.execute(() =>
              c.query('getWorkerState')
            );

            logger.debug('worker ready response', response);

            if (!response.isReady) {
              throw new Error('Worker is not ready');
            }

            return response;
          });

          if (state?.isReady) {
            setClient(newClient);

            window.dispatchEvent(new Event('webAppReady', { bubbles: true }));

            Object.assign(window, {
              webAppReady: true,
            });

            if (process.env.NX_EXPOSE_WORKER_ON_WINDOW === 'true') {
              Object.assign(window, {
                client: newClient,
              });
            }

            setIsReady(true);
          }
        } else {
          setIsWorkerSupported(false);
        }
      })
      .catch((error) => {
        logger.error('Failed to register worker', error);

        setIsWorkerSupported(false);
      });
  });

  useEffect(() => {
    const sub = workerError$.subscribe(setWorkerError);

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <BrowserRouter>
      <ReactRouterDomProvider>
        <ThemeProvider applyWindowsClipFix>
          {workerError && (
            <CenterContainer>
              <Information
                title="Worker error"
                subTitle={getUserFriendlyError(workerError)}
                asset={<CatErrorIcon width={100} />}
                action={
                  isError(workerError, StorageQuotaExceededError) ? (
                    <Button
                      colorScheme="danger"
                      onClick={async () => {
                        const indb =
                          indexedDB ||
                          (window as any).webkitIndexedDB ||
                          (window as any).mozIndexedDB ||
                          (window as any).msIndexedDB;

                        const dbs = await indb.databases();

                        for (const db of dbs) {
                          await new Promise((resolve, reject) => {
                            const request = indb.deleteDatabase(db.name as any);

                            request.onerror = reject;

                            request.onsuccess = resolve;
                          });
                        }

                        window.location.reload();
                      }}
                    >
                      <Text>Clear storage</Text>
                    </Button>
                  ) : undefined
                }
              />
            </CenterContainer>
          )}
          {!isWorkerSupported && !workerError && (
            <CenterContainer>
              <Information
                asset={<CatErrorIcon width={100} />}
                action={<DownloadButtons mt="6 !important" />}
                title="Not supported"
                subTitle="Sorry, it looks like our app won't work in your browser."
              />
            </CenterContainer>
          )}
          <WebAppDomainProvider>
            {isWorkerSupported && (
              <>
                <Splash isAbsolute={isReady} />
                {isReady && (
                  <MusubiProvider queryClient={queryClient} client={client}>
                    <FrontendIntegrationsDefinitionsProvider
                      definitions={definitions}
                    >
                      <DialogProvider>{children}</DialogProvider>
                    </FrontendIntegrationsDefinitionsProvider>
                  </MusubiProvider>
                )}
              </>
            )}
          </WebAppDomainProvider>
        </ThemeProvider>
      </ReactRouterDomProvider>
    </BrowserRouter>
  );
}
