/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Ref } from 'react';

export function setRefValue<T>(ref: Ref<T | undefined>, value?: T) {
  if (!ref) {
    return;
  }

  if (typeof ref === 'function') {
    ref(value);
  }

  if (typeof ref === 'object') {
    Object.assign(ref, {
      current: value,
    });
  }
}
