/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import * as ReactDOM from 'react-dom/client';
import { WebAppProvider } from './providers/WebApp.provider';
import { App } from './App';
import React, { Fragment, StrictMode } from 'react';
import './index.css';
import { isProduction } from '@time-neko/shared/utils';

if (process.env.NX_ENABLE_WDYR === 'true') {
  void import('@welldone-software/why-did-you-render').then((mod) => {
    mod.default(React, {
      trackAllPureComponents: true,
    });
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const Wrapper = isProduction() ? StrictMode : Fragment;

root.render(
  <Wrapper>
    <WebAppProvider>
      <App />
    </WebAppProvider>
  </Wrapper>
);

document.addEventListener('touchmove', (event) => {
  event.preventDefault();
});
