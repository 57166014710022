/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { UseFormReturn } from 'react-hook-form';
import { SettingsFieldRenderer } from './SettingsFieldRenderer';
import { SettingsFields } from '@time-neko/frontend/domain/settings/field-definitions';
import { SettingsFieldRendererProps } from '../SettingsForm.types';

export interface SettingFieldsRendererProps<Fields extends SettingsFields[]>
  extends Pick<
    SettingsFieldRendererProps,
    'customRenderer' | 'skippedFieldNames'
  > {
  form: UseFormReturn;
  fields: Fields;
}

export const SettingFieldsRenderer = <Fields extends SettingsFields[]>({
  form,
  fields,
  customRenderer,
  skippedFieldNames,
}: SettingFieldsRendererProps<Fields>) => {
  return (
    <>
      {fields.map(
        (field, index) =>
          Boolean(field) && (
            <SettingsFieldRenderer
              skippedFieldNames={skippedFieldNames}
              key={'name' in field ? field.name : index}
              form={form}
              field={field}
              customRenderer={customRenderer}
            />
          )
      )}
    </>
  );
};
