/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Task } from '@time-neko/shared/domain/tasks';

export const taskItemName = 'task';

export interface TaskDragPayload {
  task: Task;
  element?: HTMLElement;
}
