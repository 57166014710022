/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useNavigate } from '@time-neko/frontend/router';
import { appRoutes } from '@time-neko/shared/routes';
import {
  DomainNotification,
  DomainProvider,
} from '@time-neko/frontend/providers/domain';
import { AppPlatform } from '@time-neko/shared/domain/types';
import { areWebNotificationsSupported } from '@time-neko/frontend/support';

function useWebAppNotifications() {
  const [notificationPermission, setNotificationPermission] = useState(
    areWebNotificationsSupported() ? Notification.permission : 'default'
  );

  return useMemo<DomainNotification | undefined>(() => {
    if (!areWebNotificationsSupported()) {
      return undefined;
    }

    let permission: DomainNotification['permissionState'];

    if (!notificationPermission) {
      permission = 'loading';
    } else {
      permission = notificationPermission;
    }

    return {
      permissionState: permission,
      requestPermission: async () => {
        if (typeof Notification === 'undefined') {
          return false;
        }

        const result = await Notification.requestPermission().catch(
          () => 'denied'
        );

        setNotificationPermission(result as typeof Notification.permission);

        return result === 'granted';
      },
    };
  }, [notificationPermission]);
}

export function WebAppDomainProvider({ children }: PropsWithChildren<unknown>) {
  const notifications = useWebAppNotifications();

  const navigate = useNavigate();

  const openSettings = useCallback(() => {
    navigate(appRoutes.settings());
  }, [navigate]);

  const openFeedback = useCallback(() => {
    navigate(appRoutes.feedback());
  }, [navigate]);

  return (
    <DomainProvider
      platform={AppPlatform.Webapp}
      openSettings={openSettings}
      openFeedbackForm={openFeedback}
      appName="Time Neko"
      version={process.env.NX_APP_VERSION}
      notifications={notifications}
    >
      {children}
    </DomainProvider>
  );
}
