/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ChangeEventHandler, forwardRef, HTMLProps, useState } from 'react';
import { Text } from '../text/Text';
import classNames from 'classnames';
import { useTheme } from '@time-neko/frontend/providers/theme';
import { chakra, ChakraProps } from '@chakra-ui/system';
import { Asset } from '@time-neko/frontend/assets';
import { useColorMode } from '@chakra-ui/react';

export interface RadioProps
  extends Omit<HTMLProps<HTMLInputElement>, 'checked' | 'type' | 'onChange'> {
  isChecked?: boolean;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  labelProps?: ChakraProps;
  isDisabled?: boolean;
}

const Label = chakra.label;

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ isChecked, className, isDisabled, label, labelProps, ...props }, ref) => {
    const { colorMode } = useColorMode();

    const theme = useTheme();

    const [isFocused, setIsIsFocused] = useState(false);

    return (
      <Label
        {...labelProps}
        display="flex"
        onFocus={() => setIsIsFocused(true)}
        onBlur={() => setIsIsFocused(false)}
        alignItems="center"
        sx={{
          'span::before': {
            display: 'none',
          },

          cursor: theme.cursors.pointer,
        }}
      >
        <input
          disabled={isDisabled}
          ref={ref}
          type="radio"
          className={classNames(className, 'nes-radio', {
            'is-dark': colorMode === 'dark',
          })}
          checked={isChecked}
          {...props}
        />
        <Asset
          containerProps={{
            pointerEvents: 'none',
          }}
          opacity={isChecked ? undefined : 0}
          className={classNames({ 'animation-blink': isFocused })}
          name="CatPointing"
          boxSize="22px"
          mr={1}
        />
        <Text as="span">{label}</Text>
      </Label>
    );
  }
);
