/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import sample from 'lodash.sample';
import shuffle from 'lodash.shuffle';

export function castAsArray<T>(item: T | T[] | Set<T>): T[] {
  if (item instanceof Set) {
    return Array.from(item);
  }

  return Array.isArray(item) ? item : [item];
}

export const uniqueArray = <T>(array: T[]): T[] => Array.from(new Set(array));

export const isLast = <T>(array: T[], index: number): boolean =>
  index === array.length - 1;

export function randomArrayElement<T>(
  array: ReadonlyArray<T>,
  isValid: (result: T) => boolean
): T | undefined;
export function randomArrayElement<T>(array: ReadonlyArray<T>): T;
export function randomArrayElement<T>(
  array: ReadonlyArray<T>,
  isValid?: (result: T) => boolean
): T | undefined {
  if (!isValid) {
    return sample(array);
  }

  for (const item of shuffle(array)) {
    if (isValid(item)) {
      return item;
    }
  }

  return undefined;
}
