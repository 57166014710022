/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { HStack, Text, TextProps } from '@time-neko/frontend/ui';
import {
  PomodoroState,
  pomodoroStateDictionary,
  PomodoroStates,
} from '@time-neko/shared/domain/pomodoro';

import { ChakraProps } from '@chakra-ui/system';
import { ReactNode } from 'react';

export interface PomodoroStateTextProps extends ChakraProps {
  pomodoro?: PomodoroState;
  isEdit?: boolean;
  hidePomodoroStateOnEdit?: boolean;
  hideStateText?: boolean;
  textProps?: TextProps;
  rightAddon?: ReactNode;
}

const shouldHide = ({
  isEdit,
  hidePomodoroStateOnEdit,
  hideStateText,
}: Pick<
  PomodoroStateTextProps,
  'hideStateText' | 'isEdit' | 'hidePomodoroStateOnEdit'
>) => hideStateText || (isEdit && hidePomodoroStateOnEdit);

export function PomodoroStateText({
  pomodoro,
  isEdit,
  hideStateText,
  hidePomodoroStateOnEdit,
  textProps,
  rightAddon,
  ...props
}: PomodoroStateTextProps) {
  if (shouldHide({ hidePomodoroStateOnEdit, hideStateText, isEdit })) {
    return null;
  }

  return (
    <HStack
      alignItems="center"
      className="pomodoro-state-text-container no-drag"
      justifyContent="space-between"
      {...props}
    >
      <Text
        whiteSpace="nowrap"
        className="pomodoro-state-text"
        color="white"
        {...textProps}
      >
        {pomodoroStateDictionary[pomodoro?.state ?? PomodoroStates.Work]}
      </Text>
      {rightAddon}
    </HStack>
  );
}

PomodoroStateText.shouldHide = shouldHide;
