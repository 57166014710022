/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useQueryParam } from '@time-neko/frontend/query-params';
import { previewQueryKey } from '@time-neko/shared/web-app';

export function useIsPreview() {
  const [param] = useQueryParam<'0' | '1'>(previewQueryKey, '0');

  return param === '1';
}
