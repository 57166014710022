/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */
import { frontendPomodoroSchema } from '@time-neko/frontend/domain/pomodoro/schema';
import { usePomodoro } from './usePomodoro';
import { usePomodoroNextState } from './usePomodoroNextState';
import { useKeyboardShortcutHandler } from '@time-neko/frontend/keyboard-shortcuts';

export function usePomodoroShortcuts() {
  const resetPomodoroMutation =
    frontendPomodoroSchema.restartPomodoro.useCommand();
  const stopTimerMutation =
    frontendPomodoroSchema.stopPomodoroTimer.useCommand();

  const { toggle } = usePomodoro();
  const { moveToNextStateMutation } = usePomodoroNextState();

  useKeyboardShortcutHandler(
    'togglePomodoro',
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      void toggle();
    },
    [toggle]
  );

  useKeyboardShortcutHandler(
    'restartPomodoro',
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      resetPomodoroMutation.mutate(undefined);
    },
    [resetPomodoroMutation]
  );

  useKeyboardShortcutHandler('moveToNextPomodoroState', (event) => {
    event.preventDefault();
    event.stopPropagation();

    moveToNextStateMutation.mutate(undefined);
  });

  useKeyboardShortcutHandler('stopTimer', (event) => {
    event.preventDefault();
    event.stopPropagation();

    stopTimerMutation.mutate(undefined);
  });
}
