/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { BottomNavigationItemProps } from './types';
import classNames from 'classnames';
import { Button, Text } from '../../atoms';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from '@time-neko/frontend/router';

export function BottomNavigationItem(props: BottomNavigationItemProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = useMemo(() => {
    if (!props.isLink) {
      return props.isActive;
    }

    return location.pathname === props.href;
  }, [location.pathname, props]);

  const handleClick = useCallback(() => {
    if (props.isLink) {
      navigate(props.href);

      return;
    }

    props.onClick();
  }, [navigate, props]);

  return (
    <Button
      className={classNames({ 'is-active': isActive })}
      variant="nes-ghost"
      onClick={handleClick}
      height="100%"
      aria-label={props['aria-label']}
      display="inline-flex"
      flexDirection="column"
      alignItems="center"
      key={props.id}
      sx={{
        '& .button-content': {
          flexDirection: 'column',
        },

        ...(isActive ? {} : props.notActiveSx),

        '&.is-active *': {
          fill: 'brand.secondary !important',
          color: 'brand.secondary',
          ...props.activeSx,
        },
      }}
    >
      {props.icon}
      {props.title && <Text>{props.title}</Text>}
    </Button>
  );
}
