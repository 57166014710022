/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { useRouterContext } from '../Router.provider';
import { Location } from '../types';

export const useLocation = <State>() =>
  useRouterContext((store) => store.location) as Location<State>;
