/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */
/// <reference types="dom-screen-wake-lock" />

import { PowerSaveBlocker } from '@time-neko/shared/domain/types';
import { logger } from '@time-neko/shared/logger';

export class WebPowerSaveBlocker implements PowerSaveBlocker {
  private lock?: WakeLockSentinel;

  private readonly logger = logger.getChild('WebPowerSaveBlocker');

  async start() {
    if ('wakeLock' in navigator) {
      try {
        this.lock = await navigator.wakeLock.request('screen');

        this.logger.debug('Wake lock is active', this.lock);
      } catch (e) {
        this.logger.error('Failed to request wake lock', e);
      }
    }
  }

  async stop() {
    if ('wakeLock' in navigator && this.lock) {
      try {
        if (!this.lock.released) {
          await this.lock.release();
        }

        this.logger.debug('Wake lock is released', this.lock);
      } catch (e) {
        this.logger.error('Failed to release wake lock', e);
      } finally {
        this.lock = undefined;
      }
    }
  }
}
