/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  PomodoroTimerBox,
  PomodoroTimerBoxProps,
  StopTimerBtn,
} from '@time-neko/frontend/domain/pomodoro/web';
import {
  AppView,
  CenterContainer,
  CenterContainerProps,
  Loading,
  TitleBar,
  TitleBarProps,
  useColorMode,
  useControllableState,
} from '@time-neko/frontend/ui';
import { ComponentType, useCallback, useMemo } from 'react';
import { useTasksListeners } from '@time-neko/frontend/domain/tasks/hooks';
import { useSettingsListener } from '@time-neko/frontend/domain/settings/hooks';
import classNames from 'classnames';
import {
  PomodoroTasksList,
  PomodoroTasksListProps,
} from './parts/PomodoroTasksList';
import {
  usePomodoro,
  usePomodoroListeners,
} from '@time-neko/frontend/domain/pomodoro/hooks';
import {
  PomodoroDropdownMenu,
  PomodoroDropdownMenuProps,
} from './parts/PomodoroDropdownMenu';
import { placement } from './const';
import { getPlatformFromNavigator } from '@time-neko/frontend/common';

export interface CorePomodoroViewProps
  extends Omit<PomodoroDropdownMenuProps, 'placement'> {
  pomodoroTimerBoxProps?: Pick<
    PomodoroTimerBoxProps,
    'additionalControls' | 'backgroundProps'
  >;
  containerProps?: CenterContainerProps;
  hideTasksList?: boolean;
  tasksListProps?: PomodoroTasksListProps;
  TitleBarComponent?: ComponentType<TitleBarProps>;
}

export function CorePomodoroView({
  pomodoroTimerBoxProps,
  containerProps,
  dropdownMenuProps,
  hideTasksList,
  tasksListProps,
  TitleBarComponent = TitleBar,
}: CorePomodoroViewProps) {
  usePomodoroListeners();
  useTasksListeners();
  useSettingsListener();

  const platform = useMemo(() => getPlatformFromNavigator(), []);

  const [menuOpen, setMenuOpen] = useControllableState({
    defaultValue: false,
    value: dropdownMenuProps.menuProps?.isOpen,
    onChange: dropdownMenuProps.menuProps?.onOpenChange,
  });

  const handleClose = useCallback(() => {
    setMenuOpen(false);
    dropdownMenuProps.menuProps?.onClose?.();
  }, [dropdownMenuProps.menuProps, setMenuOpen]);
  const handleOpen = useCallback(() => {
    setMenuOpen(true);
    dropdownMenuProps.menuProps?.onOpen?.();
  }, [dropdownMenuProps.menuProps, setMenuOpen]);

  const { pomodoro, loading } = usePomodoro();

  const { colorMode } = useColorMode();

  if (!pomodoro || loading) {
    return (
      <CenterContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Loading boxSize={5} />
      </CenterContainer>
    );
  }

  const dropdownMenu = (
    <PomodoroDropdownMenu
      onOpen={handleOpen}
      onClose={handleClose}
      isOpen={menuOpen}
      placement={placement}
      dropdownMenuProps={{
        ...dropdownMenuProps,
        menuButtonProps: {
          ...dropdownMenuProps.menuButtonProps,
          sx: {
            ...dropdownMenuProps.menuButtonProps?.sx,
          },
        },
      }}
    />
  );

  return (
    <AppView
      gap={0}
      width="100%"
      height="100%"
      backgroundColor="brand.bg"
      zIndex={1}
      TitleBar={TitleBarComponent}
      position="absolute"
      contentsContainerProps={{
        overflow: 'hidden',
      }}
      titleBarProps={{
        height: 'auto',
        isMinimizable: false,
        additionalWindowControlsStartContent:
          platform !== 'darwin' && dropdownMenu,
        oppositeWindowControlsContent: (
          <>
            <StopTimerBtn variant="nes-ghost" />
            {platform === 'darwin' && dropdownMenu}
          </>
        ),
      }}
    >
      <CenterContainer
        position="relative"
        className={classNames('pomodoro-view', `pomodoro-view-${colorMode}`, {
          'menu-is-open': menuOpen,
        })}
        id="timer"
        {...containerProps}
        sx={{
          ...containerProps?.sx,

          '.app-menu-btn': {
            // Overwrite default dropdown menu position
            position: 'relative !important',
            top: 0.5,
          },
        }}
      >
        {pomodoro && (
          <>
            <PomodoroTimerBox
              hidePomodoroStateOnEdit
              containerProps={{
                width: '100%',
                pt: 5,
                pb: 5,
              }}
              {...pomodoroTimerBoxProps}
            />
            {!hideTasksList && <PomodoroTasksList {...tasksListProps} />}
          </>
        )}
      </CenterContainer>
    </AppView>
  );
}
