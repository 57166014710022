/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { chakra, ChakraProps } from '@chakra-ui/system';
import classNames from 'classnames';

export interface ProgressBarProps extends ChakraProps {
  value: number;
  max: number;
  className?: string;
}

const Progress = chakra('progress');

export const ProgressBar = (props: ProgressBarProps) => {
  return (
    <Progress
      {...props}
      className={classNames(props.className, 'nes-progress')}
    />
  );
};
