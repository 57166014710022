/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { Stack } from '@time-neko/frontend/ui';
import {
  IntegrationSection,
  IntegrationSectionComponentProps,
} from './IntegrationSection';
import { memo, useCallback } from 'react';
import { IntegrationType } from '@time-neko/shared/domain/integrations';
import { useNavigate } from '@time-neko/frontend/router';
import { FrontendIntegrationSectionDefinition } from '../types';
import { RouteReturnType } from '@time-neko/shared/routes';

export interface IntegrationsFormProps
  extends Pick<Partial<IntegrationSectionComponentProps>, 'onOpen'> {
  definitions: FrontendIntegrationSectionDefinition[];
}

export const IntegrationsForm = memo(
  ({ definitions, onOpen, ...props }: IntegrationsFormProps) => {
    const navigate = useNavigate();

    const handleOpen = useCallback(
      async (token: string, type: IntegrationType) => {
        if (onOpen) {
          onOpen(token, type);

          return;
        }

        const definition = definitions.find((d) => d.type === type);

        if (definition?.managePath) {
          navigate(definition.managePath(RouteReturnType.FullPath));
        }
      },
      [definitions, navigate, onOpen]
    );

    return (
      <Stack spacing={6} width="100%">
        {definitions.map((definition) => (
          <IntegrationSection
            key={definition.label}
            definition={definition}
            onOpen={handleOpen}
            {...props}
          />
        ))}
      </Stack>
    );
  }
);
