/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ReactNode } from 'react';
import { MenuItemProperties } from '../../shared/menu.types';
import { Box, MenuList, MenuListProps } from '@chakra-ui/react';
import { MenuItemRenderer } from './MenuItemRenderer';
import { AbsoluteBackground } from '../../atoms';
import { ChakraProps } from '@chakra-ui/system';
import { useMenuItemsState } from './MenuItemsState.provider';

export interface MenuItemsRendererProps {
  items: MenuItemProperties[];
  menuListProps?: MenuListProps;
  menuToolbar?: ReactNode;
  menuFooter?: ReactNode;
  scrollContainerProps?: ChakraProps;
  background?: string;
}

export function MenuItemsRenderer({
  items,
  menuListProps,
  menuToolbar,
  menuFooter,
  scrollContainerProps,
  background = 'brand.bgSecondary',
}: MenuItemsRendererProps) {
  const isHighlight = useMenuItemsState((store) =>
    Boolean(store.highlightedItemId)
  );

  return (
    <MenuList {...menuListProps} bg={'transparent !important'}>
      <AbsoluteBackground
        background={isHighlight ? 'transparent' : background}
        userSelect="none"
        pointerEvents="none"
      />
      <Box opacity={isHighlight ? 0 : 1} width="100%">
        {menuToolbar}
      </Box>

      <Box
        position="relative"
        zIndex={2}
        className="menu-scroll-container"
        overflow="auto"
        maxHeight="calc(100vh - 60px)"
        {...scrollContainerProps}
      >
        {items.map((item) => (
          <MenuItemRenderer
            item={item}
            key={item.id}
            highlightBackground={background}
          />
        ))}
      </Box>
      {menuFooter}
    </MenuList>
  );
}
