/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { isSSR } from '@time-neko/frontend/ssr';
import { useColorMode } from '@chakra-ui/react';
import { usePrefersColorMode } from './hooks/usePrefersColorMode';
import { useMount } from 'react-use';

export function ColorModeController() {
  const colorMorePreference = usePrefersColorMode();
  const { setColorMode } = useColorMode();

  useMount(() => {
    if (!isSSR()) {
      setColorMode(colorMorePreference);
    }
  });

  return null;
}
