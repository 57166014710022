/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { useCallback } from 'react';
import { tasksSchema } from '@time-neko/shared/domain/tasks';
import { OperationSchemaOperations } from '@musubi/core';
import { frontendTasksSchema } from '@time-neko/frontend/domain/tasks/schema';

export function useTasksSync() {
  const isAtLeastOneTaskProviderConfiguredQuery =
    frontendTasksSchema.isAtLeastOneTaskProviderConfigured.useQuery({
      refetchOnWindowFocus: 'always',
    });
  const isAtLeastOneTaskProviderConfigured =
    isAtLeastOneTaskProviderConfiguredQuery.data === true;

  const isSyncingQuery = frontendTasksSchema.areTasksSyncingWithApis.useQuery();

  const syncMutation = frontendTasksSchema.syncTasksWithApis.useCommand({
    invalidateQueries: [
      ['getTasks' as OperationSchemaOperations<typeof tasksSchema>],
      ['countTasksByState' as OperationSchemaOperations<typeof tasksSchema>],
      [
        'areTasksSyncingWithApis' as OperationSchemaOperations<
          typeof tasksSchema
        >,
      ],
    ],
  });

  const cancelSyncMutation = frontendTasksSchema.cancelTasksSync.useCommand({
    onSuccess: () => {
      isSyncingQuery.setQueryData({
        isSyncing: false,
      });
    },
  });

  frontendTasksSchema.tasksSyncStarted.useEvent(() => {
    isSyncingQuery.setQueryData({ isSyncing: true });
  }, [isSyncingQuery.setQueryData]);

  frontendTasksSchema.tasksSyncFinished.useEvent(() => {
    isSyncingQuery.setQueryData({ isSyncing: false });
  }, [isSyncingQuery.setQueryData]);

  frontendTasksSchema.tasksSyncFailed.useEvent(() => {
    isSyncingQuery.setQueryData({ isSyncing: false });
  }, [isSyncingQuery.setQueryData]);

  return {
    isSyncing: Boolean(isSyncingQuery.data?.isSyncing),
    cancelSync: () => cancelSyncMutation.mutateAsync(undefined),
    sync: useCallback(async () => {
      await syncMutation.mutateAsync({
        cancelOngoingSync: false,
      });
    }, [syncMutation]),
    error: syncMutation.error,
    isAtLeastOneTaskProviderConfigured,
  };
}
