/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import {
  Box,
  Tooltip as BaseTooltip,
  TooltipProps as BaseProps,
  useColorMode,
  useConst,
} from '@chakra-ui/react';
import classNames from 'classnames';
import { ChakraProps } from '@chakra-ui/system';
import { isTouchDevice } from '@time-neko/frontend/touch-device';
import { useIsSmallMobile } from '@time-neko/frontend/responsive';

export interface TooltipProps extends BaseProps {
  wrapperProps?: ChakraProps;
}

export function Tooltip({
  shouldWrapChildren = true,
  wrapperProps,
  ...props
}: TooltipProps) {
  const isTouch = useConst(isTouchDevice());
  const isMobile = useIsSmallMobile();

  const colorMode = useColorMode();

  const children = shouldWrapChildren ? (
    <Box as="span" display="inline-block" width="inherit" {...wrapperProps}>
      {props.children}
    </Box>
  ) : (
    props.children
  );

  return (
    <BaseTooltip
      isDisabled={isTouch || isMobile}
      maxWidth="inherit"
      {...props}
      className={classNames(props.className, 'tooltip', {
        'is-dark': colorMode.colorMode === 'dark',
        'nes-balloon': props.variant === 'nes-balloon' || !props.variant,
      })}
    >
      {children}
    </BaseTooltip>
  );
}
