/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { DomainContextValue, InternalDomainContextValue } from './types';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import createCtx from 'zustand/context';
import create, { StoreApi } from 'zustand';
import { useConst } from '@chakra-ui/hooks';

const { useStore, Provider } =
  createCtx<StoreApi<InternalDomainContextValue>>();

export const useDomain = <Key extends keyof DomainContextValue>(key: Key) =>
  useStore((store) => store[key]);

export function DomainProvider(props: PropsWithChildren<DomainContextValue>) {
  const value = useMemo<DomainContextValue>(
    () => ({
      ...props,
      appName: props.appName ?? 'Time Neko',
    }),
    [props]
  );

  const store = useConst(() =>
    create<InternalDomainContextValue>((set) => ({
      ...value,
      notifications: value.notifications
        ? {
            ...value.notifications,
            setPermissionGranted: (permissionGranted) =>
              set((state) => ({
                notifications: {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  ...state.notifications!,
                  permissionGranted,
                  permissionState: permissionGranted ? 'granted' : 'denied',
                },
              })),
          }
        : undefined,
    }))
  );

  useEffect(() => {
    store.setState((store) => ({
      ...store,
      ...value,
      notifications: store.notifications
        ? {
            ...store.notifications,
            ...value.notifications,
          }
        : undefined,
    }));
  }, [store, value]);

  useEffect(() => {
    store.setState((prev) => ({
      ...prev,
      notifications: prev.notifications
        ? {
            ...prev.notifications,
            permissionState: props.notifications?.permissionState,
          }
        : undefined,
    }));
  }, [props.notifications, store]);

  return <Provider createStore={() => store}>{props.children}</Provider>;
}
