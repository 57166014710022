/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2023
 */

import { OnboardingStepProps } from './types';
import { useCallback, useState } from 'react';
import { features } from './features';
import {
  Balloon,
  Button,
  Frame,
  Heading,
  HStack,
  IconButton,
  Text,
  VStack,
} from '@time-neko/frontend/ui';
import { Asset, CatIdleIcon } from '@time-neko/frontend/assets';
import { isLast } from '@time-neko/shared/utils';

export function OnboardingFeatures({ onNext, onSkip }: OnboardingStepProps) {
  const [featureIndex, setFeatureIndex] = useState(0);
  const feature = features[featureIndex];

  const handleNext = useCallback(() => {
    const nextIndex = featureIndex + 1;
    const nextFeature = features[nextIndex];

    if (nextFeature) {
      setFeatureIndex(nextIndex);

      return;
    }

    onNext?.();
  }, [onNext, featureIndex]);

  return (
    <VStack width="100%" height="100%" spacing={6}>
      <Heading key={featureIndex} className="animation-fade-in" fontSize="xl">
        <Text>{feature.title}</Text>
      </Heading>
      <VStack spacing={6} flex={1} overflow="hidden">
        <Frame key={featureIndex} className="animation-fade-in" overflow="auto">
          {feature.image}
        </Frame>
        <HStack flex={1} alignItems="flex-end">
          <CatIdleIcon />
          <Balloon p={2} balloonPartPlacement="left">
            {feature.description}

            <HStack width="100%" justifyContent="center" spacing={0} mt={2}>
              {features.map((_, index) => (
                <IconButton
                  boxSize="sm"
                  aria-label="Change page"
                  variant="nes-ghost"
                  key={index}
                  onClick={() => setFeatureIndex(index)}
                >
                  <Asset
                    boxSize="sm"
                    name={index === featureIndex ? 'DotFilled' : 'DotEmpty'}
                  />
                </IconButton>
              ))}
            </HStack>

            <HStack mt={2} spacing={4} justifyContent="center">
              <Button onClick={handleNext} colorScheme="primary">
                <Text>{isLast(features, featureIndex) ? 'Start' : 'Next'}</Text>
              </Button>
              <Button onClick={onSkip}>Skip</Button>
            </HStack>
          </Balloon>
        </HStack>
      </VStack>
    </VStack>
  );
}
