/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { ColorSchemeType } from '../themeParts/colors';
import { useTheme } from '../Theme.provider';

export function useColorSchemeVariant(colorScheme?: ColorSchemeType) {
  const theme = useTheme();

  return colorScheme ? theme.createColorScheme(colorScheme).styles : {};
}
