/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { EditablePreviewHeading } from './EditablePreviewHeading';
import { Editable, EditableProps } from '@time-neko/frontend/ui';
import { ChakraProps } from '@chakra-ui/system';
import { forwardRef } from 'react';
import { PomodoroState } from '@time-neko/shared/domain/pomodoro';

export type TimerEditableProps = Pick<
  EditableProps,
  | 'value'
  | 'onSubmit'
  | 'onChange'
  | 'onEditChange'
  | 'isDisabled'
  | 'isEdit'
  | 'displayValue'
  | 'formatValueOnChange'
>;

export interface TimerProps
  extends ChakraProps,
    Pick<PomodoroState, 'clockString' | 'remainingSeconds'> {
  isEdit: boolean;
  editableProps: Partial<TimerEditableProps> & ChakraProps;
}

const Heading = forwardRef<
  HTMLDivElement,
  ChakraProps & { remainingSeconds: number; clockString: string }
>(({ clockString, remainingSeconds, ...props }, ref) => {
  return (
    <EditablePreviewHeading
      key="heading"
      remainingSeconds={remainingSeconds}
      clockString={clockString}
      ref={ref}
      {...props}
    />
  );
});

export function Timer({
  clockString,
  editableProps,
  remainingSeconds,
}: TimerProps) {
  return (
    <Editable
      key="timer"
      maxWidth="300px"
      fontSize="2xl"
      className="remaining-time"
      {...editableProps}
      onSubmit={editableProps.onSubmit}
      ContentComponent={Heading}
      contentComponentProps={{
        remainingSeconds,
        clockString,
      }}
      inputProps={{
        height: 'xl',
        lineHeight: '30px',
        overflow: 'hidden',
        minHeight: 'auto',
        width: '100%',
        fontSize: '2xl',
      }}
      shouldHandleOutsideClick={(event) => {
        const target = event.target as HTMLElement;

        return !target.closest('.timer-edit-btn');
      }}
    />
  );
}
