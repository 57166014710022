/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { AppPlatform } from '@time-neko/shared/domain/types';
import { RouteParams } from './types';
import { z } from 'zod';

export const DownloadRouteParamsSchema = z.object({
  platform: z.nativeEnum(AppPlatform),
});

export type DownloadRouteParams = z.infer<typeof DownloadRouteParamsSchema> &
  RouteParams;

export const DownloadPageQueryParamsSchema = z.object({
  platform: z.nativeEnum(AppPlatform).optional(),
  version: z.string().optional(),
  isUpdateError: z
    .string()
    .transform((v) => v === '1')
    .optional(),
});

export type DownloadsPageQueryParams = z.infer<
  typeof DownloadPageQueryParamsSchema
>;

export interface RawDownloadsPageQueryParams
  extends Omit<DownloadsPageQueryParams, 'isUpdateError'> {
  isUpdateError?: '0' | '1';
}
