/*
 * Copyright (C) Przemysław Żydek - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Przemysław Żydek <przemyslawzydek@gmail.com>, 2022
 */

import { AppPlatform } from '@time-neko/shared/domain/types';
import { PomodoroStateSchema } from '@time-neko/shared/domain/pomodoro';
import { z } from 'zod';
import { defineSchema, operation } from '@musubi/core';

export const TrackerDataSchema = z.record(z.string(), z.any());

export type TrackerData = z.infer<typeof TrackerDataSchema>;

export interface Tracker {
  track: (event: string, data?: TrackerData) => void;

  register?: (superProperties: TrackerData) => void;

  loginUser?: (userId: string) => void;

  setUserProperties?: (obj: TrackerData) => void;

  registerOnce?: (superProperties: TrackerData) => void;

  setDistinctId?: (id: string) => void;
}

export const TrackPayloadSchema = z.object({
  event: z.string(),
  data: TrackerDataSchema.optional(),
});

export type TrackPayload = z.infer<typeof TrackPayloadSchema>;

export const RootTrackingDataSchema = z.object({
  userId: z.string(),
  lastVisitDate: z.string().datetime(),
});

export type RootTrackingData = z.infer<typeof RootTrackingDataSchema>;

export const TrackingDataSchema = RootTrackingDataSchema.extend({
  pomodoroState: PomodoroStateSchema,
  todoTasksCount: z.number(),
  platform: z.nativeEnum(AppPlatform),
  appVersion: z.string(),
  distinct_id: z.string(),
});

export type TrackingData = z.infer<typeof TrackingDataSchema>;

export const PrivacySettingsSchema = z.object({
  trackingEnabled: z.boolean().optional(),
});

export type PrivacySettings = z.infer<typeof PrivacySettingsSchema>;

export const trackingSchema = defineSchema({
  commands: {},
  queries: {
    getTrackingData: operation.query.withResult(TrackingDataSchema),
  },
  events: {},
});
